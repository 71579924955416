import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-wharehouse-order-sale-searial',
  templateUrl: './wharehouse-order-sale-searial.component.html',
  styleUrls: ['./wharehouse-order-sale-searial.component.scss']
})
export class WharehouseOrderSaleSearialComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang = JSON.parse(<any>localStorage.getItem('lang'));
  public dir = JSON.parse(<any>localStorage.getItem('dir'));
  public server: any = this.serverService.get_server();
  public loading = false;
  public user_id: number | undefined;
  public title: string | undefined;
  public order_id: number;
  public document_type_text: string;
  public subscription: Subscription;
  public list_record: any = [];
  public order_buy_id: number;
  public list_searial: any = [];
  public list_select_items: any = [];
  public list_select_items_length: number = 0;
  public list_selected_searial: any = [];
  public searial_used: any = [];
  public index: number;
  public order_number: number = 0;
  public readonly: boolean = false;

  constructor(
    private serverService: ServerService
    , public router: Router
    , public messageService: MessageService
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public matDialogRef: MatDialogRef<WharehouseOrderSaleSearialComponent>
    , public matSnackBar: MatSnackBar
    ,) {
    if (dialog_data) {
      if (dialog_data.readonly) this.readonly = dialog_data.readonly;
      this.order_id = dialog_data.id;
      this.order_buy_id = dialog_data.order_buy_id;
      this.title = dialog_data.title;
      this.document_type_text = dialog_data.document_type_text;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (this.document_type_text == 'input') {
      this.get_searial(this.order_id);
    } else if (this.document_type_text == 'exit' && this.order_buy_id > 0) {
      this.get_data();
    }
  }



  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6678, order_id: this.order_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.order_number = res['result'][0].wharehouse_order_number;
            this.list_selected_searial = res['result'][0].wharehouse_order_searial;
            this.list_select_items = res['result'][0].wharehouse_order_searial;
          }
          this.get_searial2(this.order_buy_id);
          this.get_consumeed_searial();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  get_searial2(id: number) {
    var selected_array: any = [];
    if (this.list_selected_searial) {
      selected_array = this.list_selected_searial.split(",").map((items) => {
        return parseInt(items);
      });
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6673, order_id: id }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (selected_array.indexOf(res['result'][i].wharehouse_order_sale_searial_id) != -1) {
              res['result'][i].checked = true;
            }
            this.list_record.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  select_items(type: number) {
    this.list_select_items = [];
    var list_select_items_temp = <any>document.getElementsByName("searial[]");
    for (var i = 0; i < list_select_items_temp.length; i++) {
      if (list_select_items_temp[i].checked == true) {
        this.list_select_items.push(list_select_items_temp[i].value);
      }
      this.list_select_items = this.list_select_items.map(function (items) {
        return parseInt(items);
      })
    }
    if (type == 2) {
      this.update2();
    }
    //this.list_select_items_length = this.list_select_items.length;
  }

  update2() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (this.list_select_items.length > this.order_number) {
      var message = "تعداد سریالهای انتخابی نباید بیشتر از تعداد فروش باشد";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      address: 6679
      , "user_id": this.user_id
      , 'order_id': this.order_id
      , 'select_items': this.list_select_items
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ result: res['result'][0] });
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update2
  //********************************************************* */
  get_searial(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6673, order_id: id }).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6674
      , "order_id": this.order_id
      , "user_id": this.user_id
    };
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.list_record.push(res['result'][0]);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var message = " تعداد رکوردهای سریال نمی تواند بیشتر از تعداد خرید باشد.";
          this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }//end if 
    )
  }//end insert

  update(i: number, id: number, event: any) {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6675
      , "user_id": this.user_id
      , 'id': id
      , 'order_id': this.order_id
      , 'value': event.target.value
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          this.index = i;
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update

  delete(i: number, id: any) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 6676, "user_id": this.user_id, 'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(i, 1);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.message_action(this.lang));
          }
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.message_action(this.lang));
          }//end else
        }//end res:ResponseQ
      )//end this.subscriptionفاهس
    }//end this.confirm_delete == true      
  }//end delete 
  //********************************************************************************************************** */
  get_consumeed_searial() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6680
      , order_buy_id: this.order_buy_id
      , id: this.order_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            if (res['result'][0].wharehouse_order_searial) {
              this.searial_used = res['result'][0].wharehouse_order_searial.split(",").map((items: any) => {
                return parseInt(items)
              });
            }
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //********************************************************************************************************** */
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}