<form [formGroup]="form1_group">

    <div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
        <span>
            <span>{{ serverService.get_title(type_task,lang) }}</span>
        </span>
        <span (click)="close()">
            <mat-icon class="close">close</mat-icon>
        </span>
        <div class="video_div1" (click)="open_video()" *ngIf="video" style="float: left;">
            <mat-icon class="video">smart_display</mat-icon>
        </div>
        <input type="color" formControlName="color" class="cursor color">
    </div>


    <div mat-dialog-content class="matDialogContent">

        <div class="row" *ngIf="type_task == 1">
            <div
                class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe reception-detaile-button1 fleft reception-detaile-button2_en div_panding div_panding3">
                <span> !Stay tuned </span>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe reception-detaile-button1 reception-detaile-button2 div_panding"
                style="padding-top: 0px;"
                [ngClass]="{'fleft':dir == 'ltr','reception-detaile-button2_en' : dir == 'ltr'}">
                <div [ngClass]="{'fleft':dir == 'ltr' }">
                    <img src="../../../../../assets/images/rstatus1.png" class="calen" style="float: right;">
                </div>
                <div class="notice-new">
                    <div [ngClass]="{'en':dir == 'ltr' }">
                        <span [ngClass]="{'en':dir == 'ltr' }"> {{
                            today_repairman_reception_default |
                            number }} </span>
                        <span *ngIf="lang == 1"> دستگاه در صف </span>
                        <span *ngIf="lang == 2"> Device in line </span>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe reception-detaile-button1 reception-detaile-button2 div_panding"
                style="padding-top: 0px;"
                [ngClass]="{'fleft':dir == 'ltr','reception-detaile-button2_en' : dir == 'ltr'}">
                <div>
                    <img src="../../../../../assets/images/rstatus2.png" class="calen" style="float: right;"
                        [ngClass]="{'fleft':dir == 'ltr' }">
                </div>
                <div class="notice-new">
                    <div [ngClass]="{'en':dir == 'ltr' }">
                        <span [ngClass]="{'en':dir == 'ltr' }" style="margin:0 0.2rem"> {{
                            repairman_rstatus_repairing |
                            number }} </span>
                        <span> Under repair </span>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12  reception-detaile-button1 reception-detaile-button2 div_panding"
                style="padding-top: 0px;"
                [ngClass]="{'fleft':dir == 'ltr','reception-detaile-button2_en' : dir == 'ltr'}">
                <div>
                    <img src="../../../../../assets/images/reception.png" class="calen" style="float: right;"
                        [ngClass]="{'fleft':dir == 'ltr' }">
                </div>
                <div class="notice-new">
                    <div>
                        <span> {{
                            reception_id |
                            number }} </span>
                        <span> Ticket ID </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pe1">
            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding full-left'
                [ngClass]="{width50:type_task == 3}" [hidden]="type_task != 3">
                <label for="creator" class='wh-label'>
                    <span>Ticket Registrar</span>
                    <span (click)="reset_input(220,1)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="creator_title" (click)="load_services(220,1) " readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="creator" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}" [hidden]="type_task != 3">
                <label for="model" class='wh-label'>
                    <span *ngIf="lang == 1"> شماره پذیرش</span>
                    <span *ngIf="lang == 2">Ticket no</span>
                    <input class="wh-input" formControlName="reception_id" [readonly]="type_task == 1"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}" [hidden]="type_task == 3">
                <label for="reception_date" class="wh-label">
                    <span>Ticket Date</span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" formControlName="reception_date" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}" [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}"
                            [required]="required()">
                    </div>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="type" class='wh-label'>
                    <span *ngIf="lang == 1"> نوع پذیرش </span>
                    <span *ngIf="lang == 2"> Ticket Type </span>
                    <span (click)="reset_input(260,0)" [hidden]="readonly == true" [hidden]="true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="type_title" (click)="load_services(260,0)" readonly
                        [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="type" [hidden]="true" readonly [required]="required()">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="customer" class='wh-label'>
                    <span>Name and Surname</span>
                    <span (click)="add_user()" [hidden]="readonly == true || type_task == 3">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_customer()" [hidden]="readonly == true">
                        <mat-icon style="text-align: left;" class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class='wh-input' formControlName="customer_title" (click)="open_user()" autocomplete="off"
                        [required]="required()" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class='wh-input' formControlName="customer" autocomplete="off" [hidden]="true"
                        [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="device" class='wh-label'>
                    <span *ngIf="lang == 1"> دستگاه</span>
                    <span *ngIf="lang == 2">Device</span>
                    <span (click)="add_device()" [hidden]="readonly == true || type_task == 3">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(154,0)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="device_title" (click)="load_services(154,0)" readonly
                        [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="device" [hidden]="true" readonly [required]="required()">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="brand" class='wh-label'>
                    <span *ngIf="lang == 1"> برند</span>
                    <span *ngIf="lang == 2">Brand</span>
                    <span (click)="add_brand()" [hidden]="readonly == true || type_task == 3">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(164,0)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="brand_title" (click)="load_services(164,0) " readonly
                        [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="brand" [hidden]="true" readonly [required]="required()">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="model" class='wh-label'>
                    <span *ngIf="lang == 1"> مدل</span>
                    <span *ngIf="lang == 2">Model</span>
                    <span (click)="add_model()" [hidden]="readonly == true  || type_task == 3">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>

                    <span (click)="reset_input(163,0)" [hidden]="readonly == true">
                        <mat-icon style="text-align: left;" class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>

                    <input class="wh-input font-en" formControlName="model_title" (click)="load_services(163,0) "
                        readonly [required]="required()" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="model" [hidden]="true" readonly [required]="required()">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="model" class='wh-label'>
                    <span *ngIf="lang == 1"> سریال / پلاک</span>
                    <span *ngIf="lang == 2">Serial No</span>
                    <input class="wh-input font-en" formControlName="searial" (change)='change_letter()'
                        (keyup)="change_letter()" [readonly]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="model" class='wh-label'>
                    <span *ngIf="lang == 1"> نمایش در پنل</span>
                    <span *ngIf="lang == 2">Select Technicians</span>
                    <span (click)="reset_input(220,2)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:dir=='ltr'}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="open_select_select() " readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="repairman" readonly [hidden]="true">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="model" class='wh-label'>
                    <span *ngIf="lang == 1"> شماره قفسه </span>
                    <span *ngIf="lang == 2">Shelf no</span>
                    <span (click)="reset_input(173,0)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="shelf_title" (click)="load_services(173,0) " readonly
                        [attr.disabled]="rstatus == 7 ? true : null" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="shelf" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="model" class='wh-label'>
                    <span *ngIf="lang == 1"> هزینه تقریبی</span>
                    <span *ngIf="lang == 2">Approximate cost</span>
                    <input class="wh-input" formControlName="approximate" currencyMask [readonly]="readonly"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="estimated_time" class="wh-label">
                    <span *ngIf="lang == 1"> موعد تقریبی تحویل </span>
                    <span *ngIf="lang == 2">Estimated delivery date</span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" formControlName="estimated_time" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                    </div>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}">
                <label for="model" class='wh-label'>
                    <span *ngIf="lang == 1"> پسورد : </span>
                    <span *ngIf="lang == 2">Device Password</span>
                    <input class="wh-input" formControlName="password" [readonly]="readonly"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}"
                [hidden]="true">
                <label for="model" class='wh-label'>
                    <span *ngIf="lang == 1"> پترن</span>
                    <span *ngIf="lang == 2">Pattern</span>
                    <input class="wh-input" formControlName="pattern" readonly [readonly]="readonly"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}" [hidden]="type_task != 3"> <label
                    for="issue_date" class="wh-label">
                    <span>Date from</span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" formControlName="date1" autocomplete="off">
                    </div>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}" [hidden]="type_task != 3"> <label
                    for="issue_date" class="wh-label">
                    <span>Date to</span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" formControlName="date2" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                    </div>
                </label>
            </div>

            <div class='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir=='ltr',width50:type_task == 3}" [hidden]="true">
                <label for="model" class='wh-label' style="padding-top:0.8rem">
                    <div *ngIf="lang == 1"> تحویل سریع</div>
                    <span *ngIf="lang == 2">Quick delivery</span>
                    <mat-checkbox formControlName="urgent" [disabled]="readonly"></mat-checkbox>
                </label>
            </div>


        </div>

        <div class="row reception-div3" style="border-top: solid 1px #ccc;padding-top:1rem"
            [hidden]="type_task==1 || type_task == 3">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir=='ltr'}">
                <span>Please specify ticket status:</span>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding">
            </div>
            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe1 div_panding div_status'
                [ngClass]="{'full-left':dir=='ltr'}" *ngFor="let x of list_rstatus;let i = index"
                style="float: right;min-height:10rem">
                <div class="status_header">
                    <span *ngIf="lang == 1">مرحله</span>
                    <span *ngIf="lang == 2">Step</span>
                    <span [ngClass]="{'en':dir == 'ltr'}"> {{ i + 1 }} </span>
                </div>
                <mat-radio-group aria-label="Select an option" name="status" formControlName="status">
                    <mat-radio-button class="reception-status" [value]="x.repairman_rstatus_id" [disabled]="readonly"
                        style="width:100%" (click)="change_rstatus(x.repairman_rstatus_repairing)">
                        <span class="status_span_label">{{x.repairman_rstatus_title }}</span>
                    </mat-radio-button>

                    <div *ngFor="let y of list_rstatus2" style="width:100%">
                        <mat-radio-button *ngIf="( x.repairman_rstatus_repairing == 1 && y.repairman_rstatus_repairing == 1 ) || 
                            (x.repairman_rstatus_sub_ready_delivery == 1 && y.repairman_rstatus_sub_ready_delivery == 1) || 
                            (x.repairman_rstatus_deliverd == 1 && y.repairman_rstatus_deliverd == 1)
                            " class="reception-status" [value]="y.repairman_rstatus_id" [disabled]="readonly"
                            (click)="change_rstatus(x.repairman_rstatus_repairing)">
                            <span class="status_span_label">{{y.repairman_rstatus_title }}</span>
                        </mat-radio-button>
                    </div>
                </mat-radio-group>
            </div>
        </div>
        <!-- ****************************************************************************************************** -->
        <div class="row" style="border-bottom: solid 1px #ccc;border-top: solid 1px #ccc;" *ngIf=" type_task != 3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 reception_div1 " *ngIf=" type_task != 3">
                <span>
                    <div class="row">
                        <div class="pe1 div_panding" [ngClass]="{'full-left' : dir == 'ltr'}">
                            <span class="fright">
                                <span *ngIf="lang == 1">ایرادات</span>
                                <span *ngIf="lang == 2">Problems</span>
                                :</span>
                            <span class="cursor search-icon">
                                <mat-icon class="material-symbols-outlined" (click)="open_objections()">
                                    saved_search
                                </mat-icon>
                            </span>
                            <span (click)="add_objections()" class="add_icon add-icon-objections">
                                <i class="fas fa-bookmark cursor add-icon-objections-i"></i>
                            </span>
                        </div>
                    </div>

                    <div class="row" *ngIf="list_objections.length > 0">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fright wh-label objections-title"
                            *ngFor="let x of list_objections" [ngClass]="{'full-left' : dir == 'ltr'}">
                            <span class="cursor trash" (click)="delete_objections(x.repairman_objections2_id)">
                                <i class="far fa-trash-alt"></i>
                            </span>
                            {{ x.repairman_objections2_title }}
                        </div>
                    </div>
                </span>
            </div><!-- col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 -->
            <!-- ****************************************************************************************************** -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 reception_div1 " *ngIf=" type_task != 3">
                <span>
                    <div class="row">
                        <div class="pe1 div_panding" [ngClass]="{'full-left' : dir == 'ltr'}">
                            <span class="fright">
                                <span *ngIf="lang == 1">متعلقات</span>
                                <span *ngIf="lang == 2">Accessories</span>
                                :</span>
                            <span class="cursor search-icon">
                                <mat-icon class="material-symbols-outlined" (click)="open_belong()">
                                    saved_search
                                </mat-icon>
                            </span>
                            <span (click)="add_belong()" class="add_icon add-icon-belong">
                                <i class="fas fa-bookmark cursor add-icon-belong-i"></i>
                            </span>
                        </div>
                    </div>

                    <div class="row" *ngIf="list_belong.length > 0">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 fright wh-label belong-title"
                            [ngClass]="{'full-left' : dir == 'ltr'}" *ngFor="let x of list_belong">
                            <span class="cursor trash" (click)="delete_belong(x.repairman_belong2_id)">
                                <i class="far fa-trash-alt"></i>
                            </span>
                            {{ x.repairman_belong2_title }}
                        </div>
                    </div>
                </span>
            </div><!-- col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 -->
        </div><!-- row -->
        <!-- *********************************************************************************************************** -->
        <div class="row" [hidden]="type_task == 3">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات تعمیرکار - مشتری این توضیحات را نمی بیند : </span>
                    <span *ngIf="lang == 2"> Repairman’s description – client can’t see this section: </span>
                    <textarea class='wh-input' style="height:5rem" formControlName="comment" autocomplete="off"
                        [readonly]="readonly" [ngClass]="{'en':dir == 'ltr'}"></textarea>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات برای مشتری - مشتری این توضیحات را خواهد دید : </span>
                    <span *ngIf="lang == 2"> Descriptions for client - client can see this section :
                    </span>
                    <textarea class='wh-input' style="height:5rem;background-color: #F1DCDC;" formControlName="comment2"
                        autocomplete="off" [readonly]="readonly" [ngClass]="{'en':dir == 'ltr'}"></textarea>
                </label>
            </div>
        </div>
        <!-- ******************************************************************************************************** -->
        <div class="row" [hidden]="type_task == 3">
            <div class="row file">
                <div class="row" style="height:5px;padding: 0px !important;">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" style="padding: 0px !important;"
                        [hidden]="!loading_file">
                        <mat-progress-bar mode="indeterminate" class="detaile">
                        </mat-progress-bar>
                    </div>
                </div>


                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pe1  dropzone_div_2"
                    [ngClass]="{dropzone_div:count_count_list_load == 0}" style="text-align:center;padding:0px">
                    <form [dropzone]="config" class="dropzone dz-started cursor" id="my-awesome-dropzone"
                        (success)="onUploadSuccess($event)">
                        <input type="" name="code" value="{{ id }}" style="display:none">
                        <input type="" name="user_id" value="{{ user_id }}" style="display:none">
                        <input type="" name="type" value="{{ 2 }}" style="display:none">
                        <input type="" name="site" value="{{ site }}" style="display:none">
                        <input type="" name="token" value="{{ token }}" style="display:none">
                        <div class="dz-preview dz-processing dz-image-preview dz-success dz-complete box box-right deopzone"
                            *ngFor="let file of list_load_file;let i = index" id="fileInput"
                            [ngClass]="{'full-left':dir == 'ltr'}">
                            <div class="dz-image" style="border:solid 1px #ccc">
                                <a href='{{ file.reception_items_attach_site }}/{{ file.reception_items_attach_name }}'
                                    target="_blank">
                                    <span
                                        *ngIf="file.reception_items_attach_type == 'image/jpeg' || file.reception_items_attach_type == 'image/gif' ||  file.reception_items_attach_type == 'image/png' ">
                                        <img data-dz-thumbnail="" alt="{{ file.reception_items_attach_title }}"
                                            src="{{ file.reception_items_attach_site }}/{{ file.reception_items_attach_name }}">
                                    </span>
                                    <span
                                        *ngIf="file.reception_items_attach_type == 'application/msword' || file.reception_items_attach_type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ">
                                        <i class="far fa-file-word icone"></i>
                                    </span>
                                    <span
                                        *ngIf="file.reception_items_attach_type == 'application/vnd.ms-excel' || file.reception_items_attach_type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ">
                                        <i class="fas fa-file-excel icone" style='color:green'></i>
                                    </span>
                                    <span *ngIf="file.reception_items_attach_type == 'application/pdf' ">
                                        <i class="far fa-file-pdf icone" style='color:red'></i>
                                    </span>
                                    <span *ngIf="file.reception_items_attach_type == 'application/zip' ">
                                        <i class="fas fa-file-archive icone"></i>
                                    </span>
                                    <span
                                        *ngIf="file.reception_items_attach_type == 'video/mp4' || file.reception_items_attach_type == 'video/x-matroska';else other ">
                                        <i class="fas fa-file-video icone" style='color:black'></i>
                                    </span>
                                    <ng-template #other>
                                        <i class="fas fa-file-alt icone" style='color:black'></i>
                                    </ng-template>
                                </a>
                            </div><!-- dz-image -->
                            <div class="dz-details">
                                <div style="display:inline;float:left">
                                    <span (click)="delete_file(i,file.reception_items_attach_id)"
                                        *ngIf="file.reception_items_attach_request_status == request_status_number">
                                        <i class="material-icons icon" style='font-size:1.2rem'>delete_forever</i>
                                    </span>
                                    <span (click)="detaile_file(i,file.reception_items_attach_id)"
                                        *ngIf="file.reception_items_attach_request_status == request_status_number">
                                        <i class="material-icons icon" style='font-size:1.2rem'>video_label</i>
                                    </span>
                                </div>
                                <div class="dz-size">
                                    <span data-dz-size=""><strong [ngClass]="{'en':dir == 'ltr'}">{{
                                            file.reception_items_attach_size }}</strong>
                                        KB</span>
                                </div><!-- dz-size-->
                                <div class="dz-filename">
                                    <span data-dz-name="" class="pe1" [ngClass]="{'en':dir == 'ltr'}">{{
                                        file.reception_items_attach_title | substr:0:10
                                        }}</span>
                                </div><!-- dz-filename-->
                            </div> <!-- dz-detaile-->
                            <div class="dz-progress">
                                <span class="dz-upload" data-dz-uploadprogress="" style="width: 100%;"></span>
                            </div><!-- dz-progress -->
                            <div class="dz-error-message">
                                <span data-dz-errormessage=""></span>
                            </div><!-- dz-error-message -->
                        </div><!-- dz-preview dz-processing dz-image-preview dz-success dz-complete -->
                    </form><!-- dropzone dz-started -->
                </div><!-- col-md-12 -->
            </div><!-- row file -->
        </div>
        <!-- ******************************************************************************************************** -->
    </div><!-- content -->

    <div mat-dialog-actions class="matDialogActions">
        <div>
            <button type="button" [disabled]="!form1_group.valid || loading == true" [hidden]="readonly" class="btn_pe"
                [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 1 || type_task == 2" (click)="save('close')">
                <span *ngIf="loading == false">
                    <span *ngIf="lang == 1">ذخیره و بستن</span>
                    <span *ngIf="lang == 2">Save and close</span>
                </span>
                <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status" *ngIf="loading == true">
                    <span class="visually-hidden"></span>
                </div>
            </button>
            <button type="button" [disabled]="!form1_group.valid || loading == true" [hidden]="readonly" class="btn_pe"
                [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 1 || type_task == 2" (click)="save('print')">
                <span *ngIf="loading == false">
                    <span *ngIf="lang == 1">ذخیره و چاپ</span>
                    <span *ngIf="lang == 2">Save and print</span>
                </span>
                <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status" *ngIf="loading == true">
                    <span class="visually-hidden"></span>
                </div>
            </button>

            <button type="button" [disabled]="!form1_group.valid" [hidden]="readonly || true" class="btn_pe"
                [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 1" (click)="save('continue')">
                <span *ngIf="lang == 2">Save and new</span>
                <span *ngIf="lang == 1">ذخیره و جدید</span>
            </button>
            <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3"
                (click)="check_access(type_task,1)">
                <span *ngIf="lang == 2">Filter</span>
                <span *ngIf="lang == 1">فیلتر</span>
            </button>
        </div>

        <div style="padding-right:1rem;padding-top:0.2rem" *ngIf="type_task != 1"
            [ngClass]="{'full-left':dir == 'ltr'}">
            <mat-radio-group aria-label="Select an option" formControlName="device_status">
                <div class="device_status_text">
                    <span *ngIf="lang == 1">وضعیت دستگاه </span>
                    <span *ngIf="lang == 2"> Device status </span>
                    :
                </div>
                <mat-radio-button class="status_title_items reception-status" *ngFor="let x of list_device_status"
                    [value]="x.repairman_device_status_id">
                    <span class="status_span_label">{{ x.repairman_device_status_title }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </div>


    </div>
</form>