<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span>Device delivery code</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="row delivery-row1">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 left fleft">
            Name and Surname
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 left">
            {{ customer_title }}
        </div>
    </div>

    <div class="row delivery-row1">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 left fleft">
            Device
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 left">
            {{ device }}
        </div>
    </div>

    <div class="row delivery-row1">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 left fleft">
            Status
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 left">
            {{ rstatus_title }}
        </div>
    </div>

    <div class="row delivery-row1">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 center" style="padding:1rem">
            <button class="btn_pe delivery-button1" (click)="check_charge_sms(1)" style="width:15rem">
                Send device delivery code
            </button>
        </div>
    </div>


    <div class="row">
        <form [formGroup]="form1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' [ngClass]="{en:lang==2}">
                <label for="brand" class='wh-label'>
                    <input class="wh-input" formControlName="code" style="text-align: center !important"
                        (keypress)="serverService.get_number($event)"
                        placeholder="Please enter the code read by the customer here...">
                </label>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 center">
                <button class="btn_pe delivery-button1" (click)="check_code()" style="width:15rem">
                    Verification of received code
                </button>
            </div>
        </form>
    </div>
    <div class="row" style="padding-top:2rem;font-size: 0.85rem;">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 left fleft"
            style="color:#888;padding:0px;direction: ltr;">
            If the received code is correct, the device will be transferred to the delivered state and the customer will
            be notified of the delivery of the device via SMS.
        </div>
    </div>

</div>