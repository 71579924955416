<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>Bank Report</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="content-body control-content-body">
            <mat-table [dataSource]="dataSource" class='matTable table-scroll en11'>

                <ng-container matColumnDef="check">
                    <mat-header-cell *matHeaderCellDef style="max-width:50px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:50px">
                        <mat-radio-group aria-label="Select an option" name="account" formControlName="account"
                            (click)="get_bg(x.id)">
                            <mat-radio-button [value]="x.id"></mat-radio-button>
                        </mat-radio-group>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Account Number</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;">
                        <div class="left">{{ x.title }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_branch_number">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Account Holder name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;">{{
                        x.site_bank_accounts_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_bank_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Bank name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;">{{
                        x.site_bank_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="account_branch_name">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Branch name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" (click)="select(x.id,x.title)" class="cursor"
                        style="text-align: center;">{{
                        x.site_bank_accounts_branch_title }}</mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
                <mat-row (click)="mat_table_selectedRow = row" *matRowDef="let x;let i = index;columns:displayedColumns"
                    (keyup.enter)="select(x.id,x.title)" [ngClass]="{mat_table_selected:row_id == x.id}"></mat-row>
            </mat-table>
        </div>

        <div class="row pe1">
            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                <label for="issue_date" class="wh-label">
                    <span> Date from </span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" formControlName="date3" autocomplete="off">
                    </div>
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                <label for="issue_date" class="wh-label">
                    <span> Date to </span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" formControlName="date4" autocomplete="off">
                    </div>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="button" class="btn" (click)="search()">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>