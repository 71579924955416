<div class="container-fluit" (dblclick)="get_show_language()">
  <div class="row login-row1">
    <div class="col-xl-6 xol-lg-6 col-md-6 col-sm-6 col-xs-12 login-div-6">
      <div class="left-panel"></div>
    </div>
    <div class="col-xl-6 xol-lg-6 col-md-6 col-sm-6 col-xs-12 login-div-6">
      <div class="right-panel">
        <div class="panel-header">
          <div class="logo">
            <!-- <img class="logo-src" alt="logo"> -->
          </div>
        </div>
        <div class="right-panel-content" [hidden]="true">
          <div class="suggestion">
            <div class="instagram">
              برای اطلاع از اخبار آپدیت های نرم افزار در اینستاگرام ما را دنبال کنید.
            </div>
            <div class="phone" (click)="get_today_order2()">
              پشتیبانی : 09370640060
            </div>
            <div class="olgu">
              <span style="padding-left: 1rem;"> کاربر تست : olgu</span>
              <span>رمز عبور : 123456789</span>
            </div>
          </div>
        </div>
        <div class="right-panel-footer">
          <div class="copyright-text">

          </div>
        </div>
      </div>
    </div>

    <div class="center-panel">
      <div class="div_loading" [hidden]="!loading">
        <div class="my-container">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>

      <div style="padding:0 16px 0 22px" [hidden]="validation_cellphone == true">
        <h3 class="title">
          <span><img src="../../../../assets/images/finty-logo2.png" style="width:12rem"></span>
        </h3>
        <form [formGroup]="form1" (ngSubmit)="login(1)">
          <label for="title" class='wh-label'>
            <div class="login-label" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
              <span *ngIf="lang == 1">نام کاربری</span>
              <span *ngIf="lang == 2">Username</span>
            </div>
            <input class="wh-input" formControlName="username" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
          </label>

          <label for="password" class='wh-label password'>
            <div class="login-label" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
              <span *ngIf="lang == 1"> رمز عبور </span>
              <span *ngIf="lang == 2"> Password </span>
            </div>
            <input type="password" class="wh-input" formControlName="password"
              [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
          </label>
          <!-- [hidden]="server != 'https://server.finty.co'" -->
          <label for="language" class='wh-label password'
            *ngIf="server == 'https://server.finty.ir' && show_language == true ">
            <div class="login-label" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
              <span *ngIf="lang == 1"> زبان </span>
              <span *ngIf="lang == 2"> Language </span>
            </div>
            <select class="wh-input" formControlName="language" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}"
              (change)="change_language()">
              <option [selected]="lang == 2" value="2">English</option>
              <option [selected]="lang == 1" value="1">فارسی</option>
            </select>
          </label>

          <div class="checkbox" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">
            <mat-checkbox class="reminder" style="vertical-align:text-top" formControlName="remember">
            </mat-checkbox>
            <span *ngIf="lang == 1">مرا به خاطر بسپار</span>
            <span *ngIf="lang == 2">Remember me</span>
          </div>

          <div class="forgotten-password" style="display: none"><a>گذرواژه را فراموش
              کرده ام</a></div>


          <div class="login-buttons">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 login-buttons2" *ngIf="lang == 1">
                <a href="https://my.finty.co/register2">
                  <button *ngIf="lang == 2" type="button" class="login-button">
                    Sign up
                  </button>
                </a>
                <a href="https://my.finty.ir/register" *ngIf="lang == 1">
                  <button type="button" class="login-button">
                    <span>ثبت نام</span>
                  </button>
                </a>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 login-buttons2" [ngClass]="{width100:lang == 2}">
                <button type="submit" class="login-button" [disabled]="!form1.valid">
                  <div class="spinner-border spinner-border-sm" role="status" *ngIf="entering == true || loading">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span *ngIf="entering == false && !loading">
                    <span *ngIf="lang == 1">ورود به فینتی</span>
                    <span *ngIf="lang == 2">Sign in</span>
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div class="mobile">
            <div class="suggestion">
              <div class="olgu" style="display: none;">
                <span style="padding-left: 1rem;"> کاربر تست : olgu</span>
                <span>رمز عبور : 123456789</span>
              </div>
              <div class="phone" style="display: none;">
                پشتیبانی : 09370640060
              </div>
              <div class="instagram" style="display: none;">
                <a href="https://www.instagram.com/fixsmart.co" target="_blank"><img
                    src="../../assets/images/instagram.png" class="cursor" style="width: 2rem;"></a>
                برای اطلاع از اخبار آپدیت های نرم افزار در اینستاگرام ما را دنبال کنید.
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- ******************************************************************************************** -->
      <div style="padding:4rem 16px 0 22px" [hidden]="validation_cellphone == false">
        <form [formGroup]="form2" (ngSubmit)="login(2)">
          <label for="code" class='wh-label'>
            <div class="login-label" style="padding-bottom: 0.6rem;"> لطفا کد دریافتی را در باکس پایین وارد
              کنید:</div>
            <input class="wh-input code center" formControlName="code" required minlength="5" maxlength="5" length="5"
              (keypress)="serverService.get_number($event)">
          </label>
          <div style="text-align: center " class="login-buttons">
            <button type="submit" class="login-button" [disabled]="!form2.valid">تایید کد و ورود</button>
          </div>
        </form>
      </div>
      <!-- ******************************************************************************************** -->
    </div>
  </div>
</div>