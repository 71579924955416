
import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InsertComponent } from '../../service/insert/insert.component';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-new-fast-material',
  templateUrl: './new-fast-material.component.html',
  styleUrls: ['./new-fast-material.component.scss']
})
export class NewFastMaterialComponent implements OnInit, OnDestroy {
  public user_info: any | undefined = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public cate: number;
  public list_group: any = [];
  public list_cate: any = [];
  public material_type: number | undefined;
  public result: any = [];
  public creator: number | undefined;
  public list_weight: any = [];
  public pending: any = false;
  public ref: any | undefined;


  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<NewFastMaterialComponent>
    , public dialog: MatDialog
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.cate = dialog_data.cate;
      this.material_type = dialog_data.material_type;
      this.creator = dialog_data.creator;
      this.pending = dialog_data.pending;
      this.ref = dialog_data.ref;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.create_form();
    this.get_group();
    this.get_weight();
  }


  create_form() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'cost': new FormControl(0, [Validators.pattern('[0-9.]{1,}')]),
      'price2': new FormControl(0, [Validators.pattern('[0-9.]{1,}')]),
      'group': new FormControl(null),
      'weight': new FormControl(null),
      'cate': new FormControl(null),
      'inventory': new FormControl(0),
    })
  }

  save(type: string): any {
    if (!this.creator) {
      var pe_message = "نام صاحب اکانت مشخص نیست . لطفا یکبار دیگر لاگین شوید";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
      return false;
    }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var address;
    if (this.material_type == 1) {
      address = 6228;
    } else {
      address = 1997;
    }
    this.loading = true;
    var obj = {
      address: address,
      title: this.form1_group.value.title,
      cost: this.form1_group.value.cost,
      price2: this.form1_group.value.price2,
      cate: this.form1_group.value.cate,
      inventory: this.form1_group.value.inventory,
      weight: this.form1_group.value.weight,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.result = res['result'][0];
            if (this.material_type == 1) this.insert_order(type, res['result'][0].wharehouse_material_id);
            else {
              if (type == 'close') {
                this.matDialogRef.close(this.result);
              } else {
                this.form1_group.patchValue({
                  'title': null,
                  'cost': null,
                  'price2': 0,
                  'inventory': 0
                });
                this.serverService.send_material(this.result);
                this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
              }
            }
          }
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان می بایست منحصر به فرد باشد";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert_order(type: string, id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6453, id: id, user_id: this.user_id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          if (type == 'close') this.matDialogRef.close(this.result);
          else {
            this.form1_group.patchValue({
              'title': null,
              'inventory': 0,
              'cate': null,
              'cost': 0,
              'price2': 0
            });
            this.serverService.send_material(this.result);
          }

          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_group() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6330, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_group = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].wharehouse_group_default == true) {
              this.form1_group.patchValue({
                'group': res['result'][i].wharehouse_group_id
              });
              this.get_cate();
            }
            this.list_group.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_weight() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6213, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_weight = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].wharehouse_weight_default == true) {
              this.form1_group.patchValue({
                'weight': res['result'][i].wharehouse_weight_id
              });
            }
            this.list_weight.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_cate() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6331, group: this.form1_group.value.group }).subscribe(
      (res: any) => {
        this.list_cate = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_cate.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  add_group(): any {
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'group' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_group.unshift({ wharehouse_group_title: res.wharehouse_group_title, wharehouse_group_id: res.wharehouse_group_id })
          this.form1_group.patchValue({
            'group': res.wharehouse_group_id
          });
          this.list_cate = [];
        }
      }
    )
  }

  add_cate(): any {
    if (!this.form1_group.value.group) {
      var pe_message = "برای درج شاخه نیاز است تا دستگاه انتخاب شده باشد";
      var en_message = "To insert a branch, the device needs to be selected";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(InsertComponent, {
      width: '20rem',
      height: 'auto',
      data: { creator: this.creator, service: 'cate', group: this.form1_group.value.group }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_cate.unshift({ wharehouse_material_cate_title: res.wharehouse_material_cate_title, wharehouse_material_cate_id: res.wharehouse_material_cate_id })
          this.form1_group.patchValue({
            'cate': res.wharehouse_material_cate_id
          })
        }
      }
    )
  }




  close() {
    if (this.result.length > 0) this.matDialogRef.close(this.result);
    else this.matDialogRef.close();
  }

  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
