<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span> Receive a check </span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row" style="direction: rtl;">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="title" class='wh-label'>
                        <span> Check purpose: For </span>
                        <input class='wh-input' formControlName="title" autocomplete="off">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="amount" class='wh-label'>
                        <span> Check Amount </span>
                        <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask required>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="bank" class='wh-label'>
                        <span> Bank </span>
                        <input class="wh-input" formControlName="bank" [required]="required()">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="account_number" class='wh-label'>
                        <span> Account number </span>
                        <input class="wh-input" formControlName="account_number" required>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="number" class='wh-label'>
                        <span> Check number </span>
                        <input class="wh-input" formControlName="number" required>
                    </label>
                </div>
                <!--************************************************************************************ -->
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="due" class="wh-label">
                        <span> Due Date </span>
                        <div style="width:100%">
                            <input type="date" class="wh-input left" formControlName="due" [required]="required()"
                                autocomplete="off">
                        </div>
                    </label>
                </div>
                <!--************************************************************************************ -->
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="issue_date" class="wh-label">
                        <span> Date of issuance </span>
                        <div style="width:100%">
                            <input type="date" class="wh-input left" formControlName="issue_date"
                                [required]="required()" autocomplete="off">
                        </div>
                    </label>
                </div>
                <!--************************************************************************************ -->
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="branch_name" class='wh-label'>
                        <span> Branch name</span>
                        <input class="wh-input" formControlName="branch_name">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="branch_code" class='wh-label'>
                        <span> Branch code </span>
                        <input class="wh-input" formControlName="branch_code">
                    </label>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------- -->
        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>
