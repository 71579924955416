<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row dflex direction_ltr">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='open_detaile(0,1,0)' [disabled]='new_status' *ngIf="code == 161">
                    <span>{{ messageService.get_new(lang) }}</span>
                </button>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll en1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px' (click)="show_hiddens()">
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' class="cell1 ">{{ i + page_number_first
                }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef style="min-width: 350px;">
                <span>Name and sure name</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 350px;">
                <div class="div2 fleft left" (click)='open_detaile(i,2,x.user_id)'>
                    {{ x.user_sex_title }} {{ x.user_title }}
                </div>
                <span (click)="open_Menu(x.user_id)" class="fright" [matMenuTriggerFor]="menu" [hidden]="level == 1">
                    <i class="fas fa-info-circle icon"></i>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 350px;"></mat-footer-cell>
        </ng-container>

        <mat-menu #menu="matMenu" class="users-mat-menu-panel"
            style="max-width:42rem !important;min-width:42rem !important" xPosition="before">
            <div class="row" (click)="$event.stopPropagation()">
                <table class="table table-bordered">
                    <tr>
                        <td (click)="open_first_balance()" class="cursor">
                            <span>Old debit/ credit</span>
                        </td>
                    </tr>
                </table>
            </div>
        </mat-menu>

        <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef>
                <span>User groups</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1">{{
                x.user_groups_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="store">
            <mat-header-cell *matHeaderCellDef style="min-width: 200px;">
                <span>Company name</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 200px;"
                (click)='open_detaile(i,2,x.user_id)'>
                <div class="left">{{ x.user_store }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 200px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="cellphone">
            <mat-header-cell *matHeaderCellDef>
                <span>Cellphone Number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                class="center">
                <div *ngIf="show_cellphone == true" class="cellphone">{{
                    x.user_cellPhone }}</div>
                <div *ngIf="show_cellphone == false" class="cellphone">{{
                    x.user_cellPhone | hide_cellphone
                    }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef [hidden]="true">
                <span>ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="true">{{
                x.user_id }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="true"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                <span>Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px' class="cell1">
                <span (click)="check_access(3,0,x.user_id,0,1)" style="color:red"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
                <span class="info" [matMenuTriggerFor]="menu2" (click)="get_bg2(x.user_id)"><i
                        class="fas fa-info-circle icon"></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:70px'></mat-footer-cell>
        </ng-container>
        <mat-menu #menu2="matMenu" class="services-mat-menu-panel" xPosition="before">
            <table class="table table-bordered" (click)="$event.stopPropagation()">
                <tr>
                    <td class="left">
                        <a href="/home/financial2/202?user={{ record_id }}" target="_blank">
                            <span>Statement</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        <a href="/home/recieve/278?user={{ record_id }}" target="_blank">
                            <span>Recieve</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        <a href="/home/recieve/279?user={{ record_id }}" target="_blank">
                            <span>Payment</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        <a href="/home/buy/272?user={{ record_id }}" target="_blank">
                            <span>Purchase Invoice</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        <a href="/home/sale/273?user={{ record_id }}" target="_blank">
                            <span>Sale Invoice</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        <a href="/home/reception/160?customer={{ record_id }}" target="_blank">
                            <span>Ticket-Customer</span>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        <a href="/home/reception/160?repairman={{ record_id }}" target="_blank">
                            <span>Ticket-Technicians</span>
                        </a>
                    </td>
                </tr>
            </table>
        </mat-menu>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow  || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>

</div>