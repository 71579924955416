import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class ServerService {
  public sms_token = "https://api.kavenegar.com/v1/2F5648346F796F7743377650354D426F4D6251455131657873772B554479724753496A2B457533347546343D/verify/lookup.json";
  public alter_sb_tab: Subject<any> = new Subject<any>();
  public pattern_title: any | undefined;
  public sb_fmea_process: Subject<any> = new Subject();
  public sb_menu_ab: Subject<any> = new Subject();
  public sb_material_id: Subject<any> = new Subject<any>();
  public sb_material: Subject<any> = new Subject<any>();
  public sb_ticket_unit_id: Subject<any> = new Subject<any>();
  public isOnline: boolean | undefined;
  public sb_change_router: Subject<any> = new Subject<any>();

  public sb_list_tab: Subject<any> = new Subject<any>();
  public sb_list_section: Subject<any> = new Subject<any>();
  public sb_list_menu: Subject<any> = new Subject<any>();
  public sb_list_menu2: Subject<any> = new Subject<any>();

  public sb_user_logo: Subject<any> = new Subject<any>();
  public sb_status: Subject<any> = new Subject<any>();
  public sb_new_reception: Subject<any> = new Subject<any>();
  public sb_reception_repairman: Subject<any> = new Subject<any>();
  public sb_finance_document: Subject<any> = new Subject<any>();

  public sb_cheque_status: Subject<any> = new Subject<any>();
  public sb_device: Subject<any> = new Subject<any>();
  public sb_bascket: Subject<any> = new Subject<any>();
  public sb_invoice: Subject<any> = new Subject<any>();
  public sb_invoice_material: Subject<any> = new Subject<any>();
  public sb_financial2: Subject<any> = new Subject<any>();
  public sb_financial: Subject<any> = new Subject<any>();
  public sb_currency_title: Subject<any> = new Subject<any>();
  public sb_invoice_order: Subject<any> = new Subject<any>();
  public sb_reception_rstatus_id: Subject<any> = new Subject<any>();
  public sb_destory_subscription: Subject<any> = new Subject<any>();

  public sb_version: Subject<any> = new Subject<any>();
  public sb_remain_account: Subject<any> = new Subject<any>();
  public sb_payments: Subject<any> = new Subject<any>();
  public sb_services_id: Subject<any> = new Subject<any>();
  public sb_reset_services_id: Subject<any> = new Subject<any>();
  public wharehouse_group_id: Subject<any> = new Subject<any>();
  public sb_weight_title: Subject<any> = new Subject<any>();
  public sb_user_title: Subject<any> = new Subject<any>();
  public sb_sms_charge: Subject<any> = new Subject<any>();
  public sb_group: Subject<any> = new Subject<any>();
  public sb_expire_date: Subject<any> = new Subject<any>();
  public sb_update_invoice: Subject<any> = new Subject<any>();
  public sb_belong: Subject<any> = new Subject<any>();
  public sb_objections: Subject<any> = new Subject<any>();
  public sb_wharehouse_group: Subject<any> = new Subject<any>();
  public sb_material_group_cate: Subject<any> = new Subject<any>();
  public sb_my_network: Subject<any> = new Subject<any>();
  public sb_reset: Subject<any> = new Subject<any>();
  public count: number = 0;
  public dl_site_path = "object/movie";
  public server: string | undefined;
  public site: string | undefined;
  public err_internet_validation: any | undefined;
  public loading: boolean | undefined;
  public lang: any | undefined;
  public subscription: Subscription | undefined;
  public domain: string;
  public domain_server: string;
  public expire: any | undefined;
  public service_access: any | undefined;
  public server_strip_payment: string = "https://payment.finty.co";
  public server_strip_payment_test: string = "https://paymenttest.finty.co";



  constructor(
    public http: HttpClient
    , public matSnackBar: MatSnackBar
    , public router: Router,
    @Inject(DOCUMENT) private document: Document
    , public meta: Meta
    , public title: Title) {
    //**************************Real***********************  */

    var x = this.document.location.origin.split('.');
    if (x.length == 3) {
      this.domain_server = "https://server." + x[x.length - 2] + "." + x[x.length - 1];
    } else {
      x = this.document.location.origin.split('//');
      this.domain_server = x[0] + "//server." + x[1];
    }
    this.domain = this.document.location.origin;
    //***********************localhost***********************  */
    this.domain = "https://fix.finty.co";
    this.domain_server = "https://server.finty.co";
  }

  get_path_update() {
    var x = this.document.location.origin.split('.');
    if (x.length == 3) {
      return (x[x.length - 3].split("//")[1])
    }
    else {
      return '';
    }
  }

  get_domain_main(): any {
    if (this.domain == 'https://fix.finty.co') {
      return 'https://finty.co';
    } else {
      return 'https://finty.ir';
    }

  }

  get_direction() {
    if (this.domain == 'https://fix.finty.co') {
      return 'ltr';
    } else {
      return 'rtl';
    }
  }

  get_lang() {
    if (this.domain == 'https://fix.finty.co') {
      this.set_metas('Finty Software', 'Finty Software', '');
      return 2;
    } else {
      this.set_metas('نرم افزار فینتی', 'نرم افزار فینتی', '');
      return 1;
    }
  }

  get_my_domain() {
    if (this.domain == 'https://fix.finty.co') {
      return 'https://my.finty.co';
    } else {
      return 'https://my.finty.ir';
    }
  }

  get_software_id() {
    return 1;
  }

  post_address_login(server: any, address: string, obj: any) {
    return this.http.post(server + "/" + address, obj);
  }//end manager_get_data

  post_address_token(server: any, address: string, obj: any, token: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      })
    };
    return this.http.post(server + "/" + address, obj, httpOptions);
  }//end manager_get_data

  post_address(server: any, address: string, obj: any) {

    var token = ""
    var user_info = JSON.parse(<any>localStorage.getItem('user_info'));
    if (user_info) {
      token = user_info.user_token;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token,
        'time': new Date(new Date().getTime() + 0 * 60 * 60 * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
      })
    };
    return this.http.post(server + "/" + address, obj, httpOptions);
  }//end manager_get_data

  post_address_file(server: string, address: string, obj: any) {
    return this.http.post(server + "/" + address, obj, {
      reportProgress: true,
      'observe': 'events'
    });
  }//end manager_get_data



  get_address(server: string, address: string) {
    return this.http.get(server + "/" + address);
  }//end manager_get_data

  get_server_main() {
    return this.domain_server;
  }



  get_site_upload_video_main() {
    return this.domain;
  }



  get_server(): any {
    return this.domain_server;
  }



  get_site_media() {
    return this.domain;
  }

  get_server_site_media() {
    return this.get_server_main;
  }

  get_site(): string {
    return this.domain;
  }

  get_current_site(): string {
    return this.domain;
  }

  get_current_site2() {
    return 'http://localhost:5001';
  }

  get_site_upload_image() {
    return this.domain;
  }


  get_path_upload_image() {
    return 'object/uploads/images/';
  }
  get_path_upload_image_menu() {
    return 'object/uploads/images-menu/';
  }
  get_path_upload_image_user() {
    return 'object/uploads/images-user/';
  }

  get_default_user_logo() {
    return "../../../../assets/images/default_user.png";
  }

  get_default_image() {
    return "../../../../assets/images/default_image.png";
  }

  get_text_expire() {
    return "اکانت شما منقضی شده است . لطفا تمدید نمائید.";
  }

  convert_to_number(amount) {
    return amount.toString().replace(/\,/g, '')
  }
  get_number(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && charCode != 13) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  get_number_float(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  //****************************************** */
  send_group() {
    this.sb_group.next();
  }

  get_group(): Observable<any> {
    return this.sb_group.asObservable();
  }
  //****************************************** */
  send_user_logo(value: any) {
    this.sb_user_logo.next(value);
  }

  get_user_logo(): Observable<any> {
    return this.sb_user_logo.asObservable();
  }
  //****************************************** */
  send_my_network() {
    this.sb_my_network.next();
  }

  get_my_network(): Observable<any> {
    return this.sb_my_network.asObservable();
  }
  //****************************************** */
  send_material_group_cate(value: any) {
    this.sb_material_group_cate.next(value);
  }

  get_material_group_cate(): Observable<any> {
    return this.sb_material_group_cate.asObservable();
  }
  //****************************************** */
  send_belong(value: any) {
    this.sb_belong.next(value);
  }

  get_belong(): Observable<any> {
    return this.sb_belong.asObservable();
  }
  //****************************************** */
  send_wharehouse_group() {
    this.sb_wharehouse_group.next();
  }

  get_wharehouse_group(): Observable<any> {
    return this.sb_wharehouse_group.asObservable();
  }
  //****************************************** */
  send_objections(value: any) {
    this.sb_objections.next(value);
  }

  get_objections(): Observable<any> {
    return this.sb_objections.asObservable();
  }
  //****************************************** */
  send_user_title(value: any) {
    this.sb_user_title.next(value);
  }

  get_user_title(): Observable<any> {
    return this.sb_user_title.asObservable();
  }
  //****************************************** */
  send_sms_charge() {
    this.sb_sms_charge.next();
  }

  get_sms_charge(): Observable<any> {
    return this.sb_sms_charge.asObservable();
  }
  //****************************************** */
  send_expire_date() {
    this.sb_expire_date.next();
  }

  get_expire_date(): Observable<any> {
    return this.sb_expire_date.asObservable();
  }
  //****************************************** */
  get_site_upload_video() {
    return this.domain;
  }

  get_path_upload_video() {
    return this.dl_site_path;
  }

  //****************************************** */
  send_order() {
    this.sb_invoice_order.next();
  }

  get_order(): Observable<any> {
    return this.sb_invoice_order.asObservable();
  }
  //****************************************** */
  send_remain_account() {
    this.sb_remain_account.next();
  }

  get_remain_account(): Observable<any> {
    return this.sb_remain_account.asObservable();
  }
  //****************************************** */
  send_payments(value: any) {
    this.sb_payments.next(value);
  }

  get_payments(): Observable<any> {
    return this.sb_payments.asObservable();
  }
  //****************************************** */
  send_reception_rstatus(value: any) {
    this.sb_reception_rstatus_id.next(value);
  }

  get_reception_rstatus(): Observable<any> {
    return this.sb_reception_rstatus_id.asObservable();
  }
  //****************************************** */
  send_destory_subscription(value: any) {
    this.sb_destory_subscription.next(value);
  }

  get_destory_subscription(): Observable<any> {
    return this.sb_destory_subscription.asObservable();
  }
  //****************************************** */
  send_weight_title() {
    this.sb_weight_title.next();
  }

  get_weight_title(): Observable<any> {
    return this.sb_weight_title.asObservable();
  }
  //****************************************** */
  send_currency_title() {
    this.sb_currency_title.next();
  }

  get_currency_title(): Observable<any> {
    return this.sb_currency_title.asObservable();
  }
  //****************************************** */
  send_financial(value: any) {
    this.sb_financial.next(value);
  }

  get_financial(): Observable<any> {
    return this.sb_financial.asObservable();
  }
  //****************************************** */
  send_financial2(value: any) {
    this.sb_financial2.next(value);
  }

  get_financial2(): Observable<any> {
    return this.sb_financial2.asObservable();
  }
  //****************************************** */
  send_bascket(value: any) {
    this.sb_bascket.next(value);
  }

  get_bascket(): Observable<any> {
    return this.sb_bascket.asObservable();
  }
  //****************************************** */
  send_new_reception(value: any) {
    this.sb_new_reception.next(value);
  }

  get_new_reception(): Observable<any> {
    return this.sb_new_reception.asObservable();
  }
  //****************************************** */
  send_invoice(value: any) {
    this.sb_invoice_material.next(value);
  }

  get_invoice(): Observable<any> {
    return this.sb_invoice_material.asObservable();
  }
  //****************************************** */
  send_invoice_material(value: any) {
    this.sb_invoice.next(value);
  }

  get_invoice_material(): Observable<any> {
    return this.sb_invoice.asObservable();
  }
  //**************************************** */
  send_update_invoice_material() {
    this.sb_update_invoice.next();
  }

  get_update_invoice_material(): Observable<any> {
    return this.sb_update_invoice.asObservable();
  }
  //**************************************** */
  get_count() {
    return this.count++;
  }
  //****************************************** */
  send_services_id(value) {
    this.sb_services_id.next(value);
  }

  get_services_id(): Observable<any> {
    return this.sb_services_id.asObservable();
  }
  //********************************************* */
  send_reset_services_id(value) {
    this.sb_reset_services_id.next(value);
  }

  get_reset_services_id(): Observable<any> {
    return this.sb_reset_services_id.asObservable();
  }
  //********************************************* */
  send_wharehouse_group_id(value) {
    this.wharehouse_group_id.next(value);
  }

  get_wharehouse_group_id(): Observable<any> {
    return this.wharehouse_group_id.asObservable();
  }
  //****************************************** */
  send_reset(value) {
    this.sb_reset.next(value);
  }

  get_reset(): Observable<any> {
    return this.sb_reset.asObservable();
  }
  //****************************************** */
  // send_reception_repairman() {
  //   this.sb_reception_repairman.next();
  // }

  // get_reception_repairman(): Observable<any> {
  //   return this.sb_reception_repairman.asObservable();
  // }
  //****************************************** */
  send_cheque_status(value: any) {
    this.sb_cheque_status.next(value);
  }

  get_cheque_status(): Observable<any> {
    return this.sb_cheque_status.asObservable();
  }
  //****************************************** */
  send_finance_document(value: any) {
    this.sb_finance_document.next(value);
  }

  get_finance_document(): Observable<any> {
    return this.sb_finance_document.asObservable();
  }
  //****************************************** */
  send_device() {
    this.sb_device.next();
  }

  get_device(): Observable<any> {
    return this.sb_device.asObservable();
  }
  //****************************************** */
  send_status() {
    this.sb_status.next();
  }

  get_status(): Observable<any> {
    return this.sb_status.asObservable();
  }
  //****************************************** */
  send_tab(result: any, action: string) {
    this.sb_list_tab.next({ result: result, action: action });
  }

  get_tab(): Observable<any> {
    return this.sb_list_tab.asObservable();
  }

  send_section(result: any, action: string) {
    this.sb_list_section.next({ result: result, action: action });
  }

  get_section(): Observable<any> {
    return this.sb_list_section.asObservable();
  }

  send_menu(result: any, action: string) {
    this.sb_list_menu.next({ result: result, action: action });
  }

  get_menu(): Observable<any> {
    return this.sb_list_menu.asObservable();
  }

  send_menu2() {
    this.sb_list_menu2.next();
  }

  get_menu2(): Observable<any> {
    return this.sb_list_menu2.asObservable();
  }
  //*********************************************************** */
  send_version(value: any) {
    this.sb_version.next(value);
  }

  get_version(): Observable<any> {
    return this.sb_version.asObservable();
  }
  //********************************************************  */
  send_change_router(id: any, user: any) {
    this.sb_change_router.next({ id: id, user: user });
  }

  get_change_router(): Observable<any> {
    return this.sb_change_router.asObservable();
  }
  //********************************************************  */
  pattern_title_with_persian() {
    this.pattern_title = "-()A-Za-z0-9 , ، \n / % آ ض ص ث ق ف غ ع ه خ ح ج چ پ ش س ی ب ل ا ت ن م ک ك گ ظ ط ز ر ذ د ئ ي و ژ . :";
    return this.pattern_title;
  }//end pattern_title_with_persian

  //*********************************
  no_record(): any {
    var text = " هیچ رکورد وجود ندارد";
    return text;
  }

  status1(status: number) {
    var user_info = JSON.parse(<any>localStorage.getItem('user_info'));
    if (user_info) {
      var user_id = user_info.user_id;
      var user_token = user_info.user_token;
      var user_username = user_info.user_username;
      var user_password = user_info.user_password;
      var user_creator = user_info.user_creator;
      var user_similar_admin = user_info.similar;
    } else {
      this.signout();
    }
    if (user_creator == 2445 || user_similar_admin == true) {
      return false;
    }
    var obj = { address: 6733, "user_id": user_id, user_token: user_token }
    this.subscription = this.post_address(this.get_server_main(), 'new_address', obj).subscribe(
      (res: any) => {
        if (res['num'] == 1) {
          // if (res['result'][0].user_expire_date < res['date1']) {
          //   this.signout();
          // }
        }//end else if 
        else {
          this.signout();
        }//end else
      }
    )
  }


  //************************************** */
  get_title(type_task, lang) {
    if (type_task == 1) {
      if (lang == 1) { return 'جدید'; }
      if (lang == 2) { return 'New'; }
    }
    if (type_task == 2) {
      if (lang == 1) { return 'ویرایش'; }
      if (lang == 2) { return 'Update'; }
    }
    if (type_task == 3) {
      if (lang == 1) { return 'فیلتر'; }
      if (lang == 2) { return 'Filter'; }
    }
  }

  convertNumbers2English(string) {
    return string.replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (c) {
      return c.charCodeAt(0) - 1632;
    }).replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (c) {
      return c.charCodeAt(0) - 1776;
    });
  }

  get_event_title(type_task, lang) {
    if (type_task == 1) {
      if (lang == 1) { return 'ذخیره'; }
      if (lang == 2) { return 'Save'; }
    }
    if (type_task == 2) {
      if (lang == 1) { return 'ویرایش'; }
      if (lang == 2) { return 'Update'; }
    }
    if (type_task == 3) {
      if (lang == 1) { return 'جستجو'; }
      if (lang == 2) { return 'Search'; }
    }
  }

  get_no_record_text(lang) {
    if (lang == 1) { return 'هیچ رکوردی برای نمایش وجود ندارد'; }
    if (lang == 2) { return 'There are no records to display'; }
  }
  //****************************************
  send_material_id(id: any) {
    this.sb_material_id.next(id);
  }
  get_material_id(): Observable<any> {
    return this.sb_material_id.asObservable();
  }
  //****************************************
  send_material(id: any) {
    this.sb_material.next(id);
  }

  get_material(): Observable<any> {
    return this.sb_material.asObservable();
  }
  //****************************************
  send_ticket_unit_id(id: any) {
    this.sb_ticket_unit_id.next(id);
  }

  get_ticket_unit_id(): Observable<any> {
    return this.sb_ticket_unit_id.asObservable();
  }
  //***************************************** */
  set_metas(title: any, keywords: any, description: any) {
    this.meta.updateTag({ name: "keywords", content: keywords });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ name: 'title', content: title });
    if (title == 'not-found') {
      this.meta.updateTag({ name: 'robots', content: "noindex" });
    } else {
      this.meta.updateTag({ name: 'robots', content: "index,follow" });
    }
    this.title.setTitle(title);
  }

  //*****************************************
  change_password(server: string, obj: any) {
    return this.http.post(server + "/change_password", obj);
  }
  //*****************************************
  change_window_resize(start: number, object: string, num: number) {
    if (start == 1) {
      $("." + object).slideToggle("normal", function () {
        //var x = <any>$(".content-body").offset().top;
        var x = 162;
        $(".content-body").css('height', 'calc(100vh - ' + x + 'px' + ')');
      });
    }//end if
    else {
      //var x = $(".content-body").offset().top;
      var x = 162;
      $(".content-body").css('height', 'calc(100vh - ' + x + 'px' + ')');
    }
  }//end change_resize

  window_dialog_size() {
    var x = 22;
    $(".content-body2").css('height', 'calc(100vh - ' + x + 'rem' + ')');
  }//end change_resize

  /*
  change_window_resize2(start, object, num) {
    if (start == 1) {
      $("." + object).slideToggle("normal", function () {
        var x = $(".content-body2").offset().top + num;
        $(".content-body2").css('height', 'calc(100vh - ' + x + 'px' + ')');
      });
    }//end if
    else {
      var x = $(".content-body2").offset().top + num;
      $(".content-body2").css('height', 'calc(100vh - ' + x + 'px' + ')');
    }
  }//end change_resize
  */
  //******************************************* */
  check_expire(creator) {
    this.subscription = this.post_address(this.get_server_main(), 'new_address', { address: 6, user_id: creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.expire = res['expire']
        }//end if
        else {
          this.expire = false;
        }
      }
    )
    return this.expire;
  }

  async check_expire2(creator) {
    this.subscription = this.post_address(this.get_server_main(), 'new_address', { address: 6, user_id: creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.expire = res['expire']
          this.service_access = res['result'][0].count;
        }//end if
        else {
          this.expire = false;
        }
      }
    )
    return { expire: this.expire, service_access: this.service_access };
  }

  check_internet() {
    this.isOnline = navigator.onLine;
    if (this.isOnline == false) {
      return false;
    }
    else {
      return true;
    }//end else
  }//end check_internet
  //*********************************************** */
  direction_text(value: string, box: string) {
    if (this.isUnicode(value)) {
      $("#" + box).css("cssText", "direction: rtl !important;text-align: right !important");
    }
    else {
      $("#" + box).css("cssText", "direction: ltr !important;text-align: left !important");
    }
  }

  isUnicode(str: any) {
    var letters = [];
    if (str.substring((0), 1).charCodeAt() > 255) { return true; }
    else {
      return false;
    }
  }
  //*********************************************** */
  message_delete(id: any, changedRows: number): string {
    var number; var text;
    if (typeof id == 'number') { text = "با موفقیت حذف شد"; }
    else {
      number = id.length;
      text = " تعداد " + changedRows + " رکورد از " + number + " رکوورد انتخابی شما با موفقیت حذف شدند";
    }
    return text;
  }

  message_delete_alarm(): string {
    var text = "این رکورد در ماژول هایی در حال استفاده می باشد و قادر به حذف نخواهید بود";
    return text;
  }

  message_delete_accept(): any {
    var text = "آیا از حذف اطمینان دارید؟";
    return text;
  }

  message_error(): string {
    var text = "دریافت خطا";
    return text;
  }

  message_action(): string {
    return 'Close';
  }
  //*********************************************** */
  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 2000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 2000 });
    }//end if
    else {
      this.matSnackBar.dismiss();
    }
  }
  //*********************************************** */
  signout() {

  

    const reception_list_columns = localStorage.getItem("reception_list_columns");



    const profit_list_columns = localStorage.getItem("profit_list_columns");

    



    const profit2_list_columns = localStorage.getItem("profit2_list_columns");

    localStorage.clear();

    if (reception_list_columns) {
      localStorage.setItem("reception_list_columns", reception_list_columns);
    }



    if (profit_list_columns) {
      localStorage.setItem("profit_list_columns", profit_list_columns);
    }


    if (profit2_list_columns) {
      localStorage.setItem("profit2_list_columns", profit2_list_columns);
    }


    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy

}
