<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>Products and Services</span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="row row1">
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2 full-left">
            <div class="div1">
                <span> Account Holder </span>
                : {{ customer_title }}
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2 full-left">
            <div class="div1" [ngStyle]="{'margin-left':dir == 'ltr' ? '1rem' : '0px'}">
                <span> Cellphone Number </span>
                :
            </div>
            <div class="div1">
                <div *ngIf="show_cellphone == false"> {{ customer_cellphone }} </div>
                <div *ngIf="show_cellphone == true" class="cellphone">{{
                    customer_cellphone
                    | hide_cellphone }}</div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2 full-left">
            <div class="div1" [ngStyle]="{'margin-left':dir == 'ltr' ? '1rem' : '0px'}">
                <span> Device </span>
            </div>
            <div class="div1">
                <div>{{ device_title }}</div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2 full-left">
            <div class="div1" [ngStyle]="{'margin-left':dir == 'ltr' ? '1rem' : '0px'}">
                <span> Brand </span>
            </div>
            <div class="div1">
                <div> {{ brand_title }} </div>
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-sm-4 col-12 col-md-12 div2 full-left">
            <div class="div1" [ngStyle]="{'margin-left':dir == 'ltr' ? '1rem' : '0px'}">
                <span> Model </span>
            </div>
            <div class="div1">
                <div> {{ model_title }} </div>
            </div>
        </div>
    </div>
    <div class="row reception-div3" [hidden]="readonly == true">
        <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding full-left'>
            <span>Please specify reception status in the section below</span>
        </div>
        <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding div_status full-left'
            *ngFor="let x of list_rstatus;let i = index" style="float: right;min-height:10rem">
            <div class="status_header">
                <span>Step</span>
                <span> {{ i + 1 }} </span>
            </div>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="status_id" name="status_id">
                <mat-radio-button class="reception-status" [value]="x.repairman_rstatus_id"
                    [checked]="x.repairman_rstatus_id == status_id"
                    (change)="change_status(x.repairman_rstatus_deliverd,x.repairman_rstatus_repairing)"
                    style="width:100%">
                    <span class="status_span_label">{{x.repairman_rstatus_title }}</span>
                </mat-radio-button>

                <div *ngFor="let y of list_rstatus2" style="width:100%">
                    <mat-radio-button *ngIf="( x.repairman_rstatus_repairing == 1 && y.repairman_rstatus_repairing == 1 ) || 
                            (x.repairman_rstatus_sub_ready_delivery == 1 && y.repairman_rstatus_sub_ready_delivery == 1) || 
                            (x.repairman_rstatus_deliverd == 1 && y.repairman_rstatus_deliverd == 1)
                            " class="reception-status" [value]="y.repairman_rstatus_id" [disabled]="readonly"
                        (change)="change_status(x.repairman_rstatus_deliverdd,x.repairman_rstatus_repairing)">
                        <span>
                            <span class="status_span_label">{{y.repairman_rstatus_title }}</span>
                        </span>
                    </mat-radio-button>
                </div>

            </mat-radio-group>
        </div>
    </div>

    <div class="full-left-padding0" style="width:100%;text-align: left;">
        <span [hidden]="readonly == true">
            <button (click)="which(2)" [disabled]='next_status'><i class="fas fa-forward"></i></button>
            <button (click)="which(3)" [disabled]='pre_status'><i class="fas fa-backward"></i></button>
        </span>

        <button type="button" class="search_button" (click)="get(1,1,1)" [ngClass]="{active1:1 == material_type}"
            [hidden]="readonly == true">
            <span>
                <span>Product list</span>
                <span> {{ count_total_material }} </span>
            </span>
        </button>
        <button type="button" class="search_button" (click)="get(1,1,2)" [ngClass]="{active1:2 == material_type}"
            [hidden]="readonly == true || ref == 'order'">
            <span>Services</span>
            <span> {{ count_total_service }} </span>
        </button>
        <button type="button" class="search_button" [hidden]="readonly == true" (click)="get_exit(1,1)"
            [ngClass]="{active1:material_type == 0}">
            <span>Repair shop bill</span>
            <span> {{ count_exit }} </span>
        </button>
        <button type="button" class="search_button fright" (click)="add()" *ngIf="material_type == 1">
            <span>Add new product</span>
        </button>
        <button type="button" class="search_button fright" (click)="add()" *ngIf="material_type == 2">
            <span>Add new service</span>
        </button>
    </div>


    <input class="search full-left" (keyup)="get_search()" [(ngModel)]="search" autocomplete="off"
        placeholder="{{ messageService.get_search(lang) }}">

    <div class="content-body control-content-body"
        style='overflow-x:auto;direction:rtl;max-height:22rem;min-height:1rem'>
        <mat-table [dataSource]="dataSource" style='min-width:100%'
            class='table-scroll mat_table_en_without_scroll mat_table_fa_without_scroll_bg not- en1 ltr'
            [hidden]="material_type != 1">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style="max-width:40px">
                    #
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width:40px">{{
                    i+page_number_first }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="material">
                <mat-header-cell *matHeaderCellDef style="text-align: center !important;" class="right">
                    <span>Product Name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor right">
                    <span class="paddingRight full-left">
                        {{ x.wharehouse_material_title }} | {{ x.wharehouse_group_title }} | {{
                        x.wharehouse_material_cate_title }}
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="seller">
                <mat-header-cell *matHeaderCellDef style="max-width:200px">
                    <span>Salesman</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor"
                    style="text-align: center;height:2rem;padding:0px !important;max-width:200px">
                    <select class="select full-left" id="repairman{{ x.wharehouse_order_id  }}">
                        <option *ngFor="let repairman of list_repairman" [selected]="repairman.user_id == user_id"
                            [value]="repairman.user_id">{{
                            repairman.user_title }}</option>
                    </select>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="inventory">
                <mat-header-cell *matHeaderCellDef style="max-width:120px">
                    <span>Stock</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;" style="max-width:120px">
                    <span id="inventory1{{ x.wharehouse_order_id }}">{{ x.inventory
                        }}</span>

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="invoice_price">
                <mat-header-cell *matHeaderCellDef style="max-width:120px">
                    <span>Purchase price</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;" style="max-width:120px"
                    id="invoice_price1{{ x.wharehouse_order_id }}">
                    {{ x.wharehouse_order_cost | currency:'':'' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef style="max-width:120px">
                    <span>Number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                    style="max-width:120px;padding:0px !important">
                    <input type="number" class=" wh-input input1" id="input1{{ x.wharehouse_order_id }}" [value]="1"
                        autocomplete="off" min="0">
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef style="max-width:110px" [hidden]="ref == 'order'">
                    <span>Selling price</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                    style="max-width:110px;padding:0px !important" [hidden]="ref == 'order'">
                    <input class="wh-input input1" id="cost1{{ x.wharehouse_order_id }}" autocomplete="off"
                        [ngModel]="x.wharehouse_material_price2" currencyMask>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="add">
                <mat-header-cell *matHeaderCellDef style="max-width:80px">
                    <span *ngIf="lang == 1">
                        <span>Add</span>
                    </span>
                    <span>Inventory</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" class="cursor" style="text-align: center;"
                    style="max-width:80px;padding-top:3px !important">
                    <mat-icon class="add_circle_outline"
                        (click)="check_reception_for_material(i,x.wharehouse_material_id,x.wharehouse_order_id, 1 , 1 , x.wharehouse_order_cost)"
                        style="vertical-align: middle;font-size: 20px;">
                        add_circle_outline
                    </mat-icon>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
        </mat-table>

        <mat-table [dataSource]="dataSource" style='min-width:100%'
            class='table-scroll mat_table_en_without_scroll mat_table_fa_without_scroll_bg not- pe1 ltr'
            *ngIf="material_type == 2">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style="max-width:40px">
                    #
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width:40px">{{
                    i+page_number_first }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span>Service Name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;">
                    {{ x.repairman_services_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="repairman">
                <mat-header-cell *matHeaderCellDef style="max-width:150px">
                    <span>Repairman</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor"
                    style="text-align: center;height:2rem;padding:0px !important;max-width:150px">
                    <select class="select" id="repairman{{ x.repairman_services_id  }}">
                        <option *ngFor="let repairman of list_repairman" [selected]="repairman.user_id == user_id"
                            [value]="repairman.user_id">{{
                            repairman.user_title }}</option>
                    </select>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="comment">
                <mat-header-cell *matHeaderCellDef style="max-width:400px">
                    <span>Description</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                    style="max-width:400px;padding:0px !important">
                    <input class="wh-input input1 full-left" id="comment2{{ x.repairman_services_id }}"
                        autocomplete="off" style="text-align: right;">
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                    <span>Number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                    style="max-width:100px;padding:0px !important" [hidden]="true">
                    <input type="number" class="wh-input input1" id="input2{{ x.repairman_services_id }}" [value]="1"
                        autocomplete="off" currencyMask>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef style="max-width:100px">
                    <span>Price</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                    style="max-width:100px;padding:0px !important">
                    <input class="wh-input input1" id="cost2{{ x.repairman_services_id }}"
                        [ngModel]="x.repairman_services_cost" currencyMask autocomplete="off">

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="add">
                <mat-header-cell *matHeaderCellDef style="max-width:80px">
                    <span>Add</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" class="cursor" style="text-align: center;"
                    style="max-width:80px;padding-top:3px !important">
                    <mat-icon class="add_circle_outline"
                        (click)="check_reception_for_services(i,x.repairman_services_id,0,1,2,0)"
                        style="vertical-align: middle;font-size: 20px;">
                        add_circle_outline
                    </mat-icon>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns2" color="primary"></mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns2;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
        </mat-table>

        <mat-table [dataSource]="dataSource" class='matTable table-scroll mat_table_en_without_scroll en1 ltr'
            *ngIf="material_type == 0">

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i
                    + 1 }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef style="max-width:70px">
                    <span>Type</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                    style="max-width:70px">
                    {{ x.wharehouse_material_type_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="material">
                <mat-header-cell *matHeaderCellDef style="min-width:200px">
                    <span>Product/ service name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                    style="min-width:200px">
                    <div class="right full-left">{{ x.title }}
                        <span *ngIf="x.wharehouse_order_material_type == 1">| {{x.wharehouse_material_cate_title }}
                        </span>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef style="max-width:60px">
                    <span>Number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                    style="max-width:60px">
                    {{ x.wharehouse_order_number | number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef style="max-width: 75px;">
                    <span>Price</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 75px;"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                    {{ currency_title2 }} {{ x.wharehouse_order_cost | currency:'':'' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="discount">
                <mat-header-cell *matHeaderCellDef style="max-width: 75px;">
                    <span>Discount</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 75px;"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                    {{ currency_title2 }} {{ x.wharehouse_order_discount | currency:'':'' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="tax">
                <mat-header-cell *matHeaderCellDef style="max-width: 75px;">
                    <span>Tax</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 75px;"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                    <span>{{ x.tax | currency:'':'' }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="sum">
                <mat-header-cell *matHeaderCellDef style="max-width: 75px;">
                    <span>Total</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style="max-width: 75px;"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                    {{ currency_title2 }} {{ x.sum | currency:'':'' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="repairman">
                <mat-header-cell *matHeaderCellDef style="max-width:150px">
                    <span>Repairman Name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                    style="max-width:150px">
                    <span class="full-left">{{ x.repairman_title | substr:0:15 }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="searial">
                <mat-header-cell *matHeaderCellDef style="max-width: 100px;">
                    <span>Serial Numbers</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="open_serial(i,x.wharehouse_order_id,x.title,x.wharehouse_order_consumed_invoice)"
                    style="max-width: 100px;" [ngClass]="{bg2:x.searial == true}">
                    <span *ngIf="x.wharehouse_order_material_type == 1">
                        <mat-icon class="add_circle_outline">add_circle_outline</mat-icon>
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="comment">
                <mat-header-cell *matHeaderCellDef style="max-width:200px">
                    <span>Description</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="check_for_access(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                    style="max-width:200px">
                    <div class="right full-left ltr">
                        {{ x.wharehouse_order_comment | substr:0:25 }}
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="readonly == true">
                    <span>Operation</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:100px' [hidden]="readonly == true">
                    <span
                        (click)="access_for_delete(x.wharehouse_order_id,x.wharehouse_order_repairman,x.wharehouse_order_material_type,x.wharehouse_order_creator)"><i
                            class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
                </mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns3" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns3;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{bg:order_id > 0 && order_id == row.wharehouse_order_id,'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
        </mat-table>

        <mat-table [dataSource]="dataSource" style='min-width:100%;direction:ltr'
            class='table-scroll mat_table_fa_without_scroll pe1'
            [ngClass]="{mat_table_en_without_scroll:lang==2,en1:lang == 2}" *ngIf="material_type == 4">
            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i + 1 }}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="material">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">عنوان قطعات </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="open_detaile_exit(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_repairman)">
                    <div class="right">{{ x.title }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1"> دستگاه </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="open_detaile_exit(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_repairman)">
                    <div>{{ x.wharehouse_group_title }}</div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cate">
                <mat-header-cell *matHeaderCellDef style="max-width:150px">
                    <span *ngIf="lang == 1">شاخه</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="open_detaile_exit(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_repairman)"
                    style="max-width:150px">
                    {{ x.wharehouse_material_cate_title }}</mat-cell>
            </ng-container>


            <ng-container matColumnDef="brand">
                <mat-header-cell *matHeaderCellDef style="max-width:120px">
                    <span *ngIf="lang == 1">برند</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;max-width:120px">
                    {{ x.repairman_brand_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="number">
                <mat-header-cell *matHeaderCellDef>
                    <span *ngIf="lang == 1">تعداد</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)="open_detaile_exit(i,2,x.wharehouse_order_id,x.title,x.footer,x.wharehouse_order_material_type,x.wharehouse_order_repairman)">
                    {{ x.wharehouse_order_number | number }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="readonly == true">
                    <span *ngIf="lang == 1"> عملیات </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:100px' [hidden]="readonly == true">
                    <span (click)="delete_exit(x.wharehouse_order_id,x.wharehouse_order_repairman)"><i
                            class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
                </mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns4" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns4;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
        </mat-table>
    </div>

    <div class="row" *ngIf="material_type == 0">
        <div style="padding-top:1rem;text-align: left;">
            <div class="ltr">
                <span>Sum total in numbers </span>
                <span> {{ currency_title2 }} {{ sum_all | currency:'':'' }}</span>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="material_type == 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-2 col-xs-12"
            style="text-align: right;padding:0;padding-top:1rem">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 full-left" style="padding:0px">
                <span>Sum of service price </span>
                <span style="color:blue"> {{ currency_title2 }} {{ sum_all_services | currency:'':'' }}
                </span>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 full-left" style="padding:0px">
                <span>Sum of product price </span>
                <span style="color:blue">{{ currency_title2 }} {{ sum_all_material | currency:'':'' }}</span>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12 full-left" style="padding:0px">
                <span>Sum of tax </span>
                <span style="color:blue">{{ sum_tax_all | currency:'':'' }}</span>
            </div>
        </div>
    </div>
    <!-- **************************************************************************************************** -->
</div>

<div mat-dialog-actions class="matDialogActions" *ngIf="readonly == false">
    <button type="button" class="btn_en" (click)="save_status(1)">
        <span>Save</span>
    </button>

    <div style="padding-right:1rem;padding-top:0.2rem">
        <mat-radio-group aria-label="Select an option" name="device_status" [(ngModel)]="device_status">
            <div class="device_status_text">
                <span>Device status</span>
            </div>
            <mat-radio-button class="status_title_items reception-status" *ngFor="let x of list_device_status"
                [value]="x.repairman_device_status_id" [checked]="x.repairman_device_status_id == device_status">
                <span class="status_span_label">{{ x.repairman_device_status_title }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>