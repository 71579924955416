import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../../service/message/message.service';
import { BalanceSmsComponent } from '../balance-sms/balance-sms.component';
import { RecieveDetaileComponent } from "../../finance-recieve/recieve-detaile/recieve-detaile.component";
import { PayDetaileComponent } from '../../finance-pay/pay-detaile/pay-detaile.component';

@Component({
  selector: 'app-balance-list',
  templateUrl: './balance-list.component.html',
  styleUrls: ['./balance-list.component.scss']
})
export class BalanceListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | undefined;
  public loading: boolean = false;
  public loading_copy: boolean = false;
  public count_total: number = 1;
  public page: number = 1;
  public show_excel: boolean = false;
  public show_financial: boolean = false;
  public show_cellphone: boolean = true;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public search_all_params: boolean = false;
  public excel: number = 1;
  public asc_desc: string = 'asc';
  public list_record: any = [];
  public list_status: any = [];
  public search_array: any = [];
  public selected_record: any = [];
  public subscription: Subscription | any;
  public task_typ!: number;
  public order!: string;
  public confirm_delete!: boolean;
  public page_number_first!: number;
  public page_number_second!: number;
  public code!: any;
  public export_code!: string;
  public type_task!: number;
  public type: string = 'all';
  public type_search!: number;
  public search_all!: string;
  public bed!: number;
  public best!: number;
  public remain!: number;
  public path_export!: string;
  public id!: number;
  public pre_status!: boolean;
  public next_status!: boolean;
  public new_status!: boolean;
  public status_id!: number;
  public title!: string;
  public select_all!: boolean;
  public ccess_service!: number;
  public level!: number;
  public index!: number;
  public access_service!: any;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'title', 'cellphone', 'bed', 'best', 'remain', 'log_date'];
  public document_type_text!: string;
  public creator!: number;
  public back_i!: number;
  public video!: string;
  public active_sort!: string;
  public currency_title2!: string;
  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialog,
    public messageService: MessageService,
    public http: HttpClient) { }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.back_i = params['back_i'];
        this.search_all = params['search_all'];
        if (params['back_order']) {
          this.order = params['back_order'];
          this.active_sort = params['back_order'].split(" ")[0];
        }
        if (params['back_page'] > 0) {
          this.count_total = params['count_total'];
          this.page = params['back_page'] - 1;
          this.check_access(4, 2, 0, 1, 1);
        } else {
          this.check_access(4, 1, 0, 1, 1);
        }
      }
    )
    //**************
  }//end ngOnInit
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.show_cellphone = res['show_cellphone'];
          this.path_export = res['path_export'];
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
          }
          if (type_task == 4) {
            this.update_taraznameh();
            this.get_currency();
            this.load_data(page, excel, loading, -1);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  sort(sort: string, asc_desc: string) {
    this.active_sort = "";
    if (asc_desc == 'asc') this.asc_desc = 'desc';
    else this.asc_desc = 'asc';
    this.order = sort + " " + this.asc_desc;
    this.load_data(1, 1, 1, -1);
  }
  reset_sort(sort: string, asc_desc: string) {
    this.active_sort = "";
    this.order = sort + " " + asc_desc;
    this.load_data(1, 1, 1, -1);
  }
  load_data2(type: string) {
    this.type = type;
    this.load_data(1, 1, 1, -1);
  }
  update_taraznameh() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6924, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_currency() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6853, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.currency_title2 = res['result'][0].site_currency_title2;
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    if (page == 6) { this.page = 1; }
    if (page == 1) { this.page = 1; }
    var obj = {
      'address': 6925,
      "page": this.page,
      'type': this.type,
      'code': this.code,
      'excel': excel,
      'user_id': this.user_id,
      'search': this.search_array,
      'search_all': this.search_all,
      'changedRows': changedRows,
      'creator': this.creator,
      'path_export': this.path_export,
      'show_cellphone': this.show_cellphone,
      'order': this.order
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.bed = res['bed'];
            this.best = res['best'];
            this.remain = res['remain'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reset({});
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data3
  export_excel(code: number) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  get_bg(id: number) {
    this.id = id;
  }
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  show_financial2(i: number, user: number) {
    this.show_financial = true;
    this.router.navigate(['/home/financial2/202'], {
      queryParams:
      {
        user: user,
        ref: this.code,
        page: this.page,
        search_all: this.search_all,
        order: this.order,
        count_total: this.count_total,
        link: 'statement',
        service: 274,
        i: i,
      }
    })
  }
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  go_to_balance() {
    window.open(this.serverService.get_my_domain() + "/balance/" + this.list_record[this.index].user_id + "/" + this.list_record[this.index].user_token + "?admin=2", "_blank")
  }
  open_menu(i: number) {
    this.index = i;
  }
  check_new_balance(i: number, user: number, balance: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    if (balance > 0) {
      this.new_recieve(i, user, balance);
    }
    else if (balance < 0) {
      this.new_pay(i, user, balance);
    } else {
      var message = "این کاربر تسویه شده می باشد";
      this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
    }
  }
  new_recieve(i: number, user: number, balance: number) {
    var obj = {
      address: 6927,
      user: user,
      user_id: this.user_id,
      creator: this.creator,
      document_type_text: this.document_type_text
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.open_financial_recieve(i, 2, res['id'], balance);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_financial_recieve(i: number, type_task: number, id: number, balance: number) {
    const dialogRef = this.dialog.open(RecieveDetaileComponent, {
      width: '65rem',
      height: 'auto',
      data: { type_task: type_task, id: id, i: -1, code: this.code, document: 1, sum: balance }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.get_account(i, res.result.finance_financial_user);
        }
      }
    )
  }
  //*************************************************************************************** */
  new_pay(i: number, user: number, balance: number) {
    var obj = {
      address: 7012,
      user: user,
      user_id: this.user_id,
      creator: this.creator,
      document_type_text: this.document_type_text
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.open_financial_pay(i, 2, res['id'], balance);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_financial_pay(i: number, type_task: number, id: number, balance: number) {
    const dialogRef = this.dialog.open(PayDetaileComponent, {
      width: '65rem',
      height: 'auto',
      data: { type_task: type_task, id: id, i: -1, code: this.code, document: 1, sum: balance }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.get_account(i, res.result.finance_financial_user);
          this.cheque_spend(res.result.finance_financial_id);
        }
      }
    )
  }
  cheque_spend(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7068, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.update_cheque_spend(res['result'][i].finance_financial2_cheque_spend);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_cheque_spend(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7069, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_account(i: number, user: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1731, user: user }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_record[i].remain = res['result'][0].balance;
          this.dataSource.data = this.list_record;
        }//end if
        else {
          var pe_message = "خطا در $";
          this.message(true, this.messageService.message(this.lang, pe_message, 'Erorr in $'), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  send_sms() {
    this.dialog.open(BalanceSmsComponent, {
      width: '35rem',
      height: 'auto',
      data: { userId: this.list_record[this.index].user_id, creator: this.creator }
    })
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}