<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row direction_ltr dflex">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <div class="pager_pe">
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <span class="cursor" (click)="back_to_bill()" *ngIf="ref_link" style="vertical-align:top">
                <mat-icon>redo</mat-icon>
            </span>
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='matTable table-scroll mat_table_fa_without_scroll en1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'
                (click)="reset_sort('finance_financial2_id','desc')">
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="text-align: center !important;" style="max-width:120px"
                [ngClass]="{active1:order == 'date ' + asc_desc }" (click)="sort('date',asc_desc)">
                <span>Date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width:120px">
                {{ x.date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'type_title ' + asc_desc }"
                (click)="sort('type_title',asc_desc)" style="max-width:130px">
                <span>Type</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width:130px">
                <div>{{ x.type_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:130px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="document">
            <mat-header-cell *matHeaderCellDef style="min-width:550px"
                [ngClass]="{active1:order == 'finance_payment_type_title ' + asc_desc }"
                (click)="sort('finance_payment_type_title',asc_desc)">
                <span>Description</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:550px">
                <div class="right">
                    <span class="fleft left">
                        {{ x.alias }}
                        <span> {{ x.finance_payment_type_title }} </span>
                        <span>Based on Document No. </span>
                        <span> {{x.id2 }}</span>
                    </span>
                    <div class="invoice_type_title fright">
                        <span (click)="order_ref(x.id,x.finance_financial2_ref,x.finance_financial_document)">
                            <i class="fas fa-info-circle icon"></i>
                        </span>
                    </div>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:550px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width: 250px"
                [ngClass]="{active1:order == 'user_title ' + asc_desc }" (click)="sort('user_title',asc_desc)">
                <span>Title</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width: 250px"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="left">{{ x.user_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="direction: rtl;min-width: 250px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="bed">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'bed ' + asc_desc }"
                (click)="sort('bed',asc_desc)" style="max-width: 150px;">
                <span>Debtor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width: 150px;">
                <div>
                    <span *ngIf="x.bed">{{ currency_title2 }}
                        {{x.bed | currency:'':'' }}
                    </span>

                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 150px;">
                <div class="money">
                    <span *ngIf="bed">{{ currency_title2 }} </span>
                    {{ bed | currency:'':'' }}
                </div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="best">
            <mat-header-cell *matHeaderCellDef style="max-width: 150px;"
                [ngClass]="{active1:order == 'best ' + asc_desc }" (click)="sort('best',asc_desc)">
                <span>Creditor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width: 150px;">
                <div>
                    <span *ngIf="x.best">{{ currency_title2 }} </span>
                    {{x.best | currency:'':'' }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 150px;">
                <div class="row">
                    <div class="money">
                        <span *ngIf="best">{{ currency_title2 }} </span>
                        {{ best | currency:'':'' }}
                    </div>
                </div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="remain">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'site_abortion_vin ' + asc_desc }"
                style="max-width: 150px;">
                <span>Balance</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width: 150px;">
                <div class="money">
                    <span *ngIf="x.remain2">{{ currency_title2 }} </span>
                    {{ x.remain2 | currency:'':'' }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 150px;">
                <div class="money">
                    <span *ngIf="remain">{{ currency_title2 }} </span>
                    {{ remain | currency:'':'' }}
                </div>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>