import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-cash-to-bank-filter',
  templateUrl: './cash-to-bank-filter.component.html',
  styleUrls: ['./cash-to-bank-filter.component.scss']
})
export class CashToBankFilterComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public token: any = Math.floor(Math.random() * 99999999999999);
  public loading: boolean = false;
  public form1_group: FormGroup | any;
  public subscription: Subscription | any;
  public search_array: any = [];
  public type_task!: number;
  public user_id!: number;
  public code!: number;
  public creator: number | undefined;
  public expire: boolean | undefined;
  public access_service: number | undefined;
  public level: number | undefined;

  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public matDialogRef: MatDialogRef<CashToBankFilterComponent>) {

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.code = dialog_data.code;
      if (this.type_task == 3) {
        if (this.dialog_data) this.creator = this.dialog_data.creator;
        this.form1_group.patchValue({
          comment: this.dialog_data.search_array.comment,
          date3: this.dialog_data.search_array.date3,
          date4: this.dialog_data.search_array.date4,
        })
      }
    }
  }//end consructor
  search() {
    this.matDialogRef.close({
      comment: this.form1_group.value.comment,
      date3: this.form1_group.value.date3,
      date4: this.form1_group.value.date4,
    })
  }
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'date3': new FormControl(null),
      'date4': new FormControl(null),
      'comment': new FormControl(null),
    });
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access3(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access3(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
          }
          if (type_task == 2) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
            }
          }
          if (type_task == 3) {
            this.search();
          }
          if (type_task == 4) {
          }
          if (type_task == 5) { }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_services(id: number): any {
    var code_id;
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator, code_id: code_id, obj: {} }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
        }
      }
    )
  }
  reset_input(id: number): any {
  }
  close() {
    this.matDialogRef.close();
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


