import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MaterialDetaileComponent } from "../material-detaile/material-detaile.component";
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { MessageService } from '../../../service/message/message.service';
import { WharehouseOrderSaleSearialComponent } from '../../wharehouse-order-sale-searial/wharehouse-order-sale-searial.component';

@Component({
  selector: 'app-material-list',
  templateUrl: './material-list.component.html',
  styleUrls: ['./material-list.component.scss']
})
export class MaterialListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public lang_text: any = [];
  public user_id: number | undefined;
  public loading = false;
  public loading2 = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public navigate: boolean = false;
  public show_excel: boolean = false;
  public excel: number = 1;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_all: string | undefined;
  public available: string = 'available';
  public wharehouse: boolean = false;
  public sum_stock: number = 0;

  public inventory: number = 0;
  public invoice_buy: number = 0;
  public consumed_reception: number = 0;
  public invoice_sale: number = 0;
  public invoice_back_than_buy: number = 0;
  public invoice_back_than_sale: number = 0;
  public creator: number | undefined;
  public video: string | undefined;
  public access_service: number | undefined;
  public level: number | undefined;
  public path_export: string | undefined;
  public sum_price: number = 0;
  public sum_price2: number = 0;
  public sum_all: number = 0;
  public material_id: number = 0;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public title: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'title', 'group', 'cate', 'code', 'inventory', 'operation'];
  //************** */
  constructor(
    public serverService: ServerService
    , public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , public dialogRef: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    );
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access('first', 4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  //****************************************************************************
  check_access(step: string, type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(step, type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    )
  }

  check_access2(step: string, type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));

            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));

            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
            var selected;
            if (typeof id == 'object') {
              selected = this.get_select_records(-1);
            } else {
              var selected = id;
            }
            this.delete(selected);
          }
          if (type_task == 4) {
            this.get_user();
            this.load_data(step, page, excel, loading, -1);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    )
  }

  get_user() {
    //this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6525, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.wharehouse = res['result'][0].user_wharehouse;
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    )
  }

  load_data2(type: string) {
    this.available = type;
    this.navigate = false;
    this.load_data(1, 1, 1, 1, -1);
  }

  load_data3() {
    this.navigate = true;
    this.available = '';
    this.load_data(1, 1, 1, 1, -1);
  }

  load_data(step, page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5 || page == 1) { this.page = 1; }
    var obj = {
      'address': 6436, "page": this.page, 'type': this.type
      , 'excel': excel
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'creator': this.creator
      , 'path_export': this.path_export
      , 'available': this.available
      , 'navigate': this.navigate
      , 'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                res['result'][i].average_price = -1;
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.sum_price = res['sum_price'];
            this.sum_price2 = res['sum_price2'];
            this.sum_stock = res['sum_inventory'];

            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reset({});
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));

        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  show_average_price(i: number, material_id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6735, material_id: material_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.list_record[i].average_price = res['price']
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  open_serial(material_id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6681, material_id: material_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.dialog.open(WharehouseOrderSaleSearialComponent, {
            width: '22rem',
            height: 'auto',
            data: { id: res['result'][0].wharehouse_order_id, title: this.title, document_type_text: 'input', order_buy_id: 0 }
          })

          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(MaterialDetaileComponent, {
      width: '30rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator, wharehouse: this.wharehouse }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[res.i].wharehouse = res.result.wharehouse;
            this.list_record[res.i].wharehouse_weight_title = res.result.wharehouse_weight_title;
            this.list_record[res.i].wharehouse_group_title = res.result.wharehouse_group_title;
            this.list_record[res.i].wharehouse_material_price = res.result.wharehouse_material_price;
            this.list_record[res.i].wharehouse_material_price2 = res.result.wharehouse_material_price2;
            this.list_record[res.i].wharehouse_material_title = res.result.wharehouse_material_title;
            this.list_record[res.i].wharehouse_material_coding = res.result.wharehouse_material_coding;
            this.list_record[res.i].wharehouse_material_sort = res.result.wharehouse_material_sort;
            this.list_record[res.i].wharehouse_material_cate_title = res.result.wharehouse_material_cate_title;
            this.list_record[res.i].status_title = res.result.status_title;
            this.list_record[res.i].inventory = res.result.inventory;
            this.update_attachment(id, res.token);
          }
          else if (type_task == 3) {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access('', 4, 1, 0, excel, 1);
          }
        }
      }
    )
  }

  update_attachment(id: number, token: number) {
    var obj = {
      address: 6532,
      id: id,
      user_id: this.user_id,
      creator: this.creator,
      token: token
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var pe_message = "خطا در ذخیره ضمیمه ها";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  delete(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));

      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 6040; } else { address = 6039; }
      var obj = {
        'address': address, "user_id": this.user_id
        , 'id': id, "page": this.page, 'type': this.type
        , search: this.search_array
        , search_all: this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_material_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.wharehouse_material_id == res['result_record'][i].wharehouse_material_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            this.pages(-res['result'].changedRows);
            this.load_data('', this.page, 1, 1, res['result'].changedRows);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.message_action(this.lang));
          }
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.message_action(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete

  go_to_cardex(id: number) {
    this.router.navigate(['/home/cardex/207'], { queryParams: { material: id } })
  }

  get_select(event: boolean): any {
    this.selected_record = [];
    this.get_select_all(event).then((res: any) => {
      if (res == this.list_record.length - 1) this.get_select_records(-1);
    }).catch((err: any) => { console.log(err); });
  }

  get_select_all(event: boolean): any {
    var finish;
    var promise = new Promise<void>((resolve, reject) => {
      this.indeterminate = false;
      this.select_all = event;
      for (var i = 0; i < this.list_record.length; i++) {
        this.list_record[i].checked = this.select_all;
        finish = i;
      }
      this.dataSource.data = this.list_record;
      return resolve()
    });
    return Promise.resolve(finish);
  }

  get_select_records(index: any) {
    if (index >= 0) {
      this.list_record[index].checked = !this.list_record[index].checked;
    }
    this.selected_record = [];
    var selected_record_temp;
    selected_record_temp = <any>document.getElementsByName('selete_records[]');
    for (var i = 0; i < selected_record_temp.length; i++) {
      if (selected_record_temp[i].checked == true) {
        this.selected_record.push(selected_record_temp[i].value);
      }
    }
    this.selected_record = this.selected_record.map((items: any) => {
      return parseInt(items);
    });
    if (this.selected_record.length != this.list_record.length && this.selected_record.length > 0) {
      this.indeterminate = true;
    } else {
      this.indeterminate = false;
    }
    return this.selected_record;
  }

  open_report(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));

      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.material_id = id;
    this.loading2 = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6125, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          this.inventory = res['result'][0].wharehouse_material_inventory;
          this.invoice_buy = res['result'][0].invoice_buy;
          this.invoice_sale = res['result'][0].invoice_sale;
          this.invoice_back_than_buy = res['result'][0].invoice_back_than_buy;
          this.invoice_back_than_sale = res['result'][0].invoice_back_than_sale;
          this.consumed_reception = res['result'][0].consumed_reception;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    )
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
      this.loading2 = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


