import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor() { }

  get_filter(lang) {
    return 'Filter';
  }
  get_new(lang) {
    return 'New';
  }
  get_search(lang) {
    return 'Search...';
  }
  get_all(lang) {
    return 'ALL';
  }
  get_today(lang) {
    return 'Today';
  }
  send_message(lang: number) {
    return "Message was sent successfully";
  }
  erorr_in_load(lang: number) {
    return "An error occurred while receiving";
  }
  erorr_in_save(lang: number) {
    return "An error occurred while saving";
  }
  erorr_in_send(lang: number) {
    return "An error occurred while sending";
  }

  message(lang: number, pe_message, en_message) {
    if (lang == 2) {
      return en_message;
    } else {
      return pe_message;
    }
  }
  internet(lang: number) {
    return "A network error occurred";
  }
  erorr_in_save_default(lang: number) {
    return "You can only set one option as default";
  }
  save(lang: number) {
    return "Saved successfully";
  }
  change(lang: number) {
    return "Changed successfully";
  }
  //******************************************************************************** */
  repeat(lang: number) {
    if (lang == 1) {
      return "عنوان باید منحصر به فرد باشد";
    } else {
      return "Please set a unique title";
    }
  }

  repeat2(lang: number) {
    if (lang == 1) {
      return "هر عنوان در هر دسته و گروه باید منحصر به فرد باشد";
    } else {
      return "Please set a unique title in each group";
    }
  }


  repeat3(lang: number) {
    if (lang == 1) {
      return "این رکورد قبلا اضافه شده است";
    } else {
      return "The record has been added before";
    }
  }
  //******************************************************************************** */
  access(lang: number) {
    if (lang == 1) {
      return "خطا در دسترسی";
    } else {
      return "Erorr in Access";
    }
  }

  access2(lang: number) {
    return "Unfortunately you don't have an access to this service";
  }
  //******************************************************************************** */
  close(lang: number) {
    return "Close";
  }
  add(lang: number) {
    return "Added.";
  }
  expire(lang: number) {
    return "We kindly request you to recharge your account as it has expired";
  }
  //******************************************************************************** */
  delete(lang: number, id: any, changedRows: number): string {
    var number; var text;
    if (typeof id == 'number') {
      if (lang == 1) {
        return "با موفقیت حذف شد";
      } else {
        return "It was successfully deleted";
      }
    }
    else {
      number = id.length;
      if (lang == 1) {
        return " تعداد " + changedRows + " رکورد از " + number + " رکوورد انتخابی شما با موفقیت حذف شدند";
      } else {
        return " Number " + changedRows + " Record of " + number + " Your selected record has been successfully deleted";
      }
    }
    return text;
  }

  delete2(lang: number) {
    if (lang == 1) {
      return "حذف شد";
    } else {
      return "Deleted";
    }
  }

  message_action(lang: number): string {
    if (lang == 1) {
      return 'بستن';
    } else {
      return 'close';
    }
  }

  message_delete(lang: number, id: any, changedRows: number): string {
    var number; var text;
    if (typeof id == 'number') {
      if (lang == 1) text = "با موفقیت حذف شد";
      else text = "Deleted successfully";
    }
    else {
      number = id.length;
      if (lang == 1) text = " تعداد " + changedRows + " رکورد از " + number + " رکوورد انتخابی شما با موفقیت حذف شدند";
      else text = " Number " + changedRows + " Record of " + number + " Your selected record has been successfully deleted ";
    }
    return text;
  }

  message_delete_alarm(lang: number) {
    if (lang == 1) {
      return "این رکورد در ماژول هایی در حال استفاده می باشد و قادر به حذف نخواهید بود";
    } else {
      return "It is not possible to delete this record as it is currently in use by other modules";
    }
  }

  message_delete_erorr(lang: number) {
    if (lang == 1) {
      return "خطا در حذف";
    } else {
      return "An error occurred while deleting";
    }
  }

  message_delete_accept(lang: number) {
    if (lang == 1) {
      return "آیا از حذف اطمینان دارید؟";
    } else {
      return "Are you sure to delete?";
    }
  }
  //********************************wharehouse************************************************ */
  wharehouse_inventory1(lang: number) {
    if (lang == 1) {
      return "تعدادی درج شده بیشتر ار خروجی انبار می باشد .لطفا اصلاح نمائید";
    } else { return "The selected quantity exceeds the available stock in the warehouse. Please choose a lower quantity"; }
  }

  wharehouse_alarm_cate(lang: number) {
    if (lang == 1) {
      return "برای درج زیرگروه نیاز است تا گروه انتخاب شده باشد";
    } else { return "In order to add a subgroup, you need to select a group first"; }
  }
  //********************************sms************************************************ */
  error_format_cellphone(lang: number) {
    return "The phone number format is not valid for sending text messages";
  }

  error_sharj_sms(lang: number) {
    return "You are unable to send text messages. Please recharge your account";
  }


  error_sharj_sms_with_save(lang: number) {
    return "The invoice was successfully recorded, but you are unable to send text messages. Please recharge your account";
  }

  message_sms_accept(lang: number) {
    return "Are you sure to send?";
  }

}
