<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>{{ serverService.get_title(3,lang) }}</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="material" class='wh-label'>
                    <span *ngIf="lang == 1"> نام کالا </span>
                    <span *ngIf="lang == 2"> Product Name </span>
                    <span (click)="reset_input(171)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="material_title" (click)="load_services(171) " readonly>
                    <input class="wh-input" formControlName="material" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="user" class='wh-label'>
                    <span *ngIf="lang == 1"> نام طرف حساب </span>
                    <span *ngIf="lang == 2"> Account Holder </span>
                    <span (click)="reset_input(161)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="user_title" (click)="open_user() " readonly>
                    <input class="wh-input" formControlName="user" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="date1" class='wh-label'>
                    <span *ngIf="lang == 1"> از تاریخ </span>
                    <span *ngIf="lang == 2"> Date from</span>
                    <input type="date" class="wh-input" matInput formControlName="date1" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="date2" class='wh-label'>
                    <span *ngIf="lang == 1"> تا تاریخ </span>
                    <span *ngIf="lang == 2"> Date to</span>
                    <input type="date" class="wh-input" matInput formControlName="date2" autocomplete="off">
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="search(1)">
            <span *ngIf="lang == 1">مشاهده ریز کالا</span>
            <span *ngIf="lang == 2"> Product card index</span>
        </button>
    </div>
</form>