<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1">خرید کالا</span>
        <span *ngIf="lang == 2">Purchase product</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="group_title" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه </span>
                    <span *ngIf="lang == 2"> Group </span>
                    <input class="wh-input" formControlName="group_title" required readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="cate_title" class='wh-label'>
                    <span *ngIf="lang == 1"> زیر گروه </span>
                    <span *ngIf="lang == 2"> Subgroup </span>
                    <input class="wh-input" formControlName="cate_title" required readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="material_title" class='wh-label'>
                    <span *ngIf="lang == 1"> نام کالا </span>
                    <span *ngIf="lang == 2"> Product Name </span>
                    <input class="wh-input" formControlName="material_title" required readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="number" class='wh-label'>
                    <span *ngIf="lang == 1"> تعداد </span>
                    <span *ngIf="lang == 2"> number </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" required
                        (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="cost" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت خرید </span>
                    <span *ngIf="lang == 2"> Purchase price </span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="discount" class='wh-label'>
                    <span *ngIf="lang == 1"> تخفیف </span>
                    <span *ngIf="lang == 2"> Discount </span>
                    <input class='wh-input' formControlName="discount" autocomplete="off" currencyMask>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding' [ngClass]="{en:lang==2}">
                <label for="comment" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description </span>
                    <input class="wh-input" formControlName="comment">
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
    </div>
</form>