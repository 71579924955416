<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span>Sell product</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(2)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding en'
                [ngClass]="{ width100:ref2 == 'requests'}">
                <label for="group" class='wh-label full-left-padding0'>
                    <span>Group</span>
                    <input class="wh-input" formControlName="group_title" (click)="load_services(223,0) " required>
                    <input class="wh-input" formControlName="group" [hidden]="true" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding en'
                [ngClass]="{ width100:ref2 == 'requests' }">
                <label for="cate" class='wh-label full-left-padding0'>
                    <span>Subgroup</span>
                    <input class="wh-input" formControlName="cate_title" (click)="load_services(184,0)" required>
                    <input class="wh-input" formControlName="cate" [hidden]="true" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding en'
                [ngClass]="{ width100:ref2 == 'requests'}">
                <label for="material" class='wh-label full-left-padding0'>
                    <span>Product Name</span>
                    <input class="wh-input" formControlName="material_title" (click)="load_services(171,0) " required>
                    <input class="wh-input" formControlName="material" [hidden]="true" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding en'
                [ngClass]="{ width100:ref2 == 'requests'}">
                <label for="number" class='wh-label full-left-padding0'>
                    <span>Number</span>
                    <input class='wh-input' formControlName="number" autocomplete="off" required
                        (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [hidden]="access_price_column_buy == false">
                <label for="price" class='wh-label full-left-padding0'>
                    <span>Purchase price</span>
                    <input class='wh-input' formControlName="price" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="cost" class='wh-label full-left-padding0'>
                    <span>Selling price</span>
                    <input class='wh-input' formControlName="cost" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="discount" class='wh-label full-left-padding0'>
                    <span>Discount</span>
                    <input class='wh-input' formControlName="discount" autocomplete="off" currencyMask required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [hidden]="ref == 'invoice' || level == 3">
                <label for="repairman" class='wh-label full-left-padding0'>
                    <span>Repairman Name</span>
                    <span (click)="reset_input(220,1)" [hidden]="level == 3">
                        <mat-icon class="close" class="input_search input_search_en">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="load_services(220,1)" readonly
                        [required]="required() && ref == 'reception'">
                    <input class="wh-input" formControlName="repairman" [hidden]="true" readonly
                        [required]="required() && ref == 'reception'" [attr.disabled]="level == 3 ? true : null">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [hidden]="ref == 'reception' || level == 3">
                <label for="seller" class='wh-label full-left-padding0'>
                    <span>Salesman Name</span>
                    <span (click)="reset_input(220,2)" [hidden]="level == 3">
                        <mat-icon class="close" class="input_search input_search_en">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="seller_title" (click)="load_services(220,2)" readonly
                        [required]="required() && ref == 'invoice'" [attr.disabled]="level == 3 ? true : null">
                    <input class="wh-input" formControlName="seller" [hidden]="true" readonly
                        [required]="required() && ref == 'invoice'" [attr.disabled]="level == 3 ? true : null">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding' [hidden]="level == 3">
                <label for="profit" class='wh-label full-left-padding0'>
                    <span>Profit commission</span>
                    <input class='wh-input' formControlName="profit" autocomplete="off" required
                        (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="tax" class='wh-label'>
                    <span (click)="reset_input(268,0)">
                        <mat-icon class="close" class="input_search input_search_en">close</mat-icon>
                    </span>
                    <span> Tax Type </span>
                    <input class="wh-input" formControlName="tax_title" (click)="load_services(268,0)" readonly>
                    <input class="wh-input" formControlName="tax" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="tax_price" class='wh-label'>
                    <span> Tax Rate </span>
                    <input class="wh-input" formControlName="tax_price" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="comment" class='wh-label full-left-padding0'>
                    <span>Description</span>
                    <input class="wh-input" formControlName="comment">
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_en">
            <span>Save</span>
        </button>
    </div>
</form>