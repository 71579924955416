import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-repairman-attach-detaile',
  templateUrl: './repairman-attach-detaile.component.html',
  styleUrls: ['./repairman-attach-detaile.component.scss']
})
export class RepairmanAttachDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();;
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public isOnline: any | undefined;
  public loading = false;
  public user_id: number | undefined;
  public subscription: Subscription | any;
  public form1: FormGroup;
  public id: number | undefined;

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public matDialogRef: MatDialogRef<RepairmanAttachDetaileComponent>) {
    if (dialog_data) {
      this.id = dialog_data.id;
    }
  }//end consructor
  ngOnInit() {
    this.create_form1();
    this.get_data();
  }
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6360, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1.patchValue({
              'title': res['result'][0].reception_items_attach_title,
              'comment': res['result'][0].reception_items_attach_comment
            })
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  create_form1() {
    this.form1 = new FormGroup({
      'title': new FormControl(),
      'comment': new FormControl()
    })
  }
  save() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6361
      , id: this.id
      , title: this.form1.value.title
      , comment: this.form1.value.comment
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ title: res['result'][0].reception_items_attach_title });
          this.message(false, "", 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      })
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

