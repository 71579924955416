import { Component, OnInit } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServerService } from '../../service/server/server.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageService } from '../../service/message/message.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: []
})
export class LoginComponent implements OnInit {
  public server: any = this.serverService.get_server();
  public server_main: any = this.serverService.get_server_main();
  public lang: any = JSON.stringify(this.serverService.get_lang());
  public dir: any = this.serverService.get_direction();
  public loading: boolean = false;
  public validation_cellphone: boolean = false;
  public subscription: Subscription | any;
  public form1: FormGroup | any;
  public form2: FormGroup | any;
  public result: any;
  public entering: boolean = false;
  public date1: string;
  public refrerr: any = 1;
  public show_language: boolean = false;
  constructor(
    public router: Router,
    public messageService: MessageService,
    public serverService: ServerService,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute) { }
  ngOnInit() {
    this.matSnackBar.dismiss();
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        if (params['token_login'] && params['userId']) {
          this.login2(params['token_login'], params['userId']);
        }
      }
    )
    this.create_form1()
    this.create_form2();
    var remember = JSON.parse(<any>localStorage.getItem("remember"));
    if (remember) {
      this.form1.patchValue({
        'username': remember.username,
        'password': remember.password,
        'remember': remember.remember,
      })
    }
    if (localStorage.getItem("refrerr")) {
      this.refrerr = localStorage.getItem("refrerr");
    }

    const reception_list_columns = localStorage.getItem("reception_list_columns");

    const profit_list_columns = localStorage.getItem("profit_list_columns");

    const profit2_list_columns = localStorage.getItem("profit2_list_columns");

    localStorage.clear();

    if (reception_list_columns) {
      localStorage.setItem("reception_list_columns", reception_list_columns);
    }

    if (profit_list_columns) {
      localStorage.setItem("profit_list_columns", profit_list_columns);
    }


    if (profit2_list_columns) {
      localStorage.setItem("profit2_list_columns", profit2_list_columns);
    }

  }
  create_form1() {
    this.form1 = new FormGroup({
      'username': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required]),
      'language': new FormControl(null),
      'lang': new FormControl(1, [Validators.required]),
      'remember': new FormControl(null),
    })
  }
  create_form2() {
    this.form2 = new FormGroup({
      'code': new FormControl(null, [Validators.required, Validators.pattern('[0-9]{1,5}')]),
    })
  }
  //**********************************************************قسمت پایین لاگین به نرم افزار می باشد - لاگین دو مرحله ای هم موجود می باشد*************************************** */
  login(step: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (!this.form1.value.username || this.form1.value.username.length == 0 || !this.form1.value.password || this.form1.value.password.length == 0) {
      var message = "It is mandatory to fill in the username and password and select the language.";
      this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
      return false;
    }
    this.loading = true;
    var obj = {
      address: 1040,
      "username": this.form1.value.username,
      "password": this.form1.value.password,
      "code": this.form2.value.code,
      "step": step
    }
    this.subscription = this.serverService.post_address_login(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.date1 = res['date1'];
            if (res['result'][0].user_userName === this.form1.value.username && res['result'][0].user_repassword === this.form1.value.password) {
              if (res['result'][0].user_login_with_sms == 1 && step == 1) {
                this.check_inventory_sms(res['result'][0])
              }
              else {
                this.set_login(res['result'][0], 1);
              }
            }
            else {
              var message;
              if (step == 1) {
                message = "Username or password is wrong";
              }
              if (step == 2) {
                message = "The entered code is wrong";
              }
              this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
            }
          }//end if
          else {
            message = "Username or password is wrong";
            this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
          }
          this.loading = false;
        }//end else if  
        else {
          message = "Username or password is wrong";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }//emd else
      }
    )
  }//end login
  login2(token_login: number, userId: number) {
    this.loading = true;
    var obj = {
      'address': 6743,
      "token_login": token_login,
      "userId": userId
    }
    this.subscription = this.serverService.post_address_login(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.set_login(res['result'][0], 2)
            this.router.navigate(["/home/" + res['result'][0].site_services_link + "/" + res['result'][0].site_services_id]);
          }//end if
          else {
            var message = "Username or password is wrong";
            this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
          }
          this.loading = false;
        }//end else if  
        else {
          var message = "Username or password is wrong";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }//emd else
      }
    )
  }
  check_inventory_sms(result: any) {
    var creator;
    if (result.user_level == 2) creator = result.user_id;
    else if (result.user_level == 3) creator = result.user_creator;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6312, creator: creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['result'][0].amount >= 100) {
            this.validation_cellphone = true;
            this.send_sms(result.user_cellphone, result.user_id, creator);
          }
          else {
            this.set_login(result, 1);
            alert("اعتبار کافی برای ورود دو مرحله ای با پیامک ندارید.لطفا بعد از ورود مقدار پیامک خود را شارژ نمائید و یا از منوی پروفایل تیک گزینه دو مرحله ای را بردارید.")
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  send_sms(cellphone: number, user: number, creator: number) {
    var obj = {
      'address': 6654,
      'cellphone': cellphone,
      'user': user
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.log_sms(res['response'].body.entries[0].cost, creator);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  log_sms(cost: number, creator: number) {
    var obj = {
      'address': 6308,
      'creator': creator,
      'cost': cost
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var message = "Error in recording the sent SMS log";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  sign_in() {
    this.router.navigate(['/register2'], { queryParams: { step: 1 } })
  }
  set_login(result: any, step: number) {
    if (this.refrerr == 2) {
      localStorage.setItem('refrerr', '2');
    }
    var user_info = {
      user_id: result.user_id,
      user_creator: result.user_creator2,
      user_title: result.user_title,
      user_store: result.user_store,
      user_token: result.user_token,
      user_username: result.user_userName,
      user_password: result.user_repassword,
      remember: this.form1.value.remember,
    };
    if (this.form1.value.remember == true) {
      var status_remember = {
        remember: 1,
        username: this.form1.value.username,
        password: this.form1.value.password,
      }
      localStorage.setItem("remember", JSON.stringify(status_remember));
    }
    localStorage.setItem("user_info", JSON.stringify(user_info));
    localStorage.setItem('status', '1');
    localStorage.setItem("lang", JSON.stringify(this.lang));
    localStorage.setItem("dir", JSON.stringify(this.dir));
    this.router.navigate(['/home/' + result.site_services_link + '/' + result.site_services_id]);
    if (step == 1) {
      this.insert_log(result.user_userName, result.user_id);
      this.update_last_login(result.user_id);
      this.server_update_last_login(result.user_id);
      this.get_today_order();
      this.get_today_order2();
    }
  }
  insert_log(username: string, user_id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6572,
      'username1': this.form1.value.username,
      'username2': username,
      'user_id': user_id,
      'password': this.form1.value.password,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_last_login(user_id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6344, user_id: user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

        }//end if
        else {
          var pe_message = "خطا در ثبت ورود کاربر";
          var en_message = "Error registering user login";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  server_update_last_login(user_id: number) {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6388, user_id: user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var pe_message = "خطا در ثبت ورود کاربر";
          var en_message = "Error registering user login";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************* */
  get_today_order() {
    var obj = {
      address: 6577,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.set_consumed(res['result'][i].wharehouse_order_consumed_invoice, res['result'][i].wharehouse_order_id);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_today_order2() {
    var obj = {
      address: 6594,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.set_consumed(res['result'][i].wharehouse_order_consumed_invoice, res['result'][i].wharehouse_order_id);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  set_consumed(order_id: number, order_id2: number) {
    var obj = {
      address: 6446,
      order_id: order_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.set_order_check(order_id2);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  set_order_check(order_id: number) {
    var obj = {
      address: 6578,
      order_id: order_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_show_language() {
    this.show_language = !this.show_language;
  }
  //******************************************************************************** */
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 


}//end class