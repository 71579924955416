import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription | any;
  public list_user: any = [];
  public title_search: string | undefined;
  public creator: number | undefined;
  public code: any | undefined;
  public user_id: number | undefined;
  public back: number | undefined;
  public user: number | undefined;
  public document_type_text: number | undefined;
  public ref: any | undefined;
  public currency_title2: string;
  public show_cellphone: boolean = true;
  public dataSource: any;
  public displayedColumns: any = ['row', 'user', 'cellphone', 'phone', 'balance'];
  public current_user_level: number = 0;
  public current_user_group_title: string = "";
  public show_ramin_column: boolean = true;
  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<UserSearchComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {

    if (dialog_data) {
      this.ref = dialog_data.ref;
      this.code = dialog_data.code;
      this.back = dialog_data.back;
      this.user = dialog_data.user;
      this.document_type_text = dialog_data.document_type_text;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
      this.check_user_is_repairman();
    }
    this.check_access(1, 1);
  }

  check_user_is_repairman() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1660, id: this.user_id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {

          this.current_user_level = res['result'][0].user_level;

          this.current_user_group_title = res['result'][0].user_groups_title;

          if ((this.current_user_level == 3) && (this.current_user_group_title == "تعمیرکاران")) {
            this.show_ramin_column = false;
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.creator = res['creator'];
            this.show_cellphone = res['show_cellphone'];
            this.get_currency();
            this.get_user(1);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_currency() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6853, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.currency_title2 = res['result'][0].site_currency_title2;
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_user(type: number) {
    if (!this.creator) {
      return false;
    }
    if (type == 1) this.loading = true;
    //1731
    var obj = {
      address: 6799, title: this.title_search
      , creator: this.creator
      , back: this.back
      , document_type_text: this.document_type_text
      , ref: this.ref
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_user = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_user.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_user);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  select(id: number, title: string) {
    this.matDialogRef.close({ id: id, title: title })
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

