<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row direction_ltr dflex">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='open_detaile(0,1,0)' [disabled]='new_status'>
                    <span>{{ messageService.get_new(lang) }}</span>
                </button>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <button class="search1" (click)='openchart()' hidden>
                  <span>
             Chart
                  </span>
                </button>
                <div class="pager_en">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                -
                <span class="filter" *ngIf="level == 1 || similar_admin">
                    <button (click)='load_data2("active",0)' [ngClass]="{active1:tsearch == 'active' && free == 0}">
                        <span>Active (free)</span>
                    </button>
                    <button (click)='load_data2("active",1)' [ngClass]="{active1:tsearch == 'active' && free == 1}">
                        <span>Active (paid)</span>
                    </button>
                    <button (click)='load_data2("expire",0)' [ngClass]="{active1:tsearch == 'expire' && free == 0}">
                        <span>Expired (free)</span>
                    </button>
                    <button (click)='load_data2("expire",1)' [ngClass]="{active1:tsearch == 'expire' && free == 1}">
                        <span>Expired (paid)</span>
                    </button>
                </span>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
                <mat-checkbox name="show_more" [(ngModel)]="show_more" (click)="get_show_hiddens()"></mat-checkbox>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_en_without_scroll en1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px' (click)="reset_sort('user_id','desc')">
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' class="cell1 "
                (click)="save_id2(x.user_id)">{{ i + page_number_first
                }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="country">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'user_country_title ' + asc_desc }"
                (click)="sort('user_country_title',asc_desc)">
                <span>Country</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'>
                <div>{{ x.user_country_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="cellphone">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'user_cellPhone ' + asc_desc }"
                (click)="sort('user_cellPhone',asc_desc)">
                <span>Cellphone Number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                class="center">
                <div *ngIf="show_cellphone == true" class="cellphone">{{
                    x.user_cellPhone }}</div>
                <div *ngIf="show_cellphone == false" class="cellphone">{{
                    x.user_cellPhone | hide_cellphone
                    }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef style="min-width: 350px;"
                [ngClass]="{active1:order == 'user_title ' + asc_desc }" (click)="sort('user_title',asc_desc)">
                <span>Name</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 350px;">
                <div class="div2 left fleft" (click)='open_detaile(i,2,x.user_id)'>
                    {{ x.user_sex_title }} {{ x.user_title }}
                </div>
                <span (click)="go_to_user2(x.user_id,x.user_token_login)" class="cursor fright">
                    <i class="fa fa-eye icon"></i>
                </span>
                <span class="fright" (click)="send_sms(x.user_id)"><i class="far fa-comments"></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 350px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="last_login">
            <mat-header-cell *matHeaderCellDef style="max-width: 140px;min-width: 140px;"
                [ngClass]="{active1:order == 'user_last_login ' + asc_desc }"
                (click)="sort('user_last_login',asc_desc)">
                <span>Last login date</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="max-width: 140px;min-width: 140px;"
                [ngStyle]="{background:date == x.user_last_login ? '#a7e9a7' : 'transparent' }"
                (click)='open_detaile(i,2,x.user_id)'>
                {{ x.user_last_time }} - {{ x.user_last_login }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 140px;min-width: 140px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="sms_charge">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'user_sms_charge ' + asc_desc }"
                (click)="sort('user_sms_charge',asc_desc)">
                <span>SMS amount</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'>
                {{ x.user_sms_charge | currency:'':'' }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width: 140px;min-width: 140px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="copy">
            <mat-header-cell *matHeaderCellDef>
                <span>Default values</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" class="cell1">
                <div *ngIf="x.user_copy == false">
                    <span (click)="accept(i,6140,x.user_id,x.site_guilds_user)" *ngIf="!loading_copy">
                        <mat-icon style="font-size: 1rem;">content_copy</mat-icon>
                    </span>
                    <div class="spinner-border spinner-border-sm" role="status" *ngIf="loading_copy">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div *ngIf="x.user_copy == true"> <i class="fas fa-check icon" style="color:green;"></i> </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="copy_user">
            <mat-header-cell *matHeaderCellDef style='max-width:40px' [hidden]="!show_more">
                <span>Copy</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' class="cell1 "
                (click)="copy(x.user_id,x.user_token)" [hidden]="!show_more">
                <i class="fas fa-copy"></i>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px' [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="store">
            <mat-header-cell *matHeaderCellDef style="min-width: 200px;"
                [ngClass]="{active1:order == 'user_store ' + asc_desc }" (click)="sort('user_store',asc_desc)">
                <span>Company/ Store name</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" style="min-width: 200px;"
                (click)='open_detaile(i,2,x.user_id)'>
                <div class="left">{{ x.user_store }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="min-width: 200px;"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="similar_admin">
            <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="!show_more"
                [ngClass]="{active1:order == 'user_similar_admin ' + asc_desc }"
                (click)="sort('user_similar_admin',asc_desc)">
                <span>Pseudo admin</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px' [hidden]="!show_more">
                <span (click)='change_similar_admin(i,x.user_similar_admin,x.user_id)'>
                    <span *ngIf="x.user_similar_admin == 0"><i class="far fa-star icon"></i> </span>
                    <span *ngIf="x.user_similar_admin == 1"><i class="fas fa-star icon" style="color: blue;"></i>
                    </span>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px' [hidden]="!show_more"> </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="clear_token">
            <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="!show_more"
                [ngClass]="{active1:order == 'site_abortion_vin ' + asc_desc }"
                (click)="sort('site_abortion_vin',asc_desc)">
                <span>Change token</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px;padding:0.2rem !important'
                [hidden]="!show_more">
                <span (click)='clear_token(i,x.user_id)'>
                    <mat-icon>delete_sweep</mat-icon>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px' [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="guilds">
            <mat-header-cell *matHeaderCellDef style='max-width:250px;min-width:250px;'
                [ngClass]="{active1:order == 'site_guilds_title ' + asc_desc }"
                (click)="sort('site_guilds_title',asc_desc)">
                <span>Guild</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:250px;min-width:250px;' class="cell1"
                (click)='open_detaile(i,2,x.user_id)'>
                <div class="left">{{ x.site_guilds_title |
                    substr:0:30 }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:250px;min-width:250px;'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef style="padding-top:0.3rem !important" [hidden]="!show_more"
                [ngClass]="{active1:order == 'user_amount ' + asc_desc }" (click)="sort('user_amount',asc_desc)">
                <span>Monthly fee</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="!show_more">
                {{ x.user_amount | number}} {{ x.site_currency_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'user_username ' + asc_desc }" (click)="sort('user_username',asc_desc)">
                <span>Username</span>
            </mat-header-cell>
            <mat-cell class=" cursor" *matCellDef="let x;let i = index" (click)='open_detaile(i,2,x.user_id)'
                [hidden]="!show_more">
                <div class="left">{{ x.user_username }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'user_date ' + asc_desc }" (click)="sort('user_date',asc_desc)">
                <span>register date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="!show_more">{{
                x.user_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="expire">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'user_expire_date ' + asc_desc }"
                (click)="sort('user_expire_date',asc_desc)">
                <span>Expiration date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="!show_more">{{
                x.user_expire_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="access">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'user_count_access ' + asc_desc }"
                (click)="sort('user_count_access',asc_desc)">
                <span>Access</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="set_and_get_number_access(i,x.user_id)" class="cell1"
                [hidden]="!show_more">{{
                x.user_count_access }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="reception">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'count_reception ' + asc_desc }"
                (click)="sort('count_reception',asc_desc)">
                <span>Reception</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="!show_more">{{
                x.count_reception }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="invoice">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'count_invoice ' + asc_desc }" (click)="sort('count_invoice',asc_desc)">
                <span>Invoices</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="!show_more">{{
                x.count_invoice }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="finance">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'count_finance ' + asc_desc }" (click)="sort('count_finance',asc_desc)">
                <span>Financial affairs</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="!show_more">{{
                x.count_finance }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'user_groups_title ' + asc_desc }"
                (click)="sort('user_groups_title',asc_desc)">
                <span>User groups</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="!show_more">{{
                x.user_groups_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef [hidden]="!show_more"
                [ngClass]="{active1:order == 'user_id ' + asc_desc }" (click)="sort('user_id',asc_desc)">
                <span>ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_id)" class="cell1"
                [hidden]="!show_more">{{
                x.user_id }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="!show_more"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px'
                [ngClass]="{active1:order == 'site_abortion_vin ' + asc_desc }"
                (click)="sort('site_abortion_vin',asc_desc)">
                <span>Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px' class="cell1">
                <span (click)="check_access(3,0,x.user_id,0,1)" style="color:red"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:50px'></mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow  || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>

</div>
