<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>Preview Text Message</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent">
    <div class="row area_code_box">
        <div>
            SMS Recipient: <span class="area_code">+{{ area_code }}</span> {{ userCellphone }}
        </div>
    </div>
    <div class="row textBox">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 text">
            Dear  {{user_title}}
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 text">
            {{ text }}
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 text">
            <a href="{{ Link}}" target="_blank">
                Link
            </a>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 text">
            {{ user_store }}
        </div>
    </div>
    <div class="row">
        <div class="alarm alarm_box2" *ngIf="head_number">
            The text messaging service is active in your account.
        </div>
        <div class="alarm alarm_box" *ngIf="!head_number">
            Text messaging service is disabled in your account.
        </div>
    </div>

    <div class="row" style="margin-top:1rem">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 padding0">
            Text message credit balance: $ {{ sms_charge | currency:'':' '}}
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xl-12 padding0">
            The cost to send this text message is: $ {{ cost_sms }}
        </div>
    </div>

</div>

<div mat-dialog-actions class="pe1 matDialogActions">


    <button type="button" class="btn" [ngClass]="{green:head_number && cost_sms && text}" (click)="check_chrge_sms()"
        [disabled]="!head_number || !cost_sms || !text" style="min-width: 6rem;">
        <span [hidden]="loading">
            Send Sms
        </span>
        <span [hidden]="!loading">
            <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status">
                <span class="visually-hidden"></span>
            </div>
        </span>

    </button>

    <button type="button" class="btn" [ngClass]="{green:head_number}" (click)="RedirectWhatsApp()"
    [disabled]="!head_number || !text" style="min-width: 6rem;">
    <span [hidden]="loading">
      Send With Whats App
    </span>
    <span [hidden]="!loading">
      <div class="spinner-border" style="width: 1rem; height: 1rem;" role="status">
        <span class="visually-hidden"></span>
      </div>
    </span>

  </button>


</div><!-- row -->
