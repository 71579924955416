import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { TabComponent } from '../../service/menu/tab/tab.component';
import { MenuItemsComponent } from '../../service/menu/menu-items/menu-items.component';
import { HttpClient } from '@angular/common/http';
import { InvoiceOfPersonComponent } from '../../finance/invoice-of-person/invoice-of-person.component';
import { CardexDetaileComponent } from '../../wharehouse/wharehouse-cardex/cardex-detaile/cardex-detaile.component';
import { UserVideoComponent } from '../../user/user-video/user-video.component';
import { FinanaceCardexDetaileComponent } from '../../finance/finanace-cardex-detaile/finanace-cardex-detaile.component';
import { MyProfileComponent } from '../../user/my-profile/my-profile.component';
import { DOCUMENT } from '@angular/common';
import { MessageService } from '../../service/message/message.service';
import { AccountRenewalEnComponent } from '../account-renewal-en/account-renewal-en.component';
import { BuySmsComponent } from '../buy-sms/buy-sms.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public site: string = this.serverService.get_site();
  @ViewChild('myModalPassword', { static: true }) myModalPassword: any;
  public loading: boolean = false;
  public network_access: boolean = false;
  public wallet: number = 0;
  public tab_id: number = 156;
  public list_tab: any = [];
  public list_tab_temp: any = [];
  public list_section: any = [];
  public list_section_temp: any = [];
  public list_rstatus_in_menu: any = [];
  public list_rstatus: any = [];
  public list_menu: any = [];
  public list_menus: any = [];
  public list_cate: any = [];
  public list_software: any = [];
  public list_cheque_status: any = [];
  public list_finance_document: any = [];
  public list_groups: any = [];
  public search_array_bank: any = [];
  public list_bascket_status: any = [];
  public setting_menu: boolean = false;
  public show_extra_menu: boolean = true;
  public loading2: boolean = false;
  public amount2: number = 0;
  public month_extension: number = 0;
  public code_bascket: number = 0;
  public subscription: Subscription | any;
  public fname!: string;
  public lname!: string;
  public sum_charge!: number;
  public code!: number;
  public uploadedAvaterProgess!: number;
  public user_id!: number;
  public user_store!: string;
  public rstatus_id!: number;
  public admin!: number;
  public services_id!: number;
  public groups_title!: string;
  public job!: number;
  public user_amount!: number;
  public menu_id!: number;
  public menu_id_temp!: number;
  public user_creator!: number
  public refrerr!: any;
  public search_finance!: string;
  public search_finance_cardex!: string;
  public search_finance_newspaper!: string;
  public search_material!: string;
  public level!: number;
  public creator!: number;
  public similar_admin!: number;
  public expire!: boolean;
  public expire_date!: string;
  public user_setting_menu!: number;
  public amount!: number;
  public group_id!: number;
  public now_date!: string;
  public bascket_status!: number;
  public token_login!: any;
  public last_date_update!: string;
  public Status_pay!: string;
  constructor(
    public router: Router,
    public serverService: ServerService,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public http: HttpClient,
    public messageService: MessageService,
    public activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) public document: any
  ) {
    this.serverService.get_services_id().subscribe(
      (res) => {
        this.get_services_id(res);
      }
    )
    this.serverService.get_reset_services_id().subscribe(
      (res) => {
        this.rstatus_id = 0;
      }
    )
    this.serverService.get_sms_charge().subscribe(
      (res) => {
        this.get_sms();
      }
    )
    this.serverService.get_expire_date().subscribe(
      () => {
        this.get_expire_date();
      }
    )

    this.serverService.get_tab().subscribe(
      (res: any) => {
        if (res.action == 'insert') {
          this.list_tab.push(res.result);
          this.list_tab_temp[res.result.site_tab_id] = new Array();
        }
        if (res.action == 'update') {
          for (var i = 0; i < this.list_tab.length; i++) {
            if (this.list_tab[i].site_tab_id == res.result.id) {
              this.list_tab[i].site_tab_title = res.result.title;
            }
          }
        }
        if (res.action == 'delete') {
          for (var i = 0; i < this.list_tab.length; i++) {
            if (this.list_tab[i].site_tab_id == res.result) {
              this.list_tab.splice(i, 1)
            }
          }
        }//end if delete tab
        if (res.action == 'sort') {
          for (var x = 0; x < this.list_tab.length; x++) {
            for (var i = 0; i < res.result.data.length; i++) {
              if (this.list_tab[x].site_tab_id == res.result.data[i].site_tab_id) {
                this.list_tab[x].site_tab_sort = i + 1;
              }
            }
          }
          this.list_tab.sort(this.compare_tab);
        }
        if (res.action == 'select') {
          if (res.result) {
            $(document).ready(function () {
              $(".active").removeClass("active");
              $("#a" + res.result).addClass("active");
              $("#" + res.result).addClass("active");
            })
          }
        }
        if (res.action == 'status') {
          if (res.result.site_tab_status == 2) {
            for (var i = 0; i < this.list_tab.length; i++) {
              if (this.list_tab[i].site_tab_id == res.result.site_tab_id) {
                this.list_tab.splice(i, 1)
              }
            }
          }
          else {
            this.list_tab.push(res.result);
            this.list_tab.sort(this.compare_tab);
          }
        }
      }
    )

    this.serverService.get_section().subscribe(
      (res: any) => {
        if (res.action == 'insert') {
          this.list_section.push(res.result.data);
          this.list_tab_temp[res.result.tab_id].push(res.result.data);
          this.list_tab_temp[res.result.tab_id].sort(this.compare_section);
        }
        if (res.action == 'update') {
          for (var i = 0; i < this.list_section.length; i++) {
            if (this.list_section[i].site_section_id == res.result.id) {
              this.list_section[i].site_section_title = res.result.title;
            }
          }
        }
        if (res.action == 'delete') {
          for (var i = 0; i < this.list_tab_temp[res.result.tab_id].length; i++) {
            if (this.list_tab_temp[res.result.tab_id][i].site_section_id == res.result.section_id) {
              this.list_tab_temp[res.result.tab_id].splice(i, 1);
            }
          }
        }//end if delete tab
        if (res.action == 'sort') {
          for (var x = 0; x < this.list_tab_temp[res.result.tab_id].length; x++) {
            for (var i = 0; i < res.result.data.length; i++) {
              if (this.list_tab_temp[res.result.tab_id][x].site_section_id == res.result.data[i].site_section_id) {
                this.list_tab_temp[res.result.tab_id][x].site_section_sort = i + 1;
              }
            }
          }
          this.list_tab_temp[res.result.tab_id].sort(this.compare_section);
        }
        if (res.action == 'status') {
          if (res.result.data.site_section_status == 2) {
            for (var i = 0; i < this.list_section.length; i++) {
              if (this.list_section[i].site_section_id == res.result.data.site_section_id) {
                this.list_section.splice(i, 1);
              }
            }
            for (var i = 0; i < this.list_tab_temp[res.result.tab_id].length; i++) {
              if (this.list_tab_temp[res.result.tab_id][i].site_section_id == res.result.data.site_section_id) {
                this.list_tab_temp[res.result.tab_id].splice(i, 1);
              }
            }
          }
          else {
            this.list_section.push(res.result.data);
            this.list_tab_temp[res.result.tab_id].push(res.result.data);
            this.list_tab_temp[res.result.tab_id].sort(this.compare_section);
          }
        }
      }
    )
    this.serverService.get_menu2().subscribe(
      (res) => {
        this.list_tab = [];
        this.list_tab_temp = [];
        this.list_menus = [];
        this.list_section = [];
        this.get_tab();
      }
    )
    this.serverService.get_menu().subscribe(
      (res: any) => {
        if (res.action == 'insert') {
          this.list_menus[res.result.section_id] = [];
          for (var i = 0; i < res.result.data.length; i++) {
            if (res.result.data[i].site_services_img) {
              res.result.data[i].src = res.result.data[i].site_services_site + "/" + res.result.data[i].site_services_img;
            } else {
              res.result.data[i].src = this.serverService.get_default_image();
            }

            if (res.result.data[i].site_services_status == 1) {
              this.list_menus[res.result.section_id].push(res.result.data[i]);
            }

          }
        }
        if (res.action == 'remove') {
          for (var i = 0; i < this.list_menus[res.result.section_id].length; i++) {
            if (this.list_menus[res.result.section_id][i].site_menu_id == res.result.menu_id) {
              this.list_menus[res.result.section_id].splice(i, 1);
            }
          }
        }
        if (res.action == 'update') {
          for (var i = 0; i < this.list_menus[res.result.section_id].length; i++) {
            if (this.list_menus[res.result.section_id][i].site_services_id == res.result.id) {
              this.list_menus[res.result.section_id][i].site_services_title = res.result.title;
            }
          }
        }
        if (res.action == 'logo') {
          for (var i = 0; i < this.list_menus[res.result.menu_section].length; i++) {
            if (this.list_menus[res.result.menu_section][i].site_menu_id == res.result.menu_id) {
              if (res.result.data.path) {
                this.list_menus[res.result.menu_section][i].src = res.result.data.site + "/" + res.result.data.path;
              } else {
                this.list_menus[res.result.menu_section][i].src = this.serverService.get_default_image();
              }
            }
          }
        }
        if (res.action == 'status') {
          if (res.result.data.site_services_status == 2) {
            for (var i = 0; i < this.list_menus[res.result.section_id].length; i++) {
              if (this.list_menus[res.result.section_id][i].site_services_id == res.result.data.site_services_id) {
                this.list_menus[res.result.section_id].splice(i, 1);
              }
            }
          }
          else {
            if (res.result.data.site_services_img) {
              res.result.data.src = res.result.data.site_services_site + "/" + res.result.data.site_services_img;
            } else {
              res.result.data.src = this.serverService.get_default_image();
            }
            this.list_menus[res.result.section_id].push(res.result.data);
            this.list_menus[res.result.section_id].sort(this.compare_menus);
          }
        }
      }
    )
  }
  select(id: number) {
    this.tab_id = id;
    $(".active").removeClass("active");
    $("#a" + id).addClass("active");
    $("#" + id).addClass("active");
  }
  //**************************************************************************************** */
  ngOnInit() {
    if (!this.token_login) this.serverService.status1(2);
    this.refrerr = JSON.parse(<any>localStorage.getItem('refrerr'));
    if (this.user_info) {
      this.user_store = this.user_info.user_store;
    }
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
      this.admin = this.user_info.admin;
      this.groups_title = this.user_info.groups_title;
      this.job = this.user_info.job;
      this.user_creator = this.user_info.user_creator;
    }
    if (!this.token_login && this.user_info) this.get_logo();
  }
  insert_reminder_log() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6316, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
      }
    )
  }
  open_show_extra_menu() {
    this.show_extra_menu = !this.show_extra_menu;
  }
  get_tab(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 1004, lang: this.lang, software_id: this.serverService.get_software_id() }).subscribe(
      (res: any) => {
        this.list_tab = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].site_tab_title) {
              res['result'][i].width = Number(res['result'][i].site_tab_title.length + 5);
            }
            this.list_tab.push(res['result'][i]);
            this.list_tab_temp[res['result'][i].site_tab_id] = new Array();
          }//end for
          this.tab_id = res['result'][0].site_tab_id;
          this.get_section();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end get_tab
  get_section() {
    this.loading = true;
    var obj = {
      address: 1021
      , lang: this.dir
      , software_id: this.serverService.get_software_id()
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_section = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_section.push(res['result'][i]);
            for (var j = 0; j < this.list_tab.length; j++) {
              this.list_tab[j].show = false;
              if (this.list_tab[j].site_tab_id == res['result'][i].site_section_tab) {
                this.list_tab_temp[this.list_tab[j].site_tab_id].push(res['result'][i]);
              }
            }
            this.list_menus[res['result'][i].site_section_id] = new Array();
          }//end for
          this.get_menu();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end get_section
  open_add_section() {
    const dialogRef = this.dialog.open(TabComponent, {
      width: '60rem',
      height: 'auto',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        //this.get_tab();
      }
    )
  }
  compare_tab(a: any, b: any): any {
    let comparison = 0;
    if (a.site_tab_sort > b.site_tab_sort) {
      comparison = 1;
    } else if (a.site_tab_sort < b.site_tab_sort) {
      comparison = -1;
    }
    return comparison;
  }
  compare_section(a: any, b: any): any {
    let comparison = 0;
    if (a.site_section_sort > b.site_section_sort) {
      comparison = 1;
    } else if (a.site_section_sort < b.site_section_sort) {
      comparison = -1;
    }
    return comparison;
  }
  compare_menus(a: any, b: any): any {
    let comparison = 0;
    if (a.site_menu_sort > b.site_menu_sort) {
      comparison = 1;
    }
    else if (a.site_menu_sort < b.site_menu_sort) {
      comparison = -1;
    }
    return comparison;
  }
  get_services_id(id: number): any {
    this.menu_id = id;
  }
  open_menu(i: number, link: string, id: number, title: string, section: any) {
    this.matSnackBar.dismiss();
    this.services_id = id;
    this.menu_id_temp = this.menu_id;
    this.menu_id = id;
    if (this.setting_menu == true) {
      this.open_menu_item(i, id, title, section);
      this.menu_id = id;
      this.rstatus_id = 0;
    }
    else if (id == 202) {
      this.invoice_of_person(id);
    }
    else if (id == 226) {
      this.cardex_person(id);
    }
    else if (id == 205) {
      this.open_cardex();
    }
    else if (id == 222) {
      this.open_profile();
    }
    else {
      //********** */
      this.search_material = "";
      this.search_array_bank = "";
      this.search_finance_cardex = "";
      this.search_finance_newspaper = "";
      this.search_finance = "";
      //********** */
      this.router.navigate(['/home', link, id]);
      this.menu_id = id;
      this.rstatus_id = 0;
    }
  }
  open_profile() {
    this.rstatus_id = 0;
    this.services_id = 0;
    const dialogRef = this.dialog.open(MyProfileComponent, {
      width: '50rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: 2, id: this.user_id, code: 222, ref: 'menu' }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        this.menu_id = this.menu_id_temp;
      }
    )
  }
  //******************************************************************************** */
  get_sms(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6872, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.sum_charge = res['result'][0].user_sms_charge;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //******************************************************************************** */
  openRenwqDialog() {
    this.dialog.open(AccountRenewalEnComponent, {
      width: '35rem',
      height: 'auto',
      disableClose: false,
      data: { user_id: this.user_id, creator: this.creator, expire_date: this.expire_date, calltype: 2 }
    })
  }
  openBuySmsDialog() {
    this.dialog.open(BuySmsComponent, {
      width: '35rem',
      height: 'auto',
      disableClose: false,
      data: { user_id: this.user_id, creator: this.creator, expire_date: this.expire_date, calltype: 2 }
    })
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: 241 }
    })
  }
  //******************************************************************************** */
  open_cardex() {
    const dialogRef = this.dialog.open(CardexDetaileComponent, {
      'width': '22rem',
      'height': 'auto',
      data: { res: this.search_material, 'ref': 'home', creator: this.creator }
    })
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.search_material = res;
          this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
              if (params['search'] == undefined) {
                this.menu_id = this.menu_id_temp;
              }
            }
          )
        }
      }
    )
  }
  invoice_of_person(id: number) {
    const dialogRef = this.dialog.open(InvoiceOfPersonComponent, {
      width: '22rem',
      height: 'auto',
      data: { res_search_finance: this.search_finance, creator: this.creator, id: id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.search_finance = res;
          this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
              if (params['search'] == undefined) {
                this.menu_id = this.menu_id_temp;
              } else {
                if (res.close == true) {
                  this.menu_id = this.menu_id_temp;
                }
              }
            }
          )
        }
      }
    )
  }
  cardex_person(id: number) {
    const dialogRef = this.dialog.open(FinanaceCardexDetaileComponent, {
      width: '22rem',
      height: 'auto',
      data: { res_search_finance_newspaper: this.search_finance_newspaper, res_search_finance_cardex: this.search_finance_cardex, creator: this.creator, id: id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 226) this.search_finance_cardex = res;

          this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
              if (params['search'] == undefined) {
                this.menu_id = this.menu_id_temp;
              } else {
                if (res.close == true) {
                  this.menu_id = this.menu_id_temp;
                }
              }
            }
          )
        }
      }
    )
  }
  open_menu_item(i: number, id: number, title: string, section_id: string) {
    const dialogRef = this.dialog.open(MenuItemsComponent, {
      width: '22rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, title: title, type_task: 2 }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          this.serverService.send_menu({ title: res.result.site_services_title, id: res.result.site_services_id, section_id: section_id }, 'update');
        }
      }
    )
  }
  //**************************************************************************************************** */
  get_menu() {
    this.loading = true;
    var obj = {
      address: 6569
      , user_id: this.user_id
      , level: this.level
      , creator: this.creator
      , similar_admin: this.similar_admin
      , lang: this.dir
      , software_id: this.serverService.get_software_id()
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_menu = [];
        if (res['status'] == 1) {
          this.user_setting_menu = res['user_setting_menu'];
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].site_services_img) {
              res['result'][i].src = res['result'][i].site_services_site + "/" + res['result'][i].site_services_img;
            } else {
              res['result'][i].src = this.serverService.get_default_image();
            }
            if (res['result'][i].site_services_id == 253) {
              this.network_access = true;
            }
            if (res['result'][i].site_services_id == 197) {
              this.get_rstatus();

              this.activatedRoute.queryParams.subscribe(
                (params: Params) => {
                  if (params['user_reception'] != null) {
                    this.rstatus_id = 0;
                  }
                }
              )
            }
            this.list_menu.push(res['result'][i]);
          }//end for
          this.join_section_menu();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.serverService.signout();
        }
      }
    )
  }//end get_menu res['result'][i].site_section_tab
  join_section_menu() {
    for (var i = 0; i < this.list_section.length; i++) {
      for (var j = 0; j < this.list_menu.length; j++) {
        if (this.list_section[i].site_section_id == this.list_menu[j].site_menu_section) {
          this.list_menus[this.list_section[i].site_section_id].push(this.list_menu[j]);

          for (var x = 0; x < this.list_tab.length; x++) {
            if (this.list_tab[x].site_tab_id == this.list_section[i].site_section_tab) {
              this.list_tab[x].show = true;
            }//end if
          }
        }
      }
    }
    var x1 = this.document.location.pathname.split("/")[3];
    for (var i = 0; i < this.list_menu.length; i++) {
      if (this.list_menu[i].site_services_id == x1) {

        for (var ii = 0; ii < this.list_section.length; ii++) {
          if (this.list_section[ii].site_section_id == this.list_menu[i].site_menu_section) {
            this.select(this.list_section[ii].site_section_tab);
          }
        }

      }
    }
  }
  //**************************************************************************************************************************************** */
  get_logo() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1038, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.level = res['level'];
          this.creator = res['creator'];
          this.similar_admin = res['user_similar_admin'];
          this.last_date_update = res['last_date_update'];
          this.get_tab();
          this.get_expire_date();
          this.get_sms();
        }//end if
        else {
          this.serverService.signout();
        }
      }
    )
  }
  get_expire_date() {
    var obj = {
      'address': 6771,
      'creator': this.creator,
      'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.expire_date = res['expire_date'];
          this.now_date = res['now_date'];
          if (new Date(this.now_date).getTime() > new Date(this.expire_date).getTime()) {
            this.dialog.open(AccountRenewalEnComponent, {
              width: '35rem',
              height: 'auto',
              disableClose: true,
              data: { user_id: this.user_id, creator: this.creator, expire_date: this.expire_date, calltype: 1 }
            })
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_rstatus() {
    var obj = {
      address: 1682
      , user_id: this.user_id
      , creator: this.creator
      , level: this.level
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_groups = [];
        this.list_rstatus = [];
        this.list_cheque_status = [];
        this.list_finance_document = [];
        this.list_bascket_status = [];
        this.list_rstatus_in_menu = [];

        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].repairman_rstatus_logo) {
              res['result'][i].logo = res['result'][i].repairman_rstatus_site_logo + "/" + res['result'][i].repairman_rstatus_logo;
            } else {
              res['result'][i].logo = this.serverService.get_default_image();
            }
            this.list_rstatus.push(res['result'][i]);
            if (res['result'][i].repairman_rstatus_show_menu == 1) this.list_rstatus_in_menu.push(res['result'][i]);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  go_to_status(id: number) {
    this.rstatus_id = id;
    this.router.navigate(['/home', 'reception', 170], { queryParams: { rstatus: id } });
    this.menu_id = 0;
  }
  open_whatsapp() {
    window.open('whatsapp://send?phone=85252290845', '_blank');
  }
  open_telegram() {
    window.open('https://t.me/finty_en', '_blank');
  }
  go_to_user(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6570, id: this.user_creator }).subscribe(
      (res: any) => {
        //localStorage.clear();
        if (res['status'] == 1) {
          var user_info = {
            user_id: res['result'][0].user_id,
            username: res['result'][0].user_userName,
            user_title: res['result'][0].user_title,
            groups_title: res['result'][0].user_groups_title,
            user_token: res['result'][0].user_token,
            lang: this.lang,
          };
          localStorage.setItem("user_info", JSON.stringify(user_info));
          localStorage.setItem('status', '1');
          localStorage.setItem('refrerr', '1');
          localStorage.setItem('lang', this.lang);
          if (this.level == 2) window.open("/home/user/261", "_self");
          else window.open("/home/access/219", "_self");
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //********************************************************************************************** */
  access_setting_menu() {
    this.setting_menu = !this.setting_menu;
  }
  //***********************************************************************************
  signout() {
    localStorage.removeItem('user_info');
    localStorage.removeItem('status');
    localStorage.removeItem('language');
    localStorage.removeItem('lang');
    localStorage.removeItem('dir');
    window.open("/", "_self");
  }//end signout
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
      this.loading2 = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //************************************************************************************
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }
}
