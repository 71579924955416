<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span>Product list</span>
    </span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent matDialogContent1">
    <div style="text-align: left;">
        <span class="hidden-xs">
            <button (click)="which(2)" [disabled]='next_status'><i class="fas fa-forward"></i></button>
            <button (click)="which(3)" [disabled]='pre_status'><i class="fas fa-backward"></i></button>
        </span>
        <button type="button" (click)='get_material3()'
            [ngClass]="{button_active:type_list == 'material','full-left':dir == 'ltr'}" class="invoice_button2">
            <span>Product List</span>
            {{ count_total_material | number}}
        </button>
        <button type="button" (click)='get_cost3()'
            [ngClass]="{button_active:type_list == 'cost','full-left':dir == 'ltr'}" class="invoice_button2"
            [hidden]="true">
            <span>Expense list</span>
            {{ count_total_cost | number }}
            <span></span>
        </button>
        <button type="button" class="invoice_button2"
            [ngClass]="{button_active:type_list == 'order','full-left':dir == 'ltr'}" (click)='get_order()'>
            <span>
                <span> Shopping cart </span>
            </span>
            <span [ngClass]="{'en':dir == 'ltr'}">{{ count_order }}</span>
        </button>

        <button type="button" (click)='add()' class="search_button hidden-xs" *ngIf="type_list == 'material'">
            <span>Add new product</span>
        </button>
    </div>

    <div class="row" *ngIf="type_list == 'material'">
        <input class="search" (keyup)="get_material2()" [(ngModel)]="search" autocomplete="off"
            placeholder="{{ serverService.get_title(3,lang) }}..." [ngClass]="{'full-left':dir == 'ltr'}">

        <div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}">

            <mat-table [dataSource]="dataSource" style='min-width:100%'
                class='table-scroll mat_table_fa_without_scroll pe1'
                [ngClass]="{mat_table_en_without_scroll_bg:dir=='ltr',en1:dir == 'ltr'}">

                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:30px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:30px"
                        [ngClass]="{'en':dir == 'ltr'}">{{ i + page_number_first }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef style="text-align: center !important;" class="right">
                        <span>Product Name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor right">
                        <span class="paddingRight" [ngClass]="{'full-left':dir == 'ltr'}">
                            {{ x.wharehouse_material_title }} | {{ x.wharehouse_group_title }} | {{
                            x.wharehouse_material_cate_title }} </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="seller">
                    <mat-header-cell *matHeaderCellDef style="max-width:150px" [hidden]="document_type_text != 'exit'">
                        <span>Salesman</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor"
                        style="text-align: center;height:2rem;padding:0px !important;max-width:150px"
                        [hidden]="document_type_text != 'exit'">
                        <select class="select" id="repairman{{ x.wharehouse_order_id  }}"
                            [ngClass]="{'full-left':dir == 'ltr'}">
                            <option *ngFor="let repairman of list_repairman" [selected]="repairman.user_id == user_id"
                                [value]="repairman.user_id">{{
                                repairman.user_title }}</option>
                        </select>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="inventory">
                    <mat-header-cell *matHeaderCellDef style="max-width:135px" [hidden]="document_type_text == 'input'">
                        <span>Stock</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding" style="text-align: center;"
                        style="max-width:135px" [hidden]="document_type_text == 'input'">
                        <input class=" wh-input input1" id="inventory1{{ x.wharehouse_material_id }}" currencyMask
                            [value]="x.inventory | number" autocomplete="off" readonly>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="order">
                    <mat-header-cell *matHeaderCellDef style="max-width:120px" [hidden]='true'>
                        <span>Request no</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding" style="max-width:120px" [hidden]='true'>
                        <input class=" wh-input input1" id="order1{{ x.wharehouse_material_id }}" autocomplete="off"
                            readonly>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="last_invoice_price">
                    <mat-header-cell *matHeaderCellDef style="max-width:135px"
                        [hidden]="document_type_text == 'request'">
                        <span>Last purchase price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor" style="max-width:135px;"
                        [hidden]="document_type_text == 'request'">
                        <span (click)="show_price(i,x.wharehouse_material_id)" *ngIf="!x.price && x.price != 0">
                            <span>Display</span>
                        </span>
                        <span *ngIf="x.price >= 0" (click)="hide_price(i)" [ngClass]="{'en':dir == 'ltr'}">
                            <span *ngIf="x.price"> {{ currency_title2 }} </span>
                            {{ x.price | currency:'':'' }}
                        </span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="invoice_price">
                    <mat-header-cell *matHeaderCellDef style="max-width:135px"
                        [hidden]="document_type_text == 'request'">
                        <span>Purchase price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="max-width:135px;"
                        [hidden]="document_type_text == 'request'">
                        <input class=" wh-input input1" id="invoice_price1{{ x.wharehouse_material_id }}" currencyMask
                            [ngModel]="x.wharehouse_order_cost" autocomplete="off"
                            [readonly]="document_type_text != 'input'">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef style="max-width:135px">
                        <span>Number</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="max-width:135px;">
                        <input type="number" class=" wh-input input1" id="input1{{ x.wharehouse_material_id }}"
                            [value]="1" autocomplete="off" min="0">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef style="max-width:120px"
                        [hidden]="ref == 'order' || back == true || document_type_text == 'request' ">
                        <span>Discount</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor padding0" style="max-width:120px"
                        [hidden]="ref == 'order' || back == true || document_type_text == 'request'">
                        <input class="wh-input input1" id="discount1{{ x.wharehouse_material_id }}" currencyMask
                            autocomplete="off" [ngModel]="0" [disabled]="back == true" [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px">
                        <span>Add</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor"
                        style="max-width:80px;padding-top:3px !important">
                        <mat-icon class="add_circle_outline"
                            (click)="save(i,x.wharehouse_material_id,x.wharehouse_order_id, 1 , 1 , 0)"
                            style="vertical-align: middle;font-size: 20px;" *ngIf="ref == 'invoice' ">
                            add_circle_outline
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns2" color="primary"></mat-header-row>
                <mat-row *matRowDef="let x;let i = index;columns:displayedColumns2"></mat-row>
            </mat-table>

        </div>
    </div>

    <div class="row" *ngIf="type_list == 'cost'">
        <input class="search" (keyup)="get_services2(2)" [(ngModel)]="search" autocomplete="off"
            placeholder="{{ serverService.get_title(3,lang) }}..." [ngClass]="{'full-left':dir == 'ltr'}">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}">

            <mat-table [dataSource]="dataSource" style='direction:ltr' class='matTable table-scroll not- pe1'>
                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style="max-width:30px">
                        #
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="max-width:30px"
                        [ngClass]="{'en':dir == 'ltr'}">{{ i+
                        page_number_first }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Cost title</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.finance_cost_type_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="group">
                    <mat-header-cell *matHeaderCellDef>
                        <span>Cost group title</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.finance_cost_type_group_title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                        <span>Number </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:100px;padding:0px !important" [hidden]="true">
                        <input type="number" class="wh-input input1" id="input2{{ x.finance_cost_type_id }}" [value]="1"
                            autocomplete="off" currencyMask [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cost">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px">
                        <span>Starting price </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:100px;padding:0px !important">
                        <input class="wh-input input1" id="cost2{{ x.finance_cost_type_id }}"
                            [ngModel]="x.finance_cost_type_cost" currencyMask autocomplete="off" currencyMask
                            [ngClass]="{'en':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="comment">
                    <mat-header-cell *matHeaderCellDef style="max-width:400px">
                        <span> Description </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x" class="cursor" style="text-align: center;"
                        style="max-width:400px;padding:0px !important">
                        <input class="wh-input input1" id="comment{{ x.finance_cost_type_id }}" autocomplete="off"
                            style="text-align: right;" [ngClass]="{'full-left':dir == 'ltr'}">
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="add">
                    <mat-header-cell *matHeaderCellDef style="max-width:80px">
                        <span> Add </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" class="cursor" style="text-align: center;"
                        style="max-width:80px;padding-top:3px !important">
                        <mat-icon class="add_circle_outline" (click)="add_to_invoice_cost(i,x.finance_cost_type_id)"
                            style="vertical-align: middle;font-size: 20px;">
                            add_circle_outline
                        </mat-icon>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns3" color="primary"></mat-header-row>
                <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns3;let i = index"
                    (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                    [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                    class="cursor"></mat-row>
            </mat-table>
        </div>

    </div>
    <!-- **************************************************************************************************** -->
    <div class="row" *ngIf="type_list != 'material' && type_list != 'cost'">
        <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}">
            <mat-table [dataSource]="dataSource" style='direction:ltr' class='table-scroll en1'>
                <ng-container matColumnDef="row">
                    <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                        <span>#</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'
                        [ngClass]="{'en':dir == 'ltr'}">{{ i + 1 }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="material">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'min-width':document_type_text != 'request' ? '200px' : 'auto' }">
                        <span>Product Name</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style="direction: rtl;"
                        [ngStyle]="{'min-width':document_type_text != 'request' ? '200px' : 'auto' }"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)">
                        <div class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{ x.title }} | {{
                            x.wharehouse_material_cate_title | substr:0:20 }}</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="title">
                    <mat-header-cell *matHeaderCellDef [hidden]="true">
                        <span>Component model</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="number">
                    <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '120px' : 'auto' }">
                        <span>Number</span>
                        <span *ngIf="document_type_text == 'request' ">
                            <span>Requested</span>
                        </span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        [ngStyle]="{'max-width':document_type_text != 'request' ? '120px' : 'auto' }"
                        [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_order_number }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="weight">
                    <mat-header-cell *matHeaderCellDef style="max-width:100px" [hidden]="true">
                        <span>Counting unit</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width:100px" [hidden]="true" [ngClass]="{'en':dir == 'ltr'}">
                        {{ x.wharehouse_weight_title}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="price">
                    <mat-header-cell *matHeaderCellDef style="max-width: 150px;"
                        [hidden]="document_type_text == 'request' ">
                        <span>Unit Price</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 150px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        <span *ngIf="x.wharehouse_order_cost">{{ currency_title2 }} </span>
                        {{ x.wharehouse_order_cost | currency:'':'' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="discount">
                    <mat-header-cell *matHeaderCellDef style="max-width: 150px;"
                        [hidden]="document_type_text == 'request' ">
                        <span>Discount</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 150px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        <span *ngIf="x.wharehouse_order_discount">{{ currency_title2 }} </span>
                        {{ x.wharehouse_order_discount | currency:'':'' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sum">
                    <mat-header-cell *matHeaderCellDef style="max-width: 150px;"
                        [hidden]="document_type_text == 'request' ">
                        <span>Total</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index"
                        (click)="check_for_access(i,2,x.wharehouse_order_id,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                        style="max-width: 150px;" [hidden]="document_type_text == 'request' "
                        [ngClass]="{'en':dir == 'ltr'}">
                        <span *ngIf="x.sum">{{ currency_title2 }} </span>
                        {{ x.sum | currency:'':'' }}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="operation">
                    <mat-header-cell *matHeaderCellDef style='max-width:70px' [hidden]="readonly">
                        <span>Operation</span>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let x;let i = index" style='max-width:70px' [hidden]="readonly">
                        <span
                            (click)="access_for_delete(x.wharehouse_order_id,x.wharehouse_order_material,x.wharehouse_order_trash,x.wharehouse_order_material_type,x.wharehouse_order_creator)"
                            *ngIf="document_type_text != 'request' "><i class="far fa-trash-alt icon"
                                style='margin:0px 4px;'></i></span>

                        <span (click)="delete_request(x.wharehouse_order_id)"
                            *ngIf="document_type_text == 'request' "><i class="far fa-trash-alt icon"
                                style='margin:0px 4px;'></i></span>
                    </mat-cell>
                </ng-container>

                <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                </mat-header-row>
                <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                    (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                    [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                    class="cursor" [ngStyle]="{'backgroundColor':row.wharehouse_order_status_color}"></mat-row>

            </mat-table>
        </div>
    </div><!-- row -->
    <!-- **************************************************************************************************** -->
</div>