<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 1">برگشت از خرید</span>
        <span *ngIf="lang == 2">Purchase return</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="group" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه </span>
                    <span *ngIf="lang == 2"> Group </span>
                    <input class="wh-input" formControlName="group_title" readonly required [attr.disabled]="true"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="cate" class='wh-label'>
                    <span *ngIf="lang == 1"> زیر گروه </span>
                    <span *ngIf="lang == 2"> Subgroup </span>
                    <input class="wh-input" formControlName="cate_title" readonly required [attr.disabled]="true"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="material" class='wh-label'>
                    <span *ngIf="lang == 1"> نام کالا </span>
                    <span *ngIf="lang == 2"> Product Name </span>
                    <input class="wh-input" formControlName="material_title" readonly required [attr.disabled]="true"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="number" class='wh-label'>
                    <span *ngIf="lang == 1"> تعداد </span>
                    <span *ngIf="lang == 2"> Number </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" currencyMask required
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="cost" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت خرید </span>
                    <span *ngIf="lang == 2"> Purchase price </span>
                    <input class='wh-input' formControlName="price" autocomplete="off" currencyMask
                        [attr.disabled]="true" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="comment" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description </span>
                    <textarea style="height:10rem" class="wh-input" formControlName="comment"
                        [ngClass]="{'en':dir == 'ltr'}"></textarea>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
    </div>
</form>