import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import { BankToCashDetaile3Component } from '../bank-to-cash-detaile3/bank-to-cash-detaile3.component';
import Num2persian from 'num2persian';


@Component({
  selector: 'app-bank-to-cash-detaile',
  templateUrl: './bank-to-cash-detaile.component.html',
  styleUrls: ['./bank-to-cash-detaile.component.scss']
})
export class BankToCashDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public token: any = Math.floor(Math.random() * 99999999999999);
  public loading: boolean = false;
  public readonly: boolean = false;
  public search_array: any = [];
  public list_record: any = [];
  public list_document: any = [];
  public list_record_financial2: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public type_task!: number;
  public sum: number = 0;
  public id!: number;
  public level!: number;
  public expire!: boolean;
  public i!: number;
  public code!: number;
  public amount!: number;
  public result!: any;
  public user!: number;
  public currency_title2!: string;
  public creator!: number;
  public sum_all_letter!: string;
  public access_service!: number;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'amount', 'bank', 'account', 'operation'];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public matDialogRef: MatDialogRef<BankToCashDetaileComponent>) {

    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.readonly = dialog_data.readonly;
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'document_date': new FormControl(null),
      'document_title': new FormControl(null),
      'comment': new FormControl(null),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['document_date'].setValidators([Validators.required]);
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access3(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access3(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 2) {
            if (this.expire == true && this.i == -1) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.update();
            }
          }
          if (type_task == 3) {
          }
          if (type_task == 4) {
            this.get_data();
          }
          if (type_task == 5) { }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      "address": 6963,
      "id": this.id,
      "creator": this.creator,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'document_date': res['result'][0].finance_bank_to_cash_document_date,
              'comment': res['result'][0].finance_bank_to_cash_comment,
            });
            if (this.i == -1) {
              this.get_today_date();
            }
            this.get_financial2();
            this.get_currency();
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_today_date() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6848 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'document_date': res['date']
          })
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_financial2() {
    var obj = {
      'address': 6964,
      'user_id': this.user_id,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.get_amount();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  get_amount() {
    this.amount = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.amount += Number(this.list_record[i].finance_bank_to_cash2_amount);
    }
    this.sum_all_letter = Num2persian(this.amount);
  }
  get_currency() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6115, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.currency_title2 = res['result'][0].site_currency_title2;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6965,
      "user_id": this.user_id,
      'amount': this.amount,
      'comment': this.form1_group.value.comment,
      'document_date': this.form1_group.value.document_date,
      'token': this.token,
      'id': this.id,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.update_financial2_trash3_to_2();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  update_financial2_trash3_to_2() {
    var obj = {
      address: 6966,
      id: this.id,
      token: this.token,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ result: this.result })
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  close() {
    var j = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      if (this.list_record[i].finance_bank_to_cash2_trash == 3) {
        j++;
      }
    }
    if (j > 0) {
      var message = "اینجا تراکنشهای انجام شده هنوز ذخیره نشده است؟";
      if (this.lang == 2) {
        message = "Are the transactions done here not saved yet?";
      }
      var accept: boolean = confirm(message);
      if (accept == true) {
        this.matDialogRef.close();
      } else {
      }
    } else {
      this.matDialogRef.close();
    }
  }
  //*********************************************************************************************** */
  check_access2(i: number, type_task: number, page: number, id: any, excel: number, loading: number, cheque: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            if (type_task == 3) {
              this.delete(i, id);
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_detaile(index: number, type_task: number, id: number): any {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(BankToCashDetaile3Component, {
      width: '30rem',
      height: 'auto',
      disableClose: true,
      data: {
        type_task: type_task,
        id: id,
        search_array: this.search_array,
        code: this.code,
        financial: this.id,
        token: this.token,
        sum: Math.abs(this.sum) - Number(this.amount)
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.dataSource.data = this.list_record;
            this.get_amount();
          }
          else if (type_task == 2) {
            this.list_record[index].finance_bank_to_cash2_amount = res.result.finance_bank_to_cash2_amount;
            this.list_record[index].accounts_number = res.result.accounts_number;
            this.list_record[index].site_bank_title = res.result.site_bank_title;
            this.get_amount();
          }
        }
      }
    )
  }
  get_bg(id: number) {
    //this.id = id;
  }
  delete(i: number, id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 6967,
        "user_id": this.user_id,
        'id': id,
        'financial': this.id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.finance_bank_to_cash2_id == id), 1);
            this.dataSource.data = this.list_record;
            this.message(false, "", 1, this.messageService.close(this.lang));
            this.get_amount();
            //****************** */
          }
          else {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

