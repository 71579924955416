
<div class="p-1 text-center pb-2" dir="ltr">
  <h3 mat-dialog-title>Confirm</h3>
  <div mat-dialog-content>
    <p>{{ data.message }}</p>
  </div>
  <div mat-dialog-actions>
    <button class="ml-2" class="btn"  mat-button (click)="onNoClick()">No</button>
    <button  mat-button class="btn" style="background-color: rgb(148, 147, 147) !important;color: #fff;" (click)="onYesClick()" cdkFocusInitial>Yes</button>


  </div>
</div>


