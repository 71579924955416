import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CostTypeDetaileComponent } from "../cost-type-detaile/cost-type-detaile.component";
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-cost-type-list',
  templateUrl: './cost-type-list.component.html',
  styleUrls: ['./cost-type-list.component.scss']
})
export class CostTypeListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public language: any = JSON.parse(<any>localStorage.getItem('language'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public user_id!: number;
  public loading: boolean = false;
  public show_excel: boolean = false;
  public count_total: number = 1;
  public page: number = 1;
  public excel: number = 1;
  public list_record: any = [];
  public list_status: any = [];
  public selected_record: any = [];
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public subscription: Subscription | any;
  public task_type!: number;
  public confirm_delete!: boolean;
  public page_number_first!: number;
  public page_number_second!: number;
  public code!: any;
  public export_code!: string;
  public type_task!: number;
  public type!: number;
  public type_search!: number;
  public search_all!: string;
  public id!: number;
  public title!: string;
  public select_all!: boolean;
  public creator!: number;
  public video!: string;
  public access_service!: number;
  public level!: number;
  public path_export!: string;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'title', 'cost', 'status', 'operation'];
  //************** */
  constructor(
    public serverService: ServerService,
    public ngxSmartModalService: NgxSmartModalService,
    public router: Router,
    public messageService: MessageService,
    public activatedRoute: ActivatedRoute,
    public matDialogRef: MatDialogRef<CostTypeListComponent>,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.id = dialog_data.id;
    }
  }
  ngOnInit() {
    this.code = 221;
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    var obj = {
      'address': 6
      , 'user_id': this.creator
      , 'id': this.code
      , 'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) { this.load_data(page, 1, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_data(page: number, excel: number, loading: number, changedRows: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    if (page == 6) { this.page = 1; }
    var obj = {
      'address': 6605
      , "page": this.page
      , 'type': this.type
      , 'id': this.id
      , 'export_excel': excel
      , 'user_id': this.user_id
      , 'search_all': this.search_all
      , 'creator': this.creator
      , 'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['num'];
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(CostTypeDetaileComponent, {
      width: '22rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, creator: this.creator, id: id, i: i, code: this.code, title: this.title, group: this.id }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
            this.count_total = this.list_record.length;
          }
          else if (type_task == 2) {
            this.list_record[res.i].finance_cost_type_title = res.result.finance_cost_type_title;
            this.list_record[res.i].finance_cost_type_cost = res.result.finance_cost_type_cost;
            this.list_record[res.i].status_title = res.result.status_title;
            if (res.result.finance_cost_type_group != this.id) {
              this.list_record.splice(i, 1);
              this.dataSource.data = this.list_record;
            }

          }
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  delete(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 6232,
        "user_id": this.user_id,
        'id': id,
        "page": this.page,
        'type': this.type,
        "search_all": this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.finance_cost_type_id == id), 1);
            this.dataSource.data = this.list_record;
            this.count_total = this.list_record.length;
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.message_action(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  close() {
    this.matDialogRef.close();
  }
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
