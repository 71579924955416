import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-column-config-dialog',
  templateUrl: './column-config-dialog.component.html',
  styleUrls: ['./column-config-dialog.component.scss']
})
export class ColumnConfigDialogComponent {
  columns = this.data.columns;
  title_of_order = this.data.title;

  constructor(
    public dialogRef: MatDialogRef<ColumnConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  moveColumnUp(index: number) {
    if (index > 0) {
      const temp = this.columns[index];

      const next_col = this.columns[index-1];

      if(next_col.sortable)
      {
        this.columns[index] = this.columns[index - 1];

        this.columns[index - 1] = temp;

      }

    }
  }

  moveColumnDown(index: number) {
    if (index < this.columns.length - 1) {

      const temp = this.columns[index];

      const next_col = this.columns[index+1];

      if(next_col.sortable)
      {
        this.columns[index] = this.columns[index + 1];

        this.columns[index + 1] = temp;

      }

    }
  }

  Toggle(name: string, isHiden: boolean) {

    for (const data of this.columns) {

      if (data.name == name) {

        data.isHiden = !isHiden;

        break;

      }
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {

    if (this.title_of_order) {

      const columnConfig = this.columns.map(col => ({ name: col.name, isHiden:col.isHiden, sortable: col.sortable , hideable:col.hideable}));

      localStorage.setItem(this.title_of_order, JSON.stringify(columnConfig));

      this.dialogRef.close(this.columns);
    }

  }


}
