<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>Check payment</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row" style="direction: rtl;">
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="title" class='wh-label'>
                        <span>Check purpose: For</span>
                        <input class='wh-input' formControlName="title" autocomplete="off">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="amount" class='wh-label'>
                        <span> Check Amount </span>
                        <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask required
                            [readonly]="ref == 'cheque' ? true : null">
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="bank" class='wh-label'>
                        <span> Bank </span>
                        <span (click)="reset_input(178)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="bank_title" (click)="load_services(178)"
                            [required]="required()" readonly>
                        <input class="wh-input" formControlName="bank" [hidden]="true" required readonly>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="account_title" class='wh-label'>
                        <span> Account Number </span>
                        <span (click)="reset_account(1)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="account_title" (click)="select_account() " readonly
                            required>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="number" class='wh-label'>
                        <span> Check Number </span>
                        <input class="wh-input" formControlName="number" required>
                    </label>
                </div>
                <!--************************************************************************************************** -->
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="due" class="wh-label">
                        <span> Due Date </span>
                        <div style="width:100%">
                            <input type="date" class="wh-input" formControlName="due" [required]="required()"
                                autocomplete="off">
                        </div>
                    </label>
                </div>
                <!--************************************************************************************************** -->
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding'>
                    <label for="issue_date" class="wh-label">
                        <span>Date of issuance</span>
                        <div style="width:100%">
                            <input type="date" class="wh-input" formControlName="issue_date" [required]="required()"
                                autocomplete="off">
                        </div>
                    </label>
                </div>
                <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe div_panding' [hidden]="type_task == 1">
                    <label for="status" class='wh-label'>
                        <span> Check Status </span>
                        <span (click)="reset_input(190)">
                            <mat-icon class="close" class="input_search input_search_pe">close</mat-icon>
                        </span>
                        <input class="wh-input" formControlName="status_title" (click)="load_services(190) "
                            [required]="type_task == 2 " readonly>
                        <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="type_task == 2 "
                            readonly>
                    </label>
                </div>
                <!-- -------------------------------------------------------------------------------------------------------------- -->
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn_pe" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>
