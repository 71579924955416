<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span> Cash Entry </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row">
                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                    <label for="amount" class='wh-label'>
                        <span> Amount </span>
                        <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask required>
                    </label>
                </div>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                    <label for="comment" class='wh-label'>
                        <span> Description </span>
                        <textarea style="height:5rem" class="wh-input" formControlName="comment">
                        </textarea>
                    </label>
                </div>

            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button [disabled]="!form1_group.valid || loading || !form1_group.get('amount').value > 0" class="btn"
            (click)="save(type_task)" *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>