import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-finance-cardex-personnel-detaile',
  templateUrl: './finance-cardex-personnel-detaile.component.html',
  styleUrls: ['./finance-cardex-personnel-detaile.component.scss']
})
export class FinanceCardexPersonnelDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public type_task: number = 3;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public i: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public creator: number | undefined;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , public matDialogRef: MatDialogRef<FinanceCardexPersonnelDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.create_form1();
      this.id = dialog_data.id;
      this.title = dialog_data.title;
      this.code = 255;
      this.i = dialog_data.i;
      if (this.type_task == 3) {
        this.creator = dialog_data.creator;
        this.form1_group.patchValue({
          customer: dialog_data.search_array.customer,
          customer_title: dialog_data.search_array.customer_title,
          place: dialog_data.search_array.place,
          date1: dialog_data.search_array.date1,
          date2: dialog_data.search_array.date2,
        })
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'customer_title': new FormControl(null),
      'customer': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'place': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'date1': new FormControl(null),
      'date2': new FormControl(null),
    });
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.title = res['result'][0].title;
            this.creator = res['creator'];
            this.loading = false;
            this.search();
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  search() {
    this.matDialogRef.close({
      customer: this.form1_group.value.customer,
      customer_title: this.form1_group.value.customer_title,
      place: this.form1_group.value.place,
      date1: this.form1_group.value.date1,
      date2: this.form1_group.value.date2,
    })
  }

  load_services(type: number, id: number) {
    var code_id;
    if (id == 220) {
      code_id = 6671;
    }

    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator, user_id: this.user_id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 220 && type == 2) {
            this.form1_group.patchValue({
              'customer': res.id,
              'customer_title': res.title
            })
          }
        }
      }
    )
  }


  reset_input(type: number, id: number) {
    if (id == 220 && type == 2) {
      this.form1_group.patchValue({
        'customer': null,
        'customer_title': null
      })
    }
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
