<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span>Services</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="status_id" class='wh-label full-left-padding0'>
                    <span> Service Name</span>
                    <span (click)="reset_input(195)">
                        <mat-icon class="close" class="input_search input_search_en">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="material_title" (click)="load_services(195)" readonly
                        required>
                    <input class="wh-input" formControlName="material" [hidden]="true" readonly required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="number" class='wh-label full-left-padding0'>
                    <span> Number </span>
                    <input class='wh-input' formControlName="number" autocomplete="off" required
                        (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="cost" class='wh-label full-left-padding0'>
                    <span> Unit Price </span>
                    <input class='wh-input' formControlName="cost" currencyMask autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'>
                <label for="discount" class='wh-label full-left-padding0'>
                    <span *ngIf="lang == 1"> تخفیف </span>
                    <span *ngIf="lang == 2"> Discount </span>
                    <input class='wh-input' formControlName="discount" currencyMask autocomplete="off" required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding' [hidden]="level == 3">
                <label for="repairman" class='wh-label'>
                    <span> Repairman Name </span>
                    <span (click)="reset_input(220)" [hidden]="level == 3">
                        <mat-icon class="close" class="input_search input_search_en">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="repairman_title" (click)="load_services(220)" readonly
                        required [attr.disabled]="level == 3 ? true : null">
                    <input class="wh-input" formControlName="repairman" [hidden]="true" readonly required
                        [attr.disabled]="level == 3 ? true : null">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding' [hidden]="level == 3">
                <label for="profit" class='wh-label full-left-padding0'>
                    <span> Profit commission </span>
                    <input class='wh-input' formControlName="profit" autocomplete="off" required
                        (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="tax" class='wh-label'>
                    <span (click)="reset_input(268)">
                        <mat-icon class="close" class="input_search input_search_en">close</mat-icon>
                    </span>
                    <span> Tax Type </span>
                    <input class="wh-input" formControlName="tax_title" (click)="load_services(268)" readonly>
                    <input class="wh-input" formControlName="tax" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="tax" class='wh-label'>
                    <span> Tax Rate </span>
                    <input class="wh-input" formControlName="tax_price" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="sort" class='wh-label full-left-padding0'>
                    <span> Description </span>
                    <input class='wh-input' formControlName="comment" autocomplete="off">
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_en" *ngIf="type_task == 1 || type_task == 2">
            <span>Save</span>
        </button>
    </div>
</form>