import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReceptionDetaileComponent } from "../reception-detaile/reception-detaile.component";
import { PaymentComponent } from '../../../finance/payment/payment.component';
import { WharehouseExit2Component } from '../../../wharehouse/wharehouse-exit2/wharehouse-exit2.component';
import { HttpClient } from '@angular/common/http';
import { RepairmanShelfLogComponent } from '../../repairman-shelf-log/repairman-shelf-log.component';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { RepairmanDeliveryComponent } from '../../repairman-delivery/repairman-delivery.component';
import { MessageService } from '../../../service/message/message.service';
import { ReceptionSmsComponent } from '../reception-sms/reception-sms.component';
import { ChartsComponent } from 'src/app/system/finance/charts/charts/charts.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ColumnConfigDialogComponent } from 'src/app/system/models/column-config-dialog/column-config-dialog.component';
import { Title } from '@angular/platform-browser';
import { TableColumn } from 'src/app/system/models/TableColumn';
import { ConfirmDialogComponent } from 'src/app/system/models/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-reception-list',
  templateUrl: './reception-list.component.html',
  styleUrls: ['./reception-list.component.scss']
})

export class ReceptionListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public token: any = Math.floor(Math.random() * 99999999999999);
  public loading: boolean = false;
  public loading2: boolean = false;
  public show_excel: boolean = false;
  public loading1: boolean = false;
  public status_show_id: boolean = false;
  public show_cellphone: boolean = true;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public seeOwnPanel: boolean = false;
  public count_total: number = 1;
  public excel: number = 1;
  public page: number = 1;
  public see_own_panel: number = 0;
  public subscription: Subscription | any;
  public list_services: any = [];
  public list_record: any = [];
  public list_status: any = [];
  public list_cellphone: any = [];
  public search_array: any = [];
  public selected_record: any = [];
  public user_id!: number;
  public user_token!: string;
  public user_store!: string;
  public task_type: number;
  public i!: number;
  public confirm_delete!: boolean;
  public page_number_first!: number;
  public page_number_second!: number;
  public code!: any;
  public code_movie!: any;
  public sort!: string;
  public export_code!: string;
  public type_task!: number;
  public type!: number;
  public type_search!: number;
  public search_all!: string;
  public delivery!: number;
  public x!: any;
  public ready_delivery!: number;
  public ready_reparing!: number;
  public payment_dialog!: boolean;
  public material_dialog!: boolean;
  public back_i!: number;
  public level!: number;
  public creator!: number;
  public video!: string;
  public default!: number;
  public access_service!: number;
  public path_export!: string;
  public query_customer!: number;
  public query_repairman!: number;
  public id!: number;
  public pre_status!: boolean;
  public next_status!: boolean;
  public new_status!: boolean;
  public title!: string;
  public select_all!: boolean;
  public rstatus!: any;
  public rstatus_default!: any;
  public repairman!: number;
  public pos!: string;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'Client', 'Invoice', 'Cellphone', 'Ticket Id', 'Old No', 'Shelf No', 'Device', 'Brand', 'Model', 'Serial No', 'Received date', 'Received time', 'Exit Date', 'Exit Time', 'Estimated Date', 'Reception Status', 'Device Status', 'Operation'];

  columns: TableColumn[] = this.displayedColumns.map(column => new TableColumn(column, false, true, true));



  public orginal_order: string = "orginal_reception_list_columns";
  public title_of_order: string = "reception_list_columns";



  //************** */
  constructor(
    public page_title: Title,
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public http: HttpClient,
    public messageService: MessageService
  ) {
  }
  ngOnInit() {

    this.setcolConfig();

    localStorage.setItem(this.orginal_order, JSON.stringify(this.displayedColumns));

    this.page_title.setTitle("Tickets");
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_store = this.user_info.user_store;
      this.user_id = this.user_info.user_id;
      this.user_token = this.user_info.user_token;
    }
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
        this.id = params['id'];
        this.repairman = params['repairman'];
        if (!this.page) this.page = 1;
        this.search_array = [];
        this.get_movie();

      }
    )
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.page = Number(params['page']);
        this.search_all = params['all'];
        this.query_customer = params['customer'];
        this.rstatus = params['rstatus'];
        this.pos = params['pos'];
        if (params['back_i']) this.back_i = params['back_i'];
        this.query_repairman = params['repairman'];
        if (params['new'] == 1) {
          this.open_detaile(0, 1, 0);
        }
        this.check_access(4, 1, 0, 1, 1);
      }
    );
    //**************

    //this.loadConfig();
  }//end ngOnInit
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    var code = this.code;
    if (this.code == 170) { code = 197; }
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address_token(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: code }, this.user_token).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.path_export = res['path_export'];
          this.show_cellphone = res['show_cellphone'];

          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    var code = this.code;
    if (this.code == 170) { code = 197; }
    this.subscription = this.serverService.post_address_token(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: code }, this.user_token).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) {
            this.get_reception_with_full_shelf();
            if (this.level == 3) this.check_user_access_only_his_panel_config(page);
            else this.load_data(page, 1, loading, -1);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_user_access_only_his_panel_config(page: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6816,
      id: this.user_id,
      lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.seeOwnPanel = res['result'][0].user_can_only_see_his_own_list;
          this.load_data(page, 1, 1, -1);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_data(page: number, excel: number, loading: number, changedRows: number) {

    const savedColumnConfig = localStorage.getItem(this.title_of_order);

    if (savedColumnConfig) {

      const savedColumns = JSON.parse(savedColumnConfig);

      this.columns = savedColumns.map((col: { name: string; isHiden: boolean | undefined; sortable: boolean | undefined; hideable: boolean | undefined }) => new TableColumn(col.name, col.isHiden, col.sortable, col.hideable));

      this.displayedColumns = this.columns.filter(cd => !cd.isHiden).map(cd => cd.name);
    }

    if (loading == 1) this.loading = true;
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var address = 6845;
    if (this.lang == 2) address = 6846;
    var obj = {
      'address': address
      , "page": this.page
      , 'type': this.type
      , 'level': this.level
      , 'excel': excel
      , 'lang': this.lang
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'rstatus': this.rstatus
      , 'repairman': this.repairman
      , 'pos': this.pos
      , 'id': this.id
      , 'creator': this.creator
      , 'path_export': this.path_export
      , 'show_cellphone': this.show_cellphone
      , 'query_customer': this.query_customer
      , 'seeOwnPanel': this.seeOwnPanel
      , 'query_repairman': this.query_repairman
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              var x: any;
              for (var i = 0; i < res['num']; i++) {
                res['result'][i].sum = Number(res['result'][i].sum_order) - Number(res['result'][i].sum_payment);
                x = new Date(res['result'][i].repairman_reception_estimated_time).getTime() - new Date(res['date2']).getTime();
                res['result'][i].urgent = Math.round(x / (1000 * 3600 * 24))
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.delivery;
            this.default = res['default1'];
            this.payment_dialog = res['payment_dialog'];
            this.material_dialog = res['material_dialog'];
            this.delivery = res['delivery'];
            this.ready_delivery = res['ready_delivery'];
            this.ready_reparing = res['ready_reparing'];
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reception_rstatus({ rstatus_id: this.rstatus, code: this.code, level: this.level, type: 'load' });
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  export_excel(code: number) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  setcolConfig() {
    this.columns[0].sortable = false;

    this.columns[18].sortable = false;

    this.columns[18].hideable = false;
  }
  openColumnConfigDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '16rem';

    dialogConfig.height = "auto";
    dialogConfig.data = { columns: this.columns, title: this.title_of_order };

    const dialogRef = this.dialog.open(ColumnConfigDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.load_table_Config();

      }
    });
  }

  load_table_Config() {

    const savedColumnConfig = localStorage.getItem(this.title_of_order);

    if (savedColumnConfig) {

      const savedColumns = JSON.parse(savedColumnConfig);

      this.columns = savedColumns.map((col: { name: string; isHiden: boolean | undefined; sortable: boolean | undefined; hideable: boolean | undefined }) => new TableColumn(col.name, col.isHiden, col.sortable, col.hideable));

      this.displayedColumns = this.columns.filter(cd => !cd.isHiden).map(cd => cd.name);

    }
  }

  resetHeaders() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '22rem',
      height:'10rem',
      data: { message: 'The settings of the columns will be reset! Are you sure you want to proceed?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const savedColumnConfig = localStorage.getItem(this.title_of_order);

        if (savedColumnConfig) {

          localStorage.removeItem(this.title_of_order);
        }

        const OrginalColumnConfig = localStorage.getItem(this.orginal_order);

        if (OrginalColumnConfig) {

          this.displayedColumns = JSON.parse(OrginalColumnConfig);

          this.columns = this.displayedColumns.map(column => new TableColumn(column, false, true, true));

          this.setcolConfig();

        }
        // Proceed with the action
      } else {

        this.matSnackBar.open("Reseting Canceled By User", "close", { duration: 5000 });

      }
    });



  }


  //*************************************************************************************** */
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code_movie }
    })
  }
  get_movie(): any {
    this.code_movie = this.code;
    var user_id = this.user_id;
    if (this.code == 170) { this.code_movie = 166; user_id = 2445; }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: user_id, id: this.code_movie }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.video = res['result'][0].video;
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_reception_with_full_shelf() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6620, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.empty_shelf_and_reception(res['result'][i].repairman_reception_id, res['result'][i].repairman_reception_shelf);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_log(reception: number) {
    this.dialog.open(RepairmanShelfLogComponent, {
      width: '67rem',
      height: 'auto',
      data: { reception: reception }
    })
  }
  get_menu(i: number, x: any) {
    this.x = x;
    this.i = i;
  }
  show_id() {
    this.status_show_id = !this.status_show_id;
  }
  //************************************************************************************************************ */
  show_factor1() {
    window.open(this.serverService.get_my_domain() + "/tracking/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code, "_blank");
  }
  show_factor2() {
    window.open(this.serverService.get_my_domain() + "/tracking2/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code + "?type=reception", "_blank");
  }
  show_factor3() {
    window.open("/receipt/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code, "_blank");
  }
  show_factor4() {
    window.open(this.serverService.get_my_domain() + "/timeline/" + this.x.repairman_reception_id + "/" + this.x.repairman_reception_code, "_blank");
  }
  barcode() {
    window.open(this.site + "/barcode/barcode.html?type=1&creator=" + this.creator + "&userId=" + this.user_id + "&id=" + this.x.repairman_reception_id + "&token=" + this.x.repairman_reception_code + "&bottomtext=" + this.x.repairman_shelf_title + "&toptext=" + this.x.user_title + "&lang=" + this.lang, "_blank");
  }
  send_reception_sms(id: number) {
    this.dialog.open(ReceptionSmsComponent, {
      width: '35rem',
      height: 'auto',
      data: { reception_id: id, creator: this.creator }
    })
  }
  //************************************************************************************************************ */
  open_delivery(i: number, id: number) {
    const dialogRef = this.dialog.open(RepairmanDeliveryComponent, {
      width: '25rem',
      height: 'auto',
      data: { id: id, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.splice(i, 1);
          this.dataSource.data = this.list_record;
        }
      }
    )
  }
  get_code_delivery(id: number): any {
    return Math.floor(id * id / 2)
  }
  //************************************************************************************************************ */
  open(i: number, type_task: number, id: number, id2: number) {
    if (this.payment_dialog == true) {
      this.open_payment(i, id, id2);
    }
    else if (this.material_dialog == true) {
      this.open_exit(i, id);
    }
    else {
      this.open_detaile(i, type_task, id);
    }
  }
  open_detaile(i: number, type_task: number, id: number) {
    this.back_i = -1;
    var width = '70rem';
    if (type_task == 3) {
      width = '30rem';
    }
    const dialogRef = this.dialog.open(ReceptionDetaileComponent, {
      width: width,
      height: 'auto',
      disableClose: true,
      data: {
        "type_task": type_task,
        "id": id,
        "i": i,
        "search_array": this.search_array,
        "code": 160,
        "title": this.title,
        "creator": this.creator,
        "default_rstatus": this.default,
        "rstatus": this.rstatus
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          var change_status = 0;
          if (type_task == 1) {
            this.pages(1);
            this.list_record.push(res.result);
            this.dataSource.data = this.list_record;
            if (res.isprint == 1) window.open(this.serverService.get_my_domain() + "/tracking/" + res.result.repairman_reception_id + "/" + res.result.repairman_reception_code, "_blank");
            var index;
            index = this.list_record.length - 1;
            //********************************* */
            this.update_attachment(res.result.repairman_reception_id, res.token);
            this.update_financial2(res.result.repairman_reception_id, res.result.repairman_reception_customer);
            this.server_update_count_reception();
            this.serverService.send_reception_rstatus({ rstatus_id: this.rstatus, code: this.code, level: this.level, alter: true });
          }
          else if (type_task == 2) {
            this.list_record[i].repairman_shelf_title = res.result.repairman_shelf_title;
            this.list_record[i].user_title = res.result.user_title;
            this.list_record[i].user_cellPhone = res.result.user_cellPhone;
            this.list_record[i].wharehouse_device_title = res.result.wharehouse_device_title;
            this.list_record[i].repairman_reception_urgent = res.result.repairman_reception_urgent;
            this.list_record[i].repairman_brand_title = res.result.repairman_brand_title;
            this.list_record[i].repairman_model_title = res.result.repairman_model_title;
            this.list_record[i].repairman_reception_searial = res.result.repairman_reception_searial;
            this.list_record[i].repairman_rstatus_title = res.result.repairman_rstatus_title;
            this.list_record[i].repairman_device_status_title = res.result.repairman_device_status_title;
            this.list_record[i].repairman_title = res.result.repairman_title;
            this.list_record[i].repairman_reception_color = res.result.repairman_reception_color;
            this.list_record[i].repairman_reception_approximate = res.result.repairman_reception_approximate;
            this.list_record[i].repairman_reception_editor_date = res.result.repairman_reception_editor_date;
            this.list_record[i].repairman_reception_editor_time = res.result.repairman_reception_editor_time;
            this.list_record[i].repairman_reception_comment3 = res.result.repairman_reception_comment3;
            this.list_record[i].repairman_reception_estimated_time = res.result.repairman_reception_estimated_time;
            this.list_record[i].repairman_reception_date = res.result.repairman_reception_date;
            this.list_record[i].user_sex_title = res.result.user_sex_title;
            this.server_update_count_reception();
            this.serverService.send_reception_rstatus({ rstatus_id: this.rstatus, code: this.code, level: this.level, alter: true });
            if (res.isprint == 1) window.open(this.serverService.get_my_domain() + "/tracking/" + id + "/" + res.result.repairman_reception_code, "_blank");


            if (this.pos == 'right') {
              if (this.rstatus != res.result.repairman_reception_status) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            } else {
              if (this.default == 1) {
                if (res.result.repairman_rstatus_default == 0) {
                  this.list_record.splice(i, 1);
                  this.dataSource.data = this.list_record;
                  this.pages(-1);
                  change_status = 1;
                }
              }
              if (this.delivery == 1) {
                if (res.result.repairman_rstatus_empty_shelf == 0) {
                  this.list_record.splice(i, 1);
                  this.dataSource.data = this.list_record;
                  this.pages(-1);
                  change_status = 1;
                }
              }
              if (this.ready_delivery == 1) {
                if (res.result.repairman_rstatus_sub_ready_delivery == 0) {
                  this.list_record.splice(i, 1);
                  this.dataSource.data = this.list_record;
                  this.pages(-1);
                  change_status = 1;
                }
              }
              if (this.ready_reparing == 1) {
                if (res.result.repairman_rstatus_repairing == 0) {
                  this.list_record.splice(i, 1);
                  this.dataSource.data = this.list_record;
                  this.pages(-1);
                  change_status = 1;
                }
              }
            }
            this.update_financial2(res.result.repairman_reception_id, res.result.repairman_reception_customer);
            this.update_attachment(id, res.token);
          }
          else if (type_task == 3) {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, excel, 1);
          }
        }
      }
    )
  }
  update_financial2(id: number, customer: number) {
    var obj = {
      "address": 6934,
      "id": id,
      "user_id": this.user_id,
      "creator": this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.update_all_financial2(id, customer);
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_attachment(id: number, token: number) {
    var obj = {
      "address": 6358,
      "id": id,
      "user_id": this.user_id,
      "creator": this.creator,
      "token": token
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          var pe_message = "Error On Saveing";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_Menu(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading1 = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6122, id: id }).subscribe(
      (res: any) => {
        this.list_services = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            res['result'][i].logo = res['result'][i].user_logo_site + "/" + res['result'][i].user_logo;
            this.list_services.push(res['result'][i]);
          }//end for
          this.loading1 = false;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.loading1 = false;
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update_all_financial2(id: number, customer: number) {
    var obj = {
      "address": 6110,
      "id": id,
      "user_id": this.user_id,
      "customer": customer,
      "creator": this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  delete(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 1729; } else { address = 1728; }
      var obj = {
        'address': address, "user_id": this.user_id, creator: this.creator
        , 'id': id, "page": this.page, 'type': this.type
        , search: this.search_array
        , search_all: this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (typeof id == 'number') {
              this.list_record.splice(this.list_record.findIndex((index: any) => index.repairman_reception_id == id), 1);
            }
            else {
              for (var i = 0; i < res['result_record'].length; i++) {
                this.list_record.splice(this.list_record.findIndex((index: any) => index.repairman_reception_id == res['result_record'][i].repairman_reception_id), 1);
              }
            };
            this.dataSource.data = this.list_record;
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.empty_shelf(res['result'][0].repairman_reception_shelf);
            this.serverService.send_reception_rstatus({ rstatus_id: this.rstatus, code: this.code, level: this.level, alter: true });
            // this.serverService.send_reception_repairman();
            this.delete_financial(id);
            this.server_update_count_reception();
          }//end if
          else if (res['status'] == 4) {
            var message = "This record either has payment or parts and services listed for it. Unable to delete";
            this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.message_action(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true
  }//end delete
  server_update_count_reception() {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { address: 6387, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  delete_financial(id: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6109, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.message(true, this.messageService.delete2(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  empty_shelf(shelf: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1811, shelf: shelf, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  empty_shelf_and_reception(id: number, shelf: number) {
    var obj = { address: 6621, shelf: shelf, creator: this.creator, id: id }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_payment(i: number, id: number, id2: number) {
    const dialogRef = this.dialog.open(PaymentComponent, {
      width: '65rem',
      height: 'auto',
      data: { code: this.code, id: id, ref: 3, page: this.page, search_all: this.search_all, search_array: this.search_array, i: i, creator: this.creator, level: this.level, id2: id2 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.splice(i, 1);
          this.dataSource.data = this.list_record;
          this.pages(-1);
        }//end if
      }
    )
  }
  open_exit(i: number, id: number) {
    this.back_i = -1;
    const dialogRef = this.dialog.open(WharehouseExit2Component, {
      'width': '85rem',
      'height': 'auto',
      data: { 'reception_id': id, token: this.token, ref: 'exit', type: 2, code: 160, creator: this.creator, level: this.level, default_rstatus: this.default }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        var change_status = 0;
        if (res) {
          this.list_record[i].repairman_rstatus_title = res.repairman_rstatus_title;
          if (this.pos == 'right') {
            if (this.rstatus != res.repairman_reception_status) {
              this.list_record.splice(i, 1);
              this.dataSource.data = this.list_record;
              this.pages(-1);
              change_status = 1;
            }
          } else {
            if (this.default == 1) {
              if (res.repairman_rstatus_default == 0) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            }
            if (this.delivery == 1) {
              if (res.repairman_rstatus_empty_shelf == 0) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            }
            if (this.ready_delivery == 1) {
              if (res.repairman_rstatus_sub_ready_delivery == 0) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            }
            if (this.ready_reparing == 1) {
              if (res.repairman_rstatus_repairing == 0) {
                this.list_record.splice(i, 1);
                this.dataSource.data = this.list_record;
                this.pages(-1);
                change_status = 1;
              }
            }
          }
        }
      }
    )
  }

  openchart() {
    this.dialog.open(ChartsComponent, {
      width: '95%',
      height: '80vh',
      data: { creator: this.creator, title: " reception ", address1: 7084, address2: 7085, display_chart1: true, display_chart2: true, has_price: false }
    });
  }
  //********************************************************************************* */
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
      this.loading2 = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
