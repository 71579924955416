<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
    <div mat-dialog-title class="matDialogTitle" cdkDragHandle style="background-color: transparent;box-shadow:none">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
</div>
<div mat-dialog-content class="matDialogContent padding0" dir="ltr" style="min-height: 32rem;">
    <div class="row">
        <div class="col-12 mx-auto p-2">
            <form #f2="ngForm">
                <div class="alert text-center" style="background-color: #f2f2f2;border:1px solid #ddd;" role="alert">
                    <div class="w-100 text-center">
                        SMS Recharge
                    </div>
                </div>
                <div id="payments" class="mt-2">
                    <table id="tblinfo" class="table table-striped table-bordered mb-2">
                        <tbody>
                            <tr>
                                <td>
                                    <input class="wh-input input1 center amount" currencyMask [(ngModel)]="amount"
                                        name="amount" required style="height: 2rem !important;">
                                </td>
                                <td>
                                    Amount USD
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="alert text-center mt-2"
                        style="background-color: #f2f2f2;border:1px solid #ddd;width:90%;margin:0 auto" role="alert">
                        Select Your Payment method
                    </div>
                    <mat-tab-group (selectedTabChange)="onTabChanged($event)" mat-align-tabs="center">
                        <mat-tab label="Paypal">
                            <div id="paypal">
                                <div #paypal>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="Stripe">
                            <div id="stripe">
                                <fieldset>
                                    <legend class="card-only" data-tid="elements_examples.form.pay_with_card">Pay With
                                        Card</legend>
                                    <div class="pcontainer mb-3">
                                        <ngx-stripe-card [options]="cardOptions"
                                            [elementsOptions]="elementsOptions"></ngx-stripe-card>
                                    </div>
                                    <button type="submit" class="payBotton">
                                        <div (click)="createToken()" [hidden]="loading">
                                            PAY$ {{ amount | currency:'':''}}
                                        </div>
                                        <span [hidden]="!loading">
                                            <div class="spinner-border" style="width: 2rem; height: 2rem;"
                                                role="status">
                                                <span class="visually-hidden"></span>
                                            </div>
                                        </span>
                                    </button>
                                </fieldset>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </form>
        </div>
    </div>
</div>