<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row direction_ltr dflex">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='open_detaile(0,1,0)' [disabled]='new_status'>
                    <span>{{ messageService.get_new(lang) }}</span>
                </button>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,1,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll en1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
                page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>
                <span>Title</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_country_id)">
                <div class="left">{{ x.user_country_title }} </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="head_number">
            <mat-header-cell *matHeaderCellDef>
                <span>Head number</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_country_id)">
                <div>{{ x.user_head_number_phone }} </div>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="alpha_code">
            <mat-header-cell *matHeaderCellDef>
                <span> Alpha code</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_country_id)">
                {{ x.user_country_alpha_code}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="area_code">
            <mat-header-cell *matHeaderCellDef>
                <span> Area code</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_country_id)">
                {{ x.user_country_area_code }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef style="min-width: 300px;">
                <span> Registration and Renewal Fee in Dollars </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_country_id)"
                style="min-width: 300px;">
                {{ x.user_country_price | number }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="price2">
            <mat-header-cell *matHeaderCellDef>
                <span> Fee per user in Dollars </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_country_id)">
                {{ x.user_country_price2 | number }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="default">
            <mat-header-cell *matHeaderCellDef>
                <span>Default</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)='change_default(i,x.user_country_default,x.user_country_id)'>
                    <span *ngIf="x.user_country_default == 0"><i class="far fa-star icon"></i> </span>
                    <span *ngIf="x.user_country_default == 1"><i class="fas fa-star icon" style="color: blue;"></i>
                    </span>
                </span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sort">
            <mat-header-cell *matHeaderCellDef [hidden]="true">
                <span>Sort</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [hidden]="true" (click)="open_detaile(i,2,x.user_country_id)">{{
                x.user_country_sort }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <span>Status</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_country_id)">
                <span>{{ x.status_title }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:70px" [hidden]="true">
                <span>ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.user_country_id)" [hidden]="true"
                style="max-width:70px">
                {{ x.user_country_id }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                <span>Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'>
                <span (click)="check_access(3,0,x.user_country_id,0,1)"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
            </mat-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets//images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>