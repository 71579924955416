<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        {{ serverService.get_title(type_task,lang) }}
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row">

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="title" class='wh-label'>
                    <span> Title </span>
                    <input class="wh-input" formControlName="title" [required]="required()">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="header_number" class='wh-label'>
                    <span> Header number </span>
                    <span (click)="reset_input(270)">
                        <mat-icon class="close" class="input_icon_close">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="header_number_title" (click)="load_services(270) "
                        readonly>
                    <input class="wh-input" formControlName="header_number" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="alpha_code" class='wh-label'>
                    <span> Alpha code </span>
                    <input class="wh-input" formControlName="alpha_code">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="area_code" class='wh-label'>
                    <span> Area code </span>
                    <input class="wh-input" formControlName="area_code" (keypress)="serverService.get_number($event)">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="price" class='wh-label'>
                    <span> Registration Fee </span>
                    <input class="wh-input" formControlName="price" (keypress)="serverService.get_number($event)"
                        currencyMask>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="price2" class='wh-label'>
                    <span> Fee per user in Dollars </span>
                    <input class="wh-input" formControlName="price2" (keypress)="serverService.get_number($event)"
                        currencyMask>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="cost_sms" class='wh-label'>
                    <span> Cost per SMS </span>
                    <input class="wh-input" formControlName="cost_sms">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding' [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span> Sort </span>
                    <input class='wh-input' formControlName="sort" (keyup.enter)="check_access(type_task,1)"
                        autocomplete="off">
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="status_id" class='wh-label'>
                    <span> Display status </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_icon_close">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>

            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 div_panding'>
                <label for="tax_title" class='wh-label'>
                    <span> Tax Title </span>
                    <input class="wh-input" formControlName="tax_title">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="reception_sms" class='wh-label'>
                    <span> Reception SMS </span>
                    <textarea style="height: 3rem;" class="wh-input" formControlName="reception_sms"></textarea>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12  pe padding0' [hidden]="type_task == 3">
                <div class='wh-label logo_title'> Flag of countty</div>
                <div *ngIf="logo_loading == true" class="upload_div_loading">
                    <div class="spinner-border spinner-border-sm upload_spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <a (click)="open_logo()" class="cursor">
                    <div class="upload_div_file" *ngIf="logo_type == 'pdf' && logo_loading == false">
                        <i class="far fa-file-pdf upload_div_file_icon" style="color: red;"></i>
                    </div>
                    <mat-card class="example-card logo cursor backgroundImage upload_div_file2"
                        *ngIf="logo_type != 'pdf' && logo_loading == false"
                        [ngStyle]="{'background-image':'url( ' + logo + ')'}" accept="image/*">
                        <mat-card-header style="display:inline" class="pe1">
                        </mat-card-header>
                    </mat-card>
                </a>
                <span (click)="delete_logo()" *ngIf="logo_bin == true">
                    <button class="upload_button_change">Delete File</button>
                </span>
                <div class="upload_image_change cursor" (click)="logo3.click()">
                    <button type="button" class="upload_button_change">Upload File</button>
                </div>
                <input type="file" (change)="change_logo($event)" style="display: none;" #logo3
                    accept="image/*,application/pdf">
            </div><!-- col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe  -->

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn" *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>