import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Profit2FilterComponent } from "../profit2-filter/profit2-filter.component";
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { InvoiceDetaileComponent } from '../../../wharehouse/wharehouse-invoice/invoice-detaile/invoice-detaile.component';
import { WharehouseExit2Component } from '../../../wharehouse/wharehouse-exit2/wharehouse-exit2.component';
import { MessageService } from '../../../service/message/message.service';
import { TableColumn } from 'src/app/system/models/TableColumn';
import { ColumnConfigDialogComponent } from 'src/app/system/models/column-config-dialog/column-config-dialog.component';
import { ConfirmDialogComponent } from 'src/app/system/models/confirm-dialog/confirm-dialog.component';
// import { ChartsComponent } from '../../charts/charts/charts.component';


@Component({
  selector: 'app-profit2-list',
  templateUrl: './profit2-list.component.html',
  styleUrls: ['./profit2-list.component.scss']
})
export class Profit2ListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public list_record: any = [];
  public list_status: any = [];
  public subscription: Subscription | any;
  public loading: boolean = false;
  public show_excel: boolean = false;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public search_array: any = [];
  public selected_record: any = [];
  public count_total: number = 1;
  public page: number = 1;
  public excel: number = 1;
  public profit: number = 0;
  public discount: number = 0;
  public user_id!: number;
  public task_type!: number;
  public confirm_delete!: boolean;
  public page_number_first!: number;
  public page_number_second!: number;
  public code!: any;
  public export_code!: string;
  public type_task!: number;
  public type!: number;
  public type_search!: number;
  public search_all!: string;
  public sum_profit!: number;
  public sum_profit3!: number;
  public sum_service!: number;
  public access_service!: number;
  public level!: number;
  public path_export!: string;
  public id!: number;
  public pre_status!: boolean;
  public next_status!: boolean;
  public new_status!: boolean;
  public status_id!: number;
  public title!: string;
  public select_all!: boolean;
  public creator!: number;
  public video!: string;
  public year!: number;
  public month!: number;
  public seller!: number;
  public status_search!: boolean;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['Row', 'Date', 'Source', 'Reception / Invoice No', 'Client', 'Repairman', 'Service', 'Fee', 'Commission', 'Service commission', 'Net Margin After Commission', 'Discount'];

  columns: TableColumn[] = this.displayedColumns.map(column => new TableColumn(column, false,true,true));

  public orginal_order: string = "orginal_profit2_list_columns";

  public title_of_order: string = "profit2_list_columns";

  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialog) { }
  ngOnInit() {

    this.setcolConfig();

    localStorage.setItem(this.orginal_order, JSON.stringify(this.displayedColumns));

    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    );
    this.activatedRoute.queryParams.subscribe(
      (params: Params) => {
        this.year = params['year'];
        this.month = params['month'];
        this.seller = params['seller'];
        this.status_search = params['search'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  setcolConfig()
  {
    this.columns[0].sortable=false;
  }

  openColumnConfigDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '17rem';

    dialogConfig.height = "auto";
    dialogConfig.data = { columns: this.columns, title: this.title_of_order };

    const dialogRef = this.dialog.open(ColumnConfigDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.load_table_Config();

      }
    });
  }

  load_table_Config() {

    const savedColumnConfig = localStorage.getItem(this.title_of_order);

    if (savedColumnConfig) {

      const savedColumns = JSON.parse(savedColumnConfig);

      this.columns = savedColumns.map((col: { name: string; isHiden: boolean | undefined;sortable:boolean | undefined;hideable:boolean | undefined  }) => new TableColumn(col.name, col.isHiden,col.sortable,col.hideable));

      this.displayedColumns = this.columns.filter(cd => !cd.isHiden).map(cd => cd.name);
    }
    else
    {
      const OrginalColumnConfig = localStorage.getItem(this.orginal_order);

      if (OrginalColumnConfig)
      {
         this.displayedColumns =JSON.parse(OrginalColumnConfig);

         this.columns= this.displayedColumns.map(column => new TableColumn(column, false,true,true));

         this.setcolConfig();

      }

    }
  }

  resetHeaders() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '22rem',
      height:'10rem',
      data: { message: 'The settings of the columns will be reset! Are you sure you want to proceed?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const savedColumnConfig = localStorage.getItem(this.title_of_order);

        if (savedColumnConfig) {

          localStorage.removeItem(this.title_of_order);
        }

        const OrginalColumnConfig = localStorage.getItem(this.orginal_order);

        if (OrginalColumnConfig) {

          this.displayedColumns = JSON.parse(OrginalColumnConfig);

          this.columns = this.displayedColumns.map(column => new TableColumn(column, false, true, true));

          this.setcolConfig();

        }
        // Proceed with the action
      } else {

        this.matSnackBar.open("Reseting Canceled By User", "close", { duration: 5000 });

      }
    });



  }



  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    var obj = {
      'address': 6,
      'type': 'user',
      'user_id': this.user_id,
      'id': this.code
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    var obj = {
      'address': 6,
      'user_id': this.creator,
      'id': this.code,
      'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_data(page: number, excel: number, loading: number, changedRows: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }


    const savedColumnConfig = localStorage.getItem(this.title_of_order);

    if (savedColumnConfig) {

      const savedColumns = JSON.parse(savedColumnConfig);

      this.columns = savedColumns.map((col: { name: string; isHiden: boolean | undefined; sortable: boolean | undefined; hideable: boolean | undefined }) => new TableColumn(col.name, col.isHiden, col.sortable, col.hideable));

      this.displayedColumns = this.columns.filter(cd => !cd.isHiden).map(cd => cd.name);
    }

    if (loading == 1) this.loading = true;
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var obj = {
      'address': 6842,
      "page": this.page,
      'type': this.type,
      'excel': excel,
      'user_id': this.user_id,
      'search': this.search_array,
      'search_all': this.search_all,
      'changedRows': changedRows,
      'creator': this.creator,
      'year': this.year,
      'month': this.month,
      'seller2': this.seller,
      'path_export': this.path_export
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.discount = res['discount'];
            this.sum_profit = res['sum_profit'];
            this.sum_profit3 = res['sum_profit3'];
            this.sum_service = res['sum_service'];
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reset({});
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  open_detaile_readonly(type: string, id: number) {
    if (type == 'reception') {
      this.open_reception(id)
    } else {
      this.open_sale(id)
    }
  }
  open_reception(reception: number) {
    this.dialog.open(WharehouseExit2Component, {
      'width': '70rem',
      'height': 'auto',
      data: { 'reception_id': reception, readonly: true }
    });
  }
  open_sale(id: number) {
    this.dialog.open(InvoiceDetaileComponent, {
      width: '65rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: 2, id: Number(id), readonly: true, code: this.code }
    });
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number): any {
    const dialogRef = this.dialog.open(Profit2FilterComponent, {
      width: '30rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
          }
          else if (type_task == 2) {
          }
          else if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }
  openchart() {
    // this.dialog.open(ChartsComponent, {
    //   width: '95%',
    //   height: '80vh',
    //   data: { creator: this.creator, title: " سود ناخالص خدمات ", address1: 7093, address2: 7094,display_chart1:true,display_chart2:true,has_price:true }
    // });
  }
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

