export class TableColumn {

  name: string;

  isHiden: boolean;

  sortable: boolean;

  hideable: boolean;

  constructor(name: string, _isHiden: boolean = false,_sortable:boolean=true,_hideable:boolean=true) {
    this.name = name;

    this.isHiden = _isHiden;

    this.sortable=_sortable;

    this.hideable=_hideable;

  }
}
