<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>Cash register</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding">
                <div class="inventory">
                    {{ currency_title2 }}
                    {{ cash | currency : '':'' }}
                </div>
                <div style="text-align: center;">Cash Register Balance</div>
            </div>
            <!--**********************************************************************-->
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="issue_date" class="wh-label">
                    <span> Date from </span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" formControlName="date3" autocomplete="off">
                    </div>
                </label>
            </div>
            <!--**********************************************************************-->
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'>
                <label for="issue_date" class="wh-label">
                    <span> Date to </span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" formControlName="date4" autocomplete="off">
                    </div>
                </label>
            </div>
            <!--**********************************************************************-->
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="button" class="btn" (click)="check_search()">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>