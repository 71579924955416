<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span> {{ serverService.get_title(type_task,lang) }} </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,3)">
    <div mat-dialog-content class="matDialogContent">

        <div class="row pe1">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="date1" class='wh-label'>
                    <span> Document Date from </span>
                    <input type="date" class="wh-input left" matInput formControlName="document_date1"
                        autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="date1" class='wh-label'>
                    <span> Document Date to </span>
                    <input type="date" class="wh-input left" matInput formControlName="document_date2"
                        autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="group" class='wh-label'>
                    <span> Category </span>
                    <span (click)="reset_input(254)">
                        <mat-icon class="close" class="input_search input_icon_close">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_group_title" (click)="load_services(254)" readonly>
                    <input class="wh-input" formControlName="cost_group" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="document" class='wh-label'>
                    <span> Subcategory </span>
                    <span (click)="reset_input(221)">
                        <mat-icon class="close" class="input_search input_icon_close">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cost_title" (click)="load_services(221)" readonly>
                    <input class="wh-input" formControlName="cost" [hidden]="true" readonly>
                </label>
            </div>


            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'>
                <label for="user" class='wh-label'>
                    <span> Description </span>
                    <input class="wh-input" formControlName="comment">
                </label>
            </div>
            <!--************************************************************** -->
        </div><!-- row -->
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <div class="row" style="width: 100%;">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 right padding0">
                <button type="submit" class="btn">
                    {{ serverService.get_title(type_task,lang) }}
                </button>
            </div>
        </div>
    </div>
</form>