import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-profit-filter',
  templateUrl: './profit-filter.component.html',
  styleUrls: ['./profit-filter.component.scss']
})
export class ProfitFilterComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));;
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));;
  public loading: boolean = false;
  public search_array: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public i!: number;
  public code!: number;
  public title!: string;
  public creator!: number;

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public matDialogRef: MatDialogRef<ProfitFilterComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.title = dialog_data.title;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
      }
      if (this.type_task == 2) {
        this.check_access(4);
      }
      if (this.type_task == 3) {
        this.creator = dialog_data.creator;
        this.form1_group.patchValue({
          seller: dialog_data.search_array.seller,
          seller_title: dialog_data.search_array.seller_title,
          customer: dialog_data.search_array.customer,
          customer_title: dialog_data.search_array.customer_title,
          place: dialog_data.search_array.place,
          date1: dialog_data.search_array.date1,
          date2: dialog_data.search_array.date2,
        })
      }
    }
  }//end consructor
  search() {
    this.matDialogRef.close({
      seller: this.form1_group.value.seller,
      seller_title: this.form1_group.value.seller_title,
      customer: this.form1_group.value.customer,
      customer_title: this.form1_group.value.customer_title,
      place: this.form1_group.value.place,
      date1: this.form1_group.value.date1,
      date2: this.form1_group.value.date2,
    })
  }

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'title': new FormControl(null),
      'seller_title': new FormControl(null),
      'seller': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'customer_title': new FormControl(null),
      'customer': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'place': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'date1': new FormControl(null),
      'date2': new FormControl(null),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['title'].setValidators([Validators.required])
      this.form1_group.controls['status_id'].setValidators([Validators.required])
    }
  }
  check_access(type_task: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6,
      'type': 'user',
      'user_id': this.user_id,
      'id': this.code,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.title = res['result'][0].title;
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 1) { }
            if (type_task == 2) { }
            if (type_task == 3) { this.search(); }
            if (type_task == 4) { this.get_data(); }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1675, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].wharehouse_order_title,
            'sort': res['result'][0].wharehouse_order_sort,
            'status_id': res['result'][0].wharehouse_order_status,
            'status_title': res['result'][0].status_title,
          }
          )
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_services(id: number) {
    var code_id;
    if (id == 219) {
      code_id = 6196;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator, user_id: this.user_id }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 219) {
            this.form1_group.patchValue({
              'seller': res.id,
              'seller_title': res.title
            })
          }
          if (id == 161) {
            this.form1_group.patchValue({
              'customer': res.id,
              'customer_title': res.title
            })
          }
        }
      }
    )
  }
  reset_input(id: number) {
    if (id == 219) {
      this.form1_group.patchValue({
        'seller': null,
        'seller_title': null
      })
    }
    if (id == 161) {
      this.form1_group.patchValue({
        'customer': null,
        'customer_title': null
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
