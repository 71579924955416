<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span>
            <span *ngIf="lang == 1">روزنامه</span>
            <span *ngIf="lang == 2">Journal entry</span>
        </span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="search()">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row" pe1>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'en':dir == 'ltr' ,'left':dir == 'ltr','fleft':dir == 'ltr'}">
                    <label for="date3" class='wh-label'>
                        <span *ngIf="lang == 1"> تاریخ </span>
                        <span *ngIf="lang == 2"> Date </span>
                        <input type="date" class="wh-input" matInput formControlName="date3" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}" [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}"
                            required>
                    </label>
                </div>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'en':dir == 'ltr','fleft' : dir == 'ltr','left' : dir == 'ltr'}">
                    <label for="order" class='wh-label'>
                        <mat-radio-group aria-label="Select an option" formControlName="order"
                            class="mat-radio-button_pe" [ngClass]="{'mat-radio-button_en' : dir == 'ltr'}">
                            <mat-radio-button value="asc">
                                <span *ngIf="lang == 1">از قدیم به جدید</span>
                                <span *ngIf="lang == 2">Old to new</span>
                            </mat-radio-button>
                            <mat-radio-button value="desc">
                                <span *ngIf="lang == 1">از جدید به قدیم</span>
                                <span *ngIf="lang == 2">New to old</span>
                            </mat-radio-button>
                        </mat-radio-group>
                    </label>
                </div>

            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe" [disabled]="!form1.valid">
            <span *ngIf="lang == 1">مشاهده</span>
            <span *ngIf="lang == 2">View</span>
        </button>
    </div>
</form>