<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    {{ title }}
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="check_access(2)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding"
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label class="wh-label">
                    <span *ngIf="lang == 1 ">عنوان منو</span>
                    <span *ngIf="lang == 2 ">Title menu</span>
                    <input class="wh-input" autocomplete="off" formControlName="title" required
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>
        </div>

        <div class="row pe1">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding' style="padding-right: 0"
                [ngClass]="{'full-left':dir == 'ltr'}">
                <div class='wh-label logo_title'>
                    <span *ngIf="lang == 1">لوگو</span>
                    <span *ngIf="lang == 2">Logo</span>
                </div>
                <div *ngIf="logo_loading == true" class="upload_div_loading">
                    <div class="spinner-border spinner-border-sm upload_spinner-border-sm" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </div>
                <a (click)="open_logo()" class="cursor">
                    <div class="upload_div_file" *ngIf="logo_type == 'pdf' && logo_loading == false">
                        <i class="far fa-file-pdf upload_div_file_icon" style="color: red;"></i>
                    </div>
                    <mat-card class="example-card logo cursor backgroundImage upload_div_file2"
                        *ngIf="logo_type != 'pdf' && logo_loading == false"
                        [ngStyle]="{'background-image':'url( ' + logo + ')'}" accept="image/*">
                        <mat-card-header style="display:inline" class="pe1" [ngClass]="{en1:lang==2}">
                        </mat-card-header>
                    </mat-card>
                </a>
                <span (click)="delete_logo()" *ngIf="logo_bin == true" [ngClass]="{'full-left':dir == 'ltr'}"
                    class="padding0">
                    <button class="upload_button_change">
                        <span *ngIf="lang == 1">حذف فایل</span>
                        <span *ngIf="lang == 2">Delete file</span>
                    </button>
                </span>
                <span class="upload_image_change cursor padding0" (click)="logo3.click()"
                    [ngClass]="{'full-left':dir == 'ltr'}">
                    <button type="button" class="upload_button_change">
                        <span *ngIf="lang == 1">آپلود فایل</span>
                        <span *ngIf="lang == 2">Upload file</span>
                    </button>
                </span>
                <input type="file" (change)="change_logo($event)" style="display: none;" #logo3
                    accept="image/*,application/pdf">
            </div><!-- col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe  -->
        </div>

    </div><!-- content -->

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="button" class="btn_pe" (click)="open_paccess()" [ngClass]="{btn_en:lang == 2}">
            <span *ngIf="lang == 1">دسترسی</span>
            <span *ngIf="lang == 2">َAccess</span>
        </button>
        <button type="button" class="btn_pe" (click)="open_video()" [ngClass]="{btn_en:lang == 2}">
            <span *ngIf="lang == 1">فیلم آموزشی</span>
            <span *ngIf="lang == 2">Educational film</span>
        </button>
    </div>
</form>