import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserDetaile3Component } from "../user-detaile3/user-detaile3.component";
import { UserVideoComponent } from '../user-video/user-video.component';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../service/message/message.service';
import { BarchartComponent } from '../../finance/charts/barchart/barchart.component';

@Component({
  selector: 'app-user-list-access',
  templateUrl: './user-list-access.component.html',
  styleUrls: ['./user-list-access.component.scss']
})
export class UserListAccessComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id!: number;
  public loading: boolean = false;
  public loading_copy: boolean = false;
  public show_cellphone: boolean = true;
  public show_excel: boolean = false;
  public show_financial: boolean = false;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public search_all_params: boolean = false;
  public groups_id: number = 0;
  public list_record: any = [];
  public list_status: any = [];
  public search_array: any = [];
  public selected_record: any = [];
  public subscription: Subscription | any;
  public count_total: number = 1;
  public page: number = 1;
  public excel: number = 1;
  public code!: any;
  public export_code!: string;
  public type_task!: number;
  public search_all!: string;
  public task_type!: number;
  public confirm_delete!: boolean;
  public page_number_first!: number;
  public page_number_second!: number;
  public id!: number;
  public title!: string;
  public pre_status!: boolean;
  public next_status!: boolean;
  public new_status!: boolean;
  public select_all!: boolean;
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'clear_token', 'admin', 'username', 'last_login', 'title', 'cellphone', 'status', 'profit_sale', 'profit_repairman', 'operation'];
  public creator!: number;
  public creator_temp!: number;
  public access_service!: number;
  public level!: number;
  public video!: string;
  public path_export!: string;
  public record_id!: number;
  public person_count: number = 0;

  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public http: HttpClient,
    public messageService: MessageService,
    public dialogRef: MatDialog) { }

  ngOnInit() {
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.check_access(4, 1, 0, 1, 1);

    //**************
  }//end ngOnInit
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.creator_temp = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.show_cellphone = res['show_cellphone'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_chart() {

    this.dialog.open(BarchartComponent, {
      width: '95%',
      height: '60vh',
      data: { creator: this.creator, count: this.person_count }
    });
  }
  
  load_data(page: number, excel: number, loading: number, changedRows: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = Number(this.page) + Number(1); }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    if (page == 6 || page == 1) { this.page = 1; }
    var obj = {
      'address': 6706,
      "page": this.page,
      'excel': excel,
      'user_id': this.user_id,
      'search': this.search_array,
      'search_all': this.search_all,
      'changedRows': changedRows,
      'creator': this.creator,
      'code': this.code,
      'path_export': this.path_export,
      'level': this.level,
      'show_cellphone': this.show_cellphone
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          this.person_count = res['num'];

          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.send_services_id(this.code);
            this.serverService.change_window_resize(2, '', 0);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  export_excel(code: number) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(UserDetaile3Component, {
      width: '55rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, level: this.level, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[i].user_groups_title = res.result.user_groups_title;
            this.list_record[i].status_title = res.result.status_title;
            this.list_record[i].user_title = res.result.user_title;
            this.list_record[i].user_cellPhone = res.result.user_cellPhone;
            this.list_record[i].user_profit_sale = res.result.user_profit_sale;
            this.list_record[i].user_profit_repairman = res.result.user_profit_repairman;
            this.list_record[i].user_username = res.result.user_username;
            this.list_record[i].user_sex_title = res.result.user_sex_title;
          }
          else if (type_task == 3) {
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }
  get_bg2(id: number) {
    this.record_id = id;
  }
  go_to_user2(user_id: number, token: number) {
    localStorage.setItem('refrerr', '2');
    window.open("/login?token_login=" + token + "&userId=" + user_id, "_self");
  }
  clear_token(i: number, id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    var message = "If you suspect that your username or password has been shared with others, you can change the token to deactivate their access immediately. Are you sure you want to change the token?";
    confirm_delete = window.confirm(this.messageService.message(this.lang, '', message));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6705, id: id }).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.message(true, this.messageService.change(this.lang), 1, this.messageService.close(this.lang));
          }//end if
          else {
            this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
          }
        }
      )
    }
  }
  //*******************************************************************************
  delete(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var obj = {
        'address': 7075,
        "user_id": this.user_id,
        'id': id
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.user_id == id), 1);
            this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.serverService.message_error(), 1, this.serverService.message_action());
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true
  }//end delete
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  check_for_access_to_software(j: number, value: number, id: number): any {
    this.loading = true;
    if (value == 1) {
      this.change_admin(j, value, id);
    } else {
      var obj = {
        address: 7052,
        creator: this.creator
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            if (res['count'] >= res['number_access']) {
              var message = "The number of users you have given access to the system has ended and you cannot give new access";
              this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
              return false;
            }
            else {
              this.change_admin(j, value, id);
            }
          }//end if
          else {
            this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
          }
        }
      )
    }
  }
  change_admin(j: number, value: number, id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 7076, value: !value, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.list_record[j].user_admin = res['result'][0].user_admin;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
