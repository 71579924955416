import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServerService } from '../../service/server/server.service';
import { RecieveDetaileComponent } from "../finance-recieve/recieve-detaile/recieve-detaile.component";
import { WharehouseExit2Component } from '../../wharehouse/wharehouse-exit2/wharehouse-exit2.component';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
  public server_main: string = this.serverService.get_server_main();
  public server: string = this.serverService.get_server();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading: boolean = false;
  public subscription: Subscription | any;
  public subscription2: Subscription | any;
  public subscription3: Subscription | any;
  public user_id: number;
  public list_device_status: any = [];

  document_type: number = 1;//برای دریافت شناسه سند دریافت از ظرف حساب
  id: number | undefined;
  ref: number | undefined;
  list_record_financial: any = [];
  amount: number = 0;
  token: any = Math.floor(Math.random() * 99999999999999);
  user: number;
  code: number;
  list_not: any = [];
  list_cheque: any = [];
  document: number = 6;
  type_task: number = 2;
  user_title: string;
  user_cellphone: number;
  shelf: string | undefined;
  device: string | undefined;
  brand: string | undefined;
  model: string | undefined;
  sum: number = 0;
  data: any = [];
  trans: number | undefined;
  remain: number | undefined;
  url: string = this.router.url.split("?")[0];
  show_financial: boolean = false;
  page: number | undefined;
  search_all: string | undefined;
  search_array: any | undefined;
  i: number | undefined;
  id2: number | undefined;
  currency_title2: string | undefined;
  delivered: boolean = false;
  result: any;
  shelf_temp: number;
  creator: number;
  level: number;
  status_empty_shelf: boolean = false;
  discount_material: number = 0;
  discount_services: number = 0;
  user_sex: string | undefined;
  repairman: any | undefined;
  count_order: number = 0;
  device_status: number | undefined;

  constructor(
    public serverService: ServerService
    , public messageService: MessageService
    , public dialog: MatDialog
    , public router: Router
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public matDialogRef: MatDialogRef<PaymentComponent>) {

    this.subscription3 = this.serverService.get_payments().subscribe(
      (res) => {
        if (res) {
          this.get_reception();
        }
      }
    )

    if (dialog_data) {
      this.i = dialog_data.i;
      this.id = dialog_data.id;
      this.id2 = dialog_data.id2;
      this.ref = dialog_data.ref;
      this.creator = dialog_data.creator;
      this.level = dialog_data.level;
      this.code = dialog_data.code;
      this.page = dialog_data.page;
      this.search_all = dialog_data.search_all;
      this.search_array = dialog_data.search_array;
    }

    this.subscription2 = this.serverService.get_remain_account().subscribe(
      (res) => {
        this.get_account();
      }
    )
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

    this.get_reception();
  }

  get_device_status() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6465, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.list_device_status = [];
          for (var i = 0; i < res['num']; i++) {
            this.list_device_status.push(res['result'][i]);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_reception() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1730, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.user = res['result'][0].repairman_reception_customer;
          this.device_status = res['result'][0].repairman_reception_device_status;
          this.trans = res['result'][0].repairman_reception_trans;
          this.user_title = res['result'][0].user_title;
          this.user_sex = res['result'][0].user_sex_title;
          this.user_cellphone = res['result'][0].user_cellPhone;
          this.shelf = res['result'][0].repairman_shelf_title;
          this.device = res['result'][0].wharehouse_device_title;
          this.brand = res['result'][0].repairman_brand_title;
          this.model = res['result'][0].repairman_model_title;
          this.shelf_temp = res['result'][0].repairman_reception_shelf;
          this.status_empty_shelf = res['result'][0].repairman_rstatus_empty_shelf;
          this.sum = res['result'][0].sum;
          this.discount_material = res['result'][0].discount_material;
          this.discount_services = res['result'][0].discount_services;
          this.repairman = res['result'][0].repairman_reception_repairman;
          this.count_order = res['result'][0].count_order;

          this.get_account();
          this.get_currency();
          this.get_document_type();
          this.get_device_status();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_currency() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6853, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.currency_title2 = res['result'][0].site_currency_title2;
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_document_type() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6214, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.document_type = res['result'][0].finance_document_id;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  show_financial2() {
    this.show_financial = true;
    this.router.navigate(['/home/financial2/202'], { queryParams: { user: this.user, user_cost: 'user' } })
  }

  back() {
    this.show_financial = false;
    var obj = { 'page': this.page, 'all': this.search_all, back_i: this.i };
    this.router.navigate([this.url], { queryParams: obj });
  }

  get_account() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1731, user: this.user }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.remain = res['result'][0].balance;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          var pe_message = "خطا در $";
          var en_message = "Erorr in $";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_amount(ref: number) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6030, id: this.id, token: this.token, ref: this.ref }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.amount = res['result'][0].amount;
          if (this.amount == null) this.amount = 0;
          if (ref == 1) {
            this.list_record_financial.push({ 'amount': this.amount, 'footer': true, color: '#9ACD32', 'finance_payment_type_title': 'جمع کل' });
          } else {
            this.list_record_financial[this.list_record_financial.length - 1].amount = this.amount;
          }
        }//end if
        else {
          var pe_message = "خطا در محاسبه عدد نهایی";
          var en_message = "Error in calculating the final number";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************** */
  update2(type) {
    if (type == 'delivered') {
      this.delivered = true;
    } else {
      this.delivered = false;
    }
    this.update();
  }

  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var x;
    if (this.lang == 1) x = "آیا شما از تحویل نهایی اطمینان دارید ؟ ";
    else x = "Are you sure of the final delivery ?"
    var confirm = window.confirm(x);
    if (confirm == true) {
      this.loading = true;
      var obj = {
        address: 6119
        , delivered: this.delivered
        , id: this.id, creator: this.creator, device_status: this.device_status
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            var confirm_sms = false;
            if (res['result'][0].user_sms2 == 1) {
              var a; var b;
              if (this.lang == 1) {
                a = "آیا میخواهید ";
                b = " از تغییر وضعیت مطلع نمائید؟";
              }
              confirm_sms = window.confirm(a + this.user_sex + " " + this.user_title + b);
              res['result'][0].confirm_sms = confirm_sms;
            }
            this.result = res['result'][0];
            this.empty_shelf(this.shelf_temp, 0, 'empty_reception');
          }//end if
          else {
            this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
          }
        }
      )
    }
  }//end recieve_data


  new() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 1927
      , document_type_text: 'exit'
      , user: this.user, user_id: this.user_id
      , creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.open_financial(-1, 2, res['id']);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  open_financial(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(RecieveDetaileComponent, {
      width: '65rem',
      height: 'auto',
      data: { type_task: type_task, id: id, i: i, code: 197, document: 1, sum: this.remain, reception_number: this.id2, ref: 'reception' }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        this.get_account();
      }
    )
  }
  empty_shelf(shelf: any, new_shelf: number, type: string) {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1811, shelf: shelf, type: type, id: this.id, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ result: this.result });
          //this.serverService.send_reception_repairman();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  open_exit() {
    const dialogRef = this.dialog.open(WharehouseExit2Component, {
      'width': '70rem',
      'height': 'auto',
      data: { 'reception_id': this.id, token: this.token, ref: 'exit', type: 2, code: this.code, creator: this.creator, level: this.level }
    });
  }

  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }//end if

    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }//end if

  }//end OnDestroy
}
