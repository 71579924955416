<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row dflex direction_ltr">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_search()'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe">
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll_bg en1'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef style="max-width:100px">
                <span>Date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width:100px">
                {{ x.finance_financial2_date }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef style="max-width:120px">
                <span> Type </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)"
                style="max-width:120px">
                {{ x.type_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="max-width:120px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="document">
            <mat-header-cell *matHeaderCellDef style="min-width:400px">
                <span>Description</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:400px">
                <div class="left">
                    <span>
                        {{ x.alias }}
                        <span> {{ x.finance_payment_type_title }} </span>
                        <span>Commission for Document No. </span>
                        <span *ngIf="x.finance_financial2_ref == 1 || x.finance_financial2_ref == 4"> {{
                            x.id }}</span>
                        <span *ngIf="x.finance_financial2_ref == 3"> {{
                            x.id2 }}</span>
                        <span *ngIf="x.finance_financial2_ref == 2"> {{
                            x.wharehouse_invoice_number }}</span>
                    </span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:400px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef style="min-width: 300px">
                <span>Client Name</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width: 300px"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="left">{{ x.user_title }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style="direction: rtl;min-width: 300px"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="bed">
            <mat-header-cell *matHeaderCellDef>
                <span>Debtor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="left">
                    <span class="amount">
                        <span *ngIf="x.bed">{{ currency_title2 }} </span>
                        {{x.bed | currency:'':'' }}
                    </span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div class="left">
                    <span class="amount">
                        <span *ngIf="bed">{{ currency_title2 }} </span>
                        {{ bed | currency:'':'' }}
                    </span>
                </div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="best">
            <mat-header-cell *matHeaderCellDef>
                <span>Creditor</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="left fleft">
                    <span class="amount">
                        <span *ngIf="x.best">{{ currency_title2 }} </span>
                        {{x.best | currency:'':'' }}
                    </span>
                </div>
                <div class="right" [matMenuTriggerFor]="menu"
                    (click)="check_material(x.finance_financial2_order_id,x.wharehouse_order_material_type)"
                    *ngIf="x.finance_financial2_type_ref2 > 0"><i class="fas fa-info-circle icon"></i></div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div class="row">
                    <div class="left">
                        <span class="amount">
                            <span *ngIf="best">{{ currency_title2 }} </span>
                            {{ best | currency:'':'' }}
                        </span>
                    </div>
                </div>
            </mat-footer-cell>
        </ng-container>

        <mat-menu #menu="matMenu" class="cardex-mat-menu-panel" xPosition="before">
            <div class="div_loading">
                <div class="my-container" [hidden]="!loading2">
                    <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
                </div>
            </div>
            <table class="table table-bordered" (click)="$event.stopPropagation()">
                <tr>
                    <td class="left">
                        <span *ngIf="amount >= 0">The profit amount of this record</span>
                        <span *ngIf="amount < 0" class="alarm">The loss amount of this record</span>
                    </td>
                    <td> <span class="amount" [ngClass]="{alarm:amount < 0}">{{ amount | currency:'':''
                            }}</span> </td>
                </tr>
                <tr>
                    <td class="left">
                        <span> Discount </span>
                    </td>
                    <td> <span class="amount">{{ discount |
                            currency:'':''
                            }}</span> </td>
                </tr>
                <tr>
                    <td class="left">
                        <span> Calculated commission </span>
                    </td>
                    <td> {{ profit | currency:'':'' }} </td>
                </tr>
            </table>
        </mat-menu>

        <ng-container matColumnDef="remain">
            <mat-header-cell *matHeaderCellDef [hidden]="code == 203">
                <span>Balance</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [hidden]="code == 203"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="money">
                    <span *ngIf="x.remain2">{{ currency_title2 }} </span>
                    {{ x.remain2 | currency:'':'' }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div>
                    <span *ngIf="remain">{{ currency_title2 }} </span>
                    {{ remain | currency:'':'' }}
                </div>
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef>
                <span>Description</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)="open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type,x.finance_financial2_cheque)">
                <div class="left">{{ x.comment2 }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                <div></div>
            </mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true">
        </mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>