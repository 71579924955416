<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>Accounts</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div class="matDialogContent" mat-dialog-content style="overflow-y: hidden !important;">
    <div class="content-body control-content-body" style='max-height: 25rem;'>
        <mat-table [dataSource]="dataSource" class='matTable table-scroll mat_table_fa_without_scroll_bg'>

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i
                    + 1
                    }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span>Account Holder</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='select(x.site_bank_accounts_id,x.site_bank_accounts_number)'>
                    {{ x.site_bank_accounts_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="branch_title">
                <mat-header-cell *matHeaderCellDef>
                    <span>Branch name</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='select(x.site_bank_accounts_id,x.site_bank_accounts_number)'>
                    {{ x.site_bank_accounts_branch_title }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="cart_number">
                <mat-header-cell *matHeaderCellDef>
                    <span>Account Number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='select(x.site_bank_accounts_id,x.site_bank_accounts_number)'>
                    {{ x.site_bank_accounts_number }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="account_number">
                <mat-header-cell *matHeaderCellDef>
                    <span>Card number</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index"
                    (click)='select(x.site_bank_accounts_id,x.site_bank_accounts_number)'>
                    {{ x.site_bank_accounts_cart }}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
            <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
                (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets/images/preview2.png">
                </div>
                <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
            </div>
        </div>

    </div>
</div>