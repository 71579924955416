<span (click)="close()" style="float:right">
  <mat-icon>close</mat-icon>
</span>

<div class="row text-center" *ngIf="loading">
  <img src="https://my.finty.co/assets/images/loading_invoice.gif" style="height: 100px;margin-top: 10%;"
    alt="Loading...">
  <br />
  <p class="w-100 text-center">
    Calculating {{year}}
  </p>
</div>
<div class="row" *ngIf="!loading">

  <div class="col-12 col-xl-6 col-lg-6" style="margin-top:2rem;">
    <highcharts-chart [Highcharts]="Highcharts1" [options]="chartOptions1"
      style="width: 100%; height: 400px; display: block;"></highcharts-chart>
  </div>
  <div class="col-12 col-xl-6 col-lg-6">
    <highcharts-chart [Highcharts]="Highcharts2" [options]="chartOptions2"
      style="width: 100%; height: 400px; display: block;"></highcharts-chart>
  </div>

</div>
