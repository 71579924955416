import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { MessageService } from '../../../service/message/message.service';
import { NewspaperDetaileComponent } from '../newspaper-detaile/newspaper-detaile.component';

@Component({
  selector: 'app-newspaper-list',
  templateUrl: './newspaper-list.component.html',
  styleUrls: ['./newspaper-list.component.scss']
})
export class NewspaperListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public user_id: number | undefined;
  public loading = false;
  public subscription: Subscription;
  public task_type: number | undefined;
  public confirm_delete: boolean | undefined;
  public count_total: number = 1;
  public page_number_first: number | undefined; public page_number_second: number | undefined; public page: number = 1;
  public code: any;
  public show_excel: boolean = false;
  public excel: number = 1;
  public sum: number = 0;
  public export_code: string | undefined;
  public type_task: number | undefined;
  public type: number | undefined; public type_search: number | undefined;
  public search_all: string | undefined;
  public hidden: boolean = true;
  public list_record_invoice: any = [];
  public list_record_reception: any = [];
  public list_record_finance: any = [];
  search_finance_newspaper: string;
  //******************************************************************************
  public list_record: any = [];
  public list_status: any = [];
  public creator: number | undefined;
  public video: string | undefined;
  public user_cost: number | undefined;
  public access_service: number | undefined;
  public level: number | undefined;
  public path_export: string | undefined;
  //******************************************************************************
  public id: number | undefined;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public status_id: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  //************
  public selected_record: any = [];
  public select_all: boolean | undefined;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  //***********
  public mat_table_selectedRow: any;
  public mat_table_hoverRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'date', 'document', 'user', 'bed', 'best', 'material', 'number', 'price', 'discount', 'sum'];
  //*************
  public best: number = 0;
  public bed: number = 0;
  public remain: number = 0;
  public remain2: number = 0;
  public user: number | undefined;
  public cost: number | undefined;
  public date3: string;
  public date2: string;
  public order: number;
  public ref: number | undefined
  public bill_i: number | undefined
  public bill_page: number | undefined
  public bill_ref: number | undefined;
  public bill_search_all: number | undefined;
  public bill_link: string | undefined;
  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router
    , public activatedRoute: ActivatedRoute
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , public dialogRef: MatDialog
    , public sheet: MatBottomSheet) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.activatedRoute.queryParams.subscribe(
      (params) => {
        this.user = params['user'];
        this.cost = params['cost'];
        this.user_cost = params['user_cost'];
        this.date3 = params['date3'];
        this.date2 = params['date2'];
        this.order = params['order'];
        this.bill_ref = params['ref'];
        this.bill_i = params['i'];
        this.bill_page = params['page'];
        this.bill_link = params['link'];
        this.bill_search_all = params['search_all'];
        this.check_access(4, 1, 0, 1, 1);
      }
    );
    //**************
  }//end ngOnInit

  back_to_bill() {
    this.router.navigate(['/home', this.bill_link, this.bill_ref], { queryParams: { back_i: this.bill_i, page: this.bill_page, search_all: this.bill_search_all } })
  }

  set_hidden() {
    this.hidden = true;
    this.user = 1;
  }

  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) { }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  load_data(page: number, excel: number, loading: number, changedRows: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    var address = 6843;
    if (this.lang == 2) {
      address = 6844;
    }
    var obj = {
      'address': address
      , "page": this.page
      , 'type': this.type
      , 'excel': excel
      , 'lang': this.lang
      , 'user_id': this.user_id
      , 'search': this.search_array
      , 'ref': this.ref
      , 'search_all': this.search_all
      , 'changedRows': changedRows
      , 'date3': this.date3
      , 'code': this.code
      , 'order': this.order
      , 'creator': this.creator
      , 'path_export': this.path_export
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) { this.list_record = []; this.list_record_invoice = []; this.list_record_reception = []; this.list_record_finance = []; }
            if (changedRows > 0 || changedRows == -1) {

              for (var i = 0; i < res['num']; i++) {
                if ((res['result'][i].id > 0 && this.list_record_invoice.indexOf(res['result'][i].id) == -1 && res['result'][i].ref == 'invoice') ||
                  (res['result'][i].id > 0 && this.list_record_reception.indexOf(res['result'][i].id) == -1 && res['result'][i].ref == 'reception') ||
                  (res['result'][i].id > 0 && this.list_record_finance.indexOf(res['result'][i].id) == -1 && res['result'][i].ref == 'finance')
                ) {
                  if (res['result'][i].ref == 'invoice') this.list_record_invoice.push(res['result'][i].id);
                  if (res['result'][i].ref == 'reception') this.list_record_reception.push(res['result'][i].id);
                  if (res['result'][i].ref == 'finance') this.list_record_finance.push(res['result'][i].id);
                  this.list_record.push(
                    {
                      'header': true, date: res['result'][i].date, invoice_number: res['result'][i].wharehouse_invoice_number
                      , 'type_title': res['result'][i].type_title
                      , 'device_title': res['result'][i].device_title
                      , 'bed': res['result'][i].bed
                      , 'best': res['result'][i].best
                      , 'user_title': res['result'][i].user_title
                      , 'code': res['result'][i].code
                      , 'cost': res['result'][i].wharehouse_order_cost
                      , 'ref': res['result'][i].ref
                    });
                }
                res['result'][i].header = false;
                res['result'][i].i = i;
                res['result'][i].j = 1;
                this.list_record.push(res['result'][i]);


              }//end for
            }
            //this.sum = res['sum'];
            this.best = res['best'];
            this.bed = res['bed'];
            this.remain = res['remain'];
            this.remain2 = res['remain2'];
            this.dataSource = new MatTableDataSource(this.list_record);
            if (res['count'][0]) { this.count_total = res['count'][0].count; }
            if (res['count'][1]) { this.count_total += res['count'][1].count; }
            if (res['count'][2]) { this.count_total += res['count'][2].count; }

            if (res['sum'][0]) { this.sum = res['count'][0].sum; }
            if (res['sum'][1]) { this.sum += res['count'][1].sum; }
            if (res['sum'][2]) { this.sum += res['count'][2].sum; }

            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reset({});
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data

  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }

  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }

  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }

  open_detaile() {
    const dialogRef = this.dialog.open(NewspaperDetaileComponent, {
      width: '22rem',
      height: 'auto',
      hasBackdrop: false,
      data: { search_array: this.search_array, creator: this.creator, id: this.code }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.search_array = [];
          this.search_array = res;
          this.check_access(4, 1, 0, 1, 1);
        }
      }
    )
  }
  //***************************************************************************
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


