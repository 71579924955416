<input type="file" name="image" accept="image/*" (change)="change_avater($event)" #logo style="display: none;">
<div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div [ngStyle]="{'background-image':'url( ' + user_logo + ')'}" class="nav_avatar cursor"
            (click)="logo.click()">
            <div class="spinner-border spinner" role="status" [hidden]="!loading_logo">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <div class="pe1 nav3">
            <div class="online blink"></div>{{ user_title }}
        </div>
    </div>
</div>