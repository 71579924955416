<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr',en:lang == 2}" name="search_all"
            [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1 hidden-xs" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile(0,3,0,2)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <span style="margin-right:1rem" class="hidden-xs">
                    <button (click)='change_invoice_type(0)' [ngClass]="{button_active:invoice_type_id == 0}"> {{
                        messageService.get_all(lang)}}</button>
                    <button *ngFor="let x of list_invoice_type"
                        (click)='change_invoice_type(x.wharehouse_invoice_type_id)'
                        [ngClass]="{button_active:invoice_type_id == x.wharehouse_invoice_type_id}">
                        {{ x.wharehouse_invoice_type_title }}
                    </button>
                </span>
                <button class="new1" (click)='check_access(1,1,"",1,1)' [disabled]='new_status || invoice_type_id == 0'>
                    <span>{{ messageService.get_new(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'
                (click)="reset_sort('wharehouse_invoice_id','desc')">
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{
                i + page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="invoice_type">
            <mat-header-cell *matHeaderCellDef style='max-width:100px' [hidden]="invoice_type_id != 0"
                [ngClass]="{active1:order == 'wharehouse_invoice_type_title ' + asc_desc }"
                (click)="sort('wharehouse_invoice_type_title',asc_desc)">
                <span *ngIf="lang == 1">نوع فاکتور</span>
                <span *ngIf="lang == 2">Invoice Type</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:100px' [hidden]="invoice_type_id != 0"
                (click)="open_detaile(i,2,x.wharehouse_invoice_id,2)" [ngClass]="{'en':dir == 'ltr'}">
                {{ x.wharehouse_invoice_type_title }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:100px'
                [hidden]="invoice_type_id != 0"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="invoice_number">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'wharehouse_invoice_number ' + asc_desc }"
                (click)="sort('wharehouse_invoice_number',asc_desc)">
                <span *ngIf="lang == 1">شماره فاکتور</span>
                <span *ngIf="lang == 2">Invoice No</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span class="right" style="float: right;" (click)="open_detaile(i,2,x.wharehouse_invoice_id,2)"
                    class="right" [ngClass]="{'full-left':dir == 'ltr'}">{{x.wharehouse_invoice_number
                    }}</span>

                <div style="text-align:left;padding-left: 6px;">
                    <span class="fright" [ngClass]="{'fleft':dir == 'rtl'}" [matMenuTriggerFor]="menu"
                        (click)="get_menu(i,x)"><i class="fas fa-print icon"></i></span>
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>
        <mat-menu #menu="matMenu" class="reception-mat-menu-panel" xPosition="before">
            <table class="table table-bordered" (click)="$event.stopPropagation()">
                <tr *ngIf="lang == 1">
                    <td class="cursor right" [ngClass]="{'left':dir == 'ltr'}"
                        (click)="check_charge_sms(x.wharehouse_invoice_id,x.user_cellphone,x.wharehouse_invoice_type_title, x.user_sex_title + ' ' + x.user_title , x.user_store, x.user_phone , x.wharehouse_invoice_token_sms , true)">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}"><i
                                class="far fa-comments"></i></span>
                        <span *ngIf="lang == 1">ارسال پیامک</span>
                        <span *ngIf="lang == 2">Send SMS</span>
                    </td>
                </tr>
                <tr>
                    <td class="cursor right" (click)="show_factor1()" [ngClass]="{'left':dir == 'ltr'}">
                        <span class="eye" [ngClass]="{'matMenu-icons':dir == 'ltr'}"><i
                                class="fa fa-eye icon"></i></span>
                        <span>Print Invoice</span>
                    </td>
                </tr>
            </table>
        </mat-menu>


        <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'wharehouse_invoice_comment ' + asc_desc }"
                (click)="sort('wharehouse_invoice_comment',asc_desc)">
                <span *ngIf="lang == 1">توضیحات فاکتور</span>
                <span *ngIf="lang == 2">Invoice Description</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.wharehouse_invoice_id,2)"
                [ngClass]="{'en':dir == 'ltr'}">
                {{x.wharehouse_invoice_comment }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="invoice_date">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'wharehouse_invoice_date3 ' + asc_desc }"
                (click)="sort('wharehouse_invoice_date3',asc_desc)">
                <span *ngIf="lang == 1">تاریخ</span>
                <span *ngIf="lang == 2">Date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.wharehouse_invoice_id,2)"
                [ngClass]="{'en':dir == 'ltr'}">{{
                x.wharehouse_invoice_date3 }}</mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'user_title ' + asc_desc }"
                (click)="sort('user_title',asc_desc)">
                <span *ngIf="lang == 1">نام طرف حساب</span>
                <span *ngIf="lang == 2">Account Holder</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.wharehouse_invoice_id,2)"
                [ngClass]="{'en':dir == 'ltr'}">{{
                x.user_title }}</mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'wharehouse_invoice_amount ' + asc_desc }"
                (click)="sort('wharehouse_invoice_amount',asc_desc)">
                <span *ngIf="lang == 1">جمع کل</span>
                <span *ngIf="lang == 2">Sum Total</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.wharehouse_invoice_id,2)"
                [ngClass]="{'en':dir == 'ltr'}">
                <span *ngIf="x.wharehouse_invoice_amount">{{ currency_title2 }} </span>
                {{ x.wharehouse_invoice_amount | currency:'':'' }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef>
                {{ currency_title2 }} {{ sum | currency:'':'' }}
            </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="settlement">
            <mat-header-cell *matHeaderCellDef style="max-width:130px" [hidden]="invoice_type_id == 5"
                [ngClass]="{active1:order == 'wharehouse_invoice_settlement_date ' + asc_desc }"
                (click)="sort('wharehouse_invoice_settlement_date',asc_desc)">
                <span *ngIf="lang == 1">تاریخ تسویه</span>
                <span *ngIf="lang == 2">Settlement Date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.wharehouse_invoice_id,2)"
                style="max-width:130px" [hidden]="invoice_type_id == 5" [ngClass]="{'en':dir == 'ltr'}">
                {{
                x.wharehouse_invoice_settlement_date }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:130px'
                [hidden]="invoice_type_id == 5"></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width:120px"
                [ngClass]="{active1:order == 'wharehouse_invoice_id ' + asc_desc }"
                (click)="sort('wharehouse_invoice_id',asc_desc)">
                <span *ngIf="lang == 1">شماره سند</span>
                <span *ngIf="lang == 2">Document No</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.wharehouse_invoice_id,2)"
                style="max-width:120px" [ngClass]="{'en':dir == 'ltr'}">{{
                x.wharehouse_invoice_id }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:120px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="pay">
            <mat-header-cell *matHeaderCellDef style="max-width:120px" [hidden]="access_insert_finance == false">
                <span *ngIf="lang == 1">میانبر پرداخت</span>
                <span *ngIf="lang == 2">Direct Payment</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="padding-top:3px !important"
                style="max-width:120px;padding:0.2rem !important" [hidden]="access_insert_finance == false">
                <span
                    (click)="pay(x.user_id,x.wharehouse_invoice_amount,x.document_type_text,x.wharehouse_invoice_number,x.wharehouse_invoice_type_title)"
                    *ngIf="x.wharehouse_invoice_type == 1 || x.wharehouse_invoice_type == 3">
                    <mat-icon>credit_card</mat-icon>
                </span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:120px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                <span *ngIf="lang == 1">عملیات</span>
                <span *ngIf="lang == 2">Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'>
                <span (click)="check_access(3,0,x.wharehouse_invoice_id,0,1)"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:70px'></mat-footer-cell>
        </ng-container>

        <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
        </mat-header-row>
        <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
            (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow || i == back_i,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true" [hidden]="invoice_type_id == 0"></mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>