<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span>
        <span *ngIf="lang == 1">جستجو</span>
        <span *ngIf="lang == 2">Search</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr','fleft':dir == 'ltr'}">
                <label for="material" class='wh-label'>
                    <span *ngIf="lang == 1"> نام طرف حساب </span>
                    <span *ngIf="lang == 2"> Client Name </span>
                    <span (click)="reset_input(2,220)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="customer_title" (click)="load_services(2,220)" readonly>
                    <input class="wh-input" formControlName="customer" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr','fleft':dir == 'ltr'}">
                <label for="material" class='wh-label'>
                    <span *ngIf="lang == 1">ورودی </span>
                    <span *ngIf="lang == 2">Document source </span>
                    <select class="wh-input" formControlName="place">
                        <option></option>
                        <option [value]="'2'">
                            <span *ngIf="lang == 1">فاکتورفروش</span>
                            <span *ngIf="lang == 2">Sales invoice</span>
                        </option>
                        <option [value]="'3'">
                            <span *ngIf="lang == 1">تعمیرگاه</span>
                            <span *ngIf="lang == 2">Repair shop</span>
                        </option>
                        <option [value]="'1'">
                            <span *ngIf="lang == 1">امورمالی</span>
                            <span *ngIf="lang == 2">Financial affairs</span>
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr','fleft':dir == 'ltr'}">
                <label for="customer" class='wh-label'>
                    <span *ngIf="lang == 1"> تاریخ از </span>
                    <span *ngIf="lang == 2"> Date from </span>
                    <input type="date" class="wh-input" matInput formControlName="date1" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}" [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr','fleft':dir == 'ltr'}">
                <label for="customer" class='wh-label'>
                    <span *ngIf="lang == 1"> تاریخ تا </span>
                    <span *ngIf="lang == 2"> Date to </span>
                    <input type="date" class="wh-input" matInput formControlName="date2" autocomplete="off"
                        [ngClass]="{'en':dir == 'ltr'}" [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>