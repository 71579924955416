import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import { BankAccountsComponent } from '../../bank/bank-accounts/accounts-list/bank-accounts.component';

@Component({
  selector: 'app-cheque-recieve-detaile',
  templateUrl: './cheque-recieve-detaile.component.html',
  styleUrls: ['./cheque-recieve-detaile.component.scss']
})
export class ChequeRecieveDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading: boolean = false;
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public status_pass: number = 0;
  public status_pass2: number = 0;
  public search_array: any = [];
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public code!: number;
  public document_type!: number;
  public result!: any;
  public creator!: number;
  public token!: any;
  public expire!: boolean;
  public access_service!: number;
  public level!: number;
  public financial_id!: number;
  public cheque_status!: number;

  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<ChequeRecieveDetaileComponent>
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.creator = dialog_data.creator;
      this.create_form1();
      this.id = dialog_data.id;
      this.financial_id = dialog_data.financial_id;
      this.token = dialog_data.token;
      this.document_type = dialog_data.type;
      this.code = dialog_data.code;
      if (this.type_task == 1) {
      }
      if (this.type_task == 2 || this.type_task == 4) {
        this.check_access(4, 1);
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'number': new FormControl(null, [Validators.pattern('[0-9/-]{1,}')]),
      'title': new FormControl(null),
      'branch_name': new FormControl(null),
      'branch_code': new FormControl(null),
      'issue_date': new FormControl(new Date()),
      'due': new FormControl(null),
      'bank': new FormControl(null),
      'account_number': new FormControl(null),
      'amount': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'status_title': new FormControl(null),
      'status_id': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'bank2_title': new FormControl(null),
      'bank2': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'account2_title': new FormControl(null),
      'account2': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['issue_date'].setValidators([Validators.required])
      this.form1_group.controls['due'].setValidators([Validators.required])
      this.form1_group.controls['bank'].setValidators([Validators.required])
      this.form1_group.controls['amount'].setValidators([Validators.required])
    }
    if (this.type_task == 2) {
      this.form1_group.controls['status_id'].setValidators([Validators.required])
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          //this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.insert();
            }
          }
          if (type_task == 2) {
            if (this.cheque_status == 2) {
              this.message(false, "", 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.update();
            }
          }
          if (type_task == 3) { }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6785, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          this.form1_group.patchValue({
            'title': res['result'][0].finance_financial2_cheque_title,
            'branch_name': res['result'][0].finance_financial2_cheque_branch_name,
            'branch_code': res['result'][0].finance_financial2_cheque_branch_code,
            'due': res['result'][0].due_date,
            'issue_date': res['result'][0].issue_date,
            'bank': res['result'][0].finance_financial2_cheque_bank,
            'account_number': res['result'][0].finance_financial2_cheque_account_number,
            'number': res['result'][0].finance_financial2_cheque_number,
            'amount': res['result'][0].finance_financial2_amount,
            'status_title': res['result'][0].site_cheque_status_title,
            'status_id': res['result'][0].site_cheque_status_id,
            'bank2_title': res['result'][0].site_bank_title,
            'bank2': res['result'][0].site_bank_id,
            'account2_title': res['result'][0].site_bank_accounts_number,
            'account2': res['result'][0].site_bank_accounts_id
          });
          this.cheque_status = res['result'][0].site_cheque_status_id;
          //قسمت پایین برای بخش وصول شده می باشد.
          if (res['result'][0].site_cheque_status_pass == 1) {
            this.status_pass = 1;
          } else {
            this.status_pass = 0;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.document_type = res['result'][0].site_cheque_type;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6786
      , "user_id": this.user_id
      , "lang": this.lang
      , "financial_id": this.financial_id
      , 'title': this.form1_group.value.title
      , 'branch_name': this.form1_group.value.branch_name
      , 'branch_code': this.form1_group.value.branch_code
      , 'issue_date': this.form1_group.value.issue_date
      , 'account_number': this.form1_group.value.account_number
      , 'number': this.form1_group.value.number
      , 'due_date': this.form1_group.value.due
      , 'bank': this.form1_group.value.bank
      , 'amount': this.form1_group.value.amount
      , 'type': this.document_type
      , 'due': this.form1_group.value.due
      , 'status_id': this.form1_group.value.status_id
      , 'token': this.token
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "شماره چک درهر حساب مي بايست منحصر به فرد باشد";
          var en_message = "The check number in each account must be unique";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));

        }
      }
    );
  }//end insert
  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6787
      , "user_id": this.user_id
      , 'title': this.form1_group.value.title
      , 'branch_name': this.form1_group.value.branch_name
      , 'branch_code': this.form1_group.value.branch_code
      , 'issue_date': this.form1_group.value.issue_date
      , 'account_number': this.form1_group.value.account_number
      , 'number': this.form1_group.value.number
      , 'due_date': this.form1_group.value.due
      , 'bank': this.form1_group.value.bank
      , 'amount': this.form1_group.value.amount
      , 'status_id': this.form1_group.value.status_id
      , 'account2': this.form1_group.value.account2
      , 'id': this.id
      , 'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "شماره چک درهر حساب مي بايست منحصر به فرد باشد";
          var en_message = "The check number in each account must be unique";
          this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  select_account2(): any {
    if (!this.form1_group.value.bank2) {
      var pe_message = "لطفا در ابتدا بانک خود را انتخاب نمائید";
      var en_message = "Please select your bank first";
      this.message(true, this.messageService.message(this.lang, pe_message, en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialog_data = this.dialog.open(BankAccountsComponent, {
      width: '55rem',
      height: 'auto',
      data: { ref: 'cheque', id: this.form1_group.value.bank2, code: this.code }
    });
    dialog_data.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'account2_title': res.number,
            'account2': res.id,
          });
        }
      }
    )
  }
  reset_account(type: number) {
    if (type == 1) {
      this.form1_group.patchValue({
        'account_title': null,
        'account': null
      })
    }
    if (type == 2) {
      this.form1_group.patchValue({
        'account2_title': null,
        'account2': null
      })
    }
  }

  load_services(id: number): any {
    var code_id;
    if (id == 190) {
      code_id = 6789;
    }
    var record_id: number | undefined;
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, record_id: record_id, creator: this.creator }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 190) {
            this.form1_group.patchValue({
              'status_id': res.id,
              'status_title': res.title
            });
            this.get_status_cheque(res.id);
          }
          if (id == 178) {
            this.form1_group.patchValue({
              'bank2': res.id,
              'bank2_title': res.title,
              'account2': null,
              'account2_title': null,
            });
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (id == 190) {
      this.form1_group.patchValue({
        'status_id': null,
        'status_title': null
      })
    }
    if (id == 178) {
      this.form1_group.patchValue({
        'bank2': null,
        'bank2_title': null,
        'account2': null,
        'account2_title': null,
      });
    }
  }
  close() {
    this.matDialogRef.close();
  }
  get_status_cheque(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6477, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {

            //******************** این قسمت برای قسمت وصول شده می باشد */
            if (res['result'][0].site_cheque_status_pass == 1) {
              this.status_pass = 1;
            }
            else {
              this.status_pass = 0;
              this.form1_group.patchValue({
                'account2': null,
                'account2_title': null,
                'bank2': null,
                'bank2_title': null
              })
            }
            //******************** این قسمت برای قسمت پاس شده شده می باشد */
            if (res['result'][0].site_cheque_status_pass2 == 1) {
              this.status_pass2 = 1;
            }
            else {
              this.status_pass2 = 0;
            }

          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //**************************************************
  required(): boolean {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}


