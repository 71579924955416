<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row" style="display: flex !important;" [ngClass]="{direction_ltr:dir == 'ltr'}">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" [ngClass]="{search_box_ltr:dir == 'ltr',en:lang == 2}" name="search_all"
            [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_filter(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div [ngStyle]="{'direction' : lang == 2 ? 'ltr' : 'rtl'}" class="service_pager"
            [ngClass]="{service_pager_en:dir == 'ltr'}">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="new1" (click)='change_task(1,1,"",1,1,"")' [disabled]='new_status'>
                    <span>{{ messageService.get_new(lang) }}</span>
                </button>
                <button class="search1" (click)='change_task(3,3,"",1,1,"")'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe" [ngClass]="{pager_en:dir == 'ltr'}">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,1,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation' [ngClass]="{en_navigation_number:lang==2}"><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox" [ngClass]="{pager_checkbox_en:lang == 2}">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content-body control-content-body" [ngClass]="{content_body_en:dir=='ltr'}"
    (window:resize)="change_resize()">
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_fa_without_scroll pe1'
        [ngClass]="{mat_table_en_without_scroll:dir=='ltr',en1:dir == 'ltr'}">

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px' [ngClass]="{'en':dir == 'ltr'}">{{ i +
                page_number_first }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">عنوان</span>
                <span *ngIf="lang == 2">Category</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.wharehouse_group_id,x.wharehouse_group_title,x.status_id,x.wharehouse_group_sort,x.wharehouse_place_id)'
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.wharehouse_group_title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="group">
            <mat-header-cell *matHeaderCellDef [hidden]="level == 1">
                <span *ngIf="lang == 1">انبار</span>
                <span *ngIf="lang == 2">Warehouse</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [hidden]="level == 1"
                (click)='change_task(2,x.wharehouse_group_id,x.wharehouse_group_title,x.status_id,x.wharehouse_group_sort,x.wharehouse_place_id)'
                [ngClass]="{'en':dir == 'ltr'}">
                {{ x.wharehouse_place_title }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="cate">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">زیرگروه</span>
                <span *ngIf="lang == 2">SubCategory</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <span (click)="open_cate(x.wharehouse_group_id,x.wharehouse_group_title)"><i
                        class="fas fa-plus-circle icon"></i></span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="sort">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1">
                <span *ngIf="lang == 1">ترتیب</span>
                <span *ngIf="lang == 2">Sort</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [hidden]="level != 1" [ngClass]="{'en':dir == 'ltr'}">{{
                x.wharehouse_group_sort }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>
                <span *ngIf="lang == 1">وضعیت</span>
                <span *ngIf="lang == 2">Status</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.wharehouse_group_id,x.wharehouse_group_title,x.status_id,x.wharehouse_group_sort,x.wharehouse_place_id)'>
                <span [ngClass]="{'en':dir == 'ltr'}">{{ x.status_title }}</span>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef style="max-width: 70px" [hidden]="true">
                <span *ngIf="lang == 1">شناسه</span>
                <span *ngIf="lang == 2">ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index"
                (click)='change_task(2,x.wharehouse_group_id,x.wharehouse_group_title,x.status_id,x.wharehouse_group_sort,x.wharehouse_place_id)'
                style="max-width: 70px" [ngClass]="{'en':dir == 'ltr'}" [hidden]="true">
                {{ x.wharehouse_group_id }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px'>
                <span *ngIf="lang == 1">عملیات</span>
                <span *ngIf="lang == 2">Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px'>
                <span (click)="check_access(3,0,x.wharehouse_group_id,0,1)"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
        <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
            (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>

    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>

</div>


<ngx-smart-modal #myModal identifier="myModal" class="pe" [ngClass]="{'full-left':dir == 'ltr'}"
    (onClose)="ModalClose()">
    <form [formGroup]="form1_group">
        <div class="dialog_title1">
            <span>{{ serverService.get_title(type_task,lang) }}</span>
        </div>
        <div class="row">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان </span>
                    <span *ngIf="lang == 2"> Category </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" required
                        [ngClass]="{'full-left':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="place" class='wh-label'>
                    <span *ngIf="lang == 1"> انبار </span>
                    <span *ngIf="lang == 2"> Warehouse </span>
                    <select class='wh-input' formControlName="place" required [ngClass]="{'full-left':dir == 'ltr'}">
                        <option value=""></option>
                        <option *ngFor='let x of list_place' value="{{ x.wharehouse_place_id }}">
                            <span>{{ x.wharehouse_place_title }}</span>
                        </option>
                    </select>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}"
                [hidden]="level != 1">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب </span>
                    <span *ngIf="lang == 2"> Sort </span>
                    <input class='wh-input' formControlName="sort" autocomplete="off"
                        [ngClass]="{'full-left':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعیت نمایش </span>
                    <span *ngIf="lang == 2"> Display status </span>
                    <select class='wh-input' formControlName="status_id" required
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <option value=""></option>
                        <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                            <span>{{ x.status_title }}</span>
                        </option>
                    </select>
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' style="padding-top:0.5rem" *ngIf="level == 1">
                <span>لوگو</span>
                <mat-card class="example-card logo cursor backgroundImage"
                    style="padding:3px 16px 7px 16px;width:100%;height:10rem"
                    [ngStyle]="{'background-image':'url( ' + logo + ')'}" (click)="logos.click()" accept="image/*">
                    <mat-card-header style="display:inline" class="pe1" [ngClass]="{en1:lang==2}">
                    </mat-card-header>
                </mat-card>
                <span (click)="change_logo()" *ngIf="logo_bin == true"><i class="far fa-trash-alt icon"
                        style="margin: 0px 4px;"></i></span>
                <input type="file" (change)="change_logo($event)" style="display: none;" #logos accept="image/*">
            </div><!-- col-md-4 col-lg-4 col-sm-4 col-xs-12 pe  -->
        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style='margin-top:5px;text-align:center'>
                <button class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="check_access(type_task,0,0,0,1)"
                    [disabled]="!form1_group.valid">
                    {{ serverService.get_event_title(type_task,lang) }}
                </button>
            </div>
        </div>
    </form>
</ngx-smart-modal>

<ngx-smart-modal #myModalSearch identifier="myModal" class="pe" [ngClass]="{'full-left':dir == 'ltr'}"
    (onClose)="ModalClose()">
    <form [formGroup]="form1_search_group">
        <div class="dialog_title1">
            <span>{{ serverService.get_title(type_task,lang) }}</span>
        </div>
        <div class="row">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title_search" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان </span>
                    <span *ngIf="lang == 2"> Title </span>
                    <input class='wh-input' formControlName='title_search' autocomplete="off"
                        [ngClass]="{'full-left':dir == 'ltr'}">
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe' [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="status_id_search" class='wh-label'>
                    <span *ngIf="lang == 1"> وضعيت نمايش </span>
                    <span *ngIf="lang == 2"> Display status </span>
                    <select class='wh-input' formControlName='status_id_search' pattern="[0-9]{1,}"
                        [ngClass]="{'full-left':dir == 'ltr'}">
                        <option value=""></option>
                        <option *ngFor='let x of list_status' value="{{ x.status_id }}">
                            <span>{{ x.status_title }}</span>
                        </option>
                    </select>
                </label>
            </div>

        </div>
        <div class="row">
            <div class='col-xl-12 col-lg-12  col-md-12  col-sm-12 col-xs-12'
                style='margin-top:5px;text-align:center;font-family:irsans'>
                <button class="btn_pe" (click)="check_access(4,1,0,1,1)">
                    {{ serverService.get_event_title(type_task,lang) }}
                </button>
            </div>
        </div>
    </form>
</ngx-smart-modal>