import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ReportDeviceComponent } from '../../report/report-device/report-device.component';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-home-right',
  templateUrl: './home-right.component.html',
  styleUrls: ['./home-right.component.scss']
})
export class HomeRightComponent implements OnInit, OnDestroy {
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public no_record: string = this.serverService.no_record();
  public loading = false;
  public subscription: Subscription;
  public netwok: string = "profit";
  public moarf: string;
  public show_chart: boolean = false;
  //********************************************** */
  public list_content: any = [];
  public list_rstatus: any = [];
  public list_repairman: any = [];
  public report: string;
  public rstatus_report_id: number;
  //********************************************** */
  public user_id: number | undefined;
  public user: number | undefined;
  public rstatus_id: number | undefined;
  public groups_id: number | undefined;
  public bascket_status_id: number | undefined;
  public type: number = 1;
  public count_bascket_status_all: number = 0;
  public level: number | undefined;
  public list_groups: any = [];
  public my_network: boolean = false;
  public creator: number | undefined;
  public loading2: boolean = false;
  public group_id: number | undefined;
  public list_bascket_status: any = [];
  public bascket_status: number | undefined;
  public code_bascket: number = 0;
  public record_id: number;
  public show_record: boolean;
  constructor(
    public router: Router
    , public serverService: ServerService
    , public matSnackBar: MatSnackBar
    , public dialog: MatDialog
    , public http: HttpClient
    , public activatedRoute: ActivatedRoute
    , public messageService: MessageService
    , @Inject(DOCUMENT) public document: any
  ) {

    this.serverService.get_my_network().subscribe(
      (res) => {
        this.set_false();
        this.netwok = 'profit';
        this.my_network = true;
      }
    )

    this.serverService.get_reception_rstatus().subscribe(
      (res) => {
        if (this.list_rstatus.length == 0 || res.alter == true) {
          this.level = res.level;
          this.set_false();
          this.get_rstatus();
        }
        this.rstatus_id = res.rstatus_id;
      }
    )

    this.serverService.get_group().subscribe(
      () => {
        //this.get_groups();
        this.set_false();
      }
    )
    this.serverService.get_reset().subscribe(
      () => {
        this.set_false();
      }
    )
  }
  set_false() {
    this.show_record = false;
    this.my_network = false;
    this.report = '';
    this.list_groups = [];
    this.list_rstatus = [];
    this.list_repairman = [];
    this.list_bascket_status = [];
  }
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_logo();
  }
  get_logo() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1038, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.level = res['level'];
          this.creator = res['creator'];
          this.level = res['level'];
          if (this.level == 1) {
            this.get_groups();
          }
        }//end if
        else {
          this.serverService.signout();
        }
      }
    )
  }
  //*********************************************************************************************************************** */
  get_moarf(type: string) {
    this.groups_id = 0;
    this.moarf = type;
    this.router.navigate(['/home/user/161'], { queryParams: { moarf: type } });
  }
  //*********************************************************************************************************************** */
  get_groups() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6137, user_id: this.user_id, creator: this.creator }).subscribe(
      (res: any) => {
        this.show_record = true;
        this.my_network = false;
        this.list_groups = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_groups.push(res['result'][i]);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  go_to_group(id: number) {
    this.moarf = '';
    this.groups_id = id;
    this.router.navigate(['/home/user/161'], { queryParams: { id: id } });
  }

  get_my_consume_in_netwok() {
    this.netwok = 'consume';
    this.router.navigate(['/home/charge/232'], { queryParams: { type: 'account', typeCharge: 2 } });
  }

  get_my_netwok() {
    this.netwok = 'profit';
    this.router.navigate(['/home/network/253'], { queryParams: { type: 'account' } });
  }
  //*********************************************************************************************************************** */
  change_rstatus(status) {
    this.report = status;
  }

  change_show_chart() {
    this.show_chart = !this.show_chart;
  }
  get_rstatus() {
    var obj = {
      address: 1682
      , user_id: this.user_id
      , creator: this.creator
      , level: this.level
      , lang: this.lang
    }
    this.loading2 = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.show_record = true;
        this.list_rstatus = [];
        this.report = 'rstatus';
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            if (res['result'][i].repairman_rstatus_logo) {
              res['result'][i].logo = res['result'][i].repairman_rstatus_site_logo + "/" + res['result'][i].repairman_rstatus_logo;
            } else {
              res['result'][i].logo = this.serverService.get_default_image();
            }
            this.list_rstatus.push(res['result'][i]);
          }//end for
          if (this.level == 2) this.get_repairman();
          else this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  go_to_reception_with_rstatus(rstatus) {
    this.rstatus_id = rstatus;
    this.router.navigate(['/home/reception/170'], { queryParams: { rstatus: rstatus, pos: 'right' } });
  }
  //******************************************************************************************************** */
  get_repairman() {
    var obj = {
      address: 6815
      , creator: this.creator
      , lang: this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.show_record = true;
        this.list_repairman = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_repairman.push(res['result'][i]);
          }//end for
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  go_to_reception_with_repairman(user: number) {
    this.record_id = user;
    this.router.navigate(['/home/reception/197'], { queryParams: { repairman: user } });
    this.serverService.send_reset_services_id('');
  }
  //******************************************************************************************************** */
  open_report1() {
    this.dialog.open(ReportDeviceComponent, {
      width: '100rem',
      height: '100vh'
    })
  }
  //************************************************************************************* */
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
      this.loading2 = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //************************************************************************************
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }

}
