import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MessageService } from '../../../service/message/message.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/system/models/confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-reception-sms',
  templateUrl: './reception-sms.component.html',
  styleUrls: ['./reception-sms.component.scss']
})
export class ReceptionSmsComponent implements OnInit, OnDestroy {

  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang = JSON.parse(<any>localStorage.getItem('lang'));
  public server: any = this.serverService.get_server();
  public loading: boolean = false;
  public subscription: Subscription | any;
  public user_id!: number;
  public reception_id!: number;
  public creator!: number;
  public text!: string;
  public user_title!: string;
  public user_store!: string;
  public userId!: number;
  public reception_code!: number;
  public user_cellphone!: string;
  public head_number!: number;
  public area_code!: number;
  public cost_sms!: number;
  public sms_charge!: number;
  public whatspp_message!: string;
  public Device_name!: string;
  public Brand_name!: string;
  public Model_name!: string;
  public Link!: string;
  public formtext!: string;

  constructor(
    public serverService: ServerService,
    public dialog: MatDialog,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public _dialogRef: MatDialogRef<ReceptionSmsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any
  ) {
    if (dialog_data) {
      this.reception_id = dialog_data.reception_id;
      this.creator = dialog_data.creator;
    }
  }//end consructor

  ngOnInit() {


    if (this.user_info) {
      this.user_id = this.user_info.user_id
    }
    this.get_data();

  }


  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7088,
      'reception_id': this.reception_id,
      'creator': this.creator,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {

            this.text = res['result'][0].user_country_reception_sms;



            this.cost_sms = res['result'][0].user_country_amount_sms;
            this.user_store = res['result'][0].user_store;

            this.reception_code = res['result_user'][0].repairman_reception_code;
            this.user_cellphone = res['result_user'][0].user_cellphone;
            this.head_number = res['result'][0].user_head_number_phone;
            this.area_code = res['result'][0].user_country_area_code;
            this.sms_charge = res['result'][0].user_sms_charge;

            this.Device_name = res['result_user'][0].device_title;

            this.user_title = res['result_user'][0].user_title;

            this.Link = this.serverService.get_my_domain() + "/timeline/" + this.reception_id + "/" + this.reception_code;

            this.Brand_name = res['result_user'][0].repairman_brand_title;

            this.Model_name = res['result_user'][0].repairman_model_title;

            const orginaltext = this.text;


            if (this.user_title) {
              this.text = orginaltext.replace("#customer#", this.user_title);
            }
            else {
              this.text = orginaltext.replace("#customer#", "Customer");
            }

            if (this.user_store) {
              this.text = this.text.replace("#store#", this.user_store);
            }
            else {
              this.text = this.text.replace("#store#", "");
            }

            if (this.Brand_name) {
              this.text = this.text.replace("#brand#", this.Brand_name);
            }

            else {
              this.text = this.text.replace("#brand#", "");
            }


            if (this.Model_name) {

              this.text = this.text.replace("#model#", this.Model_name);
            }
            else {
              this.text = this.text.replace("#model#", "Device");
            }


            this.formtext = this.text;

            if (this.user_title) {
              this.whatspp_message = orginaltext.replace("#customer#", "*" + this.user_title + "*");
            }
            else {
              this.whatspp_message = orginaltext.replace("#customer#", "Customer");
            }

            if (this.user_store) {
              this.whatspp_message = this.whatspp_message.replace("#store#", "*" + this.user_store + "*");
            }
            else {
              this.whatspp_message = this.whatspp_message.replace("#store#", "Store");
            }


            if (this.Brand_name) {
              this.whatspp_message = this.whatspp_message.replace("#brand#", this.Brand_name);
            }
            else {

              this.whatspp_message = this.whatspp_message.replace("#brand#", "");
            }

            if (this.Model_name) {
              this.whatspp_message = this.whatspp_message.replace("#model#", this.Model_name);
            }
            else {
              this.whatspp_message = this.whatspp_message.replace("#model#", "Device");
            }


            this.whatspp_message = this.whatspp_message + " 👉👉 " + this.Link;

          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  RedirectWhatsApp() {

    const whatsAppLink = "https://api.whatsapp.com/send?phone=" + this.area_code + this.user_cellphone + "&text=" + this.whatspp_message;

    window.open(whatsAppLink, '_blank', 'noopener, noreferrer');
  }



  check_chrge_sms() {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '18rem',
      height: '8rem',
      data: { message: 'Are you sure you want to proceed?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        if (this.serverService.check_internet() == false) {
          this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
          return;
        }//end if
        else { this.matSnackBar.dismiss(); }
        this.loading = true;
        var obj = {
          address: 6874,
          creator: this.creator,
        }
        this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
          (res: any) => {
            if (res['status'] == 1) {
              if (res['num'] == 1) {
                if (res['result'][0].user_sms_charge > this.cost_sms) {
                  this.send_sms();
                } else {
                  var message = "You're out of texting credit. Please top up";
                  this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
                }
              }
            }//end if
            else if (res['status'] == 4) {
              var message = "You are unable to send SMS. Please notify support";
              this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
            }
            else {
              this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
            }
          }
        )

      }
    });



  }
  send_sms() {
    var obj = {
      address: 6869,
      reception_id: this.reception_id,
      creator: this.creator,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.log();
        }//end if
        else if (res['status'] == 4) {
          var message = "You are unable to send SMS. Please notify support";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }



  log(): any {
    var obj = {
      'address': 6873,
      'amount': this.cost_sms,
      'user_id': this.user_id,
      'creator': this.creator,
      'reception_id': this.reception_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_sms_charge();
          this._dialogRef.close();
          var message = "SMS sent successfully";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  close() {
    this._dialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
