import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';
import { HttpClient, HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-menu-logo',
  templateUrl: './menu-logo.component.html',
  styleUrls: ['./menu-logo.component.scss']
})
export class MenuLogoComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang = JSON.parse(<any>localStorage.getItem('lang'));
  public server: any = this.serverService.get_server();
  public uploadedAvaterProgess!: number;
  public user_logo!: string;
  public loading_logo: boolean = false;
  public user_id!: number;
  public user_title!: string;

  public subscription: Subscription | any;
  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService) {
    this.serverService.get_user_title().subscribe(
      (res) => {
        if (res) {
          this.user_title = res.title
          if (res.user_logo) this.user_logo = res.user_logo_site + "/" + res.user_logo;
          else this.user_logo = this.serverService.get_default_user_logo();
        }
      }
    )
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_logo();
  }

  get_logo() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading_logo = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6863, user_id: this.user_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1 && res['num'] == 1) {
          if (res['result'][0].user_logo) {
            this.user_logo = res['result'][0].user_logo_site + "/" + res['result'][0].user_logo;
          }
          else {
            this.user_logo = this.serverService.get_default_user_logo();
          }
          this.user_title = res['result'][0].user_title;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
      }
    )
  }
  change_avater(event: any) {
    var selectedavater: File = <File>event.target.files[0];
    var fd = new FormData();
    fd.append("image", selectedavater, selectedavater.name);
    this.loading_logo = true;
    this.user_logo = '';
    this.serverService.post_address_file(this.server, "uploadImageUser", fd)
      .subscribe(
        (event: any) => {
          if (event.type == HttpEventType.UploadProgress) {
            this.uploadedAvaterProgess = (event.loaded / event.total) * 100;
          }
          else if (event.type === HttpEventType.Response) {
            this.update_avater_user(event);
          }
        }
      )
  }
  update_avater_user(result: any) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      address: 1037,
      user_id: this.user_id,
      site: this.serverService.get_site_upload_image(),
      path: this.serverService.get_path_upload_image_user() + "/" + result.body.result.filename
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.user_logo = res['result'][0].user_logo_site + "/" + res['result'][0].user_logo;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading_logo = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}