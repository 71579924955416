import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { MessageService } from '../../../service/message/message.service';
import { FinanceBankDetaileComponent } from '../finance-bank-detaile/finance-bank-detaile.component';
import { CostDetaileComponent } from '../../finance-cost/cost-detaile/cost-detaile.component';
import { IncomeDetaileComponent } from '../../finance-income/income-detaile/income-detaile.component';
import { CashToBankDetaileComponent } from "../../finance-cash-to-bank/cash-to-bank-detaile/cash-to-bank-detaile.component";
import { BankToCashDetaileComponent } from '../../finance-bank-to-cash/bank-to-cash-detaile/bank-to-cash-detaile.component';
import { RecieveDetaileComponent } from '../../finance-recieve/recieve-detaile/recieve-detaile.component';
import { PayDetaileComponent } from '../../finance-pay/pay-detaile/pay-detaile.component';

@Component({
  selector: 'app-finance-bank-list',
  templateUrl: './finance-bank-list.component.html',
  styleUrls: ['./finance-bank-list.component.scss']
})
export class FinanceBankListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading: boolean = false;
  public show_excel: boolean = false;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public page: number = 1;
  public count_total: number = 1;
  public excel: number = 1;
  public remain_temp: number = 0;
  public remain2: number = 0;
  public sum: number = 0;
  public best: number = 0;
  public bed: number = 0;
  public remain: number = 0;
  public list_remain: any = [];
  public list_record: any = [];
  public search_array: any = [];
  public selected_record: any = [];
  public subscription: Subscription | any;
  public user_id!: number;
  public task_type!: number;
  public confirm_delete!: boolean;
  public page_number_first!: number;
  public page_number_second!: number;
  public code1!: any;
  public export_code!: string;
  public type_task!: number;
  public type!: number;
  public code!: number;
  public type_search!: number;
  public search_all!: string;
  public creator!: number;
  public video!: string;
  public path_export!: string;
  public id!: number;
  public bank_title!: string;
  public account_number!: string;
  public pre_status!: boolean;
  public next_status!: boolean;
  public new_status!: boolean;
  public status_id!: number;
  public status_id_search!: number;
  public title!: string;
  public title_search!: string;
  public select_all!: boolean;
  public access_service!: number;
  public level!: number;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'date', 'service', 'document', 'user', 'bed', 'best', 'remain'];
  //*************
  public date1!: string;
  public date2!: string;
  public order!: number;
  public ref!: number
  public account!: number;
  public currency_title2!: string;
  //************** */
  constructor(
    public serverService: ServerService,
    public ngxSmartModalService: NgxSmartModalService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialog,
    public messageService: MessageService) { }
  ngOnInit() {
    this.serverService.status1(2);
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  order_ref(id: number, ref: any, financial_document: number) {
    if (ref == 1) {
      if (financial_document == 6) {
        this.dialog.open(RecieveDetaileComponent, {
          width: '65rem',
          height: 'auto',
          disableClose: true,
          data: { type_task: 2, id: id, readonly: true, code: this.code }
        });
      }
      else if (financial_document == 7) {
        this.dialog.open(PayDetaileComponent, {
          width: '65rem',
          height: 'auto',
          disableClose: true,
          data: { type_task: 2, id: id, readonly: true, code: this.code }
        });
      }
    }
    if (ref == 'cost') {
      const dialogRef = this.dialog.open(CostDetaileComponent, {
        width: '65rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: 2, id: id, readonly: true, code: this.code }
      });
    }
    if (ref == 'income') {
      const dialogRef = this.dialog.open(IncomeDetaileComponent, {
        width: '65rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: 2, id: id, readonly: true, code: this.code }
      });
    }
    if (ref == 'bank_to_cash') {
      const dialogRef = this.dialog.open(BankToCashDetaileComponent, {
        width: '65rem',
        height: 'auto',
        disableClose: true,
        data: { type_task: 2, id: id, readonly: true, code: this.code }
      });
    }
    if (ref == "cash_to_bank") {
      this.dialog.open(CashToBankDetaileComponent, {
        'width': '70rem',
        'height': 'auto',
        data: { id: id, type_task: 2, code: this.code, creator: this.creator, readonly: true }
      });
    }
  }
  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.path_export = res['path_export'];
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          //this.loading = false;
          if (type_task == 3) {
            //this.delete(id);
          }
          if (type_task == 4) {
            this.get_currency();
            if (this.search_array && this.search_array.account > 0) {
              this.get_default_account2(this.search_array.account, page);
            } else {
              this.get_default_account();
            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_account(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6717, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.bank_title = res['result'][0].site_bank_title;
            this.account_number = res['result'][0].site_bank_accounts_number;
            this.search_array = {
              account: res['result'][0].site_bank_accounts_id
            }
            this.load_data(1, 1, 1, -1);
          } else {
            this.load_data(1, 1, 1, -1);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_account2(account: number, page: number): any {
    var obj = {
      address: 7070,
      creator: this.creator,
      account: account
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.bank_title = res['result'][0].site_bank_title;
            this.account_number = res['result'][0].site_bank_accounts_number;
            this.search_array = {
              account: res['result'][0].site_bank_accounts_id
            }
            this.load_data(page, 1, 1, -1);
          } else {
            this.load_data(page, 1, 1, -1);
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_currency() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6853, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.currency_title2 = res['result'][0].site_currency_title2;
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_data(page: number, excel: number, loading: number, changedRows: number): any {
    if (page == 1) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5) { this.page = 1; }
    //6949
    var address = 6907;
    var obj = {
      'address': address,
      "page": this.page,
      'type': this.type,
      'excel': excel,
      'user_id': this.user_id,
      'search': this.search_array,
      'ref': this.ref,
      'search_all': this.search_all,
      'changedRows': changedRows,
      'account': this.account,
      'code': this.code,
      'creator': this.creator,
      'path_export': this.path_export,
      'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                if (i == 0 && page == 1) {
                  res['result'][i].remain2 = res['remain'];
                  this.remain_temp = res['remain'];
                }
                else if (i == 0 && page == 2) {
                  res['result'][i].remain2 = this.remain_temp;
                }
                else if (i == 0 && page == 3) {
                  res['result'][i].remain2 = this.list_remain[this.page];
                  this.list_remain.splice(this.list_remain.length - 1, 1);
                  if (this.page == 1) this.list_remain[1] = res['remain'];
                }
                else {
                  if (res['result'][i - 1].bed > 0) {
                    res['result'][i].remain2 = Number(res['result'][i - 1].remain2) - Number(res['result'][i - 1].bed);
                  }
                  else {
                    res['result'][i].remain2 = Number(res['result'][i - 1].remain2) + Number(res['result'][i - 1].best);
                  }
                }
                if (i == 19) {
                  this.remain_temp = res['result'][i].remain2;
                  if (res['result'][i].bed > 0) {
                    this.remain_temp = Number(this.remain_temp) - Number(res['result'][i].bed);
                  }
                  else {
                    this.remain_temp = Number(this.remain_temp) + Number(res['result'][i].best);
                  }
                }
                this.list_record.push(res['result'][i]);
              }//end for
            }
            if (page == 1) {
              this.list_remain.push(0);
              this.list_remain.push(res['remain']);
            }
            if (page == 2 && this.list_remain.length > 0) {
              this.list_remain.push(res['result'][0].remain2);
            }
            this.sum = res['sum'];
            this.best = res['best'];
            this.bed = res['bed'];
            this.remain = res['remain'];

            this.remain2 = res['remain2'];
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
            this.serverService.send_reset({});
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  export_excel(code: number) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number, payment_type: number, cheque: number): any {
    const dialogRef = this.dialog.open(FinanceBankDetaileComponent, {
      width: '60rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
          }
          else if (type_task == 2) {
          }
          else if (type_task == 3) {
            this.page = 1;
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, 1, 1);
          }
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

