import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { BankAccountsComponent } from '../../../finance/bank/bank-accounts/accounts-list/bank-accounts.component';
import { MessageService } from '../../../service/message/message.service';


@Component({
  selector: 'app-financial-detaile3',
  templateUrl: './financial-detaile3.component.html',
  styleUrls: ['./financial-detaile3.component.scss']
})
export class FinancialDetaile3Component implements OnInit, OnDestroy {
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public no_record: string = this.serverService.no_record();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public type_task: number | undefined;
  public form1_group: FormGroup | any;
  public user_id: number | any;
  public id: number | undefined;
  public i: number | undefined;
  public code: number | undefined;
  public title: string | undefined;
  public search_array: any = [];
  public financial: number | undefined;
  public result: any = [];
  public change: boolean = false;
  public user: number | undefined;
  public close_after_save: string | undefined;
  public document: number | undefined;
  public document_type: number | undefined;
  public token: number | undefined;
  public ref: any | undefined;
  public sum: number = 0;
  public payment_bank: any = false;
  public creator: number | undefined;
  public transfer: any = false;
  public obj: any | undefined;
  public video: string | undefined;
  public video2: string | undefined;
  public document_date: string;

  constructor(
    public serverService: ServerService
    , public messageService: MessageService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any
    , public dialog: MatDialog
    , private matDialogRef: MatDialogRef<FinancialDetaile3Component>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.sum = dialog_data.sum;
      this.ref = dialog_data.ref;
      this.payment_bank = dialog_data.payment_bank;
      this.token = dialog_data.token;
      this.document_date = dialog_data.document_date;
      this.financial = dialog_data.financial;
      this.sum = dialog_data.sum;
      this.document = dialog_data.document;
      this.create_form1();
      this.user = dialog_data.user;
      this.transfer = dialog_data.transfer;
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.creator = dialog_data.creator;
      if (this.document) {
        this.get_document();
      }
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
      }
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }

  create_form1() {
    if (this.type_task == 3) { }
    this.form1_group = new FormGroup({
      'amount': new FormControl(this.sum),
      'fee': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'date': new FormControl(new Date()),
      'payment_type_title': new FormControl(null),
      'payment_type': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'user_title': new FormControl(null),
      'user': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'bank_title': new FormControl(null),
      'bank': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'account_title': new FormControl(null),
      'account': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      if (this.transfer == 1) this.form1_group.controls['payment_type'].setValidators([Validators.required]);
    }
    if (this.sum) {
      this.form1_group.patchValue({
        'amount': this.sum
      })
    }
    this.form1_group.patchValue({
      'date': this.document_date
    })
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.creator = res['creator'];
            this.loading = false;
            if (type_task == 1) { this.insert(); }
            if (type_task == 2) { this.update(); }
            if (type_task == 3) { }
            if (type_task == 4) { this.get_data(); }
            if (type_task == 5) {
              if (this.transfer == 0) this.get_default_payment_type();
              this.get_bank();

            }
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6793, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'amount': res['result'][0].finance_financial2_amount,
            'fee': res['result'][0].finance_financial2_fee,
            'comment': res['result'][0].finance_financial2_comment,
            'date': res['result'][0].finance_financial2_date2,
            'user': res['result'][0].finance_financial_user,
            'user_title': res['result'][0].user_title,
            'payment_type': res['result'][0].finance_financial2_payment_type,
            'payment_type_title': res['result'][0].finance_payment_type_title,
            'account_title': res['result'][0].site_bank_accounts_number,
            'account': res['result'][0].site_bank_accounts_id,
            'bank_title': res['result'][0].site_bank_title,
            'bank': res['result'][0].site_bank_accounts_bank,
          }
          );
          this.payment_bank = res['result'][0].finance_payment_type_bank;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  insert() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var address = 6791;
    if (this.transfer == 1) address = 6349;
    var obj = {
      'address': address, "user_id": this.user_id
      , 'amount': this.form1_group.value.amount
      , 'fee': this.form1_group.value.fee
      , 'comment': this.form1_group.value.comment
      , 'bank': this.form1_group.value.bank
      , 'date': this.form1_group.value.date
      , 'payment_type': this.form1_group.value.payment_type
      , 'document_type': this.document_type
      , 'financial': this.financial
      , 'account': this.form1_group.value.account
      , 'user': this.user
      , 'token': this.token
      , 'trash': 3
      , 'ref': this.ref
      , 'creator': this.creator
      , 'transfer': this.transfer
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.change = true;
          this.serverService.send_financial2(res['result'][0]);
          if (this.close_after_save == 'close') {
            this.close(this.result, 'other');
          } else {

          }
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));

          this.form1_group.patchValue({
            'amount': null
          })
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "شماره چک انتخابی قبلا در سیستم دریافت و پرداخت استفاده شده است";
          this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert

  update() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6792
      , "user_id": this.user_id
      , 'amount': this.form1_group.value.amount
      , 'fee': this.form1_group.value.fee
      , 'comment': this.form1_group.value.comment
      , 'bank': this.form1_group.value.bank
      , 'date': this.form1_group.value.date
      , 'payment_type': this.form1_group.value.payment_type
      , 'account': this.form1_group.value.account
      , 'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.change = true;
          this.result = res['result'][0];
          this.close(this.result, 'other');
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));

        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          this.message(true, this.messageService.repeat(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update

  open_accounts() {
    if (!this.form1_group.value.bank) {
      var pe_message = "لطفا در ابتدا بانک خود را انتخاب نمائید";
      this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
    } else {
      const dialogRef = this.dialog.open(BankAccountsComponent, {
        width: '60rem',
        height: 'auto',
        data: { id: this.form1_group.value.bank, ref: 'reception', code: this.code }
      });
      dialogRef.afterClosed().subscribe(
        (res) => {
          console.log(res);
          this.form1_group.patchValue({
            'account_title': res.number,
            'account': res.id
          })
        }
      )
    }
  }

  get_document() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1952, document: this.document }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.document_type = res['result'][0].document_type;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_payment_type() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj =
    {
      address: 2018,
      user_id: this.user_id,
      creator: this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'payment_type_title': res['result'][0].finance_payment_type_title,
            'payment_type': res['result'][0].finance_payment_type_id,
          });
          this.payment_bank = res['result'][0].finance_payment_type_bank;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_bank() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6097, user_id: this.user_id, creator: this.creator }).subscribe(
      (res) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'bank_title': res['result'][0].site_bank_title,
              'bank': res['result'][0].site_bank_accounts_bank,
              'account_title': res['result'][0].site_bank_accounts_number,
              'account': res['result'][0].site_bank_accounts_id,
            });
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  save(type_task: number, excel: number, close: string) {
    this.close_after_save = close;
    this.check_access(type_task, excel);
  }


  load_services(id: number, type: any) {
    var code_id;
    var where = "";
    if (type == 2) {
      code_id = 6101;
    }


    if (id == 178) {
      where += " and site_bank_creator2 = " + this.creator;

    }
    if (id == 161) {
      where += " and user_creator2 = " + this.creator;
    }
    if (id == 234) {
      if (!this.form1_group.value.bank) {
        var pe_message = "لطفا در ابتدا بانک خود را انتخاب نمائید.";
        this.message(true, this.messageService.message(this.lang, pe_message, ''), 1, this.messageService.close(this.lang));
        return false;
      }
      code_id = 6350;
    }
    var rem = '40rem';
    if (id == 234) rem = '45rem';
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: rem,
      height: 'auto',
      disableClose: true,
      data: { id: id, code_id: code_id, creator: this.creator, where: where, obj: { bank_id: this.form1_group.value.bank } }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 177) {
            this.form1_group.patchValue({
              'payment_type': res.id,
              'payment_type_title': res.title,
              'bank': null,
              'bank_title': null,
              'account': null,
              'account_title': null
            });
            this.get_payment_type(res.id);
          }
          if (id == 161 && type == 1) {
            this.form1_group.patchValue({
              'user': res.id,
              'user_title': res.title
            })
          }
          if (id == 178) {
            this.form1_group.patchValue({
              'bank': res.id,
              'bank_title': res.title,
              'account': null,
              'account_title': null
            })
          }
          if (id == 234) {
            this.form1_group.patchValue({
              'account': res.id,
              'account_title': res.title
            })
          }
        }
      }
    )

  }

  get_payment_type(id: number) {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1929, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.payment_bank = res['result'][0].finance_payment_type_bank;
          this.form1_group.patchValue({
            'fee': null
          })
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }


  reset_input(id: number, type: any) {
    if (id == 177) {
      this.form1_group.patchValue({
        'payment_type_title': null,
        'payment_type': null
      })
    }
    if (id == 161 && type == 1) {
      this.form1_group.patchValue({
        'user': null,
        'user_title': null
      })
    }
    if (id == 178) {
      this.form1_group.patchValue({
        'bank_title': null,
        'bank': null,
        'account_title': null,
        'account': null
      })
    }
    if (id == 180) {
      this.form1_group.patchValue({
        'account_title': null,
        'account': null
      })
    }
  }


  close(result: any, ref: string) {
    if (ref == 'close') {
      this.matDialogRef.close();
    } else {
      this.matDialogRef.close({ result: result, change: this.change });
    }
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
