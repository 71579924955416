<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
</div>
<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <span> Recieve </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 pe div_panding'>
                <label for="date1" class='wh-label'>
                    <span> Document Date </span>
                    <input type="date" class="wh-input" matInput formControlName="document_date" autocomplete="off"
                        [required]="required()" [readonly]="readonly">
                </label>
            </div>

            <div class='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12 pe div_panding'>
                <label for="user" class='wh-label'>
                    <span> Name and Surname </span>
                    <span (click)="add_user()" [hidden]="readonly == true">
                        <mat-icon class="add-icon fright">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(161)" [hidden]="readonly">
                        <mat-icon class="close" class="input_search input_icon_close">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="user_title" (click)="open_user() " [required]="required()"
                        readonly>
                    <input class="wh-input" formControlName="user" [hidden]="true" [required]="required()" readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'>
                <label for="comment" class='wh-label'>
                    <span> Description </span>
                    <input class="wh-input" formControlName="comment" [readonly]="readonly">
                </label>
            </div>
            <!--************************************************************** -->
        </div><!-- row -->

        <div class="row" *ngIf="type_task != 3">
            <div style="text-align:left;padding:0px;margin-bottom: 5px;">
                <button type="button" class="button_default" (click)='open_detaile_bank(0,1,0)' [hidden]="readonly">
                    <span>Bank Entry</span>
                </button>
                <button type="button" class="button_default" (click)='open_detaile_cash(0,1,0)' [hidden]="readonly">
                    <span>Cash Entry</span>
                </button>
                <ng-container>
                    <button type="button" class="button_default" (click)='open_detaile_cheque(0,1,0)'
                        [hidden]="readonly">
                        <span>
                            <span>Receive a check</span>
                        </span>
                    </button>
                </ng-container>
            </div>

            <div class="content-body1" class="content-body control-content-body">
                <mat-table [dataSource]="dataSource" style='min-width:100%;direction:rtl'
                    class='table-scroll mat_table_fa_without_scroll en1'>
                    <ng-container matColumnDef="row">
                        <mat-header-cell *matHeaderCellDef style='max-width:30px'>
                            <span>#</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:30px'>{{ i + 1 }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="payment_type">
                        <mat-header-cell *matHeaderCellDef style="max-width:130px">
                            <span>Transaction Type</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type2,x.site_cheque_id,x.site_cheque_type)"
                            style="max-width:130px">{{
                            x.finance_payment_type_title }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <mat-header-cell *matHeaderCellDef>
                            <span>Amount</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type2,x.site_cheque_id,x.site_cheque_type)">
                            {{ currency_title2 }}
                            {{
                            x.finance_financial2_amount | currency:'':'' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="bank">
                        <mat-header-cell *matHeaderCellDef>
                            <span>Bank</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type2,x.site_cheque_id,x.site_cheque_type)">
                            {{ x.site_bank_title | substr:0:15}}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="account">
                        <mat-header-cell *matHeaderCellDef>
                            <span>Account Number</span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type2,x.site_cheque_id,x.site_cheque_type)">
                            {{ x.accounts_number }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="cheque">
                        <mat-header-cell *matHeaderCellDef>
                            <span> Check Number </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type2,x.site_cheque_id,x.site_cheque_type)">
                            {{ x.finance_financial2_cheque_number }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="due">
                        <mat-header-cell *matHeaderCellDef>
                            <span> Due Date </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type2,x.site_cheque_id,x.site_cheque_type)">
                            {{ x.due_date }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>
                            <span> Check Status </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index"
                            (click)="check_for_open_detaile(i,2,x.finance_financial2_id,x.finance_financial2_payment_type2,x.site_cheque_id,x.site_cheque_type)">
                            {{
                            x.site_cheque_status_title }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="operation">
                        <mat-header-cell *matHeaderCellDef style='max-width:70px' [hidden]="readonly">
                            <span> Operation </span>
                        </mat-header-cell>
                        <mat-cell *matCellDef="let x;let i = index" style='max-width:70px' [hidden]="readonly">
                            <span [hidden]="x.footer == true">
                                <span
                                    (click)="check_access2(i,3,0,x.finance_financial2_id,0,1,x.site_cheque_id,x.site_cheque_type)"><i
                                        class="far fa-trash-alt icon" style='margin:0px 4px;'></i></span>
                            </span>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
                    </mat-header-row>
                    <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                        (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                        [ngStyle]="{backgroundColor: 1==1 ? row.color : 'transparent' }"
                        [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,'mat_table_hover': row === mat_table_hoverRow}"
                        class="cursor">
                    </mat-row>

                </mat-table>
            </div>

            <div style="text-align: left;padding-top:1rem;">
                <span> Sum Total in numbers : </span>
                <span> {{ currency_title2 }} </span>
                <span> {{ amount | currency:'':'' }} </span>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <div class="row" style="width: 100%;">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" style="text-align: right;padding-right:0px"
                [hidden]="readonly">
                <button type="submit" [disabled]="!form1_group.valid || (i == -1 && list_record.length == 0)"
                    class="btn" [hidden]="readonly">
                    <span>Save</span>
                </button>
            </div>

        </div>
    </div>
</form>