import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HttpClient } from '@angular/common/http';
declare var paypal;

@Component({
  selector: 'app-account-renewal-en',
  templateUrl: './account-renewal-en.component.html',
  styleUrls: ['./account-renewal-en.component.scss']
})

export class AccountRenewalEnComponent implements OnInit, OnDestroy {

  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  public isLoading: number = 1;
  public stripeTest: FormGroup | any;
  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public refrerr: any = JSON.parse(<any>localStorage.getItem("refrerr"));
  public loading: boolean = false;
  public network_access: boolean = false;
  public amount_final: number = 0;
  public number_month: number = 1;
  public user_amount: number = 0;
  public currency_title: string = "$";
  public currency_id: number = 0;
  public month_extension: number = 0;
  public calltype: number = 0;
  public subscription: Subscription | any;
  public user_id!: number;
  public creator!: number;
  public price_dollar!: number;
  public expire_date!: string;
  public forecolor: string = "green";
  public font_style: string = "normal";
  public hide_discount: boolean = false;
  public hide_final_amount: boolean = true;

  constructor(
    public fb: FormBuilder,
    public http: HttpClient,
    public stripeService: StripeService,
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    public _dialogRef: MatDialogRef<AccountRenewalEnComponent>,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.creator = dialog_data.creator;
      this.calltype = dialog_data.calltype;
    }

  }//end consructor
  @Output() exit = new EventEmitter<any>();
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.open_account();
  }
  //***************************************************************************************************************************************** */
  onTabChanged(event: MatTabChangeEvent) {
    if (event.index == 0) {
      this.BuildPaypal();
    }
  }
  BuildPaypal(): any {
    paypal
      .Buttons({
        createOrder: (data: any, actions: any) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "Renewal Recharge",
                amount: {
                  currency_code: 'USD',
                  value: this.amount_final
                },
              }
            ], application_context: {
              shipping_preference: "NO_SHIPPING",
              payment_pattern: "CUSTOMER_PRESENT_ONETIME_PURCHASE",
            }
          }
          )
        },
        onApprove: async (data: any, actions: any) => {
          const order = await actions.order.capture();
          this.log(data.paymentID, 'paypal');
        },
        onError: err => {
          console.log(err);
        }
      })
      .render(this.paypalElement.nativeElement);
  }

  //*******************************************************************************Stripe**************************************** */
  createToken(): void {
    this.loading = true;
    this.stripeService
      .createToken(this.card.element)
      .subscribe((result: any) => {
        if (result.token) {
          this.sendTokenToServer(result.token.id, this.amount_final!);
        } else if (result.error) {
          var message = "Unfortunately, your card information is not correct";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }
      });
  }
  public sendTokenToServer(token: string, amount: number) {
    var confirmation = 'automatic';
    amount = this.amount_final * 100;
    const currency = 'usd';
    var paymenturl = this.serverService.server_strip_payment + '/create-payment-intent';
    const paymentData = {
      amount,
      currency,
      payment_method: token,
      confirmation
    };
    this.http.post(paymenturl, paymentData)
      .subscribe((response: any) => {
        this.message(false, "", 1, this.messageService.close(this.lang));
        const paymentResponse = new PaymentResponse(true, response.clientSecret);
        this.log('', 'strip');
      }, error => {
        this.message(false, "", 1, this.messageService.close(this.lang));
        const paymentResponse = new PaymentResponse(false, undefined, error.message);
      });
  }
  //************************************************************************************************************************ */
  log(paymentID: string, port: string): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      'address': 6876,
      'amount': this.amount_final,
      'user_id': this.user_id,
      'creator': this.creator,
      'number_month': this.number_month,
      'paymentID': paymentID,
      'port': port,
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this._dialogRef.close();
          this.serverService.send_expire_date();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //************************************************************************************************************************ */
  open_account(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      "address": 6812,
      "creator": this.creator
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.number_month=12;
            this.user_amount = res['result'][0].user_amount;
            this.expire_date = res['result'][0].user_expire_date;
            if (this.user_amount == 10) {
              this.hide_discount = true;
            }
            this.change_amount();
            this.BuildPaypal();
            this.hide_final_amount=false;
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }

  UpdateAmount(number_month: number) {

    if (number_month == 1) {
      this.forecolor = "gray";

      this.font_style = "italic";
    }
    else {
      this.forecolor = "green";

      this.font_style = "normal";
    }
    this.number_month = number_month;
    this.change_amount();
  }

  change_amount() {
    if (this.number_month == 1) {
      this.amount_final = (this.number_month * this.user_amount);
    }
    else
    if (this.number_month == 12)
      {
        if (this.user_amount == 15) {

          if (this.number_month == 12) {

            const total = this.number_month * this.user_amount;

            this.amount_final = (this.number_month * this.user_amount) - 60;
          }
        }
        else
          if (this.user_amount == 10) {

            if (this.number_month == 12) {

              const total = this.number_month * this.user_amount;

              this.amount_final = total;
            }
          }

      }

  }
  close() {
    this._dialogRef.close();
  }
  //**************************************************** */
  open_whatsapp() {
    window.open('whatsapp://send?phone=85252290845', '_blank');
  }
  open_telegram() {
    window.open('https://t.me/finty_en', '_blank');
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

class PaymentResponse {
  success: boolean;
  clientSecret?: string;
  errorMessage?: string;
  constructor(success: boolean, clientSecret?: string, errorMessage?: string) {
    this.success = success;
    this.clientSecret = clientSecret;
    this.errorMessage = errorMessage;
  }
}
