<div class="div_loading">
  <div class="my-container" [hidden]="!loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>

<div class="row dflex direction_ltr">
  <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
    <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
      placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
  </div>
  <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
    <div class="service_pager">
      <span>
        <div class="video_div1" (click)="open_video()" *ngIf="video">
          <mat-icon class="video">smart_display</mat-icon>
        </div>
        <button class="search1" (click)='open_detaile(0,3,0)'>
          <span>{{ messageService.get_filter(lang) }}</span>
        </button>

        <button class="search1" (click)='resetHeaders()'>
          <span>
            Reset
          </span>
        </button>

        <button mat-button (click)="openColumnConfigDialog()">Config Columns</button>
        <div class="pager_pe">
          <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
              class="fas fa-fast-forward"></i></button>
          <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i class="fas fa-forward"></i></button>
          <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i class="fas fa-backward"></i></button>
          <button (click)="check_access(4,5,0,1,1)" [disabled]='pre_status'><i
              class="fas fa-fast-backward"></i></button>
        </div>
        <div class='navigation'><span> {{ page_number_first }}
          </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
            {{ count_total }} </span>
        </div>
      </span>
    </div>
  </div>
  <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
    <div class="pager_checkbox">
      <div class="pager_icon_en">
        <div class="excel">
          <img src="../../../../../assets/images/excel.png" class="excel_icon cursor" (click)="load_data(1,2,1,-1)">
          <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="content-body control-content-body" (window:resize)="change_resize()">
  <mat-table [dataSource]="dataSource" style='min-width:100%' class='table-scroll mat_table_fa_without_scroll_bg en1'>

    <ng-container matColumnDef="row">
      <mat-header-cell *matHeaderCellDef style='max-width:40px'>
        <span>#</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
        page_number_first }}</mat-cell>
      <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Date">
      <mat-header-cell *matHeaderCellDef style="max-width: 5rem;">
        <span>Date</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" class="number"  style="max-width: 5rem;">
        {{ x.date }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef style="max-width: 5rem;"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Product">
      <mat-header-cell *matHeaderCellDef >
        <span>Product</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" >
        <div class="left">{{
          x.wharehouse_material_title | substr:0:40 }}
        </div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef ></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Client">
      <mat-header-cell *matHeaderCellDef >
        <span>Client</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" >
        <div class="left">
          {{x.customer_title }}</div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef ></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Salesman">
      <mat-header-cell *matHeaderCellDef >
        <span>Salesman</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" >
        <div class="left">
          {{x.user_title }}</div>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef ></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Source">
      <mat-header-cell *matHeaderCellDef>
        <span>Source</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index">
        {{ x.place }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Ticket/Invoice NO">
      <mat-header-cell *matHeaderCellDef >
        <span>Ticket/ Invoice NO</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" >
        {{ x.id2 }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef ></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="PCs">
      <mat-header-cell *matHeaderCellDef style="max-width: 60px;">
        <span>PCs</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" style="max-width: 60px;">
        {{ x.wharehouse_order_number }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef style="max-width: 60px;"></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Total Purchase Price">
      <mat-header-cell *matHeaderCellDef >
        <span>Total Purchase Price</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" class="number" >
        <span class="fright right">{{ x.amount_buy | currency:'':''}}</span>
        <span (click)="open_sale(x.ref_buy,x.invoice_buy_id,x.wharehouse_material_id)" style="float: left;">
          <i class="fas fa-info-circle icon"></i>
        </span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef >
        <span class="fright right">{{ sum_amount_buy | currency:'':'' }}</span>
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Total Selling Price">
      <mat-header-cell *matHeaderCellDef >
        <span>Total Selling Price</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" class="number" >
        <span class="fright right">{{ x.amount_sale | currency:'':'' }}</span>
        <span (click)="open_sale(x.ref,x.sale_id,x.wharehouse_material_id)" style="float: left;"><i
            class="fas fa-info-circle icon"></i></span>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef class="number" >
        <span class="fright right">{{ sum_amount_sale | number }}</span>
      </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Total Profit on Sales">
      <mat-header-cell *matHeaderCellDef >
        <span>Total Profit on Sales</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" class="number" [ngClass]="{'red':x.profit < 0}"
        >
        {{ x.profit | currency:'':''}}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef class="number" >{{ profit |
        currency:'':'' }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Commission Percentage">
      <mat-header-cell *matHeaderCellDef >
        <span>Commission Percentage</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" class="number" >
        {{ x.wharehouse_order_profit }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef ></mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Sales Commission">
      <mat-header-cell *matHeaderCellDef >
        <span>Sales Commission</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" class="number" [ngClass]="{'red':x.profit2 < 0}"
        >
        {{ x.profit2 | number }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef class="number" >{{ sum_profit2 |
        number
        }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Net Margin After Commission">
      <mat-header-cell *matHeaderCellDef >
        <span>Net Margin After Commission</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" class="number" [ngClass]="{'red':x.profit3 < 0}"
        >
        {{ x.profit3 | number }}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef class="number" >{{ sum_profit3 |
        number
        }}</mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="Discount" >
      <mat-header-cell *matHeaderCellDef style="max-width: 60px;">
        <span>Discount</span>
      </mat-header-cell>
      <mat-cell *matCellDef="let x;let i = index" class="number" [ngClass]="{'red':x.profit < 0}" style="max-width: 60px;">
        {{ x.wharehouse_order_discount | number}}
      </mat-cell>
      <mat-footer-cell *matFooterCellDef class="number" style="max-width: 60px;">{{ discount |
        number }}</mat-footer-cell>
    </ng-container>

    <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
    </mat-header-row>
    <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
      (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
      [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
      class="cursor"></mat-row>
    <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
      *matFooterRowDef="displayedColumns;sticky : true">
    </mat-footer-row>
  </mat-table>

  <div class="row" class="preview1" [hidden]="count_total > 0">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div>
        <img src="../../../assets/images/preview.png">
      </div>
      <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
    </div>
  </div>
</div>
