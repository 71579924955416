import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ServerService } from '../../../service/server/server.service';
import { Params, ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { HeadNumberDetaileComponent } from "../head-number-detaile/head-number-detaile.component";
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-head-number-list',
  templateUrl: './head-number-list.component.html',
  styleUrls: ['./head-number-list.component.scss']
})
export class HeadNumberListComponent implements OnInit, OnDestroy {
  //**********************************************************
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public loading: boolean = false;
  public show_excel: boolean = false;
  public indeterminate: boolean = false;
  public checked: boolean = false;
  public asc_desc: string = 'asc';
  public count_total: number = 1;
  public excel: number = 1;
  public page: number = 1;
  public list_record: any = [];
  public list_status: any = [];
  public search_array: any = [];
  public selected_record: any = [];
  public subscription: Subscription | any;
  public task_type!: number;
  public confirm_delete!: boolean;
  public user_id!: number;
  public page_number_first!: number;
  public page_number_second!: number;
  public code!: any;
  public order!: string;
  public export_code!: string;
  public type_task!: number;
  public type!: number;
  public type_search!: number;
  public search_all!: string;
  public id!: number;
  public pre_status!: boolean;
  public next_status!: boolean;
  public new_status!: boolean;
  public title!: string;
  public select_all!: boolean;
  public creator!: number;
  public video!: string;
  public access_service!: number;
  public level!: number;
  public path_export!: string;
  public mat_table_selectedRow1: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'title', 'phone', 'status', 'id', 'operation'];
  //************** */
  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public messageService: MessageService,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      (params: Params) => {
        this.code = params['id'];
      }
    )
    this.serverService.status1(2);
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.check_access(4, 1, 0, 1, 1);
    //**************
  }//end ngOnInit

  //****************************************************************************
  check_access(type_task: any, page: number, id: any, excel: number, loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.path_export = res['path_export'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, page, id, excel, loading, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, page: number, id: any, excel: number, loading: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.video = res['result'][0].video;
          this.loading = false;
          if (type_task == 3) {
            this.delete(id);
          }
          if (type_task == 4) { this.load_data(page, excel, loading, -1); }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  sort(sort: string, asc_desc: string) {
    if (asc_desc == 'asc') this.asc_desc = 'desc';
    else this.asc_desc = 'asc';
    this.order = sort + " " + this.asc_desc;
    this.load_data(1, 1, 1, -1);
  }
  reset_sort(sort: string, asc_desc: string) {
    this.order = sort + " " + asc_desc;
    this.load_data(1, 1, 1, -1);
  }
  load_data(page: number, excel: number, loading: number, changedRows: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 20); }
    if (page == 3) { this.page = this.page - 1; }
    if (page == 5 || page == 1) { this.page = 1; }
    if (page == 6) { this.page = 1; }
    var obj = {
      'address': 6864,
      "page": this.page,
      "lang": this.lang,
      'type': this.type,
      'excel': excel,
      'user_id': this.user_id,
      'search': this.search_array,
      'search_all': this.search_all,
      'creator': this.creator,
      'changedRows': changedRows,
      'path_export': this.path_export,
      'order': this.order
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (excel == 1) {
            if (changedRows == -1) this.list_record = [];
            if (changedRows > 0 || changedRows == -1) {
              for (var i = 0; i < res['num']; i++) {
                this.list_record.push(res['result'][i]);
              }//end for
            }
            this.dataSource = new MatTableDataSource(this.list_record);
            this.count_total = res['count'];
            this.pages(0);
            this.serverService.change_window_resize(2, '', 0);
            this.serverService.send_services_id(this.code);
          }
          else if (excel == 2) {
            this.export_excel(res['export_code']);
          }
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 20 - 19;
    this.page_number_second = this.page * 20;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 20) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  export_excel(code) {
    if (code > 0) {
      var x = <any>document.getElementById("excel");
      x.href = this.site + "/export/" + code + ".xlsx";
      x.click();
    }
  }
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  //***************************************************************************
  open_detaile(i: number, type_task: number, id: number) {
    const dialogRef = this.dialog.open(HeadNumberDetaileComponent, {
      width: '22rem',
      height: 'auto',
      disableClose: true,
      data: { type_task: type_task, id: id, i: i, search_array: this.search_array, code: this.code, title: this.title, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.pages(1);
            if (this.list_record.length == 21) {
              this.list_record.splice(20, 1);
            }
            this.dataSource.data = this.list_record;
          }
          else if (type_task == 2) {
            this.list_record[res.i].user_head_number_title = res.result.user_head_number_title;
            this.list_record[res.i].user_head_number_phone = res.result.user_head_number_phone;
            this.list_record[res.i].status_title = res.result.status_title;
          }
          else if (type_task == 3) {
            var excel = res.export_excel;
            this.search_array = [];
            this.search_array = res;
            this.check_access(4, 1, 0, excel, 1);
          }
        }
      }
    )
  }
  get_bg(id: number) {
    this.id = id;
  }
  //*******************************************************************************
  delete(id: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address;
      if (typeof id == 'object') { address = 6867; } else { address = 6867; }
      var obj = {
        'address': address, "user_id": this.user_id
        , 'id': id, "page": this.page, 'type': this.type
        , search: this.search_array
        , search_all: this.search_all
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(this.list_record.findIndex((index: any) => index.user_head_number_id == id), 1);
            this.pages(-res['result'].changedRows);
            this.load_data(this.page, 1, 1, res['result'].changedRows);
            this.message(true, this.messageService.message_delete(this.lang, id, res['result'].changedRows), 1, this.messageService.close(this.lang));
          }//end if
          else if (res['status'] == 4) {
            this.message(true, this.messageService.message_delete_alarm(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.message(true, this.messageService.message_delete_erorr(this.lang), 1, this.messageService.close(this.lang));
          }//end else
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  //*******************************************************************************
  change_resize() {
    //this.serverService.change_window_resize(1,'',0);
  }//end change_resize
  //*******************************************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 3000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}