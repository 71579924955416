<div dir="ltr">
  <mat-dialog-content>
    <h3 style="font-size: medium;width:100%;text-align: left;">Config Columns</h3>
    <hr class="mt-0 mb-0" />
    <div class="w-100">
      <div>
        <ul id="corder">
          <li *ngFor="let column of columns; let i = index">

            <div class="col_title w-100 text-left"> {{i+1}} . {{ column.name }}</div>

            <div class="text-center">
              <div class="inlinebox"> <button [disabled]="!column.sortable" mat-button (click)="moveColumnUp(i)">Up</button></div>
              <div class="inlinebox"> <button [disabled]="!column.sortable" mat-button (click)="moveColumnDown(i)">Down</button></div>

              <div class="inlinebox"><mat-slide-toggle [disabled]="!column.hideable" (change)="Toggle(column.name,column.isHiden)" color="info"
                  [checked]="column.isHiden"></mat-slide-toggle></div>
            </div>

          </li>
        </ul>
      </div>

    </div>


  </mat-dialog-content>
  <mat-dialog-actions align="center">

    <button mat-button style="margin-bottom: 20px !important;width:7rem;background-color: white;"
      (click)="onNoClick()">Cancel</button>
    <button mat-button style="margin-bottom: 20px;background-color: #F1F11A;width:7rem" [mat-dialog-close]="true"
      (click)="onSaveClick()">Save</button>

    <br />
  </mat-dialog-actions>
</div>
