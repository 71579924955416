import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ServerService } from '../../service/server/server.service';
import { InsertModelComponent } from '../../repairman/insert-model/insert-model.component';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.scss']
})
export class ModulesComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading = false;
  public subscription: Subscription;
  public title: string | undefined;
  public page_number_first: number = 1;
  public count_total: number = 1;
  public page_number_second: number | undefined
  public id: number | undefined;
  public list_record: any = [];
  public list_record_length = 1;
  public table: any | undefined;
  public user_id: number | undefined;
  public row_id: any;
  public where: string;
  public creator: string;
  public search: string;
  public code_id: number = 1046;
  public record_id: number | undefined;
  public obj: any | undefined;
  public brand: number | undefined;
  public device: number | undefined;
  public page: number = 1;
  public pre_status: boolean | undefined; public next_status: boolean | undefined; public new_status: boolean | undefined;
  public mat_table_selectedRow: any;
  public dataSource: any | undefined;
  public displayedColumns = ['row', 'brand', 'model', 'title', 'symbol', 'place', 'account_bank_name', 'account_branch_name', 'account_branch_number', 'account_branch_remain', 'wharehouse_group', 'wharehouse_cate'];


  constructor(
    public serverService: ServerService
    , public router: Router
    , public matSnackBar: MatSnackBar
    , public messageService: MessageService
    , public matDialogRef: MatDialogRef<ModulesComponent>
    , public dialog: MatDialog
    , @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.id = dialog_data.id;
      this.brand = dialog_data.brand;
      this.device = dialog_data.device;
      this.where = dialog_data.where;
      this.creator = dialog_data.creator;
      if (dialog_data.code_id) this.code_id = dialog_data.code_id;
      if (dialog_data.server) this.server = dialog_data.server;
      this.record_id = dialog_data.record_id;
      this.obj = dialog_data.obj;
    }
  }//end consructor

  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.get_service();
  }

  get_service(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 1045,
      'id': this.id,
      'user_id': this.user_id,
      'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1 && res['num'] > 0) {
          this.title = res['result'][0].site_services_title;
          this.table = res['result'][0].site_services_table;
          if (this.id == 171) this.title = "Product selection";
          this.which_get_data(1, 1);
        }//end if 
        else if (res['status'] == 1 && res['num'] == 0) {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_bg(row_id: number) {
    this.row_id = row_id;
  }
  arrowDownEvent() {
    //this.row_id = row_id;
  }
  which_get_data(type, page) {
    if (!this.creator) {
      return false;
    }
    if (this.code_id == 1046) {
      this.get_data(type, page);
    } else {
      this.get_data2(type);
    }
  }
  get_data(type: number, page: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (page == 1 || type == 2) { this.page = 1; }
    if (page == 2) { this.page = this.page + 1; }
    if (page == 4) { this.page = Math.ceil(this.count_total / 50); }
    if (page == 3) { this.page = this.page - 1; }
    if (type == 1) this.loading = true;
    var obj = {
      'address': 1046,
      'page': this.page,
      'lang': this.lang,
      'id': this.id,
      'table': this.table,
      'where': this.where,
      'creator': this.creator,
      'search': this.search,
      'user_id': this.user_id,
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.list_record_length = this.list_record.length;
          this.count_total = res['count'];
          this.pages(0);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end recieve_data
  pages(count: number) {
    this.count_total = this.count_total + count;
    this.page_number_first = this.page * 50 - 49;
    this.page_number_second = this.page * 50;
    if (this.page_number_first == 1) { this.pre_status = true; this.new_status = false; }
    else { this.pre_status = false; this.new_status = true; }
    if (this.list_record.length < 50) { this.page_number_second = this.count_total; this.next_status = true }//end if
    else { this.next_status = false; }
  }
  get_data2(type): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (type == 1) this.loading = true;
    var obj = {
      'address': this.code_id,
      'lang': this.lang,
      'record_id': this.record_id,
      'obj': this.obj,
      'search': this.search,
      'creator': this.creator,
      'user_id': this.user_id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_record = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }//end recieve_data
  select(id: number, title: string, title2: string) {
    var title = title;
    this.matDialogRef.close({ id: id, title: title });
  }//end select
  insert_model() {
    const dialogRef = this.dialog.open(InsertModelComponent, {
      'width': '40rem',
      'height': 'auto',
      'data': { brand: this.brand, device: this.device, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.list_record.unshift({ title: res.model_title, id: res.model });
          this.dataSource.data = this.list_record;
        }
      }
    )
  }
  save_model(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.device || !this.brand) {
      var en_message = "To register the model, the device and brand must be specified";
      this.message(true, this.messageService.message(this.lang, '', en_message), 1, this.messageService.close(this.lang));
      return false;
    }
    var obj = {
      "address": 1671,
      "user_id": this.user_id,
      "status_id": 1,
      "device": this.device,
      "brand": this.brand,
      "title": this.search,
      "creator": this.creator
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close({ id: res['result'][0].repairman_model_id, title: res['result'][0].repairman_model_title })
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var en_message = "This model title for this device and brand has already been registered in the system";
          this.message(true, this.messageService.message(this.lang, '', en_message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}