<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>Add bank accounts</span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding full-left'>
                <label for="title" class='wh-label'>
                    <span> Account Holder </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" [required]="required()">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding full-left'>
                <label for="branch_title" class='wh-label'>
                    <span> Branch </span>
                    <input class='wh-input' formControlName="branch_title" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding full-left'>
                <label for="branch_code" class='wh-label'>
                    <span> Branch code </span>
                    <input class='wh-input' formControlName="branch_code" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding full-left'>
                <label for="number" class='wh-label'>
                    <span> Account Number </span>
                    <input class='wh-input center' formControlName="number" autocomplete="off"
                        (keypress)="get_account($event)" [required]="required()" length=25 maxLength="25">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding full-left'>
                <label for="cart" class='wh-label'>
                    <span> IBAN number </span>
                    <input class='wh-input center' formControlName="cart" autocomplete="off" style="direction: ltr;">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding full-left'>
                <label for="balance" class='wh-label'>
                    <span> Account Balance </span>
                    <input class='wh-input center' formControlName="balance" autocomplete="off" style="direction: ltr;"
                        currencyMask required>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding full-left'>
                <label for="status_id" class='wh-label'>
                    <span> Status </span>
                    <span (click)="reset_input(26)">
                        <mat-icon class="close" class="input_icon_close fright">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="status_title" (click)="load_services(26) "
                        [required]="required()" readonly>
                    <input class="wh-input" formControlName="status_id" [hidden]="true" [required]="required()"
                        readonly>
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding full-left'>
                <label for="title" class='wh-label'>
                    <span>Address </span>
                    <div>
                        <input class='wh-input' formControlName="branch_address" autocomplete="off">
                    </div>
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" [disabled]="!form1_group.valid" class="btn" *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="submit" class="btn" *ngIf="type_task == 3">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>