import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';
import { PayChequeDetaileComponent } from '../pay-cheque-detaile/pay-cheque-detaile.component';
import { UserSearchComponent } from '../../../user/user-search/user-search.component';
import { UserVideoComponent } from '../../../user/user-video/user-video.component';
import { UserDetaile2Component } from '../../../user/user-detaile2/user-detaile2.component';
import { MessageService } from '../../../service/message/message.service';
import { PayDetaileBankComponent } from '../pay-detaile-bank/pay-detaile-bank.component';
import { PayChequeListRecieveComponent } from '../pay-cheque-list-recieve/pay-cheque-list-recieve.component';
import { PayDetaileCashComponent } from '../pay-detaile-cash/pay-detaile-cash.component';

import Num2persian from 'num2persian';

@Component({
  selector: 'app-pay-detaile',
  templateUrl: './pay-detaile.component.html',
  styleUrls: ['./pay-detaile.component.scss']
})
export class PayDetaileComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public token: any = Math.floor(Math.random() * 99999999999999);
  public loading: boolean = false;
  public search_array: any = [];
  public list_record: any = [];
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public readonly: boolean = false;
  public type_task!: number;
  public user_id!: number;
  public id!: number;
  public i!: number;
  public code!: number;
  public title!: string;
  public document!: number;
  public amount!: number;
  public document_type!: number;
  public result!: any;
  public user!: number;
  public user_title!: string;
  public video!: string;
  public ref!: string;
  public invoice_number!: number;
  public reception_number!: number;
  public remain: number = 0;
  public list_order: any = [];
  public list_record_financial2: any = [];
  public x: number = 0;
  public x3: number = 0;
  public currency_title2!: string;
  public creator!: number;
  public expire!: boolean;
  public sum: number = 0;
  public target_user_title!: string;
  public target_user_id: number = 0;
  public sum_all_letter!: string;
  public access_service!: number;
  public level!: number;
  public invoice_title!: string;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public displayedColumns = ['row', 'payment_type', 'amount', 'type', 'bank', 'account', 'cheque', 'due', 'operation'];

  constructor(
    public serverService: ServerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public matSnackBar: MatSnackBar,
    public dialog: MatDialog,
    public messageService: MessageService,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public matDialogRef: MatDialogRef<PayDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.serverService.get_financial2().subscribe(
      (res) => {
        this.list_record.unshift(res);
        this.dataSource.data = this.list_record;
        //this.get_amount(2);
      }
    )
    if (dialog_data) {
      this.readonly = dialog_data.readonly;
      this.type_task = dialog_data.type_task;
      this.document = dialog_data.document;
      this.create_form1();
      this.id = dialog_data.id;
      this.code = dialog_data.code;
      this.i = dialog_data.i;
      this.user = dialog_data.user;
      this.sum = dialog_data.sum;
      this.ref = dialog_data.ref;
      this.invoice_title = dialog_data.invoice_title;
      this.invoice_number = dialog_data.invoice_number;
      this.reception_number = dialog_data.reception_number;
      this.user_title = dialog_data.user_title;
      if (this.type_task == 1) {

        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.check_access(3, 1);
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'user_title': new FormControl(null),
      'user': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'comment': new FormControl(null),
      'document_date': new FormControl(null),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['user'].setValidators([Validators.required]);
      this.form1_group.controls['document_date'].setValidators([Validators.required]);
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access3(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access3(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
          }
          if (type_task == 2) {
            if (this.expire == true && this.i == -1) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.update();
            }
          }
          if (type_task == 3) {
            this.form1_group.patchValue({
              user: this.dialog_data.search_array.user,
              user_title: this.dialog_data.search_array.user_title,
              comment: this.dialog_data.search_array.comment,
            })
          }
          if (type_task == 4) {
            this.get_data();
          }
          if (type_task == 5) { }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  search(excel: number) {
    this.matDialogRef.close({
      comment: this.form1_group.value.comment,
      user: this.form1_group.value.user,
      user_title: this.form1_group.value.user_title,
      export_excel: excel,
    })
  }
  get_data() {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7026,
      'id': this.id,
      'creator': this.creator,
      'lang': this.lang
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'user': res['result'][0].finance_financial_user,
              'user_title': res['result'][0].user_title,
              'comment': res['result'][0].finance_financial_comment,
              'document_date': res['result'][0].finance_financial_document_date,
            });
            if (this.ref == 'invoice') {
              this.form1_group.patchValue({
                'comment': 'For ' + this.invoice_title + " " + this.invoice_number
              })
            }
            if (this.ref == 'reception') {
              this.form1_group.patchValue({
                'comment': 'For Ticket ID ' + this.reception_number
              })
            }
            if (this.i == -1) {
              this.get_today_date();
            }
            this.get_financial2();
            this.get_currency();
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_today_date() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6848 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1_group.patchValue({
            'document_date': res['date']
          })
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 7027,
      "user_id": this.user_id,
      'user': this.form1_group.value.user,
      'comment': this.form1_group.value.comment,
      'amount': this.amount,
      'document_date': this.form1_group.value.document_date,
      'token': this.token,
      'id': this.id,
      'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close({ result: this.result })
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  //*********************************************************************************************** */
  check_access2(i: number, id: any, cheque_type: number, myself: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
          }
          else {
            this.loading = false;
            this.delete(i, id, cheque_type, myself);
          }
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_financial2() {
    var obj = {
      'address': 7028,
      'user_id': this.user_id,
      'id': this.id
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_record.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_record);
          this.get_amount();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }//end else
      }//end res:Response
    ) //ens dubscribe
  }//end load data
  get_amount() {
    this.amount = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      this.amount += Number(this.list_record[i].finance_financial2_amount);
    }
    this.sum_all_letter = Num2persian(this.amount);
  }
  check_for_open_detaile(index: number, type_task: number, id: number, payment_type: number, cheque_type: number, myself: boolean) {
    if (this.readonly == true) {
      return false;
    }
    if (payment_type == 1) {
      this.open_detaile_cash(index, type_task, id);
    }
    else if (payment_type == 2) {
      this.open_detaile_bank(index, type_task, id);
    }
    else if (payment_type == 3) {
      this.open_detaile_cheque(index, type_task, id, cheque_type, myself)
    }
  }
  open_detaile_cash(index: number, type_task: number, id: number): any {
    const dialogRef = this.dialog.open(PayDetaileCashComponent, {
      width: '21rem',
      height: 'auto',
      disableClose: true,
      data: {
        type_task: type_task,
        id: id,
        search_array: this.search_array,
        code: this.code,
        financial: this.id,
        user: this.form1_group.value.user,
        token: this.token,
        sum: Math.abs(this.sum) - Number(this.amount),
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.dataSource.data = this.list_record;
            this.get_amount();
          }
          else if (type_task == 2) {
            this.list_record[index].finance_financial2_amount = res.result.finance_financial2_amount;
            this.get_amount();
          }
        }
      }
    )
  }
  open_detaile_bank(index: number, type_task: number, id: number): any {
    const dialogRef = this.dialog.open(PayDetaileBankComponent, {
      width: '30rem',
      height: 'auto',
      disableClose: true,
      data: {
        type_task: type_task,
        id: id,
        search_array: this.search_array,
        code: this.code,
        financial: this.id,
        user: this.form1_group.value.user,
        token: this.token,
        sum: Math.abs(this.sum) - Number(this.amount),
      }
    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.dataSource.data = this.list_record;
            this.get_amount();
          }
          else if (type_task == 2) {
            this.list_record[index].finance_financial2_amount = res.result.finance_financial2_amount;
            this.list_record[index].finance_payment_type_title = res.result.finance_payment_type_title;
            this.list_record[index].accounts_number = res.result.accounts_number;
            this.list_record[index].site_bank_title = res.result.site_bank_title;
            this.get_amount();
          }
        }
      }
    )
  }
  open_detaile_cheque(index: number, type_task: number, id: number, cheque_type: number, myself: boolean): any {
    if (this.readonly == true) {
      return false;
    }
    if (myself == false && cheque_type > 0) {
      var message = "You are not able to edit payment records other than your own";
      this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
      return false;
    }
    if ((!this.form1_group.value.user)) {
      var message = "To register or edit a transaction, the name of the account party must be specified at first";
      this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(PayChequeDetaileComponent, {
      width: '32rem',
      height: 'auto',
      data: {
        type_task: type_task,
        code: this.code,
        type: this.document_type,
        id: id,
        token: this.token,
        creator: this.creator,
        financial_id: this.id
      }

    });
    dialogRef.afterClosed().subscribe(
      (res: any) => {
        if (res) {
          if (type_task == 1) {
            this.list_record.unshift(res.result);
            this.dataSource = new MatTableDataSource(this.list_record);
            this.get_amount();
          }
          else {
            this.list_record[index].finance_financial2_amount = res.result.finance_financial2_amount;
            this.list_record[index].site_bank_title = res.result.site_bank_title;
            this.list_record[index].accounts_number = res.result.accounts_number;
            this.list_record[index].finance_financial2_cheque_number = res.result.finance_financial2_cheque_number;
            this.list_record[index].due_date = res.result.due_date;
            this.list_record[index].site_cheque_status_title = res.result.site_cheque_status_title;
            this.get_amount();
          }
        }
      }
    )
  }
  //********************************************************************************************** */
  select_cheque(type: number): any {
    if (!this.form1_group.value.user) {
      var message = "To register or edit a transaction, the name of the account party must be specified at first";
      this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
      return false;
    }
    const dialogRef = this.dialog.open(PayChequeListRecieveComponent, {
      width: '74rem',
      height: 'auto',
      data: { user: this.form1_group.value.user, document_type: this.document_type, token: this.token, type: type, creator: this.creator }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.insert_cheque(res);
        }
      }
    )
  }
  insert_cheque(res: any) {
    this.loading = true;
    var obj = {
      'address': 7029,
      "user_id": this.user_id,
      'cheque': res.result.finance_financial2_id,
      'payment_type': 2,
      'cheque_type': res.result.finance_financial2_cheque_type,
      'document_type': this.document_type,
      'financial': this.id,
      'token': this.token,
      'trash': 3,
      'ref': 1,
      'creator': this.creator,
      'amount': res.result.finance_financial2_amount,
      'bank': res.result.finance_financial2_cheque_bank,
      'cheque_number': res.result.finance_financial2_cheque_number,
      'cheque_account_number': res.result.finance_financial2_cheque_account_number,
      'cheque_due_date': res.result.cheque_due_date
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          res.result[0].color = 'transparent';
          this.list_record.unshift(res.result[0]);
          this.dataSource.data = this.list_record;
          this.get_amount();
          this.message(true, this.messageService.save(this.lang), 1, this.messageService.close(this.lang));
        }//end if
        else if (res['status'] == 4) {
          var message = "The selected check number has already been used in the receiving and payment system";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  //******************************************************************************************** */
  get_bg(id: number) {
    //this.id = id;
  }
  delete(i: number, id: number, cheque_type: number, myself: number): any {
    var confirm_delete;
    confirm_delete = window.confirm(this.messageService.message_delete_accept(this.lang));
    this.loading = false;
    if (confirm_delete == true) {
      this.loading = true;
      var address = 7030;
      if (cheque_type > 0 && myself == 0) {
        address = 7031;
      }
      var obj = {
        'address': address,
        "user_id": this.user_id,
        'financial_id': this.id,
        'id': id,
      }
      this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
        (res: any) => {
          if (res['status'] == 1) {
            this.list_record.splice(i, 1);
            this.dataSource.data = this.list_record;
            this.message(false, "", 1, this.messageService.close(this.lang));
            this.get_amount();
            //****************** */
          }
          else {
            this.message(true, this.serverService.message_delete_alarm(), 1, this.serverService.message_action());
          }
        }//end res:Response
      )//end this.subscription
    }//end this.confirm_delete == true    
  }//end delete
  //****************************************************** */
  open_user(): any {
    if (this.readonly == true) {
      return false;
    }
    const dialogRef = this.dialog.open(UserSearchComponent, {
      width: '40rem',
      height: 'auto',
      data: { code: 193 }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          this.form1_group.patchValue({
            'user_title': res.title,
            'user': res.id
          });
        }
      }
    )
  }
  add_user() {
    var dialogRef = this.dialog.open(UserDetaile2Component, {
      width: '45rem',
      height: 'auto',
      data: { creator: this.creator, level: this.level }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        this.form1_group.patchValue({
          'user_title': res['result'][0].user_title,
          'user': res['result'][0].user_id,
        });
      }
    )
  }
  get_currency() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6115, creator: this.creator, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.currency_title2 = res['result'][0].site_currency_title2;
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  //*************************************************************************************** */
  load_services(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    var code_id;
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator, code_id: code_id, obj: {} }
    });
    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 161) {
            this.form1_group.patchValue({
              'user': res.id,
              'user_title': res.title
            });
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (id == 161) {
      this.form1_group.patchValue({
        'user': null,
        'user_title': null
      });
    }
  }
  close() {
    var j = 0;
    for (var i = 0; i < this.list_record.length; i++) {
      if (this.list_record[i].finance_financial2_trash == 3) {
        j++;
      }
    }
    if (j > 0) {
      var message = "اینجا تراکنشهای انجام شده هنوز ذخیره نشده است؟";
      if (this.lang == 2) {
        message = "Are the transactions done here not saved yet?";
      }
      var accept: boolean = confirm(message);
      if (accept == true) {
        this.matDialogRef.close();
      } else {
      }
    } else {
      this.matDialogRef.close();
    }
  }
  open_video() {
    this.dialog.open(UserVideoComponent, {
      width: '45rem',
      height: 'auto',
      data: { id: this.code }
    })
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

