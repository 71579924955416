import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../../service/server/server.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MessageService } from '../../../service/message/message.service';

@Component({
  selector: 'app-pay-cheque-list-recieve',
  templateUrl: './pay-cheque-list-recieve.component.html',
  styleUrls: ['./pay-cheque-list-recieve.component.scss']
})
export class PayChequeListRecieveComponent implements OnInit, OnDestroy {
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading: boolean = false;
  public subscription: Subscription | any;
  public list_cheque: any = [];
  public user_id!: number;
  public mat_table_selectedRow!: any;
  public mat_table_hoverRow!: any;
  public dataSource!: any;
  public user!: number;
  public document_type!: number;
  public search_all!: string;
  public token!: any;
  public type!: any;
  public creator!: any;
  public asc_desc: string = 'asc';
  public order!: string;
  public currency_title2!: string;
  public displayedColumns = ['row', 'title', 'user', 'bank', 'cheque_number', 'date3', 'price'];
  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public matDialogRef: MatDialogRef<PayChequeListRecieveComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.user = dialog_data.user;
      this.document_type = dialog_data.document_type;
      this.token = dialog_data.token;
      this.type = dialog_data.type;
      this.creator = dialog_data.creator;
    }
  }//end consructor
  ngOnInit() {
    this.get_data(1);
  }
  sort(sort: string, asc_desc: string) {
    if (asc_desc == 'asc') this.asc_desc = 'desc';
    else this.asc_desc = 'asc';
    this.order = sort + " " + this.asc_desc;
    this.get_data(1);
  }
  reset_sort(sort: string, asc_desc: string) {
    this.order = sort + " " + asc_desc;
    this.get_data(1);
  }
  get_data(loading: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    //else { this.matSnackBar.dismiss(); }
    if (loading == 1) this.loading = true;
    var obj = {
      'address': 7032,
      'creator': this.creator,
      'search_all': this.search_all,
      'order': this.order
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        this.list_cheque = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_cheque.push(res['result'][i]);
          }//end for
          this.dataSource = new MatTableDataSource(this.list_cheque);
          this.get_currency();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_currency(): any {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6115, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.currency_title2 = res['result'][0].site_currency_title2;
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  select(i: number) {
    this.matDialogRef.close({ result: this.list_cheque[i] });
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}
