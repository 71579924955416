<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<mat-drawer-container autosize style="background-color: transparent;">
    <mat-drawer #drawer mode="side" opened position="end" style="max-width: 13rem;">
        <div class="nav_button cursor" (click)="open_show_extra_menu()" [ngClass]="{nav2:show_extra_menu}">
            <span class="material-icons" style="font-size:1.5rem">
                reorder
            </span>
        </div>
        <div class="row nav1" *ngIf="show_extra_menu" style="min-width: 13rem;">
            <app-menu-logo></app-menu-logo>
            <app-home-right></app-home-right>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_thumb nav_exit" style="text-align:right">
                <span class="material-icons cursor password" (click)="access_setting_menu()"
                    *ngIf="setting_menu == false && user_setting_menu == true" style="font-size:22px">
                    settings
                </span>

                <span class="material-icons cursor password" (click)="access_setting_menu()"
                    *ngIf="user_setting_menu == true && setting_menu == true" style="font-size:22px">
                    highlight_off
                </span>

                <span class="material-icons cursor" (click)="signout()">
                    settings_power
                </span>
            </div>
        </div>
        <div *ngIf="!show_extra_menu">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_thumb">
                    <img [src]="user_logo" alt="Avatar" class="nav_avatar_thumb">
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_thumb nav_setting"
                    *ngIf="user_setting_menu == true">
                    <span class="material-icons cursor" (click)="access_setting_menu()" *ngIf="setting_menu == false">
                        settings
                    </span>

                    <span class="material-icons cursor" (click)="access_setting_menu()" *ngIf="setting_menu == true">
                        highlight_off
                    </span>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 nav_thumb nav_exit" (click)="signout()">
                    <span class="material-icons cursor">
                        settings_power
                    </span>
                </div>
            </div>
        </div>
    </mat-drawer>
    <mat-drawer-content >
        <div style="width:100%;min-height:5px;background:#d93025;" class="spinner"></div>
        <div style="position:absolute;left:0;width:15rem !important" class="software1 mat-menu-content1">
            <mat-menu #menu="matMenu">
                <div class="row">
                    <a *ngFor="let x of list_software" href="{{ x.site_software_link }}">
                        <div class="col-lg-4 col-xl-4 col-md-4 software_div cursor">
                            <div class="software_logo" [ngStyle]="{'background-image':'url( ' + x.logo + ')'}">
                            </div>
                            <div class="software_text">{{ x.title }}</div>
                        </div>
                    </a>
                </div>
            </mat-menu>
        </div>
        <nav data-role="ribbonmenu" class="ribbon">
            <ul class="tabs-holder">
                <span style="vertical-align: middle;color:#E44E6E" class="material-icons cursor visible-xs"
                    (click)="signout()">
                    settings_power
                </span>
                <span *ngFor="let x of list_tab;let i = index" [ngClass]="{active:i == 0}"
                    (click)="select(x.site_tab_id)" id="a{{ x.site_tab_id }}">
                    <li *ngIf="x.show == true" [ngClass]="{tabActive:tab_id == x.site_tab_id}"
                        [ngStyle]="{backgroundColor:x.site_tab_color}">
                        <a href="#{{ x.site_tab_id }}">
                            {{ x.site_tab_title }}
                            <span *ngIf="x.site_tab_id == 215">{{ count_material_wharehouse }}</span>
                        </a>
                    </li>
                </span>

                <li class="active desktop1_en" style="min-width:fit-content">
                    <span style="vertical-align: middle;color:#E44E6E;float: right;" class="cursor" (click)="signout()"
                        *ngIf="dir == 'ltr'">
                        <span class="material-icons cursor" (click)="signout()">
                            settings_power
                        </span>
                    </span>
                    <div class="expire_date alarm1 cursor" (click)="openRenwqDialog()" *ngIf="level != 1"
                        [ngClass]="{alarm2:p7 <= now_date}">
                        <span class="ringing-bell" title="Renewal Membership">
                            <span> {{ expire_date }} </span>
                            <span>Renewal Membership</span>
                        </span>
                    </div>
                    <div class="alarm1 cursor" (click)="openBuySmsDialog()" *ngIf="level != 1">
                        <span class="ringing-bell" title="SMS Credit" (click)="get_sms()">
                            <span>SMS Credit</span>
                            <span> $ {{ sum_charge | currency:'':''}} </span>
                        </span>
                    </div>
                    <div class="expire_date alarm1 cursor" *ngIf="level != 1">
                        <span class="telegram" (click)="open_telegram()">
                            <i class="fab fa-telegram"></i>
                        </span>
                        <span class="whatsapp" (click)="open_whatsapp()">
                            <i class="fab fa-whatsapp"></i>
                        </span>
                    </div>
                    <!---->
                    <span style="vertical-align: middle;color:#E44E6E" class="cursor" (click)="signout()"
                        *ngIf="dir == 'rtl'">
                        <span><img src="../../assets/images/exit.png" class="exit_icon"></span>
                    </span>
                    <!---->
                </li>
                <div (click)="go_to_user()" class="hidden-xs" *ngIf="refrerr == 2">
                    <i class="fas fa-arrow-alt-circle-left icon back"></i>
                </div>
                <span (click)="open_add_section()" class="cursor" [hidden]="setting_menu == false"
                    style="margin-right: 5px;"><i class="fas fa-plus"></i></span>
            </ul>
            <div class="content-holder">
                <div class="section" *ngFor="let y of list_tab;let i = index" id="{{ y.site_tab_id }}"
                    [ngClass]="{active:i == 0}">
                    <div class="group">
                        <span *ngFor="let x of list_tab_temp[y.site_tab_id];let i = index">
                            <span *ngIf="list_menus[x.site_section_id] && list_menus[x.site_section_id].length > 0">
                                <section>
                                    <div class="group flex-column" style="border-right:solid 1px #ccc;">
                                        <span *ngFor="let xx of list_menus[x.site_section_id];let menu = index">
                                            <mat-card
                                                class="example-card pe1 example-header-image service_card_logo cursor"
                                                (click)="open_menu(menu,xx.site_services_link,xx.site_services_id,xx.title,x.site_section_id)"
                                                [ngClass]="{active_menu:menu_id == xx.site_services_id}">
                                                <div class="mat-card-avatar service_logo" mat-card-avatar
                                                    [ngStyle]="{'background-image':'url( ' + xx.src + ')'}"></div>
                                                <div class="service_text">{{ xx.title }}</div>
                                            </mat-card>
                                        </span>
                                        <span class="title cursor section_title">
                                            {{ x.title }}
                                        </span>
                                    </div><!-- group flex-column -->
                                    <div class="group-divider"></div>
                                </section>
                            </span>
                        </span>

                        <span *ngIf="tab_id == 198 && list_rstatus_in_menu.length > 0 && level != 1">
                            <section>
                                <div class="group flex-column" style="border-right:solid 1px #ccc;">
                                    <span *ngFor="let x of list_rstatus_in_menu"
                                        (click)="go_to_status(x.repairman_rstatus_id)">
                                        <mat-card class="example-card pe1 example-header-image service_card_logo cursor"
                                            [ngClass]="{active_menu:rstatus_id  == x.repairman_rstatus_id}">
                                            <div class="mat-card-avatar service_logo" mat-card-avatar
                                                [ngStyle]="{'background-image':'url( ' + x.logo + ')'}"></div>
                                            <div class="service_text">{{ x.repairman_rstatus_title }}</div>
                                        </mat-card>
                                    </span>
                                    <span class="title cursor" (click)="get_rstatus()">
                                        <span *ngIf="lang == 1">وضعیت ها</span>
                                        <span *ngIf="lang == 2"> Status </span>
                                    </span>
                                </div><!-- group flex-column -->
                                <div class="group-divider"></div>
                            </section>
                        </span>
                    </div>
                </div>
            </div>
        </nav>
        <!-- *********************************************************************************************************** -->
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
