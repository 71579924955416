import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-house-heating-detaile',
  templateUrl: './house-heating-detaile.component.html',
  styleUrls: ['./house-heating-detaile.component.scss']
})
export class HouseHeatingDetaileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
