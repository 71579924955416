<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>{{ serverService.get_title(type_task,lang) }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>
<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row">
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 en div_panding'>
                <label for="customer" class='wh-label'>
                    <span> Company name </span>
                    <span (click)="reset_input(161)">
                        <mat-icon class="close" class="input_icon_close">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="customer_title" (click)="load_services(161)" readonly>
                    <input class="wh-input" formControlName="customer" [hidden]="true" readonly>
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 en div_panding'>
                <label for="amount" class='wh-label'>
                    <span> Number of months </span>
                    <input type="number" class='wh-input' formControlName="number" autocomplete="off">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 en div_panding'>
                <label for="amount" class='wh-label'>
                    <span> Payment amount </span>
                    <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask
                        [readonly]="type_task != 3">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 en div_panding'>
                <label for="amount" class='wh-label'>
                    <span> Tracking number </span>
                    <input class='wh-input' formControlName="code" autocomplete="off"
                        (keypress)="serverService.get_number($event)">
                </label>
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="button" (click)="search()" class="btn">
            <span>Filter</span>
        </button>
    </div>
</form>