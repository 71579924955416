<div cdkDrag cdkDragRootElement=".cdk-overlay-pane">
  <div mat-dialog-title class="matDialogTitle relative" cdkDragHandle
    style="background-color: transparent;box-shadow:none">
    <div class="my-container" [hidden]="!loading">
      <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
    <span (click)="close()" class="close" *ngIf="refrerr == 2">
      <mat-icon>close</mat-icon>
    </span>
  </div>
</div>

<div mat-dialog-content class="matDialogContent padding0" dir="ltr">
  <div class="row">
    <div class="col-12 mx-auto p-2">
      <form #f2="ngForm">
        <div class="alert text-center" style="background-color: #f2f2f2;border:1px solid #ddd;" role="alert">
          <div *ngIf="calltype==1">
            Your account is no longer active. Kindly recharge to resume service .
          </div>
          <div *ngIf="calltype==2" class="w-100 text-center">
            Renewal MemberShip
          </div>
        </div>
        <div id="payments" class="mt-2">
          <table id="tblinfo" class="table table-striped table-bordered mb-2" dir="ltr">
            <tbody>
              <tr>
                <td class="w-50">
                  {{ expire_date }}
                </td>
                <td style="font-size: 0.9rem !important;">
                  Expiration Date
                </td>

              </tr>

              <tr>
                <td colspan="2">
                  <div class="alert text-center" style="background-color: #f2f2f2;border:1px solid #ddd;" role="alert">
                    <h4  class="mb-0 mt-0" style="font-size: 1.2rem !important;">Choose Your Membership Plan</h4>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div class="text-center" dir="ltr" style="width:420px;margin:0 auto" [hidden]="hide_final_amount">

                      <mat-radio-group aria-label="Select an option">
                        <mat-radio-button value="1"  (change)="UpdateAmount(1)">Monthly
                        </mat-radio-button>

                        <mat-radio-button style="margin-left: 25px;" value="12" [checked]="true" (change)="UpdateAmount(12)"> Yearly <span [hidden]="hide_discount"  [style.color]="forecolor" [style.font-style]="font_style" >Save 33%</span><span [hidden]="!hide_discount" [style.color]="forecolor">+ 1 Month Free </span>  </mat-radio-button>
                      </mat-radio-group>

                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div dir="ltr" class="text-center">

                    <span style="font-size: large;">{{ amount_final | currency:'':'' }} {{ currency_title }}</span>
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
          <div class="alert text-center mt-2"
            style="background-color: #f2f2f2;border:1px solid #ddd;width:90%;margin:0 auto" role="alert">
            Select Your Payment method
          </div>
          <mat-tab-group (selectedTabChange)="onTabChanged($event)" mat-align-tabs="center">
            <mat-tab label="Paypal">
              <div id="paypal">
                <div #paypal>
                </div>
              </div>
            </mat-tab>
            <mat-tab label="Stripe">
              <div id="stripe">
                <fieldset>
                  <legend class="card-only" data-tid="elements_examples.form.pay_with_card">Pay With
                    Card</legend>
                  <div class="pcontainer mb-3">
                    <ngx-stripe-card [options]="cardOptions" [elementsOptions]="elementsOptions"></ngx-stripe-card>
                  </div>
                  <button type="submit" class="payBotton">
                    <div (click)="createToken()" [hidden]="loading">
                      PAY$ {{ amount_final | currency:'':''}}
                    </div>
                    <span [hidden]="!loading">
                      <div class="spinner-border" style="width: 2rem; height: 2rem;" role="status">
                        <span class="visually-hidden"></span>
                      </div>
                    </span>
                  </button>
                </fieldset>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </form>
    </div>
  </div>
  <div class="social cursor">
    <span class="telegram" (click)="open_telegram()">
      <i class="fab fa-telegram"></i>
    </span>
    <span class="whatsapp" (click)="open_whatsapp()">
      <i class="fab fa-whatsapp"></i>
    </span>
  </div>
</div>
