<div class="div_loading">
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
</div>

<div class="row direction_ltr dflex">
    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-xs-12 padding0">
        <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
            placeholder="{{ messageService.get_search(lang) }}" autocomplete="off" style="width:100%">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-7 col-sm-8 col-xs-12 padding0">
        <div class="service_pager">
            <span>
                <div class="video_div1" (click)="open_video()" *ngIf="video">
                    <mat-icon class="video">smart_display</mat-icon>
                </div>
                <button class="search1" (click)='open_detaile(0,3,0)'>
                    <span>{{ messageService.get_filter(lang) }}</span>
                </button>
                <div class="pager_pe">
                    <button (click)="check_access(4,4,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-fast-forward"></i></button>
                    <button (click)="check_access(4,2,0,1,1)" [disabled]='next_status'><i
                            class="fas fa-forward"></i></button>
                    <button (click)="check_access(4,3,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-backward"></i></button>
                    <button (click)="check_access(4,1,0,1,1)" [disabled]='pre_status'><i
                            class="fas fa-fast-backward"></i></button>
                </div>
                <div class='navigation'><span> {{ page_number_first }}
                    </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
                        {{ count_total }} </span>
                </div>
            </span>
        </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12 padding0 hidden-sm hidden-xs">
        <div class="pager_checkbox">
            <div class="pager_icon_en">
                <div class="excel">
                    <img src="../../../../../assets/images/excel.png" class="excel_icon cursor"
                        (click)="load_data(1,2,1,-1)">
                    <a href="" id="excel"><i class="far fa-file-excel" [hidden]="true"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content-body control-content-body" (window:resize)="change_resize()">

    <mat-table [dataSource]="dataSource" class='matTable table-scroll mat_table_en_without_scroll'>

        <ng-container matColumnDef="row">
            <mat-header-cell *matHeaderCellDef style='max-width:40px'
                (click)="reset_sort('bascket_renewal_id ','desc')">
                <span>#</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i +
                page_number_first }}</mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:40px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="port">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'bascket_renewal_port ' + asc_desc }"
                (click)="sort('bascket_renewal_port',asc_desc)" style='max-width:130px'>
                <span> Payment method </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:130px'>
                {{ x.bascket_renewal_port }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:130px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'title ' + asc_desc }"
                (click)="sort('title',asc_desc)" style='max-width:130px'>
                <span> Payment detaile </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:130px'>
                <div class="left">
                    {{ x.title }}
                </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:130px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="user_title">
            <mat-header-cell *matHeaderCellDef [hidden]="level != 1"
                [ngClass]="{active1:order == 'user_store ' + asc_desc }" (click)="sort('user_store',asc_desc)"
                style='min-width:230px'>
                <span> Company/ Store name </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" [hidden]="level != 1" style='min-width:230px'>
                <div class="left">{{ x.user_store | substr:0:30 }} </div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef [hidden]="level != 1" style='min-width:230px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="expire">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'bascket_renewal_expire_date ' + asc_desc }"
                (click)="sort('bascket_renewal_expire_date',asc_desc)">
                <span> Expiration date </span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                <div>{{ x.bascket_renewal_expire_date }}</div>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="amount">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'bascket_renewal_amount ' + asc_desc }"
                (click)="sort('bascket_renewal_amount',asc_desc)">
                <span>Membership fee</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.bascket_renewal_amount | currency:'':''}}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width: 180px;min-width: 180px;'>{{ sum | currency:'':''
                }}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="code">
            <mat-header-cell *matHeaderCellDef style="min-width:150px"
                [ngClass]="{active1:order == 'bascket_renewal_paymentID ' + asc_desc }"
                (click)="sort('bascket_renewal_paymentID',asc_desc)">
                <span>Transaction ID</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style="min-width:150px">
                {{ x.bascket_renewal_paymentID }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='min-width:150px'></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef [ngClass]="{active1:order == 'bascket_renewal_date ' + asc_desc }"
                (click)="sort('bascket_renewal_date',asc_desc)">
                <span>Registration date</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index">
                {{ x.bascket_renewal_date }} {{ x.bascket_renewal_time }}
            </mat-cell>
            <mat-footer-cell *matFooterCellDef></mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="operation">
            <mat-header-cell *matHeaderCellDef style='max-width:70px' [hidden]="level != 1">
                <span>Operation</span>
            </mat-header-cell>
            <mat-cell *matCellDef="let x;let i = index" style='max-width:70px' [hidden]="level != 1">
                <span class="operation-icon" (click)="delete(x.bascket_renewal_id)"><i class="far fa-trash-alt icon"
                        style='margin:0px 4px;'></i></span>
            </mat-cell>
            <mat-footer-cell *matFooterCellDef style='max-width:70px' [hidden]="level != 1"></mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
        <mat-row *matRowDef="let row;columns:displayedColumns;let i = index" (click)="mat_table_selectedRow = row"
            (mouseenter)="mat_table_hoverRow = row"
            [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
            class="cursor"></mat-row>
        <mat-footer-row style="background: rgb(245 245 163) !important;bottom:-2px"
            *matFooterRowDef="displayedColumns;sticky : true"></mat-footer-row>
    </mat-table>

    <div class="row" class="preview1" [hidden]="count_total > 0">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div>
                <img src="../../../assets/images/preview2.png">
            </div>
            <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
        </div>
    </div>
</div>