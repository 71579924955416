<span (click)="close()" style="float:right">
  <mat-icon>close</mat-icon>
</span>

<div style="min-height: 100vh;overflow-y: scroll;">
  <div class="container-fluid p-2" dir="ltr">
    <div class="row d-flex justify-content-start">
      <div class="col-12">
        <form [formGroup]="myForm">
          <div class="row">
            <div class="col-md-4 pull-left col-12 col-sm-6 col-lg-3 text-left">
              <div class="form-group d-inline">
                <select class="form-control" id="selectControl1" formControlName="selectControl1">
                  <option value="">2024</option>
                  <option value="2023">2023</option>
                  <option value="2022">2022</option>
                </select>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="row">

      <div class="col-12">
        <ng-container *ngIf="display_chart1">
          <div [ngClass]="{'mx-auto': display_chart2==false}"  [class.col-lg-8]="display_chart2==false" [class.col-lg-6]="display_chart2==true" class="col-12 col-md-12" *ngIf="display_chart1">
            <highcharts-chart [Highcharts]="Highcharts1" [options]="chartOptions1"
              style="width: 100%; height: 65vh; display: block;"></highcharts-chart>
          </div>
        </ng-container>

        <ng-container *ngIf="display_chart2">
          <div class="col-lg-6 col-12 col-md-12" >
            <highcharts-chart [Highcharts]="Highcharts2" [options]="chartOptions2"
              style="width: 100%; height:65vh; display: block;font-weight: normal !important;"></highcharts-chart>
          </div>
        </ng-container>

      </div>

    </div>
    <div class="row" *ngIf="has_price==true">

      <div class="col-12 text-center">
        <table class="table table-bordered table-hover mt-2 ml-2" style="width:500px">
          <tr>

            <td>
              <h5 class="mx-auto">{{sumtotal_chart}}</h5>

            </td>
            <td> Total</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row" *ngIf="!has_price">

      <div class="col-12 text-center" style="width:500px">
        <table class="table table-bordered table-hover mt-2">
          <tr>

            <td>
              <h5 class="mx-auto">{{sumtotal_chart}}</h5>

            </td>
            <td>total number</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row d-none d-print-block" id="printable">
      <div class="col-12">
        <highcharts-chart [Highcharts]="Highcharts1" [options]="chartOptions1"
          style="width: 100%; height: 400px; display: block;"></highcharts-chart>
      </div>
      <div class="col-12 d-none d-print-block">
        <highcharts-chart [Highcharts]="Highcharts2" [options]="chartOptions2"
          style="width: 100%; height: 400px; display: block;"></highcharts-chart>
      </div>
    </div>
  </div>

</div>

