<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="div_loading">
    <div class="my-container" [hidden]="!loading">
      <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
  </div>
  {{ title }}
  <span class="close" (click)="close()">
    <mat-icon>close</mat-icon>
  </span>
</div>

<div mat-dialog-content class="matDialogContent" style="overflow-y: hidden">
  <div style="text-align: right;">
    <div>
      <div>
        <input class="search full-left" (keyup)="which_get_data(2,0)" [(ngModel)]="search">
      </div>
      <span *ngIf="code_id == 1046" class="full-left-padding0">
        <button (click)="which_get_data(1,4)" [disabled]='next_status'><i class="fas fa-fast-forward"></i></button>
        <button (click)="which_get_data(1,2)" [disabled]='next_status'><i class="fas fa-forward"></i></button>
        <button (click)="which_get_data(1,3)" [disabled]='pre_status'><i class="fas fa-backward"></i></button>
        <button (click)="which_get_data(1,1)" [disabled]='pre_status'><i class="fas fa-fast-backward"></i></button>
        <div class='navigation'><span> {{ page_number_first }}
          </span><span>-</span><span> {{ page_number_second }} </span><span> of </span><span>
            {{ count_total }} </span>
        </div>
      </span>
      <div style="float: left;">
        <button (click)="insert_model()" *ngIf="id == 163" style="float: left" autofocus="off">
          <span>insert new Model</span>
        </button>
        <button (click)="save_model()" *ngIf="id == 163 && list_record_length == 0">
          <span>Insert and select new Model</span>
        </button>
      </div>
    </div>
  </div>

  <div class="content-body control-content-body" [ngClass]="{content_body_en:lang==2}"
    style='overflow-x:auto;direction:rtl;overflow-y:auto;max-height: 25rem;'>
    <mat-table [dataSource]="dataSource" class='table-scroll mat_table_en_without_scroll ltr'>

      <ng-container matColumnDef="row">
        <mat-header-cell *matHeaderCellDef style="max-width:40px">
          #
        </mat-header-cell>
        <mat-cell *matCellDef="let x;let i = index" style="max-width:40px" [ngClass]="{'en':dir == 'ltr'}">{{
          i+page_number_first }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="symbol">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 156">
          <span>Symbol</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor" style="text-align: center;"
          [hidden]="id != 156" [ngClass]="{'en':dir == 'ltr'}">{{
          x.symbol }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="brand">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 197">
          <span>Brand</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor" style="text-align: center;"
          [hidden]="id != 197" [ngClass]="{'en':dir == 'ltr'}">{{
          x.repairman_brand_title }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="model">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 197">
          <span>Model</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor" style="text-align: center;"
          [hidden]="id != 197" [ngClass]="{'en':dir == 'ltr'}">{{
          x.repairman_model_title }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef>
          <span *ngIf="id != 234">Title</span>
          <span *ngIf="id == 234">Account Number</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor"
          [ngClass]="{fonten:id == 163}" style="text-align: center;"
          [ngStyle]="{backgroundColor:x.requests_reception == true ? '#e1b4b4' : 'transparent'}">
          <div class="right" [ngClass]="{'en':dir == 'ltr','left':dir == 'ltr'}">{{ x.title }}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="place">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 223">
          <span>Wharehouse</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor" style="text-align: center;"
          [hidden]="id != 223">{{
          x.wharehouse_place_title }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="account_branch_number">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 234" style="min-width: 150px;">
          <span>Account Holder name</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor"
          style="text-align: center;min-width: 150px;" [hidden]="id != 234">{{
          x.site_bank_accounts_title }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="account_bank_name">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 234">
          <span>Bank name</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor" style="text-align: center;"
          [hidden]="id != 234">{{
          x.site_bank_title | substr:0:15 }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="account_branch_name">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 234">
          <span>Branch name</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor" style="text-align: center;"
          [hidden]="id != 234">{{
          x.site_bank_accounts_branch_title }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="account_branch_remain">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 234">
          <span>Account Balance</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor"
          style="text-align: center;direction: ltr;" [hidden]="id != 234">{{
          x.balance | number }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="wharehouse_group">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 171" style="max-width: 200px;">
          <span>Group</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor"
          style="text-align: center;direction: ltr;max-width: 200px;" [hidden]="id != 171">
          <div class="left ltr">{{x.wharehouse_group_title }}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="wharehouse_cate">
        <mat-header-cell *matHeaderCellDef [hidden]="id != 171" style="max-width: 200px;">
          <span>Subgroup</span>
        </mat-header-cell>
        <mat-cell *matCellDef="let x" (click)="select(x.id,x.title,x.title2)" class="cursor"
          style="text-align: center;direction: ltr;" [hidden]="id != 171" style="max-width: 200px;">
          <div class="left ltr">{{x.wharehouse_material_cate_title |
            substr:0:20}}
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns" color="primary"></mat-header-row>
      <mat-row *matRowDef="let x;let i = index;columns:displayedColumns" (click)="get_bg(x.id)"
        (keyup.enter)="select(x.id,x.title,x.title2)" [ngClass]="{mat_table_selected:row_id == x.id}"></mat-row>
    </mat-table>
  </div>
</div>