<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    <span>
        <span *ngIf="lang == 2">Transaction Report</span>
        <span *ngIf="lang == 1">صورتحساب اشخاص</span>
    </span>
    <span (click)="close(true)">
        <mat-icon class="close">close</mat-icon>
    </span>
    <div class="video_div1" (click)="open_video()" *ngIf="video" style="float: left;">
        <mat-icon class="video">smart_display</mat-icon>
    </div>
</div>

<form [formGroup]="form1" (ngSubmit)="search()">
    <div mat-dialog-content class="matDialogContent">
        <div class="container-fluid">
            <div class="row">

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'en':dir == 'ltr','fleft' : dir == 'ltr','left' : dir == 'ltr'}">
                    <label for="user" class='wh-label'>
                        <span *ngIf="lang == 1">نام طرف حساب </span>
                        <span *ngIf="lang == 2"> Name and Surname</span>
                        <span (click)="reset_customer()">
                            <mat-icon style="text-align: left;" class="close" class="input_search input_search_pe"
                                [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                        </span>
                        <input class='wh-input' formControlName="user_title" (click)="open_user()" autocomplete="off"
                            required>
                        <input class='wh-input' formControlName="user" autocomplete="off" required readonly
                            [hidden]="true">
                    </label>
                </div>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'en':dir == 'ltr','fleft' : dir == 'ltr','left' : dir == 'ltr'}">
                    <label for="date1" class='wh-label'>
                        <span *ngIf="lang == 1"> از تاریخ </span>
                        <span *ngIf="lang == 2"> Date from </span>
                        <input type="date" class="wh-input" matInput formControlName="date1" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                    </label>
                </div>

                <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                    [ngClass]="{'en':dir == 'ltr','fleft' : dir == 'ltr','left' : dir == 'ltr'}">
                    <label for="date2" class='wh-label'>
                        <span *ngIf="lang == 1"> تا تاریخ </span>
                        <span *ngIf="lang == 2"> Date to </span>
                        <input type="date" class="wh-input" matInput formControlName="date2" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                    </label>
                </div>

            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button type="submit" class="btn" [disabled]="!form1.valid">
            <span>View</span>
        </button>
    </div>
</form>