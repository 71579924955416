import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../../service/modules/modules.component';
import { ServerService } from '../../../service/server/server.service';


@Component({
  selector: 'app-order-detaile',
  templateUrl: './order-detaile.component.html',
  styleUrls: ['./order-detaile.component.scss']
})
export class OrderDetaileComponent implements OnInit, OnDestroy {
  public isOnline: any;
  public loading = false;
  public subscription: Subscription | undefined;
  public err: string | undefined; public err_validation: boolean = false;
  public err_internet_text: string | undefined; public err_internet_validation: boolean | undefined;
  public server: any;
  lang: any;
  type_task: number | undefined;
  form1_group: FormGroup | any;
  user_info: any;
  user_id: number | any;
  id: number | undefined;
  i: number | undefined;
  code: number | undefined;
  title: string | undefined;
  search_array: any = [];
  reception_id: number | undefined;
  group: number = 0;
  sum_order_material: number = 0;
  sum_exit_material: number = 0;
  status_id_temp: number | undefined;
  currency_title: string | undefined;
  ref: string | undefined;
  ref2: string | undefined;
  invoice_id: number | undefined;
  token: number | undefined;
  trash: number | undefined;
  creator: number | undefined;
  temp_number: number = 0;
  check_order_id: number | undefined;
  document_type_text: string | undefined;
  readonly: boolean = false;
  back: number | undefined;


  constructor(public serverService: ServerService, public router: Router, public matSnackBar: MatSnackBar
    , @Inject(MAT_DIALOG_DATA) private dialog_data: any, private dialog: MatDialog
    , private matDialogRef: MatDialogRef<OrderDetaileComponent>) {
    this.server = this.serverService.get_server();
    this.user_info = JSON.parse(<any>localStorage.getItem('user_info'));
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.document_type_text = dialog_data.document_type_text;
      this.type_task = dialog_data.type_task;
      this.ref = dialog_data.ref;
      this.ref2 = dialog_data.ref2;
      this.back = dialog_data.back;
      this.trash = dialog_data.trash;
      this.invoice_id = dialog_data.invoice_id;
      this.group = dialog_data.group;
      this.reception_id = dialog_data.reception_id;
      this.create_form1();
      this.id = dialog_data.id;
      this.title = dialog_data.title;
      this.token = dialog_data.token;
      this.code = dialog_data.code;
      this.readonly = dialog_data.readonly;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.creator = dialog_data.creator;
        this.form1_group.patchValue({
          group: dialog_data.search_array.group,
          group_title: dialog_data.search_array.group_title,
          cate: dialog_data.search_array.cate,
          cate_title: dialog_data.search_array.cate_title,
          material: dialog_data.search_array.material,
          material_title: dialog_data.search_array.material_title,
          number: dialog_data.search_array.number,
          comment: dialog_data.search_array.comment,
        })
      }
    }

  }//end consructor

  ngOnInit() {
    this.server = this.serverService.get_server();
    this.lang = JSON.parse(<any>localStorage.getItem('lang'));
    this.user_info = JSON.parse(<any>localStorage.getItem('user_info'));
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }

  }

  create_form1() {
    this.form1_group = new FormGroup({
      'comment': new FormControl(null),
      'sort': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cost': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'discount': new FormControl(0, [Validators.pattern('[0-9]{1,}')]),
      'number': new FormControl(1, [Validators.pattern('[0-9]{1,}')]),
      'material_title': new FormControl(null),
      'material': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'group_title': new FormControl(null),
      'group': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'cate_title': new FormControl(null),
      'cate': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'repairman_title': new FormControl(null),
      'repairman': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'seller_title': new FormControl(null),
      'seller': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'price': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
    if (this.type_task == 2 || this.type_task == 1) {
      this.form1_group.controls['cate'].setValidators([Validators.required]);
      this.form1_group.controls['material'].setValidators([Validators.required]);
      this.form1_group.controls['number'].setValidators([Validators.required]);
      this.form1_group.controls['cost'].setValidators([Validators.required]);
    }
    if (this.type_task == 1) {
      this.get_group();
    }
  }

  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            //this.serverService.signout();
            var pe_message = "شما دسترسی به این سرویس را ندارید";
            var pe_action = "بستن";
            this.recieve_message(true, 'Access restrictions', pe_message, 1, 'close', pe_action);
          }
          else {
            this.loading = false;
            this.creator = res['creator'];
            if (type_task == 1) { this.insert(); }
            if (type_task == 2) {
              if (this.ref == 'requests') {
                this.update();
              } else {
                this.check_for_update1();
              }
            }
            if (type_task == 3) { this.search(excel); }
            if (type_task == 4) { this.get_data(); }
          }
        }//end if
        else {
          var pe_message = "خطا در دسترسی";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  search(excel: number) {
    this.matDialogRef.close({
      group: this.form1_group.value.group,
      group_title: this.form1_group.value.group_title,
      cate: this.form1_group.value.cate,
      cate_title: this.form1_group.value.cate_title,
      material: this.form1_group.value.material,
      material_title: this.form1_group.value.material_title,
      number: this.form1_group.value.number,
      comment: this.form1_group.value.comment,
      export_excel: excel
    })
  }

  get_data() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6007, id: this.id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'price': res['result'][0].wharehouse_material_price,
              'cost': res['result'][0].wharehouse_order_cost,
              'discount': res['result'][0].wharehouse_order_discount,
              'comment': res['result'][0].wharehouse_order_comment,
              'material_title': res['result'][0].wharehouse_material_title,
              'material': res['result'][0].wharehouse_material_id,
              'cate_title': res['result'][0].wharehouse_material_cate_title,
              'cate': res['result'][0].wharehouse_material_cate_id,
              'number': res['result'][0].wharehouse_order_number,
              'sort': res['result'][0].wharehouse_order_sort,
              'group': res['result'][0].wharehouse_group_id,
              'group_title': res['result'][0].wharehouse_group_title,
            });
            if (this.ref == 'invoice') {
              this.form1_group.patchValue({
                'seller': res['result'][0].seller,
                'seller_title': res['result'][0].seller_title
              })
            }
            else if (this.ref == 'reception') {
              this.form1_group.patchValue({
                'repairman': res['result'][0].repairman_id,
                'repairman_title': res['result'][0].repairman_title,
              })
            }
            if (this.document_type_text == 'input') {
              this.check_order_id = res['result'][0].wharehouse_order_id;
            }
            else if (this.document_type_text == 'exit') {
              this.check_order_id = res['result'][0].wharehouse_order_consumed_invoice;
            }
            this.temp_number = res['result'][0].wharehouse_order_number;
            this.currency_title = res['result'][0].site_currency_title;
            this.status_id_temp = res['result'][0].wharehouse_order_status_add;
          }//end if
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  insert(): any {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (this.form1_group.value.discount > this.form1_group.value.cost * this.form1_group.value.number) {
      var pe_message = "مقدار تخفیف نمی تواند بیشتر از قیمت اصلی باشد";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6008, "user_id": this.user_id
      , 'status_id': this.form1_group.value.status_id
      , 'comment': this.form1_group.value.comment
      , 'material': this.form1_group.value.material
      , 'number': this.form1_group.value.number
      , 'invoice': this.form1_group.value.invoice
      , 'cost': this.form1_group.value.cost
      , 'reception_id': this.reception_id
      , 'invoice_id': this.invoice_id
      , 'token': this.token
      , 'material_type': 1
      , 'trash': this.trash
      , 'creator': this.creator
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0] }
          )
          var pe_message = "با موفقیت ذخیره شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Done successfully', pe_message, 1, 'close', pe_action);
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          var pe_action = "بستن";
          this.recieve_message(true, 'The title should be unique', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end insert

  check_for_update() {
    if (this.status_id_temp == 1 || this.document_type_text == 'exit') {
      this.check_for_update1();
    } else {
      this.update();
    }
  }

  check_for_update1() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6003, material: this.form1_group.value.material, id: this.id, token: this.token, check_order_id: this.check_order_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {

          if (this.document_type_text == 'input' && res['num'] == 0) {
            this.update();
          }
          else if (this.document_type_text == 'input' && res['num'] > 0 && this.form1_group.value.number >= res['result'][0].order_consumed) {
            this.update();
          }
          else if (this.document_type_text == 'exit' && res['num'] > 0 && (this.form1_group.value.number <= (res['remain'] + Number(this.temp_number)))
          ) {
            this.update();
          }
          else {
            var pe_message = "تعداد خروجی های یک کالا نمی تواند بیشتر از ثبت ورودی ها باشد";
            var pe_action = "بستن";
            this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
          }
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }

  update(): any {
    if (this.readonly == true) {
      return false;
    }
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }

    if (this.form1_group.value.discount > this.form1_group.value.cost * this.form1_group.value.number) {
      var pe_message = "مقدار تخفیف نمی تواند بیشتر از قیمت اصلی باشد";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return false;
    }
    this.loading = true;
    var obj = {
      'address': 6010, "user_id": this.user_id
      , 'comment': this.form1_group.value.comment
      , 'cost': this.form1_group.value.cost
      , 'material': this.form1_group.value.material
      , 'number': this.form1_group.value.number
      , 'discount': this.form1_group.value.discount
      , 'sort': this.form1_group.value.sort
      , 'repairman': this.form1_group.value.repairman
      , 'seller': this.form1_group.value.seller
      , 'id': this.id
      , 'creator': this.creator
      , 'ref': this.ref
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.matDialogRef.close(
            { result: res['result'][0], i: this.i }
          )
          var pe_message = "با موفقیت ذخیره شد";
          var pe_action = "بستن";
          this.recieve_message(true, 'Done successfully', pe_message, 1, 'close', pe_action);
        }//end if
        else if (res['status'] == 4) {
          var pe_message = "عنوان  مي بايست منحصر به فرد باشد";
          var pe_action = "بستن";
          this.recieve_message(true, 'The title should be unique', pe_message, 1, 'close', pe_action);
        }
        else {
          var pe_message = "خطا در ذخیره";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    );
  }//end update

  load_services(id: number, type: number): any {
    if (this.readonly == true || id == 223 || id == 184 || id == 171) {
      return false;
    }
    var where = "";
    var code_id;
    if (id == 223) {
      code_id = 6346;
    }
    if (id == 220) {
      code_id = 6227;
    }
    if (id == 171) {
      where += " and  wharehouse_material_cate = " + this.form1_group.value.cate;
    }
    if (id == 161) {
      code_id = 6196;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, where: where, creator: this.creator, code_id: code_id }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 171) {
            this.form1_group.patchValue({
              'material': res.id,
              'material_title': res.title
            })
          }
          if (id == 220 && type == 1) {
            this.form1_group.patchValue({
              'repairman': res.id,
              'repairman_title': res.title
            })
          }
          if (id == 220 && type == 2) {
            this.form1_group.patchValue({
              'seller': res.id,
              'seller_title': res.title
            })
          }
          if (id == 223) {
            this.form1_group.patchValue({
              'group': res.id,
              'group_title': res.title,
              'cate_title': null,
              'cate': null,
              'material_title': null,
              'material': null
            })
          }
          if (id == 184) {
            this.form1_group.patchValue({
              'cate': res.id,
              'cate_title': res.title,
              'material_title': null,
              'material': null
            })
          }
          if (id == 184) {
            this.form1_group.patchValue({
              'cate': res.id,
              'cate_title': res.title,
              'material_title': null,
              'material': null
            })
          }
        }
      }
    )
  }

  get_currency_title() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1875, 'invoice': this.form1_group.value.invoice }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.currency_title = res['result'][0].site_currency_title;
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  reset_input(id: number, type: number) {
    if (id == 171) {
      this.form1_group.patchValue({
        'material': null,
        'material_title': null,
      })
    }
    if (id == 223) {
      this.form1_group.patchValue({
        'group': null,
        'group_title': null,
        'cate': null,
        'cate_title': null,
        'material': null,
        'material_title': null,
      })
    }
    if (id == 184) {
      this.form1_group.patchValue({
        'material': null,
        'material_title': null,
        'cate': null,
        'cate_title': null,
      })
    }
    if (id == 220 && type == 1) {
      this.form1_group.patchValue({
        'repairman': null,
        'repairman_title': null
      })
    }
    if (id == 220 && type == 2) {
      this.form1_group.patchValue({
        'seller': null,
        'seller_title': null
      })
    }
  }


  get_group() {
    if (this.serverService.check_internet() == false) {
      var pe_message = "خطا در اینترنت";
      var pe_action = "بستن";
      this.recieve_message(true, 'Erorr in Internet', pe_message, 1, 'close', pe_action);
      return;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1766, reception_id: this.reception_id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1_group.patchValue({
              'group': res['result'][0].wharehouse_group_id,
              'group_title': res['result'][0].wharehouse_group_title,
            })
          }
          this.recieve_message(false, "", "", 1, "", "");
        }//end if
        else {
          var pe_message = "خطا در دریافت";
          var pe_action = "بستن";
          this.recieve_message(true, 'Erorr in recieve', pe_message, 1, 'close', pe_action);
        }
      }
    )
  }


  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required() {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }

  recieve_message(validation: boolean, en_message: string, pe_message: string, type: number, en_action: string, pe_action: string) {
    this.err_internet_validation = false;
    if (type == 1) this.loading = false;
    if (validation == true) {
      if (this.lang == 1) this.matSnackBar.open(pe_message, pe_action, { duration: 8000 });
      if (this.lang == 2) this.matSnackBar.open(en_message, en_action, { duration: 8000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

