<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" class="detaile" [hidden]="!loading"></mat-progress-bar>
    <span>
        <span>Payment history</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>


<div mat-dialog-content class="matDialogContent">
    <div class="row" style="padding-bottom: 1rem;" [ngStyle]="{display:lang == 2 ? 'flex' : 'initial'}">
        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12" style="margin-bottom: 1rem !important;">
            <table class="table table-bordered" [ngStyle]="{direction:lang == 2 ? 'ltr' : 'rtl'}">
                <tr>
                    <td class="left" style="width:43%">
                        <span>Discount on service</span>
                    </td>
                    <td style="padding:0px !important">{{ currency_title2 }} {{ discount_material | currency:'':'' }}
                    </td>
                </tr>
                <tr>
                    <td class="left">
                        <span>Discount on product</span>
                    </td>
                    <td style="padding:0px !important">{{ currency_title2 }} {{ discount_services | currency:'':'' }}
                    </td>
                </tr>
                <tr style="background-color: #F1EFEA">
                    <td class="left">
                        <span>Amount due</span>
                    </td>
                    <td>{{ currency_title2 }} {{ sum | currency:'':'' }}
                        <span style="float: left;padding-top:1px" (click)="open_exit()"><i
                                class="fas fa-info-circle icon"></i> </span>
                    </td>
                </tr>
            </table>
        </div>

        <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-xs-12">
            <table class="table table-bordered" [ngStyle]="{direction:lang == 2 ? 'ltr' : 'rtl'}">
                <tr>
                    <td class="left" style="width:43%">
                        <span>Name and Surname</span>
                    </td>
                    <td>{{ user_title }}</td>
                </tr>
                <tr>
                    <td class="left">
                        <span>Cellphone Number</span>
                    </td>
                    <td>{{ user_cellphone }}</td>
                </tr>
                <tr>
                    <td class="left">
                        <span>Reception no</span>
                    </td>
                    <td>{{ id }}</td>
                </tr>
                <tr>
                    <td class="left">
                        <span>Device/Brand/Model</span>
                    </td>
                    <td>{{ device }} / {{ brand }} / <span class="font-en">{{ model
                            }}</span></td>
                </tr>
            </table>
        </div>

        <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-xs-12 remain">
            <div class="remain_text1" (click)="get_account()">
                <span>Total debt/ credit</span>
            </div>
            <div class="remain_text2">{{ remain | currency:'':'' }}</div>
            <div style="padding-top:0.5rem">{{ currency_title2 }}</div>
        </div>
    </div>

    <div class="row">
        <div style="text-align: center;margin-top: 6rem;">
            <button class="button_default button_default2" (click)="new()">
                <span>Shortcut to Journal Entry</span>
            </button>
            <button class="button_default button_default2" (click)="show_financial2()" *ngIf="show_financial == false">
                <span> Payment history </span>
            </button>
            <button class="button_default button_default2" (click)="back()" *ngIf="show_financial == true">
                <span>Return</span>
            </button>
        </div>
    </div>
</div>

<div mat-dialog-actions class="matDialogActions" *ngIf="!status_empty_shelf">
    <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" (click)="update()">
        <span>Change to delivered status</span>
    </button>
    <div style="padding-right:1rem;padding-top:0.2rem">
        <mat-radio-group aria-label="Select an option" name="device_status" [(ngModel)]="device_status">
            <div class="device_status_text">
                <span> Device status :</span>
            </div>
            <mat-radio-button class="status_title_items reception-status" *ngFor="let x of list_device_status"
                [value]="x.repairman_device_status_id" [checked]="device_status == x.repairman_device_status_id">
                <span class="status_span_label">{{ x.repairman_device_status_title }}</span>
            </mat-radio-button>
        </mat-radio-group>
    </div>
</div>