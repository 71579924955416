<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="my-container" [hidden]="!loading">
        <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
    </div>
    <span> Insert user</span>
    <span class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </span>
</div>

<form [formGroup]="form1" (ngSubmit)="insert()">
    <div mat-dialog-content class="matDialogContent" style="padding:0px !important">
        <div class="row">
            <div class='col-md-912 col-lg-12 col-sm-12 col-xs-12 pe'>
                <div class="row">

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="sex" class='wh-label'>
                            <span> Title </span>
                            <span (click)="reset_input(10)">
                                <mat-icon class="close" class="input_search input_icon_close">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='sex_title' autocomplete="off" readonly
                                (click)="load_services(10)" required>
                            <input class='wh-input' formControlName='sex' autocomplete="off" required readonly
                                [hidden]="true">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="title" class='wh-label'>
                            <span>Name and sure name</span>
                            <input class='wh-input' formControlName='title' autocomplete="off" required>
                        </label>
                    </div>

                    <div class='ol-md-3 col-lg-3 col-sm-4  col-xs-12 pe div_panding'>
                        <label for="groups" class='wh-label'>
                            <span>User groups</span>
                            <span (click)="reset_input(155)">
                                <mat-icon class="close" class="input_search input_icon_close">close
                                </mat-icon>
                            </span>
                            <input class='wh-input' formControlName='groups_title' autocomplete="off"
                                (click)="load_services(155)" required readonly>
                            <input class='wh-input' formControlName='groups' autocomplete="off" required [hidden]="true"
                                readonly>
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="cellphone" class='wh-label'>
                            <span>Cellphone Number</span>
                            <input class='wh-input' formControlName='cellphone' pattern="[0-9]{1,}" maxlength=25
                                autocomplete="off" (keypress)="serverService.get_number($event)" autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="phone" class='wh-label'>
                            <span>Landline number</span>
                            <input class='wh-input' formControlName='phone' autocomplete="off"
                                (keypress)="serverService.get_number($event)">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="store" class='wh-label'>
                            <span>Company name</span>
                            <input class='wh-input' formControlName='store' autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="email" class='wh-label'>
                            <span>Email</span>
                            <input class='wh-input' formControlName='email' autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="economic_code" class='wh-label'>
                            <span>VAT number</span>
                            <input class='wh-input' formControlName='economic_code' autocomplete="off"
                                (keypress)="serverService.get_number($event)">
                        </label>
                    </div>


                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="sex" class='wh-label'>
                            <span> Contact name </span>
                            <input class='wh-input' formControlName='contact_name' autocomplete="off">
                        </label>
                    </div>

                    <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'>
                        <label for="contact_number" class='wh-label'>
                            <span>Contact number</span>
                            <input class='wh-input' formControlName='contact_number' autocomplete="off">
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="address" class='wh-label'>
                            <span>Address</span>
                            <input class='wh-input' formControlName='address' autocomplete="off">
                        </label>
                    </div>

                    <div class='col-md-12 col-lg-12 col-sm-12 col-xs-12 pe div_panding'>
                        <label for="comment" class='wh-label'>
                            <span>Description</span>
                            <input class="wh-input" formControlName='comment'>
                        </label>
                    </div>
                </div><!-- row -->
            </div><!-- col-md-8 col-lg-8 col-sm-8 col-xs-12  -->
        </div><!-- row -->
    </div> <!-- content -->


    <div mat-dialog-actions class="matDialogActions" style="padding-right:25px">
        <button class="btn_pe" [disabled]="!form1.valid">
            {{ serverService.get_event_title(1,lang) }}
        </button>
    </div><!-- row -->
</form>