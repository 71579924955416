import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';


@Component({
  selector: 'app-bascket-detaile',
  templateUrl: './bascket-detaile.component.html',
  styleUrls: ['./bascket-detaile.component.scss']
})
export class BascketDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public subscription: Subscription | any;
  public form1_group: FormGroup | any;
  public loading: boolean = false;
  public readonly: boolean = false;
  public price: number = 0;
  public search_array: any = [];
  public type_task!: number;
  public user_id!: number;
  public code!: number;
  public title!: string;
  public level!: number;
  public creator!: number;
  public expire!: boolean;
  public result!: any;
  public access_service!: number;

  constructor(
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<BascketDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.create_form1();
      this.creator = dialog_data.creator;
      this.code = dialog_data.code;
      if (this.type_task == 1) {
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1_group.patchValue({
          customer: this.dialog_data.search_array.customer,
          customer_title: this.dialog_data.search_array.customer_title,
          amount: this.dialog_data.search_array.amount,
          code: this.dialog_data.search_array.code_bascket,
          number: this.dialog_data.search_array.number,
        })
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1_group = new FormGroup({
      'customer_title': new FormControl(null),
      'customer': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'code': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'amount': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'number': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
    });
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1_group.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.level = res['level'];
          this.access_service = res['result'][0].count;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.title = res['result'][0].title;
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) { }
          if (type_task == 2) { }
          if (type_task == 3) { this.search(); }
          if (type_task == 4) { }

        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  search() {
    this.matDialogRef.close({
      customer: this.form1_group.value.customer,
      customer_title: this.form1_group.value.customer_title,
      amount: this.form1_group.value.amount,
      code_bascket: this.form1_group.value.code,
      number: this.form1_group.value.number,
    })
  }
  load_services(id: number): any {
    var code_id;
    if (this.readonly == true) {
      return false;
    }
    if (id == 161) {
      code_id = 6384;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      disableClose: true,
      data: { id: id, creator: this.creator, code_id: code_id, obj: { lang: this.lang } }
    });

    dialogRef.afterClosed().subscribe(
      (res) => {
        if (res) {
          if (id == 161) {
            this.form1_group.patchValue({
              'customer': res.id,
              'customer_title': res.title
            })
          }
        }
      }
    )
  }
  reset_input(id: number): any {
    if (this.readonly == true) {
      return false;
    }
    if (id == 161) {
      this.form1_group.patchValue({
        'customer': null,
        'customer_title': null,
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required(): boolean {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) { this.loading = false; }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 

  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}