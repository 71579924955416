import { Component, OnInit, OnDestroy, Inject, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServerService } from '../../service/server/server.service';
import { MessageService } from '../../service/message/message.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HttpClient } from '@angular/common/http';
declare var paypal;

@Component({
  selector: 'app-buy-sms',
  templateUrl: './buy-sms.component.html',
  styleUrls: ['./buy-sms.component.scss']
})

export class BuySmsComponent implements OnInit, OnDestroy {
  @ViewChild('paypal', { static: true }) paypalElement: ElementRef;
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  public loading: boolean = false;;
  public stripeTest: FormGroup | any;
  public cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    }
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public server: any = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public network_access: boolean = false;
  public amount: number = 10;
  public user_amount: number = 0;
  public currency_title: string = "$";
  public currency_id: number = 0;
  public month_extension: number = 0;
  public calltype: number = 0;
  public subscription: Subscription | any;
  public user_id!: number;
  public creator!: number;
  public account_title!: string;
  public price_dollar!: number;
  public expire_date!: string;

  constructor(
    public fb: FormBuilder,
    public http: HttpClient,
    public stripeService: StripeService,
    public serverService: ServerService,
    public router: Router,
    public messageService: MessageService,
    public _dialogRef: MatDialogRef<BuySmsComponent>,
    public matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any) {
    if (dialog_data) {
      this.creator = dialog_data.creator;
      this.expire_date = dialog_data.expire_date;
      this.calltype = dialog_data.calltype;
    }
  }//end consructor
  @Output() exit = new EventEmitter<any>();
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    this.BuildPaypal();
  }
  //***************************************************************************************************************************************** */
  onTabChanged(event: MatTabChangeEvent) {
    if (event.index == 0) {
      this.BuildPaypal();
    }
  }
  BuildPaypal(): any {
    paypal
      .Buttons({
        createOrder: (data: any, actions: any) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "SMS Recharge",
                amount: {
                  currency_code: 'USD',
                  value: this.amount
                },
              }
            ], application_context: {
              shipping_preference: "NO_SHIPPING",
              payment_pattern: "CUSTOMER_PRESENT_ONETIME_PURCHASE",
            }
          }
          )
        },
        onApprove: async (data: any, actions: any) => {
          const order = await actions.order.capture();
          this.log(data.paymentID, 'paypal');
        },
        onError: err => {
          console.log(err);
        }
      })
      .render(this.paypalElement.nativeElement);
  }
  //*******************************************************************************Stripe**************************************** */
  createToken(): void {
    this.loading = true;
    this.stripeService
      .createToken(this.card.element)
      .subscribe((result: any) => {
        if (result.token) {
          this.sendTokenToServer(result.token.id, this.amount!);
        } else if (result.error) {
          var message = "Unfortunately, your card information is not correct";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }
      });
  }
  public sendTokenToServer(token: string, amount: number) {
    var confirmation = 'automatic';
    amount = this.amount * 100;
    const currency = 'usd';
    var paymenturl = this.serverService.server_strip_payment + '/create-payment-intent';
    const paymentData = {
      amount,
      currency,
      payment_method: token,
      confirmation
    };
    this.http.post(paymenturl, paymentData)
      .subscribe((response: any) => {
        this.message(false, "", 1, this.messageService.close(this.lang));
        const paymentResponse = new PaymentResponse(true, response.clientSecret);
        this.log('', 'strip');
      }, error => {
        this.message(false, "", 1, this.messageService.close(this.lang));
        const paymentResponse = new PaymentResponse(false, undefined, error.message);
      });
  }
  //************************************************************************************************************************ */
  log(paymentID: string, port: string): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    var obj = {
      'address': 6871,
      'sum': this.amount,
      'user_id': this.user_id,
      'creator': this.creator,
      'amount': this.amount,
      'paymentID': paymentID,
      'port': port,
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.serverService.send_sms_charge();
          this._dialogRef.close();
          this.message(false, "", 1, this.messageService.close(this.lang));
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  close() {
    this._dialogRef.close();
  }
  //**************************************************
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) {
      this.loading = false;
    }
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 5000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}

class PaymentResponse {
  success: boolean;
  clientSecret?: string;
  errorMessage?: string;
  orderID: string;
  constructor(success: boolean, clientSecret?: string, errorMessage?: string) {
    this.success = success;
    this.clientSecret = clientSecret;
    this.errorMessage = errorMessage;
  }
}
