import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModulesComponent } from '../../service/modules/modules.component';
import { ServerService } from '../../service/server/server.service';
import { UserVideoComponent } from '../user-video/user-video.component';
import { UserContentComponent } from '../user-content/user-content.component';
import { MessageService } from '../../service/message/message.service';

@Component({
  selector: 'app-user-detaile',
  templateUrl: './user-detaile.component.html',
  styleUrls: ['./user-detaile.component.scss']
})
export class UserDetaileComponent implements OnInit, OnDestroy {
  public user_info: any = JSON.parse(<any>localStorage.getItem('user_info'));
  public server: string = this.serverService.get_server();
  public server_main: string = this.serverService.get_server_main();
  public site: string = this.serverService.get_site();
  public lang: any = JSON.parse(<any>localStorage.getItem('lang'));
  public dir: any = JSON.parse(<any>localStorage.getItem('dir'));
  public loading: boolean = false;
  public gropus: number = 0;
  public search_array: any = [];
  public list_service: any = [];
  public list_service_select: any = [];
  public list_sgroup: any = [];
  public list_sgroup_id: any = [];
  public subscription: Subscription | any;
  public form1: FormGroup | any;
  public type_task!: number;
  public user_id!: number;
  public ref!: string
  public result!: any;
  public id!: number;
  public i!: number;
  public code!: number;
  public title!: string;
  public creator!: number;
  public level: number;
  public expire!: boolean;
  public access_service!: number;
  public similar_admin!: number;
  public default_level2 = "268,255,252,242,161,221,231,223,231,230,232,226,227,225,222,201,197,195,173,228,229,169,167,164,163,160,157,154,207,205,199,272,273,171,219,253,10,155,212,235,274,204,203,202,198,224,278,279,275,276,277,271,181,178,177,228,193";
  public default_repairman = "255,242,161,230,232,221,223,231,225,226,227,222,207,205,204,203,202,201,199,272,273,198,197,195,194,224,278,279,275,276,277,271,188,185,181,179,178,177,175,173,228,229,172,171,170,169,167,164,163,162,160,157,154,10,155,212,235,274,228,193";
  public default_repairman2 = "255,242,219,161,230,232,221,223,231,225,226,227,222,207,205,204,203,202,201,199,272,273,198,197,195,194,224,278,279,275,276,277,271,188,185,181,179,178,177,175,173,228,229,172,171,170,169,167,164,163,162,160,157,154,10,155,212,235,274,228,193";
  public default_colleague = "255,242,221,161,223,231,225,226,227,222,207,205,204,203,202,199,272,273,198,197,194,224,278,279,275,276,277,271,188,185,181,179,178,177,173,228,229,171,170,169,167,160,157,228,193";

  constructor(
    public serverService: ServerService,
    public router: Router,
    public matSnackBar: MatSnackBar,
    public messageService: MessageService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialog_data: any,
    public matDialogRef: MatDialogRef<UserDetaileComponent>) {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
    if (dialog_data) {
      this.type_task = dialog_data.type_task;
      this.level = dialog_data.level;
      this.create_form1();
      this.id = dialog_data.id;
      this.creator = dialog_data.creator;
      this.ref = dialog_data.ref;
      this.code = dialog_data.code;
      this.gropus = dialog_data.gropus;
      this.i = dialog_data.i;
      if (this.type_task == 1) {
        this.list_service_select = "";
        this.check_access(5, 1);
      }
      if (this.type_task == 2) {
        this.check_access(4, 1);
      }
      if (this.type_task == 3) {
        this.form1.patchValue({
          title: dialog_data.search_array.title,
          group_title: dialog_data.search_array.group_title,
          group: dialog_data.search_array.group,
          login_service_title: dialog_data.search_array.login_service_title,
          login_service: dialog_data.search_array.login_service,
          sex_title: dialog_data.search_array.sex_title,
          sex: dialog_data.search_array.sex,
          guilds_title: dialog_data.search_array.guilds_title,
          guilds: dialog_data.search_array.guilds,
          cellphone: dialog_data.search_array.cellphone,
          phone: dialog_data.search_array.phone,
          user: dialog_data.search_array.user,
          email: dialog_data.search_array.email,
          code_meli: dialog_data.search_array.code_meli,
          economic_code: dialog_data.search_array.economic_code,
          code_posti: dialog_data.search_array.code_posti,
          site: dialog_data.search_array.site,
          address: dialog_data.search_array.address1,
          comment: dialog_data.search_array.comment,
          comment2: dialog_data.search_array.comment2,
          status_id: dialog_data.search_array.status_id,
          status_title: dialog_data.search_array.status_title,
          state_title: dialog_data.search_array.state_title,
          state: dialog_data.search_array.state,
          country_title: dialog_data.search_array.country_title,
          country: dialog_data.search_array.country,
          currency_title: dialog_data.search_array.currency_title,
          currency: dialog_data.search_array.currency,
          store: dialog_data.search_array.store,
          expire_date: dialog_data.search_array.expire_date,
          setting_menu: dialog_data.search_array.setting_menu,
          number_access: dialog_data.search_array.number_access,
        })
      }
    }
  }//end consructor
  ngOnInit() {
    if (this.user_info) {
      this.user_id = this.user_info.user_id;
    }
  }
  create_form1() {
    this.form1 = new FormGroup({
      'title': new FormControl(null),
      'store': new FormControl(null),
      'sex_title': new FormControl(null),
      'sex': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'guilds_title': new FormControl(null),
      'guilds': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'amount': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'state_title': new FormControl(null),
      'state': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'country_title': new FormControl(null),
      'country': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'currency_title': new FormControl(null),
      'currency': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'login_service_title': new FormControl(null),
      'login_service': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'user': new FormControl(null),
      'code_meli': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'code_posti': new FormControl(null),
      'economic_code': new FormControl(null, [Validators.pattern('[0-9]{1,}')]),
      'email': new FormControl(null, [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
      'cellphone': new FormControl(null),
      'group': new FormControl(null),
      'group_title': new FormControl(null),
      'phone': new FormControl(null, [Validators.pattern('[0-9-]{1,}')]),
      'site': new FormControl(null),
      'status_id': new FormControl(null),
      'status_title': new FormControl(null),
      'address': new FormControl(''),
      'comment': new FormControl(null),
      'comment2': new FormControl(null),
      'user_date': new FormControl(null),
      'admin': new FormControl(null),
      'password': new FormControl(null),
      'expire_date': new FormControl(null),
      'setting_menu': new FormControl(null),
      'number_access': new FormControl(2),
    });
    if (this.type_task == 1) {
      this.form1.patchValue({
        'amount': 50
      })
      if (this.level == 1) {
        this.form1.patchValue({
          admin: 1
        })
      }
    }
    if (this.type_task == 1 || this.type_task == 2) {
      this.form1.controls['sex'].setValidators([Validators.required]);
      this.form1.controls['title'].setValidators([Validators.required]);
      this.form1.controls['group'].setValidators([Validators.required]);
      this.form1.controls['status_id'].setValidators([Validators.required]);
      this.form1.controls['expire_date'].setValidators([Validators.required]);
    }
  }
  check_access(type_task: any, excel: number): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    if (!this.form1.valid && (this.type_task == 1 || this.type_task == 2)) {
      return false;
    }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { 'address': 6, type: 'user', user_id: this.user_id, id: this.code, lang: this.lang }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          this.creator = res['creator'];
          this.similar_admin = res['similar_admin'];
          this.level = res['level'];
          if (this.similar_admin == 1) {
            this.creator = 2445;
            this.level = 1;
          }
          this.access_service = res['result'][0].count;
          this.title = res['result'][0].title;
          this.check_access2(type_task, excel, res['result'][0].count);
        }//end if
        else {
          this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  check_access2(type_task: any, excel: number, access_service: number): any {
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', { 'address': 6, user_id: this.creator, id: this.code }).subscribe(
      (res: any): any => {
        if (res['status'] == 1) {
          if (res['result'][0].count == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          else if (this.level == 3 && access_service == 0) {
            this.message(true, this.messageService.access(this.lang), 1, this.messageService.close(this.lang));
            return false;
          }
          this.expire = res['expire'];
          this.loading = false;
          if (type_task == 1) {
            if (this.expire == true) {
              this.message(true, this.messageService.expire(this.lang), 1, this.messageService.close(this.lang));
              return false;
            } else {
              this.check_for_access();
            }
          }
          if (type_task == 2) { this.check_for_access(); }
          if (type_task == 3) { this.search(excel); }
          if (type_task == 4) { this.get_data(); }
          if (type_task == 5) {
            this.get_status();
            this.get_default_group();
            this.get_default_sex();
            this.get_default_services();
          }
        }//end if
        else {
          this.message(true, this.messageService.access2(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_number(event: any) {
    this.serverService.get_number(event)
  }
  search(excel: number) {
    this.matDialogRef.close({
      title: this.form1.value.title,
      group_title: this.form1.value.group_title,
      group: this.form1.value.group,
      login_service_title: this.form1.value.login_service_title,
      login_service: this.form1.value.login_service,
      sex_title: this.form1.value.sex_title,
      sex: this.form1.value.sex,
      guilds_title: this.form1.value.guilds_title,
      guilds: this.form1.value.guilds,
      cellphone: this.form1.value.cellphone,
      phone: this.form1.value.phone,
      user: this.form1.value.user,
      email: this.form1.value.email,
      code_meli: this.form1.value.code_meli,
      economic_code: this.form1.value.economic_code,
      code_posti: this.form1.value.code_posti,
      site: this.form1.value.site,
      address1: this.form1.value.address,
      comment: this.form1.value.comment,
      comment2: this.form1.value.comment2,
      status_id: this.form1.value.status_id,
      status_title: this.form1.value.status_title,
      state: this.form1.value.state,
      state_title: this.form1.value.state_title,
      country: this.form1.value.country,
      country_title: this.form1.value.country_title,
      currency: this.form1.value.currency,
      currency_title: this.form1.value.currency_title,
      store: this.form1.value.store,
      expire_date: this.form1.value.expire_date,
      number_access: this.form1.value.number_access,
      setting_menu: this.form1.value.setting_menu,
      export_excel: excel
    })
  }
  get_data(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1660, id: this.id, lang: this.lang }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            'title': res['result'][0].user_title,
            'store': res['result'][0].user_store,
            'sex_title': res['result'][0].user_sex_title,
            'sex': res['result'][0].user_sex,
            'guilds_title': res['result'][0].site_guilds_title,
            'guilds': res['result'][0].site_guilds_id,
            'login_service_title': res['result'][0].site_services_title,
            'login_service': res['result'][0].site_services_id,
            'state_title': res['result'][0].site_state_title,
            'state': res['result'][0].site_state_id,
            'country_title': res['result'][0].user_country_title,
            'country': res['result'][0].user_country_id,
            'currency_title': res['result'][0].site_currency_title,
            'currency': res['result'][0].site_currency_id,
            'user': res['result'][0].user_userName,
            'cate': res['result'][0].user_cate,
            'email': res['result'][0].user_email,
            'code_meli': res['result'][0].user_code_meli,
            'economic_code': res['result'][0].user_economic_code,
            'code_posti': res['result'][0].user_code_posti,
            'cellphone': res['result'][0].user_cellPhone,
            'phone': res['result'][0].user_phone,
            'site': res['result'][0].user_site,
            'address': res['result'][0].user_address,
            'comment': res['result'][0].user_comment,
            'comment2': res['result'][0].user_comment2,
            'group': res['result'][0].user_group,
            'group_title': res['result'][0].user_groups_title,
            'status_id': res['result'][0].user_status,
            'status_title': res['result'][0].status_title,
            'password': res['result'][0].user_repassword,
            'expire_date': res['result'][0].user_expire_date,
            'setting_menu': res['result'][0].user_setting_menu,
            'number_access': res['result'][0].user_number_access,
            'admin': res['result'][0].user_admin,
            'amount': res['result'][0].user_amount,
          }
          );
          this.list_service_select = res['result'][0].user_services;
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.get_sgroup();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  save() {
    if (this.type_task == 1) {
      this.check_for_access();
    }
    else if (this.type_task == 2) {
      this.check_for_access();
    }
  }
  insert(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      'address': 6643,
      "user_id": this.user_id,
      'title': this.form1.value.title,
      'state': this.form1.value.state,
      'country': this.form1.value.country,
      'currency': this.form1.value.currency,
      'store': this.form1.value.store,
      'group': this.form1.value.group,
      'sex': this.form1.value.sex,
      'guilds': this.form1.value.guilds,
      'login_service': this.form1.value.login_service,
      'cellphone': this.form1.value.cellphone,
      'status_id': this.form1.value.status_id,
      'phone': this.form1.value.phone,
      'user': this.form1.value.user,
      'email': this.form1.value.email,
      'code_meli': this.form1.value.code_meli,
      'economic_code': this.form1.value.economic_code,
      'code_posti': this.form1.value.code_posti,
      'site': this.form1.value.site,
      'comment': this.form1.value.comment,
      'comment2': this.form1.value.comment2,
      'address1': this.form1.value.address,
      'password': this.form1.value.password,
      'creator': this.creator,
      'level': this.level,
      'expire_date': this.form1.value.expire_date,
      'setting_menu': this.form1.value.setting_menu,
      'number_access': this.form1.value.number_access,
      'admin': this.form1.value.admin,
      'amount': this.form1.value.amount,
      'list_service_select': this.list_service_select
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        //this.serverService.send_reception_repairman();
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
          this.serverService.send_group();
        }//end if
        else if (res['status'] == 4) {
          var message = "Username and mobile number must be unique";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end insert
  check_for_access(): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    var obj = {
      address: 6468,
      creator: this.creator, id: this.id, level: this.level,
      group: this.form1.value.group
    }
    this.subscription = this.serverService.post_address(this.server_main, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res.result[0].number_access > res.result[0].count || res.result[0].access == false || this.level == 1) {
            if (this.type_task == 2) this.update();
            if (this.type_task == 1) this.insert();
          }
          else {
            var message = "The number of authorized accesses for users has been reached";
            this.message(true, this.messageService.message(this.lang, message, ''), 1, this.messageService.close(this.lang));
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  update(): any {
    var x: any;
    if (typeof this.list_service_select == 'string') {
      x = this.list_service_select.split(",").map((index: any) => {
        return parseInt(index)
      })
    } else {
      x = this.list_service_select;
    }
    var obj = {
      'address': 6642,
      "user_id": this.user_id,
      'title': this.form1.value.title,
      'store': this.form1.value.store,
      'group': this.form1.value.group,
      'sex': this.form1.value.sex,
      'guilds': this.form1.value.guilds,
      'state': this.form1.value.state,
      'country': this.form1.value.country,
      'currency': this.form1.value.currency,
      'login_service': this.form1.value.login_service,
      'cellphone': this.form1.value.cellphone,
      'status_id': this.form1.value.status_id,
      'phone': this.form1.value.phone,
      'user': this.form1.value.user,
      'email': this.form1.value.email,
      'code_meli': this.form1.value.code_meli,
      'economic_code': this.form1.value.economic_code,
      'code_posti': this.form1.value.code_posti,
      'site': this.form1.value.site,
      'comment': this.form1.value.comment,
      'comment2': this.form1.value.comment2,
      'address1': this.form1.value.address,
      'password': this.form1.value.password,
      'id': this.id,
      'list_service_select': this.list_service_select,
      'expire_date': this.form1.value.expire_date,
      'setting_menu': this.form1.value.setting_menu,
      'creator': this.creator,
      'number_access': this.form1.value.number_access,
      'admin': this.form1.value.admin,
      'amount': this.form1.value.amount
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', obj).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.result = res['result'][0];
          this.matDialogRef.close(
            { result: this.result }
          )
          this.message(false, "", 1, this.messageService.close(this.lang));
          this.serverService.send_group();
        }//end if
        else if (res['status'] == 4) {
          var message = "Username must be unique";
          this.message(true, this.messageService.message(this.lang, '', message), 1, this.messageService.close(this.lang));
        }
        else {
          this.message(true, this.messageService.erorr_in_save(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    );
  }//end update
  get_sgroup() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6179 }).subscribe(
      (res: any) => {
        this.list_sgroup = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            this.list_sgroup_id[res['result'][i].site_software_id] = new Array();
            this.list_sgroup.push(res['result'][i]);
          }//end for
          this.get_services();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_services(): any {
    var selected_array: any = [];
    if (this.list_service_select) {
      selected_array = this.list_service_select.split(",").map((items) => {
        return parseInt(items);
      });
    }
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6138, user_id: this.user_id, level: this.level, creator: this.creator }).subscribe(
      (res: any) => {
        this.list_service = [];
        if (res['status'] == 1) {
          for (var i = 0; i < res['num']; i++) {
            for (var j = 0; j < this.list_sgroup.length; j++) {
              if (this.list_sgroup[j].site_software_id == res['result'][i].site_services_sgroup) {
                this.list_sgroup_id[this.list_sgroup[j].site_software_id].push(res['result'][i]);
              }
            }
            if (selected_array.indexOf(res['result'][i].site_services_id) != -1) {
              res['result'][i].checked = true;
              res['result'][i].disabled = false;
            }
            this.list_service.push(res['result'][i]);
          }//end for
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  change_select_service() {
    this.list_service_select = [];
    var service_array = <any>document.getElementsByName("service[]");
    for (var i = 0; i < service_array.length; i++) {
      if (service_array[i].checked == true) {
        this.list_service_select.push(service_array[i].value);
      }
    }
    this.list_service_select = this.list_service_select.map((items: any) => {
      return parseInt(items);
    });
  }
  open_content(id: number) {
    this.dialog.open(UserContentComponent, {
      width: '35rem',
      height: 'auto',
      data: { id: id, type_task: 2, code: this.code }
    })
  }
  //************************************************************************************************ */
  get_status() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1 }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            status_id: res['result'][0].status_id,
            status_title: res['result'][0].status_title,
          })
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_sex() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1733, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            sex: res['result'][0].user_sex_id,
            sex_title: res['result'][0].user_sex_title,
          })
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_group() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 1972, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            group: res['result'][0].user_groups_id,
            group_title: res['result'][0].user_groups_title,
          });
          if (this.level == 2 || this.level == 3) {
            if (res['result'][0].user_groups_colleague == 1) {
              this.list_service_select = this.default_colleague;
            }
            else if (res['result'][0].user_groups_repairman == 1) {
              this.list_service_select = this.default_repairman;
            }
          }
          else if (this.level == 1) {
            this.list_service_select = this.default_level2;
          }
          this.get_sgroup();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_user_group(id: any): any {
    if (this.serverService.check_internet() == false) {
      this.message(true, this.messageService.internet(this.lang), 1, this.messageService.close(this.lang));
      return false;
    }//end if
    else { this.matSnackBar.dismiss(); }
    this.loading = true;
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6209, id: id }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          this.form1.patchValue({
            group: res['result'][0].user_groups_id,
            group_title: res['result'][0].user_groups_title,
          });
          this.list_service_select = '';
          if (this.level == 2 || this.level == 3) {
            if (res['result'][0].user_groups_colleague == 1) {
              this.list_service_select = this.default_colleague;
            }
            if (res['result'][0].user_groups_repairman == 1) {
              this.list_service_select = this.default_repairman;
            }
          }
          else if (this.level == 1) {
            this.list_service_select = this.default_repairman2;
          }
          this.get_sgroup();
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  get_default_services() {
    this.subscription = this.serverService.post_address(this.server, 'new_address', { address: 6721, creator: this.creator }).subscribe(
      (res: any) => {
        if (res['status'] == 1) {
          if (res['num'] == 1) {
            this.form1.patchValue({
              login_service: res['result'][0].site_services_id,
              login_service_title: res['result'][0].site_services_title,
            });
          }
        }//end if
        else {
          this.message(true, this.messageService.erorr_in_load(this.lang), 1, this.messageService.close(this.lang));
        }
      }
    )
  }
  load_services(id: number): any {
    var where;
    var code_id;
    if (id == 155) {
      code_id = 6470;
    }
    if (id == 161) {
      code_id = 6623;
    }
    if (id == 213) {
      code_id = 6568;
    }
    const dialogRef = this.dialog.open(ModulesComponent, {
      width: '40rem',
      height: 'auto',
      data: {
        id: id, where: where, creator: this.creator, code_id: code_id
        , obj: { level: this.level }
      }
    });
    dialogRef.afterClosed().subscribe(
      (result) => {
        if (result) {
          if (id == 155) {
            this.form1.patchValue({
              'group_title': result.title,
              'group': result.id
            });
            this.get_user_group(result.id);
          }
          if (id == 26) {
            this.form1.patchValue({
              'status_title': result.title,
              'status_id': result.id
            })
          }
          if (id == 10) {
            this.form1.patchValue({
              'sex_title': result.title,
              'sex': result.id
            })
          }
          if (id == 258) {
            this.form1.patchValue({
              'guilds_title': result.title,
              'guilds': result.id
            })
          }
          if (id == 233) {
            this.form1.patchValue({
              'state_title': result.title,
              'state': result.id
            })
          }
          if (id == 259) {
            this.form1.patchValue({
              'country_title': result.title,
              'country': result.id
            })
          }
          if (id == 156) {
            this.form1.patchValue({
              'currency_title': result.title,
              'currency': result.id
            })
          }
          if (id == 213) {
            this.form1.patchValue({
              'login_service_title': result.title,
              'login_service': result.id
            })
          }
        }
      }
    )
  }
  reset_input(id: number) {
    if (id == 155) {
      this.form1.patchValue({
        'group_title': null,
        'group': null
      })
    }
    if (id == 26) {
      this.form1.patchValue({
        'status_title': null,
        'status_id': null
      })
    }
    if (id == 10) {
      this.form1.patchValue({
        'sex_title': null,
        'sex': null
      })
    }
    if (id == 258) {
      this.form1.patchValue({
        'guilds_title': null,
        'guilds': null
      })
    }
    if (id == 233) {
      this.form1.patchValue({
        'state_title': null,
        'state': null,
      })
    }
    if (id == 259) {
      this.form1.patchValue({
        'country_title': null,
        'country': null,
      })
    }
    if (id == 156) {
      this.form1.patchValue({
        'currency_title': null,
        'currency': null,
      })
    }
    if (id == 213) {
      this.form1.patchValue({
        'login_service_title': null,
        'login_service': null
      })
    }
  }
  close() {
    this.matDialogRef.close();
  }
  //**************************************************
  required(): boolean {
    if (this.type_task == 1 || this.type_task == 2) {
      return true;
    }
    else {
      return false;
    }
  }
  message(validation: boolean, message: string, type: number, action: string) {
    if (type == 1) this.loading = false;
    if (validation == true) {
      this.matSnackBar.open(message, action, { duration: 4000 });
    }//end if
    else {
      //this.matSnackBar.dismiss();
    }
  }//end 
  //*******************************************************************************
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }//end if
  }//end OnDestroy
}