<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <mat-progress-bar mode="indeterminate" [hidden]="!loading" class="detaile"></mat-progress-bar>
    {{ serverService.get_title(type_task,lang) }}
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<form [formGroup]="form1_group" (ngSubmit)="check_access(type_task,1)">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="group" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه </span>
                    <span *ngIf="lang == 2"> Category </span>
                    <span (click)="add_group()" class="add_icon" [hidden]="type_task == 3" [hidden]="readonly == true">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(223,1)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="group_title" (click)="load_services(223,1) "
                        [required]="required()" readonly [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="group" [hidden]="true" [required]="required()" readonly
                        [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="cate" class=' wh-label'>
                    <span *ngIf="lang == 1"> زیرگروه </span>
                    <span *ngIf="lang == 2"> Subcategory </span>
                    <span (click)="add_cate()" class="add_icon" [hidden]="type_task == 3 || readonly == true">
                        <mat-icon class="add-icon fright" [ngClass]="{fleft:dir=='rtl'}">
                            add </mat-icon>
                    </span>
                    <span (click)="reset_input(184,1)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">
                            close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cate_title" (click)="load_services(184,1) "
                        [required]="required()" readonly [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="cate" [hidden]="true" [required]="required()" readonly
                        [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان کالا </span>
                    <span *ngIf="lang == 2"> Product Name </span>
                    <input class='wh-input' formControlName="title" autocomplete="off" [required]="required()"
                        [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> موجودی اول دوره </span>
                    <span *ngIf="lang == 2"> Initial Stock </span>
                    <input class='wh-input' formControlName="inventory" autocomplete="off" [required]="required()"
                        [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="true">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> واحد شمارش </span>
                    <span *ngIf="lang == 2"> Counting unit </span>
                    <span (click)="reset_input(211,1)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="weight_title" (click)="load_services(211,0) " readonly
                        [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="weight" [hidden]="true" readonly
                        [attr.disabled]="readonly">
                </label>
            </div>


            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت خرید اول دوره </span>
                    <span *ngIf="lang == 2"> Initial Purchase Price </span>
                    <input class='wh-input' formControlName="price" autocomplete="off" [required]="required()"
                        currencyMask [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> قیمت فروش </span>
                    <span *ngIf="lang == 2"> Selling Price </span>
                    <input class='wh-input' formControlName="price2" autocomplete="off" [required]="required()"
                        currencyMask [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title" class='wh-label'>
                    <span *ngIf="lang == 1"> کد کالا </span>
                    <span *ngIf="lang == 2"> Product Code </span>
                    <input class='wh-input' formControlName="coding" autocomplete="off" [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="true">
                <label for="sort" class='wh-label'>
                    <span *ngIf="lang == 1"> ترتیب </span>
                    <span *ngIf="lang == 2"> Sort </span>
                    <input class='wh-input' formControlName="sort" autocomplete="off" [attr.disabled]="readonly"
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>

            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="type_task == 3">
                <label for="status_id" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description </span>
                    <textarea style="height: 5rem;" class="wh-input" formControlName="comment"
                        [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                    </textarea>
                </label>
            </div>

        </div>

        <div class="row" *ngIf="wharehouse == true">
            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="group2" class='wh-label'>
                    <span *ngIf="lang == 1"> گروه فروشگاه : </span>
                    <span *ngIf="lang == 2"> Store group : </span>
                    <span (click)="reset_input(223,2)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="group_title2" (click)="load_services(223,2)" readonly
                        [attr.disabled]="readonly" [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="group2" [hidden]="true" readonly
                        [attr.disabled]="readonly">
                </label>
            </div>

            <div class='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="cate2" class='wh-label'>
                    <span *ngIf="lang == 1"> زیر گروه فروشگاه : </span>
                    <span *ngIf="lang == 2"> Store subgroup : </span>
                    <span (click)="reset_input(184,2)" [hidden]="readonly == true">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="cate_title2" (click)="load_services(184,2)" readonly
                        [attr.disabled]="readonly" [required]="form1_group.get('group2').value && required()"
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="cate2" [hidden]="true" readonly [attr.disabled]="readonly"
                        [required]="form1_group.get('group2').value  && required()">
                </label>
            </div>

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="title2" class='wh-label'>
                    <span *ngIf="lang == 1"> عنوان در فروشگاه : </span>
                    <span *ngIf="lang == 2"> Name at store : </span>
                    <input class="wh-input" formControlName="title2"
                        [required]="form1_group.get('group2').value  && required()" [ngClass]="{'en':dir == 'ltr'}"
                        placeholder="نیازی به ذکر عنوان زیرگروه در این فیلد نیست">
                    {{ form1_group.get('cate_title2').value }} {{ form1_group.get('title2').value }}
                </label>
            </div>

            <!-- ******************************************************************************************************** -->
            <div class="row" [hidden]="type_task == 3">
                <div class="row file">
                    <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 wh-label"
                            style="text-align: right;padding: 0px !important;">
                            در این قسمت می توانید عکس ها را وارد نمائید.
                        </div>
                    </div>
                    <div class="row" style="height:5px;padding: 0px !important;">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" style="padding: 0px !important;"
                            [hidden]="!loading_file">
                            <mat-progress-bar mode="indeterminate" class="detaile">
                            </mat-progress-bar>
                        </div>
                    </div>


                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 pe1" style="text-align:center;padding:0px"
                        [ngClass]="{en1:lang==2}">
                        <form [dropzone]="config" class="dropzone dz-started cursor" id="my-awesome-dropzone"
                            (success)="onUploadSuccess($event)">
                            <input type="" name="code" value="{{ id }}" style="display:none">
                            <input type="" name="user_id" value="{{ user_id }}" style="display:none">
                            <input type="" name="type" value="{{ 3 }}" style="display:none">
                            <input type="" name="site" value="{{ site }}" style="display:none">
                            <input type="" name="token" value="{{ token }}" style="display:none">
                            <!-- <input type="" name="request_status_number" value="{{ request_status_number }}"
                                style="display:none"> -->

                            <div class="dz-preview dz-processing dz-image-preview dz-success dz-complete box box-right deopzone"
                                *ngFor="let file of list_load_file;let i = index" id="fileInput">
                                <div class="dz-image" style="border:solid 1px #ccc">
                                    <a href='{{ file.wharehouse_items_attach_site }}/{{ file.wharehouse_items_attach_name }}'
                                        target="_blank">
                                        <span
                                            *ngIf="file.wharehouse_items_attach_type == 'image/jpeg' || file.wharehouse_items_attach_type == 'image/gif' ||  file.wharehouse_items_attach_type == 'image/png' ">
                                            <img data-dz-thumbnail="" alt="{{ file.wharehouse_items_attach_title }}"
                                                src="{{ file.wharehouse_items_attach_site }}/{{ file.wharehouse_items_attach_name }}">
                                        </span>
                                        <span
                                            *ngIf="file.wharehouse_items_attach_type == 'application/msword' || file.wharehouse_items_attach_type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ">
                                            <i class="far fa-file-word icone"></i>
                                        </span>
                                        <span
                                            *ngIf="file.wharehouse_items_attach_type == 'application/vnd.ms-excel' || file.wharehouse_items_attach_type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ">
                                            <i class="fas fa-file-excel icone" style='color:green'></i>
                                        </span>
                                        <span *ngIf="file.wharehouse_items_attach_type == 'application/pdf' ">
                                            <i class="far fa-file-pdf icone" style='color:red'></i>
                                        </span>
                                        <span *ngIf="file.wharehouse_items_attach_type == 'application/zip' ">
                                            <i class="fas fa-file-archive icone"></i>
                                        </span>
                                        <span
                                            *ngIf="file.wharehouse_items_attach_type == 'video/mp4' || file.wharehouse_items_attach_type == 'video/x-matroska';else other ">
                                            <i class="fas fa-file-video icone" style='color:black'></i>
                                        </span>
                                        <ng-template #other>
                                            <i class="fas fa-file-alt icone" style='color:black'></i>
                                        </ng-template>
                                    </a>
                                </div><!-- dz-image -->
                                <div class="dz-details">
                                    <div style="display:inline;float:left">
                                        <span (click)="delete_file(i,file.wharehouse_items_attach_id)"
                                            *ngIf="file.wharehouse_items_attach_request_status == request_status_number">
                                            <i class="material-icons icon" style='font-size:1.2rem'>delete_forever</i>
                                        </span>
                                    </div>
                                    <div class="dz-size">
                                        <span data-dz-size=""><strong>{{ file.wharehouse_items_attach_size }}</strong>
                                            KB</span>
                                    </div><!-- dz-size-->
                                    <div class="dz-filename">
                                        <span data-dz-name="" class="pe1">{{ file.wharehouse_items_attach_title |
                                            substr:0:10
                                            }}</span>
                                    </div><!-- dz-filename-->
                                </div> <!-- dz-detaile-->
                                <div class="dz-progress">
                                    <span class="dz-upload" data-dz-uploadprogress="" style="width: 100%;"></span>
                                </div><!-- dz-progress -->
                                <div class="dz-error-message">
                                    <span data-dz-errormessage=""></span>
                                </div><!-- dz-error-message -->
                            </div><!-- dz-preview dz-processing dz-image-preview dz-success dz-complete -->
                        </form><!-- dropzone dz-started -->
                    </div><!-- col-md-12 -->
                </div><!-- row file -->
            </div>

        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions" *ngIf="readonly != true">
        <button type="submit" [disabled]="!form1_group.valid" class="btn_pe" [ngClass]="{btn_en:lang == 2}"
            *ngIf="type_task == 1 || type_task == 2">
            <span *ngIf="lang == 2">Save</span>
            <span *ngIf="lang == 1">ذخیره</span>
        </button>
        <button type="submit" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3">
            <span *ngIf="lang == 2">Filter</span>
            <span *ngIf="lang == 1">فیلتر</span>
        </button>
    </div>
</form>