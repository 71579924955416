<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span *ngIf="lang == 1"> تراکنش مالی</span>
    <span *ngIf="lang == 2"> Financial transaction </span>
    <span (click)="close(result,'close')">
        <mat-icon class="close">close</mat-icon>
    </span>
    <div class="video_div1" (click)="open_video()" *ngIf="video" style="float: left;">
        <mat-icon class="video">smart_display</mat-icon>
    </div>
</div>

<form [formGroup]="form1_group">
    <div mat-dialog-content class="matDialogContent">
        <div class="row pe1">

            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="transfer == 1">
                <label for="payment_type" class='wh-label'>
                    <span *ngIf="lang == 1"> نوع تراکنش </span>
                    <span *ngIf="lang == 2"> Payment type </span>
                    <span (click)="reset_input(177,'')">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="payment_type_title" (click)="load_services(177,'') "
                        [required]="required() && transfer == 0" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="payment_type" [hidden]="true"
                        [required]="required() && transfer == 0" readonly>
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="amount" class='wh-label'>
                    <span *ngIf="lang == 1"> مبلغ </span>
                    <span *ngIf="lang == 2"> Amount </span>
                    <span *ngIf="lang == 1 && payment_bank == true"> تراکنش بانکی </span>
                    <span *ngIf="lang == 2 && payment_bank == false"> Bank transaction </span>
                    <input class='wh-input' formControlName="amount" autocomplete="off" currencyMask required
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}"
                [hidden]="type_task == 3 || payment_bank != 1 || (document != 16 && document != 7)">
                <label for="fee" class='wh-label'>
                    <span *ngIf="lang == 1"> مبلغ کارمزد بانک </span>
                    <span *ngIf="lang == 2"> Bank fee </span>
                    <input class='wh-input' formControlName="fee" autocomplete="off" currencyMask
                        [ngClass]="{'en':dir == 'ltr'}">
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="payment_bank == false && type_task != 3">
                <label for="bank" class='wh-label'>
                    <span *ngIf="lang == 1"> بانک </span>
                    <span *ngIf="lang == 2"> Bank </span>
                    <span (click)="reset_input(178,'')">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="bank_title" (click)="load_services(178,'')"
                        [required]="(payment_bank == true || transfer == 1)" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="bank" [hidden]="true"
                        [required]="(payment_bank == true || transfer == 1)" readonly>
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="payment_bank == false && type_task != 3">
                <label for="account_title" class='wh-label'>
                    <span *ngIf="lang == 1"> شماره حساب </span>
                    <span *ngIf="lang == 2"> Account Number </span>
                    <span (click)="reset_input(180,'')">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="account_title" (click)="load_services(234,'')"
                        [required]="(payment_bank == true || transfer == 1)" readonly [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="account" [hidden]="true"
                        [required]="(payment_bank == true || transfer == 1)" readonly>
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="type_task != 3 || code == 202 || code == 203  ">
                <label for="user" class='wh-label'>
                    <span *ngIf="lang == 1"> نام طرف حساب : </span>
                    <span *ngIf="lang == 2"> Client Name </span>
                    <span (click)="reset_input(161,1)">
                        <mat-icon class="close" class="input_search input_search_pe"
                            [ngClass]="{input_search_en:lang==2}">close</mat-icon>
                    </span>
                    <input class="wh-input" formControlName="user_title" (click)="load_services(161,1) " readonly
                        [ngClass]="{'en':dir == 'ltr'}">
                    <input class="wh-input" formControlName="user" [hidden]="true" readonly>
                </label>
            </div>
            <div class='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}" [hidden]="true">
                <label for="date" class="wh-label">
                    <span>Transaction date</span>
                    <div style="width:100%">
                        <input type="date" class="wh-input" matInput formControlName="date"
                            [required]="payment_bank == true && type_task != 3" autocomplete="off"
                            [ngClass]="{'en':dir == 'ltr'}"
                            [ngStyle]="{'text-align' : dir == 'ltr' ? 'left' : 'right'}">
                    </div>
                </label>
            </div>
            <div class='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div_panding'
                [ngClass]="{'full-left':dir == 'ltr'}">
                <label for="comment" class='wh-label'>
                    <span *ngIf="lang == 1"> توضیحات </span>
                    <span *ngIf="lang == 2"> Description : </span>
                    <textarea style="height:5rem" class="wh-input" formControlName="comment"
                        [ngClass]="{'en':dir == 'ltr'}">

                    </textarea>
                </label>
            </div>
        </div>
    </div>

    <div mat-dialog-actions class="matDialogActions">
        <button [disabled]="!form1_group.valid || loading || !form1_group.get('amount').value > 0" class="btn_pe"
            (click)="save(type_task,1,'close')" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 1 || type_task == 2">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
        <button type="button" class="btn_pe" [ngClass]="{btn_en:lang == 2}" *ngIf="type_task == 3" (click)="search(1)">
            {{ serverService.get_event_title(type_task,lang) }}
        </button>
    </div>
</form>