<div mat-dialog-title class="matDialogTitle" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <div class="div_loading">
        <div class="my-container" [hidden]="!loading">
            <mat-progress-bar mode="indeterminate" class="detaile"></mat-progress-bar>
        </div>
    </div>
    <span>
        <span>{{ title }}</span>
    </span>
    <span (click)="close()">
        <mat-icon class="close">close</mat-icon>
    </span>
</div>

<div mat-dialog-content class="matDialogContent" style="overflow-y: hidden;">
    <div class="row dflex direction_ltr" style="display: flex !important;">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 padding0">
            <input class="search_box" name="search_all" [(ngModel)]="search_all" (keyup)="check_access(4,1,'',1,2)"
                placeholder="{{ serverService.get_title(3,lang) }}..." autocomplete="off" style="width:100%">
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 padding0">
            <div class="service_pager">
                <span>
                    <button class="new1" (click)='open_detaile(0,1,0)'>
                        <span>{{ serverService.get_title(1,lang) }}</span>
                    </button>
                </span>
            </div>
        </div>
    </div>
    <div class="content-body control-content-body" style="max-height: 21rem;">
        <mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="row">
                <mat-header-cell *matHeaderCellDef style='max-width:40px'>
                    <span>#</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:40px'>{{ i
                    + 1
                    }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="title">
                <mat-header-cell *matHeaderCellDef>
                    <span>Title</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_type_id)">
                    {{ x.finance_cost_type_title }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="cost">
                <mat-header-cell *matHeaderCellDef [hidden]="true">
                    <span>Base price</span>
                </mat-header-cell>
                <mat-cell [hidden]="true" *matCellDef="let x;let i = index"
                    (click)="open_detaile(i,2,x.finance_cost_type_id)">
                    {{ x.finance_cost_type_cost | currency:'':'' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>
                    <span>Status</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" (click)="open_detaile(i,2,x.finance_cost_type_id)">
                    <span>{{ x.status_title }}</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef style='max-width:100px'>
                    <span>Operation</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let x;let i = index" style='max-width:100px'>
                    <span (click)="check_access(3,0,x.finance_cost_type_id,0,1)"><i class="far fa-trash-alt icon"
                            style='margin:0px 4px;'></i></span>
                </mat-cell>
            </ng-container>

            <mat-header-row class="mat-header-row-dialog" *matHeaderRowDef="displayedColumns" color="primary">
            </mat-header-row>
            <mat-row class="mat-header-row-dialog" *matRowDef="let row;columns:displayedColumns;let i = index"
                (click)="mat_table_selectedRow = row" (mouseenter)="mat_table_hoverRow = row"
                [ngClass]="{'mat_table_selected': row === mat_table_selectedRow,mat_table_selected2:row.checked == true,'mat_table_hover': row === mat_table_hoverRow}"
                class="cursor"></mat-row>
        </mat-table>

        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                    <img src="../../../assets//images/preview.png">
                </div>
                <div class="preview2">{{ serverService.get_no_record_text(lang) }}</div>
            </div>
        </div>
    </div>
</div>