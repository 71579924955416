<div class="row right-menu-row1" style="margin-top:1rem">
    <ng-container *ngIf="show_record == true">
        <span *ngIf="list_groups.length > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor" *ngFor="let x of list_groups"
                (click)="go_to_group(x.user_groups_id)" [routerLinkActive]="['activeStatus']"
                [ngClass]="{activeStatus:groups_id == x.user_groups_id,div1_en:dir == 'ltr'}">
                <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">{{ x.user_groups_title }}</div>
                <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ x.count }}</span>
            </div>
        </span>
        <span *ngIf="(level == 2 || level == 3) && ( report == 'rstatus' || report == 'repairman' )">
            <ng-container *ngIf="level == 2">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe1 div2 cursor"
                    [ngClass]="{active2:report == 'rstatus'}" (click)="change_rstatus('rstatus')">
                    <span> Status </span>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pe1 div2 cursor"
                    [ngClass]="{active2:report == 'repairman'}" (click)="change_rstatus('repairman')">
                    <span> Repairmen</span>
                </div>
            </ng-container>
            <div style="padding-top: 4rem;" *ngIf="loading2 == true">
                <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>
            <ng-container *ngIf="report == 'rstatus' && loading2 == false">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    *ngFor="let x of list_rstatus" (click)="go_to_reception_with_rstatus(x.repairman_rstatus_id)"
                    [routerLinkActive]="['activeStatus']"
                    [ngClass]="{activeStatus:rstatus_id == x.repairman_rstatus_id,div1_en:dir == 'ltr',en1:dir == 'ltr'}">
                    <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">{{ x.repairman_rstatus_title }}</div>
                    <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ x.count }}</span>
                </div>
            </ng-container>
            <ng-container *ngIf="report == 'repairman'">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pe1 div1 cursor"
                    *ngFor="let x of list_repairman" [routerLinkActive]="['activeStatus']"
                    [ngClass]="{activeStatus:record_id == x.user_id,div1_en:dir == 'ltr',en1:dir == 'ltr'}"
                    (click)="go_to_reception_with_repairman(x.user_id)">
                    <div class="title" [ngClass]="{'title_en':dir == 'ltr'}">{{ x.user_title }}</div>
                    <span class="count" [ngClass]="{'count_en':dir == 'ltr'}">{{ x.count }}</span>
                </div>
            </ng-container>
            <div (click)="change_show_chart()">.</div>
        </span>
    </ng-container>
    <ng-container *ngIf="show_record == false">
        <div class="row" class="preview1" [hidden]="count_total > 0">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="preview2">
                    <span>There are no records</span>
                    <br>
                    ...
                </div>
            </div>
        </div>
    </ng-container>
</div>